<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Empowering Lives Through Lifesaving Acts</h4>
            <h2>Welcome to <span>Let's help</span></h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <p>At Blood Donation, we are dedicated to saving lives through the selfless act of blood donation. Every day, countless individuals rely on donated blood to recover from illness, injury, and surgery. Your decision to donate blood can make a profound difference in someone's life.</p>
                <p>
                    Join us in our mission to ensure a stable and safe blood supply for those in need. Whether you're a first-time donor or a regular supporter, your contribution is invaluable and deeply appreciated.
                </p>
            </div>
            <div class="col-lg-6">
                <img src="../../../../assets/img/Blood_DonationImages/home-blood.png" class="home_blood" />
            </div>
        </div>
        <p>Explore our website to learn more about the donation process, find donation centers near you, and discover how you can get involved in our lifesaving efforts.</p>
        <br />
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-solid fa-hand-holding-heart"></i>
                    </div>
                    <h3>A Ray Of Hope </h3>
                    <p>In a world often fraught with challenges and uncertainties, blood donation stands as a beacon of hope, illuminating the darkest of days with its lifesaving potential. Each donation represents a ray of hope, a chance for someone in need to find solace, healing, and renewed strength.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fa-solid fa-droplet"></i>
                    </div>
                    <h3>Blood Donation</h3>
                    <p>where every drop counts in the journey to save lives. We believe in the profound impact of each donation, as it holds the power to heal, restore, and uplift those in need. By donating blood, you become a lifeline for individuals battling illness, injury, and undergoing medical procedures.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fa-solid fa-handshake"></i>
                    </div>
                    <h3>Be A Support</h3>
                    <p> you play a vital role in raising awareness, organizing donation drives, and providing comfort to individuals and families affected by medical emergencies. Your dedication helps ensure a steady supply of blood for hospitals and patients in critical need.</p>
                  <br />  <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>


    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
