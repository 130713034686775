import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Route, Router } from '@angular/router';
//import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-requestform',
    templateUrl: './requestform.component.html',
    styleUrls: ['./requestform.component.scss']
})
export class RequestformComponent {
    public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    dropdownActive: boolean = false; unitsofblood: any = [];
    deptList4: any; selectedItem1: any;
    selectedGender: string | null = null;
    Startdate: any; fromDate: any; mindate: any; toDate: any;
    logindata: any;
    RegId: any; LoginDetails: any; 
    LoginDetails1: any;
    constructor(public genralservice: GeneralService, public http: HttpClient, public fb: FormBuilder,
        private router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
     //   this.RegId = JSON.parse(localStorage.getItem("RegID"));
          this.RegId = localStorage.getItem("RegID");

        this.form = this.fb.group({
            // Define your form controls here
            contactperson: ['',],
            username: ['', Validators.required],
            Contmobile: ['', Validators.required],
            email: ['', Validators.email],
            Gender: ['',],
            age: [''],
            BloodRequestDate: ['',],
            RequestTime: ['',],
            unitsofblood: [''],
            bloodgroup: [''],
            Purpose: ['',],
            useraddress: [''],
            area: [''],
            DoctorName: ['',],
            HospitalName: ['',],
            HospitalPhonenumber: ['',],
            HospitalAddress: ['',],
            statename: [''],
            districtname: [''],
            cityname: ['',],
            PersonalNote: ['',],
        });


    }


    ngOnInit(): void {
        this.getunitofblood();
        this.getBloodgroup();
        this.getstate();
        this.getDistrict();
        this.getCity();


    }

    reg() {
        
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }

    Requestform() {
        if (!this.form.value.username || !this.form.value.Contmobile || !this.form.value.bloodgroup || !this.form.value.username
            || !this.selectedGender || !this.form.value.unitsofblood) {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }

        
        this.arr = [];
        this.arr.push({
            RegId: this.RegId,
            ContactPerson: this.form.value.contactperson,
            FullName: this.form.value.username,
            ContactMobile: this.form.value.Contmobile,
            Email: this.form.value.email,
            Gender: this.selectedGender,
            age: this.form.value.age,
            UnitsofBloodId: this.form.value.unitsofblood.UnitsofBloodId,
            UserAddress: this.form.value.useraddress,
            Area: this.form.value.area,
            BloodRequestDate: this.form.value.BloodRequestDate,
            RequestTime: this.form.value.RequestTime,
            DoctorName: this.form.value.DoctorName,
            Purpose: this.form.value.Purpose,
            HospitalName: this.form.value.HospitalName,
            HospitalPhonenumber: this.form.value.HospitalPhonenumber,
            HospitalAddress: this.form.value.HospitalAddress,
            BloodGroupId: this.form.value.bloodgroup.BLGId,
            StateId: this.form.value.statename.StateId,
            DistrictId: this.form.value.districtname.DistrictID,
            CityId: this.form.value.cityname.CityId,
            Note: this.form.value.PersonalNote,
            requestid:1
        })
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');
        var url = "api/BG/Insert_Update_requestForm";
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
                if (this.flag == 1) {
                    this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');
                }
                else {
                    this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');

                }
                this.router.navigate(['/blooddonation']);
                this.form.reset();

            }
            else {
                this.genralservice.ShowAlert("ERROR", 'Some thing went wrong', 'error')
            }

        })


    }


    getstate() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',

        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getBloodgroup() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BloodGroupMaster_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.bloodgroupdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont3(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }

    getunitofblood() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/UnitsofBlood_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.unitsofblood = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont4(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.unitsofblood.length; i++) {
                let country = this.unitsofblood[i];
                if (country.UnitsofBlood.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList4 = filtered;
        }
    }

    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }

    toggleDropdown() {
        this.showDropdownContent = !this.showDropdownContent;
    }

    toggleDropdownActive() {
        this.dropdownActive = !this.dropdownActive;
    }

    back() {
        this.router.navigate(['/blooddonation']);
    }

    selectOption1(gender: string) {
        this.form.value('Gender').setValue(gender === '1' ? 'Female' : 'Male');
    }

    getChipStyle1(option: string): any {
        return {
            'background-color': this.selectedItem1 === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem1 === option ? 'white' : 'black',
            'border-color': this.selectedItem1 === option ? 'green' : '#ccc'
        };
    }

    selectGender(gender: string) {
        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }

    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }

}
