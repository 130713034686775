<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>

    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="Let's Help ">
    <meta property="og:image" content="dist/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="Let's Help " />
    <meta property="og:site_name" content="Let's Help " />
    <meta property="og:description" content="Let's Help " />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="col-lg-3 col-xs-6  zoom-in">
                <span class="bg"></span><br>
                <a href="">
                    <img src="assets/svg/logo.png">
                </a>
            </div>
            <div class="col-lg-3 col-xs-6">
                <input type="checkbox" id="active">
                <label for="active" class="menu-btn"><span></span></label>
                <div class="wrapper">
                    <div class="mobile_menu">
                        <ul style="margin: 5%;">
                            <li><a href="#">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/Requestform">Register for Donating Blood</a></li>
                            <li><a routerLink="/Prerequisites">Pre-Requisites for Donating Blood</a></li>
                            <li><a href="#">Volunteer</a></li>
                            <li><a routerLink="/Termsandconditions">Terms of Use and Disclaimer</a></li>
                            <li><a routerLink="/Joincampain">Join Campaign</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                            <li>
                                <div class="row">
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-8">

                                        <div class="buttons">Newsletter</div>

                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-8">
                                        <ul class="social-links social-links--nav">
                                            <li class="icons_social" style="width:50px;height:50px;border:2px solid white">
                                                <a target="_blank" href="" title="twitter" style="background:#4099ff">
                                                    <i class="icon-twitter"></i>
                                                </a>
                                            </li>
                                            <li style="width:50px;height:50px;border:2px solid white" class="icons_social">
                                                <a target="_blank" href="" title="facebook" style="background:#3b5998">
                                                    <i class="icon-facebook"></i>
                                                </a>
                                            </li>
                                            <li style="width:50px;height:50px;border:2px solid white" class="icons_social">
                                                <a target="_blank" href="" title="instagram" style="background:#3f729b">
                                                    <i class="icon-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="container-fluid web_menu">
                        <div class="row">
                            <div class="col-lg-3 bg-red--light bg_1 cardabo" style="width: 100%;">
                                <a href="">
                                    <img src="assets/svg/logo.png">
                                </a>
                                <a routerLink="/about">

                                    <div class="" style="    width: 100%;">

                                        <h2 class="heading"> about Us</h2>
                                        <div class="card-bg">
                                            <div class="card-block">
                                                <h3 class="card-title">A Brief Information</h3>
                                                <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 bg-red--darker cardre bg_1 " style="width: 100%;"> 



                                <div    style="width: 100%;">

                                    <h2 class="heading"> Register for Donating Blood</h2>
                                    <div class="card-bg">
                                        <div class="card-block">
                                            <h3 class="card-title">Save Lives</h3>
                                            <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="col-lg-3 bg-red--darker cardre">

                            <div class="" (click)="reg()">
                                <h2 class="heading">Register for Donating Blood</h2>
                                <div class="card-bg">
                                    <div class="card-block">
                                        <h3 class="card-title">Save Lives</h3>
                                        <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                    </div>
                                </div>
                            </div>

                        </div>-->

                            <div class="col-lg-3 bg-red cardpre">
                                <a routerLink="/Prerequisites">
                                    <div class="">
                                        <h2 class="heading">Pre-Requisites for Donating Blood</h2>
                                        <div class="card-bg">
                                            <div class="card-block">
                                                <h3 class="card-title">Basic but important</h3>
                                                <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-lg-3 bg-red--dark cardvol" style="background-color:#a00105">
                                <a href="">
                                    <div class="">
                                        <h2 class="heading">Volunteer</h2>
                                        <div class="card-bg">
                                            <div class="card-block">
                                                <h3 class="card-title">Empower Us</h3>
                                                <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-lg-3 bg-red--darker cardterm">
                                <a routerLink="/Termsandconditions">
                                    <div class="">
                                        <h2 class="heading"> Terms of Use and Disclaimer</h2>
                                        <div class="card-bg">
                                            <div class="card-block">
                                                <h3 class="card-title">A must read</h3>
                                                <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-lg-3 bg-red cardjio">
                                <a routerLink="/Joincampain">
                                    <div class="">
                                        <br />
                                        <h2 class="heading"> Join Campaign</h2>
                                        <div class="card-bg">
                                            <div class="card-block">
                                                <h3 class="card-title">Work With Us</h3>
                                                <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                            </div>
                                        </div>
                                    </div>

                                </a>
                            </div>

                            <div class="col-lg-3  bg-red--light cardcon">
                                <a routerLink="/contact">
                                    <div class="">
                                        <h2 class="heading">Contact</h2>
                                        <div class="card-bg">
                                            <div class="card-block">
                                                <h3 class="card-title">Get In Touch</h3>
                                                <p class="card-text"> <i class="icon-chevron-right"></i></p>
                                            </div>
                                        </div>
                                    </div>

                                </a>

                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-8">
                                        <br /><br /><br /><br /><br />
                                        <div class="buttons">Newsletter</div>

                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-8">
                                        <ul class="social-links social-links--nav">
                                            <li class="icons_social" style="width:50px;height:50px;border:2px solid white">
                                                <a target="_blank" href="" title="twitter" style="background:#4099ff">
                                                    <i class="icon-twitter"></i>
                                                </a>
                                            </li>
                                            <li style="width:50px;height:50px;border:2px solid white" class="icons_social">
                                                <a target="_blank" href="" title="facebook" style="background:#3b5998">
                                                    <i class="icon-facebook"></i>
                                                </a>
                                            </li>
                                            <li style="width:50px;height:50px;border:2px solid white" class="icons_social">
                                                <a target="_blank" href="" title="instagram" style="background:#3f729b">
                                                    <i class="icon-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6"></div>
            <br>
        </header>

        <div id="page-content">



            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">

                    <div class="bg-vid" data-src="dist/vid/Let's Help " style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="line-height: 60PX;">FAQ's</h2>
                    </header>

                </div>
            </div>

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="faq-accordion" style="padding:15px">
                        <div class="accordion">
                            <div class="accordion-section">
                                <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                                    What are the eligibility criteria for donating blood online?
                                </div>
                                <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                    <p>To donate blood online, you typically need to meet certain criteria, including being in good health, meeting age requirements (usually between 16 to 65 years old), meeting weight requirements (usually over 110 pounds), and not having certain medical conditions or recent surgeries.</p>
                                </div>
                            </div>
                            <div class="accordion-section">
                                <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                    How can I find a blood donation center near me through the website?
                                </div>
                                <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                    <p>Most blood donation websites have a search feature where you can enter your location and find nearby blood donation centers or blood drives. You may also be able to schedule appointments for donation through the website.</p>
                                </div>
                            </div>
                            <div class="accordion-section">
                                <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                    Is it safe to donate blood online?
                                </div>
                                <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                    <p>Yes, donating blood online through reputable and secure blood donation websites is safe. These websites typically have secure payment gateways and follow strict protocols to protect your personal and medical information.</p>
                                </div>
                            </div>
                            <div class="accordion-section">
                                <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                                    Can I schedule appointments for blood donation through the website?
                                </div>
                                <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                    <p>Yes, many blood donation websites allow you to schedule appointments for blood donation at your convenience. This helps streamline the donation process and ensures that blood donation centers are adequately prepared for donations.</p>
                                </div>
                            </div>
                            <div class="accordion-section">
                                <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                                    What happens after I donate blood online?
                                </div>
                                <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                    <p>After you donate blood online, you may receive a confirmation email or message with details about your donation, including any instructions for preparation before donation and aftercare tips. Your donation will then be processed by the blood donation center, and it will be used to help save lives.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>




        </div>
    </div>



    <section class="block" id="sticky-cta">
        <div class="block__row block__row--sticky">
            <div class="block__col block__col--cta">
                <span class="block__cta-text">Want to work with us?</span>
                <a href="contact/index.html" class="no-ajax btn btn-default btn-default--Let's Help ">Contact Us</a>
            </div>
        </div>
    </section>


    <div class="full-width-overlay"></div>

    <div class="remodal" data-remodal-id="modal"
         data-remodal-options="hashTracking: false, closeOnOutsideClick: false">

        <button data-remodal-action="close" class="remodal-close"></button>


        <!--<div class="row">-->
        <!--<div class="col-lg-1">
         <input type="checkbox" id="registerAsDonor" style="display: inline; margin-top: 17px;">
        </div>-->
        <!--<div class="col-lg-1">
                <input type="checkbox" id="registerAsDonor" style="display: inline; margin-top: 17px;"
                       [(ngModel)]="registerAsDonor" name="registerAsDonor">
            </div>
            <div class="col-lg-11">
                <label class="label" for="registerAsDonor">Register as a Blood Donor</label> <br />
            </div>

        </div>-->

        <h2 class="section__title">Newsletter Signup</h2>

        <div class="remodal__body">
            <form action="#" class="form form--contact form--newsletter" data-mailchimp="//o3world.us5.list-manage.com/subscribe/post-json?u=a38f844f24fcb8c227bc905a5&amp;id=3180f95113">
                <div class="form__input">
                    <label for="form--contact__email" class="sr-only">Email</label>
                    <input id="form--contact__email" data-entry="entry.2122287328" class="form-control required" name="EMAIL" type="email" placeholder="Email">
                </div>
                <div style="display:none">
                    please leave this blank
                    <input type="text" class="form__hp" name="blankCheck" value="">
                </div>
                <button class="form__submit btn btn-submit btn-lg" id="newsletter-submit-button" type="submit">
                    <span class="form__submit__val"><span>Submit</span> <i class="icon-chevron-right"></i></span>
                    <span class="form__submit__thanks">Thanks!</span>
                </button>
            </form>
            <p class="form-message">
                <span class="message"></span>
                <a href="javascript:;" class="btn btn-primary" data-remodal-action="cancel">Got It</a>
            </p>
        </div>
    </div>


    <div id="js-mediaquery-reference"></div>
    <div data-remodal-id="youtube" id="youtube-modal">
        <div id="player"></div>
    </div>

</body>


</html>

<style>
   
    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    @media (min-width: 992px) {
        .allow-scroll .pane__content {
            height: 100%;
            overflow-y: initial;
            overflow-scrolling: touch;
            overflow-x: hidden;
        }
    }

    @media (min-width: 992px) {
        .pane__inner.allow-scroll {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            overflow-x: clip;
        }
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -110%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px; 
        text-align: center;
        line-height: 50px; 
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: red;
    }

        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 2px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }



    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

   
    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color:white;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    
</style>
