
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { EmailValidators } from 'ngx-validators'

//import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../general.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    HomeUrl: any; arr: any = [];
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    flag: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true; loginDet: any;
    registerAsDonor: boolean = false; RegId: any;
    LoginDetails: any;
    EmailID: any;
    EditFlag: any;

    isMobileDisabled: boolean = false;

    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }
    ngOnInit() {
        // this.Getsurname();
    }
 

    checkMobileLength(event: any) {
        if (event.target.value.length === 10) {
            this.isMobileDisabled = true;
        } else {
            this.isMobileDisabled = false;
        }
    }


  

    reg() {
 
    if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
        // Route to login page
        this.router.navigate(['/Login']);
        return; // Stop further execution
    }
    else {
        this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
    }
    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }
    EditMobileNumber() {
        debugger
        this.EditFlag = 1;
        this.Mobile = '';
        this.OTPtoMobile = null;
        this.showLogin1 = true;
        this.showLogin = false;
        this.otpSent = false;
        this.isMobileDisabled = false;

    }
    SendOTPtoMobile() {
    
        this.CheckMobileNumber();
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.generalService.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
                if (this.EmailID != null) {
                    this.SendOTPtoMail();
                }
            })
        }
    }

    CheckMobileNumber() {
    
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.Mobile);
        var url = 'api/BG/checking_Mobile';
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            
            if (data != 'NOTEXIST') {
                this.EmailID = data[0].Email;
            }
        })
    }

    SendOTPtoMail() {
        
        var Content = "Your Login OTP is: " + this.OTPtoMobile;  
        var UploadFile = new FormData();
        UploadFile.append("EMailID", this.EmailID);
        UploadFile.append("Content", Content);
        var url = "api/BG/SendOTPtoMail";
        this.generalService.PostData(url, UploadFile).then((data: any) => {

        })
    }

    //checkmobile(Mobile) {
    //    
    //      if (!this.registerAsDonor) {
    //    alert('Please click on the checkbox to register as a blood donor.');
      
    //    return;
    //}
    //    if (Mobile.length == 10) {
    //        if (this.OTPtoMobile == this.OTP) {
    //            var UploadFile = new FormData();
    //            UploadFile.append("Mobile", Mobile);


    //            var url = 'api/BG/checking_Mobile'

    //            this.generalService.PostData(url, UploadFile).then(data => {
    //                //this.logindata = JSON.stringify(data[0]);
    //                //localStorage.setItem("LoginDetails", this.logindata);
    //                
    //                if (data != 'NOTEXIST') {
    //                    
    //                    this.pwd1 = data[0].Password;
    //                    this.userid = data[0].ID;
    //                    this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });
    //                    this.logindata = JSON.stringify(data[0]);
    //                    localStorage.setItem("LoginDetails", this.logindata);

    //                }
    //                else {

    //                    this.router.navigate(['/Donersform']);
    //                    this.logindata = JSON.stringify(data[0]);
    //                    localStorage.setItem("LoginDetails", this.logindata);
    //                }
    //            })
    //        }
    //    }
    //}
    checkmobile(Mobile) {
    //    if (!this.registerAsDonor) {
    //   alert('Please click on the checkbox to register as a blood donor.');

    //    return;
    //}
      
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);

                var url = 'api/BG/checking_Mobile'

                this.generalService.PostData(url, UploadFile).then(data => {
              
                    if (data != 'NOTEXIST') {
                        ;
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;
                        this.router.navigate(['/blooddonation'])
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);
                        this.RegId = data;
                        localStorage.setItem("RegID", this.RegId);

                    } else {
                        // If the phone number doesn't exist, insert it into the table
                        this.insertPhoneNumber(Mobile);
                        // Navigate to the registration page
                        this.router.navigate(['/Donersform'], { queryParams: { mobile: Mobile } });
                    }
                })
            }
        }
    }

    insertPhoneNumber(Mobile) {
        this.arr = [{
            Phonenumber: Mobile,
            BloodGroupId: 0,
            Lastdonatedate: null,
            Status:true,
            Statusphn:true
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/BG/Insert_Update_DonersForm';

        this.generalService.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
               
                if (this.flag == 1) {
                    this.generalService.ShowAlert("ERROR", 'You have successfully signed in to the application', 'error');
                } else {
                    this.generalService.ShowAlert("SUCCESS", 'You have successfully signed in to the application', 'success');
                    //this.router.navigate(['/Donersform']).then(() => { window.location.reload(); });
                    this.router.navigate(['/Donersform'], { queryParams: { mobile: Mobile } });
                    this.logindata = JSON.stringify(data[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                    window.location.reload()

                    this.RegId = data[0].RegId;
                    localStorage.setItem("RegID", this.RegId);
                }
            }
        });
    }

}

