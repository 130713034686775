import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

    public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    GalleryImages: any;
    imagefile2: any;
    HomeUrl: any;
    GalleryID: any;
    Form: FormGroup;
    LoginDetails1: any; LoginDetails: any
    constructor(public genralservice: GeneralService, public http: HttpClient, public fb: FormBuilder) {
        
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        const fullName: string = this.LoginDetails.FullName;
        const firstLetter: string = fullName.charAt(0);
        this.form = this.fb.group({
            Category: ['', Validators.required],
            Dateofservice: [''],
            bloodgroup: [''],
            statename: [''],
            districtname: [''],
            cityname: [''],
            Institutionname: [''],
            GalleryImages: ['', Validators.required],
        });


    }


    ngOnInit(): void {     
        this.getstate();
        this.getDistrict();
        this.getCity();
    }

    AddBanner(value, flag) {
        
        if (!value.GalleryImages) {
            this.genralservice.ShowAlert('Warning', 'Please upload an image file', 'Warning');
            return;
        }
        var arr = [];
        arr.push({
            RegId: this.LoginDetails?.RegId || 0,
            GalleryID: this.GalleryID,
            Category: this.form.value.Category,
            GalleryImages: this.GalleryImages,
            Dateofservice: this.form.value.Dateofservice,
            State: this.form.value.statename.StateId,
            District: this.form.value.districtname.DistrictID,
            City: this.form.value.cityname.CityId,
            Institutionname: this.form.value.Institutionname,

        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(arr));
        UploadFile.append("Flag", flag);
        var url = "api/BG/Gallery_Crud";

        this.genralservice.PostData(url, UploadFile).then(data => {
            
            if (data == "SUCCESS") {
                if (flag == 1) {
                    this.genralservice.ShowAlert('SUCCESS', 'Image Added successfully ', 'success');
                } else {
                    this.genralservice.ShowAlert('SUCCESS', 'Image  Updated successfully', 'success');
                }
                this.form.reset();
                window.location.reload();

            }
            if (data == "Exist") {
                this.genralservice.ShowAlert('Exist', 'Data Already Exist ', 'success');
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'
        }));
    }






    getstate() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


  

    // drop down for bloodgroup //
    searchCont3(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }

   

    detectFiles2(input2) {
        
        //let arr = [];
        //arr.push({ UserID: this.loginDet.Id, TokenID: this.loginDet.TokenID })
        const reader = new FileReader();
        if (input2.files.length) {

            const file = input2.files[0];
            reader.onload = () => {

                this.imagefile2 = reader.result;
                this.GalleryImages = this.imagefile2;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "gif" || extFile == "webp") {

                var UploadFile = new FormData();
                UploadFile.append("UploadedImage", file);
                var url = "api/BG/UploadGallery";
                this.genralservice.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        
                        this.imagefile2 = this.HomeUrl + data;
                        this.GalleryImages = data;
                    }
                });
            }
            else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }

        }
    }
   
}
