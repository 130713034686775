
<div class="ptb-70">

    <div class="container" style="margin-bottom:1%;margin-top: -0.3%;">
        <div class="row">
            <div class="col-lg-3 col-12  ">
                <a routerLink="/">
                    <img src="../../../assets/images/logo.jpg" />
                </a>
            </div>
            <div class="col-lg-6 col-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div routerLink="/Oldagehome" routerLinkActive="active"  >&nbsp;&nbsp;Oldage&nbsp;Home</div>
                        </div>
                        <div class="col-lg-4">
                            <div routerLink="/blooddonation" routerLinkActive="active" > <div>Blood&nbsp;Donation</div></div>
                        </div>
                        <div class="col-lg-4 ">
                            <!--<div (click)="share()">Orphanage</div>-->
                            <div routerLink="/Orphanage" routerLinkActive="active" >Orphanage</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-6">
                <ul style="float:right">
                    <li>
                        <a class="facebook" href="https://www.facebook.com/" target="_blank">

                            <i class="fab fa-facebook" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a class="twitter" href="https://twitter.com/i/flow/login" target="_blank">

                            <i class="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a class="instagram" href="https://www.instagram.com/accounts/login/?hl=en" target="_blank">

                            <i class="fab fa-instagram" aria-hidden="true"></i>
                        </a>
                    </li>

                </ul>
                <!--<a href="https://www.facebook.com/" target="_blank">
                    <img src="../../../assets/img/fb.png" class="zoom-in icon">
                </a>
                <a href="https://twitter.com/i/flow/login" target="_blank ">
                    <img src="../../../assets/img/twitternew.png" class="zoom-in icon">
                </a>
                <a href="https://www.instagram.com/accounts/login/?hl=en" target="_blank">
                    <img src="../../../assets/img/instagram.png" class="zoom-in icon">
                </a>-->
            </div>
            <div class="col-lg-1 col-6">
                <ng-container *ngIf="LoginDetails; else showLoginButton">
                    <div class="dropdown" (click)="toggleDropdown()" style="border: 2px solid white;
            background: red;color: white;width: 50px;height: 50px;border-radius: 50px;float: inline-end; ">
                        <button class="dropbtn" style="color:white">
                            {{ letter }}
                        </button>
                        <div class="dropdown-content" style="width:220%;">
                            <a  (click)="pro()"> My Profile </a>
                            <a (click)="signOut()">Sign Out</a>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showLoginButton>
                    <p class="services www  zoom-in" (click)="sharelog()" style="text-decoration:underline;cursor:pointer"><b>LOGIN</b></p>
                </ng-template>
            </div>
        </div>
    </div>
    <br />

    <div class="container icons_row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <!--<div class="row ">
                <div class="col-lg-3">
                    <a routerLink="/" style="color:red">
                        <i class="fas fa-home" aria-hidden="true"></i>&nbsp;Home

                    </a>
                </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-5">
                    <ul style="float:right">
                        <li>
                            <a class="facebook" href="https://www.facebook.com/" target="_blank">

                                <i class="fab fa-facebook" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a class="twitter" href="https://twitter.com/i/flow/login" target="_blank">

                                <i class="fab fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a class="instagram" href="https://www.instagram.com/accounts/login/?hl=en" target="_blank">

                                <i class="fab fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>

                    </ul>

                </div>

            </div>-->
        </div>
        <div class="col-lg-3"></div>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-lg-3">
                <div class="cards" style="cursor: default;padding: 6px;">
                    Search Orphanage
                </div><br />
                <div class="form-group1">
                    <p-autoComplete [(ngModel)]="selectedState1"
                                    [suggestions]="deptList"
                                    [dropdown]="true"
                                    (completeMethod)="searchCont($event)"
                                    field="StateName"
                                    placeholder="Search State" (click)="getDistrict()"
                                    (onSelect)="onStateSelect1($event)"
                                    [style]="{'color': 'black','width': '270px'}"
                                    [panelStyle]="{'background-color': 'white','width':'30rem !important',  'border': '1px solid black','margin-left':'3px'}"></p-autoComplete>
                </div>

                <div class="form-group1">
                    <p-autoComplete [(ngModel)]="selectedState2"
                                    [suggestions]="deptList1"
                                    [dropdown]="true"
                                    (completeMethod)="searchCont1($event)"
                                    field="DistrictName"
                                    placeholder="Search District" (click)="getCity()"
                                    (onSelect)="onStateSelect2($event)" [style]="{'color': 'black','width': '270px'}"
                                    [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                    </p-autoComplete>
                </div>

                <div class="form-group1">
                    <p-autoComplete [(ngModel)]="selectedState3"
                                    [suggestions]="deptList2"
                                    [dropdown]="true"
                                    (completeMethod)="searchCont2($event)"
                                    field="CityName"
                                    placeholder="Search City"
                                    (onSelect)="onStateSelect3($event)"
                                    [style]="{'color': 'black', 'width': '270px'}"
                                    [panelStyle]="{'background-color': 'white', 'border': '1px solid black', 'margin-left': '3px'}">
                    </p-autoComplete>

                </div>
                <div class="form-group1">
                    <p-autoComplete [(ngModel)]="selectedState6"
                                    [suggestions]="deptList6"
                                    [dropdown]="true"
                                    (completeMethod)="searchCont6($event)"
                                    field="Address"
                                    placeholder="Select Address"
                                    (onSelect)="onStateSelect6($event)"
                                    [style]="{'color': 'black','width': '270px'}"
                                    [panelStyle]="{'background-color': 'white','width':'30rem !important',  'border': '1px solid black','margin-left':'3px'}">

                    </p-autoComplete>
                </div>
                <button class="btn1" (click)="onSearchClick(selectedState1,selectedState2,selectedState3,selectedState6,selectedState5)">Search</button>
                <!--&nbsp;&nbsp; <button class="btn1 bt11 zoom-in" (click)="clearsearch()">Clear</button>-->
                <!--<div class="col-lg-1 col-xs-6">
                    <img src="../../../assets/img/download.png" (click)="refresh()" class="im" style="cursor:pointer" />
                </div>-->

            </div>

            <div class="col-lg-6">

                <div id="myCarousel" class="carousel slide" data-ride="carousel" *ngIf="showingProfile">

                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../../assets/images/slider/bg11.png" class="banner-img" />
                        </div>
                    </div>

                    <!-- Left and right controls -->
                    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>

                <form class="form_1" [formGroup]="form" (ngSubmit)="Donersform()" *ngIf="!showingProfile"style="height: 315px; overflow-y: scroll; overflow-x: hidden;">
                    <div class="row">


                        <div class="col-lg-11" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <span><b><span class="note">Note :</span> Enable or disable the visibility of your details to the public for donation</b></span>
                        </div>
                        <div class="col-lg-1" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label class="switch">
                                <input type="checkbox" formControlName="Status" id="Status"
                                       [checked]="form.get('Status').value"
                                       (change)="toggleStatuss($event.target.checked)">
                                <span class="slider"></span>
                            </label>
                        </div>




                        <div class="col-lg-11" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <span><b><span class="note">Note :</span> Enable or disable the visibility of your phone number to the public for donation</b></span>
                        </div>
                        <div class="col-lg-1" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label class="switch">
                                <input type="checkbox" formControlName="Statusphn" id="Status"
                                       [checked]="form.get('Statusphn').value"
                                       (change)="toggleStatuss1($event.target.checked)">
                                <span class="slider"></span>
                            </label>
                        </div>



                        <div class="col-lg-4">
                            <label for="username">Full Name<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="username" formControlName="username" placeholder="Donor Full Name" required />
                            <small style="color:red" class="text-danger" *ngIf="form.controls.username.touched && form.controls.username.errors?.required">Donor Name is required</small>

                        </div>

                        <div class="col-lg-4">
                            <label for="mobile">Mobile&nbsp;Number<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="mobile" formControlName="mobile" placeholder="Mobile Number" [readonly]="true" />
                            <small style="color:red" class="text-danger" *ngIf="form.controls.mobile.touched && form.controls.mobile.errors?.required">Donor Mobile is required</small>

                        </div>

                        <div class="col-lg-4">
                            <label for="email">Email</label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="email" formControlName="email" placeholder="Email ID" />
                        </div>

                        <div class="col-lg-4">
                            <label for="age">Gender<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-3 col-xs-6">
                                    <div class="chip1" (click)="selectGender('Male')" [ngClass]="{'selected': selectedGender === 'Male'}" required>Male</div>
                                </div>
                                <div class="col-lg-3 col-xs-6">
                                    <div class="chip1" (click)="selectGender('Female')" [ngClass]="{'selected': selectedGender === 'Female'}" required>Female</div>
                                </div>
                                <div class="col-lg-4 col-xs-6"></div>
                            </div>

                        </div>

                        <div class="col-lg-4" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label for="age">Ever donated blood<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <div class="row">
                                <div class="col-lg-3 col-xs-6">
                                    <div class="chip1" (click)="PastDonation('Yes')" [ngClass]="{'selected': selectedpast === 'Yes'}" required>Yes</div>

                                </div>
                                <div class="col-lg-3 col-xs-6">
                                    <div class="chip1" (click)="PastDonation('No')" [ngClass]="{'selected': selectedpast === 'No'}" required>No</div>

                                </div>
                                <div class="col-lg-4 col-xs-6"></div>
                            </div>

                        </div>
                        <div class="row" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <div class="col-lg-4 mt-1" *ngIf="selectedpast !== 'No'">

                                <label>Last donate date<span style="color:red">*</span></label>
                            </div>
                            <div class="col-lg-8 mt-2" *ngIf="selectedpast !== 'No'">

                                <div class="input-group ml-2">
                                    <input style="padding:6px" formControlName="Lastdonatedate" required type="date" name="Lastdonatedate" id="Lastdonatedate" [readonly]="selectedpast === 'No'">
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 mt-1" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label for="mobile">Weight<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8 mt-1" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <input type="text" id="Weight" formControlName="Weight" placeholder="Weight " oninput="this.value = this.value.replace(/[^0-9]/g, '');" required />
                            <small style="color:red" class="text-danger" *ngIf="form.controls.Weight.touched && form.controls.Weight.errors?.required">Donor Weight is required</small>

                        </div>


                        <div class="col-lg-4" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label for="age">Age<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <input type="text" id="age" formControlName="age" placeholder="Age" oninput="this.value = this.value.replace(/[^0-9]/g, '');" required />
                        </div>

                        <div class="col-lg-4" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label>Blood Group<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-8" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <div style="padding-left:0px">

                                <p-autoComplete formControlName="bloodgroup" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                field="BLGName" [dropdown]="true" [size]="90"
                                                placeholder="Search Blood Group" [minLength]="1"
                                                [style]="{'color': 'black','border-radius':'5px'}"
                                                [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                </p-autoComplete>


                                <small *ngIf="form.controls.bloodgroup.touched && form.controls.bloodgroup.errors?.required"
                                       class="p-error">blood group is required.</small>
                            </div>
                        </div>


                        <div class="col-lg-4">
                            <label>State Name</label>
                        </div>
                        <div class="col-lg-8">

                            <div style="padding-left:0px">

                                <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                placeholder="Search State Name" [minLength]="1"
                                                [style]="{'color': 'black'}"
                                                [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                </p-autoComplete>

                                <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                       class="p-error">State Name is required.</small>
                            </div>

                        </div>

                        <div class="col-lg-4">
                            <label>District Name</label>
                        </div>
                        <div class="col-lg-8">

                            <div style="padding-left:0px">

                                <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                placeholder="Search District Name" [minLength]="1"
                                                [style]="{'color': 'black'}"
                                                [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                </p-autoComplete>

                                <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                       class="p-error">District Name is required.</small>
                            </div>
                        </div>


                        <div class="col-lg-4">
                            <label>City Name</label>
                        </div>
                        <div class="col-lg-8">
                            <div style="padding-left:0px">

                                <p-autoComplete formControlName="cityname"
                                                [suggestions]="deptList2"
                                                (completeMethod)="searchCont2($event)"
                                                field="CityName"
                                                [dropdown]="true"
                                                [size]="120"
                                                placeholder="Search City Name"
                                                [minLength]="1"
                                                [style]="{'color': 'black'}"
                                                [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                </p-autoComplete>


                                <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                       class="p-error">City Name is required.</small>
                            </div>

                        </div>


                        <div class="col-lg-4">
                            <label for="useraddress">User Address</label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="useraddress" formControlName="useraddress" placeholder="User Address" />
                        </div>

                        <div class="col-lg-4">
                            <label for="area">Area</label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" id="area" formControlName="area" placeholder="Area" />
                        </div>

                        <div class="col-lg-4">
                            <label for="mobile">Pincode<span style="color:red">*</span></label>
                        </div>

                        <div class="col-lg-8">
                            <input type="text" id="Pincode" formControlName="Pincode" placeholder="Pincode " oninput="this.value = this.value.replace(/[^0-9]/g, '');" required />
                            <small style="color:red" class="text-danger" *ngIf="form.controls.Pincode.touched && form.controls.Pincode.errors?.required">Pincode is required</small>

                        </div>

                        <div class="col-lg-4" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <label for="area">Message</label>
                        </div>
                        <div class="col-lg-8" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                            <input type="text" id="Messagetorequester" formControlName="Messagetorequester" placeholder="Message to Blood Requester" />
                        </div>

                      

                            <div class="col-lg-11" *ngIf="(LoginDetails[0]?.RoleId === 4) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                <span><b><span class="note">Note:</span> Enable or disable to become as a donor</b></span>
                            </div>
                            <div class="col-lg-11" *ngIf="(LoginDetails[0]?.RoleId === 2) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                <span><b><span class="note">Note:</span> Enable or disable to become as a volunteer</b></span>
                            </div>
                            <div class="col-lg-1" *ngIf="(LoginDetails[0]?.RoleId === 4) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                <label class="switch">
                                    <input type="checkbox" formControlName="Rolestatus" id="Status" [checked]="form.get('Rolestatus').value" (change)="toggleStatuss2($event.target.checked)">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div class="col-lg-1" *ngIf="(LoginDetails[0]?.RoleId === 2) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                <label class="switch">
                                    <input type="checkbox" formControlName="Rolestatus" id="Status" [checked]="form.get('Rolestatus').value" (change)="toggleStatuss2($event.target.checked)">
                                    <span class="slider"></span>
                                </label>
                            </div>

                        


                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-1"></div>
                        <div class="col-lg-3 col-4">
                            <button class="btn1" style="background-color: red; color: white;" onclick="window.location.href = '/blooddonation';">Cancel</button>
                        </div>

                        <div class="col-lg-3 col-4">
                            <button class="btn1" style="background-color: #1c60d9; color: white;">Update</button>
                        </div>
                        <div class="col-lg-3 col-1"></div>
                    </div>
</form>
            </div>
            <div class="col-lg-3">
                <div class="cards Gal" (click)="Gallery()">
                    Let's Help Gallery
                </div>
                <br />
                <div class="card_img">
                    <div class="disclaimer-outer">
                        <div class="disclaimer-inner">
                            <div style="margin-right: 0px; float: none; margin-bottom: 0px;" *ngFor="let item of GallaryCategory">
                                <a routerLink="/Gallery" target="_self" href="{{HomeUrl+item.GalleryImages}}" class="img_g">
                                    <img src="{{HomeUrl+item.GalleryImages}}" alt="" class="img_g" />
                                </a>
                                <div class="cards">{{item.categoryname}}</div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div><br />

    <div class="container" *ngIf="showingcontent">
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <br />
                <p class="p_left">
                    "An <strong>orphanage</strong> is a residential institution, <strong>total institution</strong>,
                    or <strong>group home</strong>, devoted to the care of <strong>orphans and children</strong> who,
                    for various reasons, cannot be cared for by their biological families. The parents may be
                    <strong>deceased</strong>, <strong>absent</strong>, or <strong>abusive</strong>. There may be
                    <strong>substance abuse</strong> or <strong>mental illness</strong> in the biological home,
                    or the parent may simply be unwilling to care for the child. The legal responsibility for the support of
                    <strong>abandoned children</strong> differs from country to country, and within countries."<br />
                </p>
            </div>
        </div>
    </div>


    <div class="container" *ngIf="!showingcontent">
        <p style="color:crimson; font-size:20px;"><b>Search Results</b></p> <br />
        <div class="table-responsive">
            <table id="customers">
                <tr>
                    <th>Orphanage Name</th>
                    <th>Phone Number</th>
                    <th>State Name</th>
                    <th>District Name</th>
                    <th>City Name</th>
                    <th>Address</th>
                    <th>Pincode</th>
                    <th>SiteURL</th>

                </tr>
                <tr *ngFor="let item of Orphanagedata">
                    <td>{{item.Orphanage_Name}}</td>
                    <td>{{item.PhoneNumber}}</td>
                    <td>{{item.StateName}}</td>
                    <td>{{item.DistrictName}}</td>
                    <td>{{item.CityName}}</td>
                    <td>{{item.Address}}</td>
                    <td>{{item.Pincode}}</td>
                    <td>
                        <a href="{{item.SiteURL}}" [href]="'http://' + cleanUpUrl(item.SiteURL)" target="_blank" rel="noopener noreferrer" style="color:orangered;cursor:pointer">{{item.SiteURL}}</a>
                    </td>

                </tr>
            </table>

        </div><br />
        <div class="no-result" *ngIf="!Orphanagedata || Orphanagedata.length === 0">
            <b>No search results available on this search querry.</b>
        </div>
    </div><br />

    <div class="footer">
        <div class="container-fluid" style="background-color:black;padding:10px;color:white;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <div>&copy; 2024 Let's Help All Rights Reserved</div>
                    </div>
                    <div class="col-lg-5 ">
                    </div>
                    <div class="col-lg-1 zoom-in">
                        <a routerLink="/"> <div>Home</div></a>
                    </div>
                    <div class="col-lg-1 zoom-in">
                        <a routerLink="/about"> <div>About Us</div></a>
                    </div>
                    <div class="col-lg-1 zoom-in">
                        <a routerLink="/"> <div>FAQ's</div></a>
                    </div>
                    <div class="col-lg-1 zoom-in">
                        <a routerLink="/contact"> <div>Contact Us</div></a>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>



<style>
    .icon {
        height: 57%;
        width: 21%;
        border-radius: 23px;
        float: inline-end;
    }
    .ptb-70 {
        padding-bottom: 0px;
        overflow-y: scroll; /* Enable vertical scrolling */
        height: 100vh; /* Set a fixed height or adjust as needed */
    }

    .services a {
        cursor: pointer;
    }
    input {
        padding: 5px 12px !important;
        border: 1px solid rgba(73, 80, 87, 0.6);
        margin-bottom: 3%;
        width: 100%;
        border-radius: 5px;
    }

</style>




















