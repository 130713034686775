import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Route, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
declare var $: any;
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-blooddonation',
    templateUrl: './blooddonation.component.html',
    styleUrls: ['./blooddonation.component.scss']
})
export class BlooddonationComponent {
    Requestdata: any;
    arr: any; Statedata: any = [];
    deptList: any;
    districtdata: any = []; deptList1: any;
    Citydata: any = []; deptList2: any;
    bloodgroupdata: any = []; deptList3: any;
    Areadata: any = []; deptList6: any;
    selectedState1: any;
    selectedState2: any;
    selectedState3: any;
    selectedState4: any;
    selectedState5: any;
    selectedState6: any;
    loginDet: any; HomeUrl: any;
    Donersdata: any; State: any; distict: any; city: any; bloodgroup: any;
    Requestdet: any;
    FullName: any;
    CityName: any;
    DoctorName: any;
    BLGName: any;
    StateName: any;
    HospitalAddress: any;
    Gender: any;
    Phonenumber: any;
    HospitalName: any;
    DistrictName: any; GallaryCategory: any; arr1: any = [];
    GalleryImages1: any;
    LoginDetails1: any; LoginDetails: any
    showSuccessMessage: boolean = false;
    public form: FormGroup;
    public form1: FormGroup;
    public form2: FormGroup;
    public form3: FormGroup;
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    isLoggedIn: boolean = false;
    modalVisible: false;
    Age: any;
    BloodRequestDate: any;
    RequestTime: any;
    Purpose: any;
    letter: any; ContactMobile: any; ContactPerson: any; HospitalPhonenumber: any;
    flag: any;
    RegId: any;
    isDonorRegisterClicked: boolean = false;
    isRegistrationFormVisible: boolean = false;
    isDonorRegisterClickedd: boolean = false;
    isshareFormVisible: boolean = false;
    isrequestFormVisible: boolean = false;
    isprofileFormVisible: boolean = false;
    category: any = [];
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    latitude: any;
    longitude: any;
    lat: any; selectedpast: string;
    long: any;
    zoom: number;
    address: string;
    Dropaddress: string;
    private geoCoder;
    map: boolean

    trip: any; Address: any;
    profiledata: any;


    GalleryImages: any;
    imagefile2: any;

    GalleryID: any;

    dropdownActive: boolean = false; unitsofblood: any = [];
    deptList4: any; selectedItem1: any;
    selectedGenders: string | null = null;
    Startdate: any; fromDate: any; mindate: any; toDate: any;
    deptList2s: any = [];
    deptList1s: any = [];
    deptLists: any = [];
    deptList3q: any = [];
    deptList8: any = [];
    isDropdownOpen: boolean = false;
    isDropdownOpen2: boolean = false;
    isDropdownOpen3: boolean = false;
    isDropdownOpen4: boolean = false;
    isDropdownOpen5: boolean = false; pincodedata: any; deptList5: any = [];
    pincode: any; GallaryCategory12: any;
    selctedpincode: any;
    EmailID: any;
    HsptName: any;
    addextra: any;
    baseurl: string;
    arr2: any[];
    Email: any; showSecondModal: boolean = false;
    showEditForm: boolean = false;
    EditFlag: any;
    isMobileDisabled: boolean = false; gallerydate: any;
    imagedate: any;

    constructor(public genralservice: GeneralService, private route: ActivatedRoute, public http: HttpClient, public router: Router, private datePipe: DatePipe, public fb: FormBuilder,) {
        //   this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0) {
            this.letter = this.LoginDetails[0].FullName.charAt(0);
        } else if (this.LoginDetails && this.LoginDetails.FullName) {
            this.letter = this.LoginDetails.FullName.charAt(0);
        } else {
            this.letter = '';
        }
        this.form = this.fb.group({
            // Category: ['', Validators.required],
            //Dateofservice: [''],
            // Define your form controls here
            username: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['', Validators.required],
            Gender: ['',],
            Status: [''],
            Statusphn: [true],
            Rolestatus: [''],
            PastDonation: ['',],
            age: [''],
            // unitsofblood: [''],
            bloodgroup: ['', Validators.required],
            statename: [''],
            districtname: [''],
            cityname: [''],
            useraddress: [''],
            area: [''],
            Weight: [''],
            Lastdonatedate: [''],
            Pincode: [''],
            Messagetorequester: [''],
            termsAndConditions: [false, Validators.requiredTrue],
            eligibilityCriteria: [false, Validators.requiredTrue]
        });
        this.form1 = this.fb.group({
            Category: ['', Validators.required],
            Dateofservice: [''],
            //bloodgroup: [''],
            statename: [''],
            districtname: [''],
            cityname: [''],
            Institutionname: [''],
            GalleryImages: ['', Validators.required],
            Message: ['',],
        });
        this.form2 = this.fb.group({
            // Define your form controls here
            contactperson: ['',],
            username: ['', Validators.required],
            Contmobile: ['', Validators.required],
            email: ['', Validators.email],
            Gender: ['',],
            age: [''],
            BloodRequestDate: ['',],
            RequestTime: ['',],
            unitsofblood: [''],
            bloodgroup: [''],
            Purpose: ['',],
            useraddress: [''],
            area: [''],
            DoctorName: ['',],
            HsptName: ['',],
            HospitalPhonenumber: ['',],
            HospitalAddress: ['',],
            statename: [''],
            districtname: [''],
            cityname: ['',],
            Pincode: ['',],
            PersonalNote: ['',],
        });

        this.form3 = this.fb.group({
            // Define your form controls here
            username: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['',],
            Gender: ['',],
            age: ['', Validators.required],
            //unitsofblood: [''],
            bloodgroup: ['', Validators.required],
            statename: [''],
            districtname: [''],
            cityname: [''],
            useraddress: [''],
            area: [''],
            Status: [''],
            Statusphn: [''],
            Rolestatus: [''],
            Messagetorequester: [''],
            Weight: [''],
            PastDonation: ['', Validators.required],
            Lastdonatedate: ['', Validators.required],
            Pincode: ['', Validators.required],

        });



        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            // this.HomeUrl = 'https://localhost:44387/';

           
        });
    }
    
    ngOnInit(): void {
        this.GetHospitals();
        this.getBloodgroup();
        this.getgallerycat();
        this.getstate();
        this.GetPincode();
        //  this.getDistrict();
        this.getgallerycat11();
        this.getunitofblood();
        //  this.getAreas();
        this.Request();

        //  this.getCity();

        if (localStorage.getItem('LoginDetails')) {
            // If the user is logged in, set isLoggedIn to true
            this.isLoggedIn = true;
            // Call getprofile() and getprofiles()
            this.getprofile();
            this.getprofiles();
        }
        this.route.queryParams.subscribe(params => {
            this.isshareFormVisible = params.isshare === 'true';

        });
        this.route.queryParams.subscribe(params => {
            this.isDonorRegisterClicked = params.issharee === 'true';

        });
        this.route.queryParams.subscribe(params => {

            this.isrequestFormVisible = params.Visible === 'true';

        });
    }
    validateLastDonateDate(control) {
        const pastDonation = control.get('pastDonation').value;
        const lastDonateDate = control.get('Lastdonatedate').value;

        // If pastDonation is 'Yes' and lastDonateDate is empty, return an error
        if (pastDonation === 'Yes' && !lastDonateDate) {
            return { required: true };
        }

        return null;
    }

    checkMobileLength(event: any) {
        if (event.target.value.length === 10) {
            this.isMobileDisabled = true;
        } else {
            this.isMobileDisabled = false;
        }
    }
    onSearchClick1(event: MouseEvent) {
        const target = event.currentTarget as HTMLElement;
        const ripple = document.createElement('span');
        ripple.classList.add('ripple-effect');
        ripple.style.top = (event.clientY - target.getBoundingClientRect().top) + 'px';
        ripple.style.left = (event.clientX - target.getBoundingClientRect().left) + 'px';
        target.appendChild(ripple);

        // Add blink class
        ripple.classList.add('blink');

        // Remove the ripple element after the animation completes
        setTimeout(() => {
            ripple.remove();
        }, 1000); // Adjust the timeout to match the duration of your animation
    }
    Gallery() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            window.open('/Gallery', '_blank');
            return; // Stop further execution
        }
        else {
            window.open('/Gallery', '_blank');
        }

    }
    toggleDropdown() {
        const dropdownContent = document.getElementById("dropdownContent");
        if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
        } else {
            dropdownContent.style.display = "block";
        }
    }


    share() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            //  this.router.navigate(['/Login']);
            this.isDonorRegisterClicked = true;
            this.isDonorRegisterClickedd = false;
            return; // Stop further execution
        }
        else {
            //  this.router.navigate(['/Shareyourservices']);
            this.isshareFormVisible = true;
            this.isprofileFormVisible = false;
            this.isrequestFormVisible = false;
        }
    }
    //pro() {
    //    ;
    //    if (this.LoginDetails[0].RoleId === 4) {
    //        this.router.navigate(['/Volunteerprofile']);
    //    } else {
    //        // this.router.navigate(['/Profile']);
    //        this.isprofileFormVisible = true;
    //        this.isshareFormVisible = false;
    //        this.isrequestFormVisible = false;

    //    }
    //}

    pro() {
        debugger;
        const RoleId = this.LoginDetails[0].RoleId;
        const Rolestatus = this.LoginDetails[0].Rolestatus;

        if (RoleId === 4 && (Rolestatus === false || Rolestatus === null)) {
            this.router.navigate(['/Volunteerprofile']);
        } else if ((RoleId === 2 || RoleId === 0) && Rolestatus === false) {
            this.setFormVisibility(true, false, false);
        } else if (RoleId === 4 || RoleId === 2) {
            this.setFormVisibility(true, false, false);
        } else {
            // Handle any other conditions here
        }
    }
    setFormVisibility(profileVisible, shareVisible, requestVisible) {
        debugger
        this.isprofileFormVisible = profileVisible;
        this.isshareFormVisible = shareVisible;
        this.isrequestFormVisible = requestVisible;
    }

    //register() {
    //    if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
    //        // If user is not logged in, redirect to login page
    //      //  this.router.navigate(['/Login']);
    //        this.isshareFormVisible = false;
    //        this.isrequestFormVisible = false;
    //        this.isDonorRegisterClicked = true;
    //        this.isDonorRegisterClickedd = false;
    //    } else {
    //        // If user is logged in
    //        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0 && this.LoginDetails[0].RoleId === 4) {
    //            // If user's role is 4 (assuming it's a volunteer), redirect to volunteer profile page
    //            this.router.navigate(['/Volunteerprofile']);
    //        } else 
    //        this.isprofileFormVisible = true;
    //        this.isshareFormVisible = false;
    //        this.isrequestFormVisible = false;
    //    }
    //}
    register() {
        debugger;
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If user is not logged in, redirect to login page or handle accordingly
            // this.router.navigate(['/Login']);
            this.isshareFormVisible = false;
            this.isrequestFormVisible = false;
            this.isDonorRegisterClicked = true;
            this.isDonorRegisterClickedd = false;
        } else {
            const RoleId = this.LoginDetails[0].RoleId;
            const Rolestatus = this.LoginDetails[0].Rolestatus;

            if (RoleId === 4 && (Rolestatus === false || Rolestatus === null)) {
                this.router.navigate(['/Volunteerprofile']);
            } else if ((RoleId === 2 || RoleId === 0) && Rolestatus === false) {
                this.isprofileFormVisible = true;
                this.isshareFormVisible = false;
                this.isrequestFormVisible = false;
            } else if (RoleId === 4 || RoleId === 2) {
                this.isprofileFormVisible = true;
                this.isshareFormVisible = false;
                this.isrequestFormVisible = false;
            } else {
                // Handle any other conditions here
            }
        }
    }


    checkLoginStatus() {
        // Check if user is logged in (replace with your actual logic)
        // If user is logged in, set LoginDetails, otherwise set it to null
        if (this.LoginDetails != null) {
            this.LoginDetails = {

            };
        } else {
            this.LoginDetails = null;
        }
    }

    checkmobile1(Mobile) {
        //    if (!this.registerAsDonor) {
        //   alert('Please click on the checkbox to register as a blood donor.');

        //    return;
        //}

        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);

                var url = 'api/BG/checking_Mobile'

                this.genralservice.PostData(url, UploadFile).then(data => {

                    if (data != 'NOTEXIST') {
                        ;
                        //  this.isLoggedIn = true;
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;

                        // this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });

                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);
                        this.RegId = data;
                        localStorage.setItem("RegID", this.RegId);
                        this.getprofile();
                        window.location.reload();

                    } else {
                        // If the phone number doesn't exist, insert it into the table
                        this.insertPhoneNumber1(Mobile);

                        // Navigate to the registration page
                        // this.router.navigate(['/Donersform'], { queryParams: { mobile: Mobile } });
                    }
                })
            }
        }
    }

    insertPhoneNumber1(Mobile) {
        this.arr = [{
            Phonenumber: Mobile,
            BloodGroupId: 0,
            RoleId: 0,
            Lastdonatedate: null,
            Status: true,
            Statusphn: true
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/BG/Insert_Update_DonersForm';

        this.genralservice.PostData(url, uploadfile).then(data => {

            if (data == "SUCCESS") {

                if (this.flag == 1) {
                    this.genralservice.ShowAlert("ERROR", 'You have successfully signed in to the application', 'error');
                } else {
                    this.genralservice.ShowAlert("SUCCESS", 'You have successfully signed in to the application', 'success');
                    //this.router.navigate(['/Donersform']).then(() => { window.location.reload(); });
                    //this.router.navigate(['/Donersform'], { queryParams: { mobile: Mobile } });
                    localStorage.setItem("LoginDetails", JSON.stringify(this.arr));

                    // Show the registration form
                    this.isrequestFormVisible = true;
                    this.getProfileAndBindFormData();
                    this.isDonorRegisterClicked = false;
                    this.isDonorRegisterClickedd = false;
                    //this.RegId = data[0].RegId;
                    //localStorage.setItem("RegID", this.RegId);
                }
            }
        });
    }

    checkmobile(Mobile) {
        //    if (!this.registerAsDonor) {
        //   alert('Please click on the checkbox to register as a blood donor.');

        //    return;
        //}
        debugger
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);

                var url = 'api/BG/checking_Mobile'

                this.genralservice.PostData(url, UploadFile).then(data => {
                    ;
                    if (data != 'NOTEXIST') {
                        ;
                        //  this.isLoggedIn = true;
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;

                        // this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });

                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);
                        this.RegId = data;
                        localStorage.setItem("RegID", this.RegId);
                        this.getprofile();
                        window.location.reload();

                    } else {
                        // If the phone number doesn't exist, insert it into the table
                        this.insertPhoneNumber(Mobile);
                        this.Editting();
                        // Navigate to the registration page
                        // this.router.navigate(['/Donersform'], { queryParams: { mobile: Mobile } });
                    }
                })
            }
        }
    }

    insertPhoneNumber(Mobile) {
        this.arr = [{
            Phonenumber: Mobile,
            BloodGroupId: 0,
            RoleId: 0,
            Lastdonatedate: null,
            Status: true,
            Statusphn: true
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/BG/Insert_Update_DonersForm';

        this.genralservice.PostData(url, uploadfile).then(data => {

            if (data == "SUCCESS") {

                if (this.flag == 1) {
                    this.genralservice.ShowAlert("ERROR", 'You have successfully signed in to the application', 'error');
                } else {
                    this.genralservice.ShowAlert("SUCCESS", 'You have successfully signed in to the application', 'success');
                    //this.router.navigate(['/Donersform']).then(() => { window.location.reload(); });
                    //this.router.navigate(['/Donersform'], { queryParams: { mobile: Mobile } });
                    localStorage.setItem("LoginDetails", JSON.stringify(this.arr));

                    // Show the registration form
                    this.isRegistrationFormVisible = true;
                    $('#myModal1').modal('hide');
                    this.getProfileAndBindFormData();
                    this.isDonorRegisterClicked = false;
                    //this.RegId = data[0].RegId;
                    //localStorage.setItem("RegID", this.RegId);
                }
            }
        });
    }

    Editting() {
        this.showEditForm = true;
       // window.location.reload();
      //  setTimeout(() => {
          //  window.location.reload();
       // }, 100); // Adjust the delay time as needed
    }


    openLoginModal() {
        // Show the modal here
        $('#myModal1').modal('show');
    }

    EditMobileNumber() {
        debugger
        this.EditFlag = 1;
        this.Mobile = '';
        this.OTPtoMobile = null;
        this.showLogin1 = true;
        this.showLogin = false;
        this.otpSent = false;
        this.isMobileDisabled = false;
    }

    SendOTPtoMobile() {
        debugger
        this.CheckMobileNumber();
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.genralservice.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
                this.SendOTPtoMail();
            })
        }
    }

    CheckMobileNumber() {

        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.Mobile);
        var url = 'api/BG/checking_Mobile';
        this.genralservice.PostData(url, UploadFile).then((data: any) => {

            if (data != 'NOTEXIST') {
                this.EmailID = data[0].Email;
            }
        })
    }

    SendOTPtoMail() {

        var Content = "Your Login OTP is: " + this.OTPtoMobile;
        var UploadFile = new FormData();
        UploadFile.append("EMailID", this.EmailID);
        UploadFile.append("Content", Content);
        var url = "api/BG/SendOTPtoMail";
        this.genralservice.PostData(url, UploadFile).then((data: any) => {

        })
    }

    openC() {
        // Show the modal here
        $('#myModal22').modal('show');
    }
    opent() {
        // Show the modal here
        $('#myModal122').modal('show');
    }
    //viewDetails() {
    //    this.modalVisible = true;
    //    $('#myModal').modal('show');
    //}

    closeModal() {
        this.modalVisible = false;
        $('#myModal').modal('hide');
    }
    signOut() {

        // Remove the login details from localStorage
        localStorage.removeItem('LoginDetails');
        window.location.reload();
        this.isLoggedIn = false;
        // Optionally, you might want to perform other sign-out related tasks such as
        // navigating the user to the login page, clearing any local state, etc.
    }


    onSearchClick(event: MouseEvent) {
        debugger
        const target = event.currentTarget as HTMLElement;
        const ripple = document.createElement('span');
        ripple.classList.add('ripple-effect');
        ripple.style.top = (event.clientY - target.getBoundingClientRect().top) + 'px';
        ripple.style.left = (event.clientX - target.getBoundingClientRect().left) + 'px';
        target.appendChild(ripple);

        // Add blink class
        ripple.classList.add('blink');

        // Remove the ripple element after the animation completes
        setTimeout(() => {
            ripple.remove();
        }, 1000); // Adjust the timeout to match the duration of your animation

        // Check if user is logged in
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.isDonorRegisterClicked = true;
            return; // Stop further execution
        }
        else {
            this.State = this.selectedState1 != undefined ? this.selectedState1.StateName : '';
            this.distict = this.selectedState2 != undefined ? this.selectedState2.DistrictName : '';
            this.city = this.selectedState3 != undefined ? this.selectedState3.CityName : '';
            this.bloodgroup = this.selectedState5 != undefined ? this.selectedState5.BLGName : '';
            this.pincode = this.selctedpincode != undefined ? this.selctedpincode.Pincode : '';
            // Check if all parameters are present
            if (this.State && this.distict && this.city && this.bloodgroup) {
                var UploadFile = new FormData()
                UploadFile.append("Param1", this.State);
                UploadFile.append("Param2", this.distict);
                UploadFile.append("Param3", this.city);
                UploadFile.append("Param4", this.pincode);
                UploadFile.append("Param5", this.bloodgroup);
                UploadFile.append("Param6", '');

                var url = "api/BG/Get_Searchdetails";
                this.genralservice.PostData(url, UploadFile).then(data => {
                    debugger
                    this.Donersdata = data;
                    const emailsWithNames = this.Donersdata.map((donor: any) => ({ email: donor.Email, fullName: donor.FullName }));
                    this.send_Mail1(emailsWithNames);

                    this.router.navigate(['/Userdetails'], {
                        queryParams: {
                            State: this.State,
                            district: this.distict,
                            city: this.city,
                            BloodGroup: this.bloodgroup,
                            pincode: this.pincode,
                            FilteredData: JSON.stringify(data)
                        }
                    });
                }, err => {
                    this.genralservice.ShowAlert('ERROR', 'Something went Wrong', 'error')
                });
            }
            else {
                // Searching with only blood group and pincode parameters
                this.bloodgroup = this.selectedState5 ? this.selectedState5.BLGName : '';
                this.pincode = this.selctedpincode ? this.selctedpincode.Pincode : '';

                if (this.bloodgroup && this.pincode || this.pincode) {
                    var UploadFile = new FormData()
                    UploadFile.append("Param1", '');
                    UploadFile.append("Param2", '');
                    UploadFile.append("Param3", '');
                    UploadFile.append("Param4", this.pincode);
                    UploadFile.append("Param5", this.bloodgroup);
                    UploadFile.append("Param6", '');

                    var url = "api/BG/Get_Searchdetails";
                    this.genralservice.PostData(url, UploadFile).then(data => {
                        debugger
                        this.Donersdata = data;
                       
                        const emailsWithNames = this.Donersdata.map((donor: any) => ({ email: donor.Email, fullName: donor.FullName }));
                        this.send_Mail1(emailsWithNames);

                        this.router.navigate(['/Userdetails'], {
                            queryParams: {
                                BloodGroup: this.bloodgroup,
                                pincode: this.pincode,                             
                                FilteredData: JSON.stringify(data)
                            }
                        });
                    }, err => {
                        this.genralservice.ShowAlert('ERROR', 'Something went Wrong', 'error')
                    });
                } else {
                    console.log('Please select the blood group and pincode before proceeding.');
                    alert('Please select the blood group and pincode before proceeding.');
                    // Optionally show a message to the user
                    // Example: this.genralservice.ShowAlert('ERROR', 'Please select the blood group and pincode before proceeding.', 'error');
                }
            }
        }
    }



    send_Mail1(emailsWithNames: { email: string; fullName: string }[]) {
        debugger;
        this.baseurl = "https://letshelp.breakingindiaapp.com/webservices";

        // Extract the top 10 emails with names
        const top10EmailsWithNames = emailsWithNames.slice(0, 10);

        // Iterate over each email with name in the top 10 array
        top10EmailsWithNames.forEach(({ email, fullName }) => {
            // Create an array with current email and full name
            const emailArr = [{ Email: email, FullName: fullName }];

            // Create FormData for this email
            var uploadFile = new FormData();
            uploadFile.append("Blood", JSON.stringify(emailArr));
            uploadFile.append("url", this.baseurl);

            // Send the email data
            var url = "api/BG/MailPlaces";
            this.genralservice.PostData(url, uploadFile).then((data: any) => {
                // Handle the response if needed
            });
        });
    }

    Request() {

        var url = "api/BG/Get_Requestform";
        this.genralservice.GetData(url).then(data => {
            this.Requestdata = data;
        })
    }

    getstate() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }

    getDistrict() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.selectedState1.StateId

        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.selectedState2.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getBloodgroup() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BloodGroupMaster_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.bloodgroupdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont3(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }

    getAreas() {
        var url = "api/BG/Get_Areass";
        this.genralservice.GetData(url).then(data => {
            this.Areadata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    searchCont6(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList6 = [];
            for (var i = 0; i < this.Areadata.length; i++) {
                let country = this.Areadata[i];
                if (country.Area.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList6 = filtered;
        }
    }

    onStateSelect1(event) {

        console.log('Selected State:', this.selectedState1);
        // You can perform additional actions with the selected state here
    }
    onStateSelect2(event) {

        console.log('Selected Distict:', this.selectedState2);
        // You can perform additional actions with the selected state here
    }
    onStateSelect3(event) {
        console.log('Selected City:', this.selectedState3);
        // You can perform additional actions with the selected state here
    }
    onStateSelect4(event) {
        console.log('Selected Country:', this.selectedState4);
        // You can perform additional actions with the selected state here
    }
    onStateSelect5(event) {
        console.log('Selected BloodGroup:', this.selectedState5);
        // You can perform additional actions with the selected state here
    }
    onpincodeselect(event) {
        console.log('Selected Pincode:', this.selctedpincode);
        // You can perform additional actions with the selected state here
    }
    onStateSelect6(event) {
        console.log('Selected Area:', this.selectedState6);
    }

    close() {
        $('#myModal').modal('hide');
        //  this.Companydet = '';
    }

    viewDetails(val: any) {

        //  this.Id = rowData.Id
        var UploadFile = new FormData();
        UploadFile.append("Param", val);

        var url = "api/BG/Get_Requestbasedon_presonID";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.Requestdet = data;
            this.FullName = this.Requestdet[0].FullName
            this.BLGName = this.Requestdet[0].BLGName
            this.CityName = this.Requestdet[0].CityName
            this.StateName = this.Requestdet[0].StateName
            this.DoctorName = this.Requestdet[0].DoctorName
            this.Gender = this.Requestdet[0].Gender
            this.HospitalAddress = this.Requestdet[0].HospitalAddress
            this.HospitalName = this.Requestdet[0].HospitalName
            this.Phonenumber = this.Requestdet[0].Phonenumber
            this.DistrictName = this.Requestdet[0].DistrictName
            this.Gender = this.Requestdet[0].Gender
            this.Age = this.Requestdet[0].Age
            this.BloodRequestDate = this.Requestdet[0].BloodRequestDate
            this.RequestTime = this.Requestdet[0].RequestTime
            this.Purpose = this.Requestdet[0].Purpose
            this.ContactPerson = this.Requestdet[0].ContactPerson
            this.ContactMobile = this.Requestdet[0].ContactMobile
            this.HospitalPhonenumber = this.Requestdet[0].HospitalPhonenumber


        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
        $('#myModal').modal('show');
        // this.GetUsers();
    }

    //gallerycat() {
    //    
    //    this.arr = []
    //    this.arr.push({

    //    })
    //    var UploadFile = new FormData()
    //    UploadFile.append("Param", JSON.stringify(this.arr));
    //    UploadFile.append("Flag", '4');
    //    var url = "api/BG/Gallery_Crud";
    //    this.genralservice.PostData(url, UploadFile).then(data => {
    //        this.GallaryCategory = data;
    //        this.GalleryImages1 = this.GallaryCategory[0].GalleryImages;
    //    }, err => {
    //        this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

    //    })
    //}

    gallerycat() {

        var url = "api/BG/Get_Gallaryimages_bycategory";
        this.genralservice.GetData(url).then(data => {
            this.GallaryCategory = data;
            this.GalleryImages1 = this.GallaryCategory[0].GalleryImages;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    requestlogin() {

        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.isDonorRegisterClickedd = true;
            this.isDonorRegisterClicked = false;

            //  this.router.navigate(['/Requestlogin']);
            return; // Stop further execution
        }
        else {
            //this.router.navigate(['/Requestform']);
            this.isrequestFormVisible = true;
            this.isprofileFormVisible = false;
            this.isshareFormVisible = false;
            this.isRegistrationFormVisible = false;



        }
    }


    //Register start
    async getProfileAndBindFormData() {

        try {
            const profileData = await this.getprofiles();
            // Assuming `getprofile()` returns `null` or some default value when no profile data is available
            if (profileData !== null) {
                this.bindFormDatas(profileData);
            } else {
                // Handle case where profile data is not available
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
            // Handle error appropriately, e.g., show error message to user
        }
    }

    checkFormValidity() {
        // Check if all form controls are valid
        this.form.updateValueAndValidity();
    }

    openLoginModal1() {
        // Show the modal here
        $('#myModal1').modal('show');
    }



    getprofiles() {
        ;
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        //UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : '');
        UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : this.Mobile);

        var url = "api/BG/checking_Mobile";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.profiledata = data;
            this.bindFormDatas(this.profiledata);
            localStorage.setItem("LoginDetails", JSON.stringify(this.profiledata));

            this.LoginDetails = this.profiledata;// Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }


    bindFormDatas(data) {

        if (data) {

            this.selectedGender = data[0].Gender;
            this.selectedpast = data[0].PastDonation;
            const lastDonateDate = data[0].Lastdonatedate && !isNaN(Date.parse(data[0].Lastdonatedate)) ?
                this.datePipe.transform(new Date(data[0].Lastdonatedate), 'yyyy-MM-dd') : null;

            const dob = data[0].DOB && !isNaN(Date.parse(data[0].DOB)) ?
                this.datePipe.transform(new Date(data[0].DOB), 'yyyy-MM-dd') : null;


            this.form.patchValue({

                username: data[0].FullName,
                mobile: data[0].Phonenumber,
                email: data[0].Email,
                age: data[0].Age,
                bloodgroup: data[0].BLGName,
                BloodGroupId: data[0].BLGId,
                statename: data[0].StateName,
                StateId: data[0].StateId,
                districtname: data[0].DistrictName,
                DistrictId: data[0].DistrictId,
                cityname: data[0].CityName,
                CityId: data[0].CityId,
                useraddress: data[0].UserAddress,
                area: data[0].Area,
                Status: data[0].Status,
                Weight: data[0].Weight,
                Pincode: data[0].Pincode,
                DoB: dob,
                Lastdonatedate: lastDonateDate,
                Messagetorequester: data[0].Messagetorequester
            });
        }
    }


    Donersform() {
        if (!this.form.value.username || !this.form.value.mobile || !this.form.value.bloodgroup || !this.form.value.Weight || !this.form.value.age || !this.form.value.Pincode || this.selectedGender === "" ||
            this.selectedpast === "") {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }
        if (this.selectedpast === 'Yes' && !this.form.value.Lastdonatedate) {
            this.genralservice.ShowAlert("ERROR", 'Last donate date is required', 'error');
            return; // Stop further execution of the method
        }
        ;
        this.arr = [];
        const bloodgroup = this.form.value.bloodgroup && this.form.value.bloodgroup.BLGId !== undefined
            ? this.form.value.bloodgroup.BLGId
            : this.LoginDetails.BLGId !== undefined
                ? this.LoginDetails.BLGId
                : this.LoginDetails.BloodGroupId;

        const stateId = this.form.value.statename && this.form.value.statename.StateId !== undefined
            ? this.form.value.statename.StateId
            : this.LoginDetails.StateId !== undefined
                ? this.LoginDetails.StateId
                : this.LoginDetails.StateID;

        const districtId = this.form.value.districtname && this.form.value.districtname.DistrictID !== undefined
            ? this.form.value.districtname.DistrictID
            : this.LoginDetails.DistrictId !== undefined
                ? this.LoginDetails.DistrictId
                : this.LoginDetails.DistrictID;

        const cityId = this.form.value.cityname && this.form.value.cityname.CityId !== undefined
            ? this.form.value.cityname.CityId
            : this.LoginDetails.CityId !== undefined
                ? this.LoginDetails.CityId
                : this.LoginDetails.CityID;
        const lastDonateDate = this.selectedpast === 'No' ? null : this.form.value.Lastdonatedate || this.LoginDetails.Lastdonatedate;

        this.arr.push({
            RegId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
            FullName: this.form.value.username,
            Phonenumber: this.form.value.mobile,
            Weight: this.form.value.Weight,
            Email: this.form.value.email || this.LoginDetails.Email,
            Gender: this.selectedGender || this.LoginDetails.Gender,
            Lastdonatedate: lastDonateDate,
            DOB: this.form.value.DOB || this.LoginDetails.DOB,
            Pincode: this.form.value.Pincode || this.LoginDetails.Pincode,
            PastDonation: this.selectedpast,
            age: this.form.value.age || this.LoginDetails.Age,
            BloodGroupId: bloodgroup,
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
            UserAddress: this.form.value.useraddress || this.LoginDetails.UserAddress,
            Area: this.form.value.area || this.LoginDetails.Area,
            Messagetorequester: this.form.value.Messagetorequester || this.LoginDetails.Messagetorequester,
            RoleId: '2',
            Status: this.form.value.Status || this.LoginDetails.Status,
            Statusphn: this.form.value.Statusphn || this.LoginDetails.Statusphn,
            Rolestatus: this.form.value.Rolestatus || this.LoginDetails.Rolestatus,
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/BG/Insert_Update_DonersForm";
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
                if (this.flag == 2) {
                    this.genralservice.ShowAlert("ERROR", 'Profile Updated Succesfully', 'error');
                } else {
                    localStorage.setItem('LoginDetails', JSON.stringify(this.arr));
                    this.genralservice.ShowAlert("SUCCESS", 'Profile Updated Succesfully', 'success');
                    this.router.navigate(['/blooddonation']).then(() => {
                        window.location.reload();
                    });
                }
                this.form.reset();
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong. Please check validation fields', 'error');
            }
        });
    }

    toggleStatus(checked: boolean) {
        this.LoginDetails.Status = checked ? 1 : 0;
    }


    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }

    //toggleDropdown() {
    //    this.showDropdownContent = !this.showDropdownContent;
    //}


    // Function to select a blood group
    selectBloodgroup(bloodgroup: string) {
        this.selectedBloodgroup = bloodgroup;
        this.showDropdownContent = false; // Hide the dropdown after selection if needed
    }

    selectGender(gender: string) {

        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    PastDonation(Past: string) {

        this.selectedpast = Past;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    //register end

    ///share ur service
    AddBanner(value, flag) {
        debugger
        if (!value.GalleryImages) {
            this.genralservice.ShowAlert('Warning', 'Please upload an image file', 'Warning');
            return;
        }
        if (!value.Category) {
            this.genralservice.ShowAlert('Warning', 'Please specify a category', 'Warning');
            return;
        }
        if (!value.Dateofservice) {
            this.genralservice.ShowAlert('Warning', 'Please choose a Date of service', 'Warning');
            return;
        }
        if (!value.statename) {
            this.genralservice.ShowAlert('Warning', 'Please choose a state', 'Warning');
            return;
        }
        if (!value.Institutionname) {
            this.genralservice.ShowAlert('Warning', 'Please enter the institution name', 'Warning');
            return;
        }

        // Check if imagedate is available and less than 90 days
        if (this.imagedate && (new Date().getTime() - new Date(this.imagedate).getTime()) < (1 * 24 * 60 * 60 * 1000)) {
            this.genralservice.ShowAlert('Alert', 'You have already uploaded image For this day.You can upload after 24 hours.', 'error');
            return;
        }

        var arr = [];

        arr.push({
            RegId: this.LoginDetails[0]?.RegId || 0,
            GalleryID: this.GalleryID,
            Categoryid: value.Category.Categoryid,
            GalleryImages: this.GalleryImages,
            Dateofservice: this.form1.value.Dateofservice,
            State: this.form1.value.statename.StateId,
            District: this.form1.value.districtname.DistrictID,
            City: this.form1.value.cityname.CityId,
            Institutionname: this.form1.value.Institutionname,
            Message: this.form1.value.Message,
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(arr));
        UploadFile.append("Flag", flag);
        var url = "api/BG/Gallery_Crud";

        this.genralservice.PostData(url, UploadFile).then(data => {

            if (data == "SUCCESS") {
                if (flag == 1) {
                    this.genralservice.ShowAlert('SUCCESS', 'Image Uploaded successfully ', 'success');
                } else {
                    this.genralservice.ShowAlert('SUCCESS', 'Image  Updated successfully', 'success');
                }
                this.form.reset();
                this.router.navigate(['/Gallery']);
                debugger
                if (value.Category.Categoryid === 1) {
                    this.send_Mail(); // Execute send_Mail() if Categoryid is 1
                }
            }
            if (data == "Exist") {
                this.genralservice.ShowAlert('Exist', 'Data Already Exist ', 'success');
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'
        }));
    }




    send_Mail() {
        debugger
        this.baseurl = "https://letshelp.breakingindiaapp.com/webservices";
        this.arr2 = [];
        this.arr2.push({ ////SPR details
            FullName: this.LoginDetails[0].FullName,
            Email: this.LoginDetails[0].Email,
            Dateofservice: this.form1.value.Dateofservice,
            State: this.form1.value.statename.StateName
        });

        var UploadFile = new FormData();
        UploadFile.append("SPR", JSON.stringify(this.arr2));
        UploadFile.append("url", this.baseurl);
        var url = "api/BG/MailPlaceOrdercerti";
        this.genralservice.PostData(url, UploadFile).then((data: any) => {
            // Handle the response if needed
        });
    }


    getgallerycat11() {

        var url = "api/BG/Get_Gallaryimages_bycategory";
        this.genralservice.GetData(url).then(data => {

            this.GallaryCategory12 = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }


    getgallerycat() {
        this.arr = []
        this.arr.push({

        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/GalleryCat_Crud";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.category = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    searchContq3(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3q = [];
            for (var i = 0; i < this.category.length; i++) {
                let country = this.category[i];
                if (country.categoryname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3q = filtered;
        }
    }

    Getimagedata() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("regid", this.LoginDetails[0].RegId);
        uploadfile.append("catid", this.form1.value.Category.Categoryid);
        var url = "api/BG/Get_image_uploadDetails";
        this.genralservice.PostData(url, uploadfile).then((data: any[]) => {
            debugger
            if (data && data.length > 0) {
                this.gallerydate = data;
                this.imagedate = this.gallerydate[0].CreatedDate;
            } else {
                // If there is no data or no row at position zero, store today's date 95 days back in imagedate
                const today = new Date();
                const ninetyFiveDaysAgo = new Date(today.getTime() - (95 * 24 * 60 * 60 * 1000));
                this.imagedate = ninetyFiveDaysAgo.toISOString(); // Convert to ISO string format
            }
        },err =>{
          //  this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')
        })

    }

    detectFiles2(input2) {

        //let arr = [];
        //arr.push({ UserID: this.loginDet.Id, TokenID: this.loginDet.TokenID })
        const reader = new FileReader();
        if (input2.files.length) {

            const file = input2.files[0];
            reader.onload = () => {

                this.imagefile2 = reader.result;
                this.GalleryImages = this.imagefile2;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "gif" || extFile == "webp") {

                var UploadFile = new FormData();
                UploadFile.append("UploadedImage", file);
                var url = "api/BG/UploadGallery";
                this.genralservice.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {

                        this.imagefile2 = this.HomeUrl + data;
                        this.GalleryImages = data;
                    }
                });
            }
            else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }

        }

    }
    //share ur service end

    //image upload old code
    detectFiles33(input2) {
        debugger
        this.Getimagedata();
        const reader = new FileReader();
        if (input2.files.length) {
            const file = input2.files[0];
            reader.onload = () => {
                const imageUrl = URL.createObjectURL(file); // Convert ArrayBuffer to data URL
                const image = new Image();
                image.onload = () => {
                    debugger
                    const canvas = document.createElement('canvas');
                    let maxWidth = 600; // Adjust the maximum width as needed
                    let maxHeight = 400; // Adjust the maximum height as needed
                    let quality = 0.5; // Adjust the quality as needed (0.7 = 70% quality)

                    // Compress JPEG, PNG, and WebP images only
                    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp') {
                        const maxSizeKB =40; // Maximum size in KB
                        const ratio = Math.max(image.width / maxWidth, image.height / maxHeight);
                        if (ratio > 1) {
                            maxWidth = Math.round(image.width / ratio);
                            maxHeight = Math.round(image.height / ratio);
                        }
                        // Calculate the quality based on the target size
                        const targetSizeBytes = maxSizeKB * 1024;
                        const currentSizeBytes = file.size;
                        const compressionRatio = targetSizeBytes / currentSizeBytes;
                        if (compressionRatio < 1) {
                            quality *= compressionRatio;
                        }
                    }

                    canvas.width = maxWidth;
                    canvas.height = maxHeight;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

                    // Convert canvas to a Blob
                    canvas.toBlob((blob) => {
                        var UploadFile = new FormData();
                        UploadFile.append("UploadedImage", blob, file.name); // Append the compressed blob
                        var url = "api/BG/UploadGallery";
                        this.genralservice.PostData(url, UploadFile).then((data: any) => {
                            debugger
                            if (data != null) {
                                this.imagefile2 = this.HomeUrl + data;
                                this.GalleryImages = data;
                            }
                        });
                    }, file.type, quality);

                };
                image.src = imageUrl;
            };
            reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer
        }
    }

    detectFiles3(input2) {
        debugger
        this.Getimagedata();
        const reader = new FileReader();
        if (input2.files.length) {
            const file = input2.files[0];
            reader.onload = () => {
                const imageUrl = URL.createObjectURL(file); // Convert ArrayBuffer to data URL
                const image = new Image();
                image.onload = () => {
                    debugger
                    const canvas = document.createElement('canvas');
                    let maxWidth = 800; // Adjust the maximum width as needed
                    let maxHeight = 600; // Adjust the maximum height as needed
                    let quality = 0.5; // Adjust the quality as needed (0.7 = 70% quality)

                    // Compress JPEG, PNG, and WebP images only
                    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp') {
                        const maxSizeKB = 40; // Maximum size in KB
                        const ratio = Math.max(image.width / maxWidth, image.height / maxHeight);
                        if (ratio > 1) {
                            maxWidth = Math.round(image.width / ratio);
                            maxHeight = Math.round(image.height / ratio);
                        }
                        // Calculate the quality based on the target size
                        const targetSizeBytes = maxSizeKB * 1024;
                        const currentSizeBytes = file.size;
                        const compressionRatio = targetSizeBytes / currentSizeBytes;
                        if (compressionRatio < 1) {
                            quality *= compressionRatio;
                        }
                    }

                    canvas.width = maxWidth;
                    canvas.height = maxHeight;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

                    // Convert canvas to a Blob
                    canvas.toBlob((blob) => {
                        var UploadFile = new FormData();
                        UploadFile.append("UploadedImage", blob, file.name); // Append the compressed blob
                        // Check if imagedate is available and less than 90 days
                        if (this.imagedate && (new Date().getTime() - new Date(this.imagedate).getTime()) < (1 * 24 * 60 * 60 * 1000)) {
                            this.genralservice.ShowAlert('Alert', 'You have already uploaded image For this day.You can upload after 24 hours', 'error');
                        } else {
                            var url = "api/BG/UploadGallery";
                            this.genralservice.PostData(url, UploadFile).then((data: any) => {
                                debugger
                                if (data != null) {
                                    this.imagefile2 = this.HomeUrl + data;
                                    this.GalleryImages = data;
                                }
                            });
                        }
                    }, file.type, quality);

                };
                image.src = imageUrl;
            };
            reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer
        }
    }


    // reqeuest form strt
    GetHospitals() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '6');
        var url = "api/BG/HospitalDetails_CRUD";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.HsptName = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }
    searchCont8(event) {

        let filtered: any = [];
        let query = event.query;
        {

            this.deptList3 = [];
            for (var i = 0; i < this.HsptName.length; i++) {

                let country = this.HsptName[i];
                if (country.HsptName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList8 = filtered;
        }
    }

    //addNewTrip(addextra) {
    //    debugger
    //    //this.trip != newTrip
    //    this.addextra = 2;
    //}
    //addhospital() {
    //  debugger  

    //    this.arr = [];
    //    this.arr.push({

    //        HsptName: this.form2.value.HsptName,


    //    })
    //    var uploadfile = new FormData()
    //    uploadfile.append("Param", JSON.stringify(this.arr));
    //    uploadfile.append("Flag", '3');
    //    var url = "api/BG/Insert_Update_requestForm";
    //    this.genralservice.PostData(url, uploadfile).then(data => {

    //        if (data == "SUCCESS") {

    //            if (this.flag == 3) {
    //                this.genralservice.ShowAlert("SUCCESS", 'Added Hospital Succesfully', 'success');
    //            }
    //            //this.router.navigate(['/blooddonation']).then(() => {
    //            //    window.location.reload();
    //            //});
    //            this.form.reset();
    //            this.addextra = 1;
    //            this.GetHospitals();
    //            this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');


    //        }
    //        else {
    //            this.genralservice.ShowAlert("ERROR", 'Some thing went wrong', 'error')
    //        }

    //    })


    //}

    Requestform() {
        debugger
        if (!this.form2.value.username || !this.form2.value.Contmobile || !this.form2.value.bloodgroup || !this.form2.value.username
            || !this.form2.value.RequestTime || !this.form2.value.BloodRequestDate
            || !this.selectedGenders || !this.form2.value.unitsofblood || !this.form2.value.Pincode) {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }
        let HsptName; // Declare HsptName here

        if (this.form2.value.HsptName) {
            // Check if HsptName is not empty
            const hospitalNameParts = this.form2.value.HsptName.HsptName.split(' - ');
            if (hospitalNameParts.length >= 2) {
                // Ensure that HsptName can be split into parts
                HsptName = hospitalNameParts[0]; // Assign the split result to HsptName
            } else {
                // Handle the case where split result is not as expected
                console.error('Unexpected format for HsptName:', this.form2.value.HsptName);
                // Add appropriate error handling or fallback behavior here
            }
        } else {
            // Handle the case where HsptName is empty
            console.error('HsptName is empty');
            // Add appropriate error handling or fallback behavior here
        }

        this.arr = [];
        this.arr.push({
            RegId: this.RegId,
            ContactPerson: this.form2.value.contactperson,
            ContactMobile: this.form2.value.Contmobile,
            FullName: this.form2.value.username,

            Email: this.form2.value.email,
            Gender: this.selectedGenders,
            age: this.form2.value.age,
            UnitsofBloodId: this.form2.value.unitsofblood.UnitsofBloodId,
            UserAddress: this.form2.value.useraddress,
            Area: this.form2.value.area,
            BloodRequestDate: this.form2.value.BloodRequestDate,
            RequestTime: this.form2.value.RequestTime,
            DoctorName: this.form2.value.DoctorName,
            Purpose: this.form2.value.Purpose,
            HsptName: HsptName,
            HospitalPhonenumber: this.form2.value.HospitalPhonenumber,
            HospitalAddress: this.form2.value.HospitalAddress,
            BloodGroupId: this.form2.value.bloodgroup.BLGId,
            StateId: this.form2.value.statename.StateId,
            DistrictId: this.form2.value.districtname.DistrictID,
            CityId: this.form2.value.cityname.CityId,
            Note: this.form2.value.PersonalNote,
            Pincode: this.form2.value.Pincode,
            requestid: 1

        })
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/BG/Insert_Update_requestForm";
        this.genralservice.PostData(url, uploadfile).then(data => {
            debugger
            if (data == "SUCCESS") {

                if (this.flag == 2) {
                    this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');
                }
                this.router.navigate(['/blooddonation']).then(() => {
                    //  this.addextra = 1;
                    window.location.reload();
                });
                this.form.reset();
                this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');


            }
            else {
                this.genralservice.ShowAlert("ERROR", 'Some thing went wrong', 'error')
            }

        })


    }




    getunitofblood() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/UnitsofBlood_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.unitsofblood = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont4(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.unitsofblood.length; i++) {
                let country = this.unitsofblood[i];
                if (country.UnitsofBlood.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList4 = filtered;
        }
    }


    //updateSubDropdownPosition(event: MouseEvent) {
    //    // Adjust the sub-dropdown position based on mouse movement
    //    this.subDropdownStyle = {
    //        top: event.clientY + 'px',
    //        left: event.clientX + 'px',
    //        display: 'block',
    //    };
    //}

    //hideSubDropdown() {
    //    if (!this.isMouseOverSubDropdown) {
    //        this.subDropdownStyle = {
    //            display: 'none',
    //        };
    //    }
    //}

    //toggleDropdown() {
    //    this.showDropdownContent = !this.showDropdownContent;
    //}

    toggleDropdownActive() {
        this.dropdownActive = !this.dropdownActive;
    }

    back() {
        this.router.navigate(['/blooddonation']);
    }

    selectOption1(gender: string) {
        this.form.value('Gender').setValue(gender === '1' ? 'Female' : 'Male');
    }

    getChipStyle1(option: string): any {
        return {
            'background-color': this.selectedItem1 === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem1 === option ? 'white' : 'black',
            'border-color': this.selectedItem1 === option ? 'green' : '#ccc'
        };
    }

    selectGenders(gender: string) {
        this.selectedGenders = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }



    // request form end

    //profileeeeeeeeeeeeeee
    getprofile() {

        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : '');

        var url = "api/BG/checking_Mobile";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.profiledata = data;
            this.bindFormData(this.profiledata);

            this.LoginDetails = this.profiledata;
            // Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }

    reg() {

        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
    bindFormData(data) {

        if (data) {

            this.selectedGender = data[0].Gender;
            this.selectedpast = data[0].PastDonation;
            const lastDonateDate = data[0].Lastdonatedate && !isNaN(Date.parse(data[0].Lastdonatedate)) ?
                this.datePipe.transform(new Date(data[0].Lastdonatedate), 'yyyy-MM-dd') : null;

            const dob = data[0].DOB && !isNaN(Date.parse(data[0].DOB)) ?
                this.datePipe.transform(new Date(data[0].DOB), 'yyyy-MM-dd') : null;


            this.form3.patchValue({

                username: data[0].FullName,
                mobile: data[0].Phonenumber,
                email: data[0].Email,
                age: data[0].Age,
                bloodgroup: data[0].BLGName,
                BloodGroupId: data[0].BLGId,
                statename: data[0].StateName,
                StateId: data[0].StateId,
                districtname: data[0].DistrictName,
                DistrictId: data[0].DistrictId,
                cityname: data[0].CityName,
                CityId: data[0].CityId,
                useraddress: data[0].UserAddress,
                area: data[0].Area,
                Status: data[0].Status || data.Status,
                Statusphn: data[0].Statusphn || data.Statusphn,
                Rolestatus: data[0].Rolestatus || data.Rolestatus,
                Weight: data[0].Weight,
                Pincode: data[0].Pincode,
                DOB: dob,
                Lastdonatedate: lastDonateDate,
                Messagetorequester: data[0].Messagetorequester
            });
        }
    }


    Donersforms() {
        debugger
        if (!this.form3.value.username || !this.form.value.mobile || !this.form3.value.bloodgroup || !this.form3.value.Weight || !this.form3.value.age || !this.form3.value.Pincode || this.selectedGender === "" ||
            this.selectedpast === "") {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }
        if (this.selectedpast === 'Yes' && !this.form3.value.Lastdonatedate) {
            this.genralservice.ShowAlert("ERROR", 'Last donate date is required', 'error');
            return; // Stop further execution of the method
        }
        ;
        this.arr = [];
        const bloodgroup = this.form3.value.bloodgroup && this.form3.value.bloodgroup.BLGId !== undefined
            ? this.form3.value.bloodgroup.BLGId
            : this.LoginDetails[0] && this.LoginDetails[0].BLGId !== undefined
                ? this.LoginDetails[0].BLGId
                : this.LoginDetails.BLGId !== undefined
                    ? this.LoginDetails.BLGId
                    : this.LoginDetails.BloodGroupId;

        const stateId = this.form3.value.statename && this.form3.value.statename.StateId !== undefined
            ? this.form3.value.statename.StateId
            : this.LoginDetails[0] && this.LoginDetails[0].StateId !== undefined
                ? this.LoginDetails[0].StateId
                : this.LoginDetails.StateId !== undefined
                    ? this.LoginDetails.StateId
                    : this.LoginDetails.StateID;

        const districtId = this.form3.value.districtname && this.form3.value.districtname.DistrictID !== undefined
            ? this.form3.value.districtname.DistrictID
            : this.LoginDetails[0] && this.LoginDetails[0].DistrictID !== undefined
                ? this.LoginDetails[0].DistrictID
                : this.LoginDetails.DistrictID !== undefined
                    ? this.LoginDetails.DistrictID
                    : this.LoginDetails.DistrictID;

        const cityId = this.form3.value.cityname && this.form3.value.cityname.CityId !== undefined
            ? this.form3.value.cityname.CityId
            : this.LoginDetails[0] && this.LoginDetails[0].CityId !== undefined
                ? this.LoginDetails[0].CityId
                : this.LoginDetails.CityId !== undefined
                    ? this.LoginDetails.CityId
                    : this.LoginDetails.CityID;
        const lastDonateDate = this.selectedpast === 'No' ? null : this.form3.value.Lastdonatedate || this.LoginDetails.Lastdonatedate;

        let updatedRoleId: string;

        if (this.LoginDetails && this.LoginDetails[0].RoleId != null) {
            if (this.LoginDetails[0].RoleId === 0) {
                updatedRoleId = '2';
            } else if (this.LoginDetails[0].RoleId > 0) {
                updatedRoleId = this.LoginDetails[0].RoleId;
            }
        }

        this.arr.push({
            RegId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
            TokenId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].TokenId : this.LoginDetails.RegId) : '',
            FullName: this.form3.value.username,
            Phonenumber: this.form3.value.mobile,
            Weight: this.form3.value.Weight,
            Email: this.form3.value.email || this.LoginDetails.Email,
            Gender: this.selectedGender || this.LoginDetails.Gender,
            Lastdonatedate: lastDonateDate,
            DOB: this.form3.value.DOB || this.LoginDetails.DOB,
            Pincode: this.form3.value.Pincode || this.LoginDetails.Pincode,
            PastDonation: this.selectedpast,
            age: this.form3.value.age || this.LoginDetails.Age,
            BloodGroupId: bloodgroup,
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
            UserAddress: this.form3.value.useraddress || this.LoginDetails.UserAddress,
            Area: this.form3.value.area || this.LoginDetails.Area,
            Messagetorequester: this.form3.value.Messagetorequester || this.LoginDetails.Messagetorequester,
            RoleId: updatedRoleId,
            Status: this.form3.value.Status || this.LoginDetails.Status,
            Statusphn: this.form3.value.Statusphn || this.LoginDetails.Statusphn,
            Rolestatus: this.form3.value.Rolestatus || this.LoginDetails.Rolestatus,
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/BG/Insert_Update_DonersForm";
        this.genralservice.PostData(url, uploadfile).then(data => {

            if (data == "SUCCESS") {
                if (this.flag == 2) {
                    this.genralservice.ShowAlert("ERROR", 'Profile Updated Successfully', 'error');
                } else {

                    localStorage.setItem('LoginDetails', JSON.stringify(this.arr));
                    this.genralservice.ShowAlert("SUCCESS", 'Profile Updated Successfully', 'success');
                    this.router.navigate(['/blooddonation']).then(() => {
                        window.location.reload();

                    });

                }
                this.form.reset();
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong. Please check validation fields', 'error');
            }
        });
    }


    toggleStatuss(checked: boolean = true) {
        this.form.controls['Status'].setValue(checked);
    }


    toggleStatuss1(checked: boolean = true) {
        this.form.controls['Statusphn'].setValue(checked);
    }


    toggleStatuss2(checked: boolean) {
        this.form.controls['Rolestatus'].setValue(checked);
    }
    //updateSubDropdownPosition(event: MouseEvent) {
    //    // Adjust the sub-dropdown position based on mouse movement
    //    this.subDropdownStyle = {
    //        top: event.clientY + 'px',
    //        left: event.clientX + 'px',
    //        display: 'block',
    //    };
    //}

    //hideSubDropdown() {
    //    if (!this.isMouseOverSubDropdown) {
    //        this.subDropdownStyle = {
    //            display: 'none',
    //        };
    //    }
    //}

    //toggleDropdown() {
    //    this.showDropdownContent = !this.showDropdownContent;
    //}


    //// Function to select a blood group
    //selectBloodgroup(bloodgroup: string) {
    //    this.selectedBloodgroup = bloodgroup;
    //    this.showDropdownContent = false; // Hide the dropdown after selection if needed
    //}

    //selectGender(gender: string) {
    //    
    //    this.selectedGender = gender;
    //    // Optionally call your Interior function with the `id`
    //    // this.Interior(id);
    //}
    //PastDonation(Past: string) {
    //    
    //    this.selectedpast = Past;
    //    // Optionally call your Interior function with the `id`
    //    // this.Interior(id);
    //}

    //profile endddddddddd



    getstates() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchConts(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptLists = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptLists = filtered;
        }
    }


    getDistricts() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form3.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1s(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1s = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1s = filtered;
        }
    }

    getCitys() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form3.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2s(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2s = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2s = filtered;
        }
    }



    getstatess() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchContss(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrictss() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form1.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1ss(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCityss() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form1.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2ss(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }



    ///request
    getstate1() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont11(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict1() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form2.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont12(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity1() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form2.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont22(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    //register

    getstate111() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont111(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict111() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont112(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity111() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont222(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }



    ComponentDropdown() {

        this.isDropdownOpen = !this.isDropdownOpen;
    }

    ComponentDropdown2() {

        this.isDropdownOpen2 = !this.isDropdownOpen2;
    }
    ComponentDropdown3() {

        this.isDropdownOpen3 = !this.isDropdownOpen3;
    }

    ComponentDropdown4() {

        this.isDropdownOpen4 = !this.isDropdownOpen4;
    }
    ComponentDropdown5() {

        this.isDropdownOpen5 = !this.isDropdownOpen5;
    }
    GetPincode() {

        var url = "api/BG/Get_PincodeDropdown";
        this.genralservice.GetData(url).then(data => {
            this.pincodedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')
        })
    }

    Selectcount5(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList5 = [];
            for (var i = 0; i < this.pincodedata.length; i++) {
                let country = this.pincodedata[i];
                if (country.Pincode.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }
            this.deptList5 = filtered;
        }
    }

}
