import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    LoginDetails1: any; LoginDetails: any;
    constructor(private router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
    }
    ngOnInit() { }
    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']);
        }
    }

    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }

}
