import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormBuilder, FormControl, Validators, FormsModule } from '@angular/forms';
import { GeneralService } from '../../../general.service';
import { Route, Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    enquiery: any = [];
    formGroup: any;
    contactForm: FormsModule;
    Fullname: any;
    email: any;
    Message: any;
    //subject: any;
    //number: any;
    LoginDetails: any; LoginDetails1: any;
    constructor(private viewportScroller: ViewportScroller, fb: FormBuilder, public generalservice: GeneralService,
        private router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.sendmail();
    }
    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']);
        }
    }

    sendmail() {
        
        this.enquiery = [];
        this.enquiery.push({
            FarmerName: this.Fullname,
            EmailID: this.email,
            Comments: this.Message,

        })
        var uploadfile = new FormData;
        uploadfile.append("Email", JSON.stringify(this.enquiery));
        var url = "api/BG/EnquiryMailTo_BloodCustomer";
        this.generalservice.PostData(url, uploadfile).then(data => {
            
            this.generalservice.ShowAlert("SUCCESS", 'Mail sent Successfuly', 'success');
            Swal.fire('SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success')
            this.Fullname = '';
            this.email = '';
            this.Message = '';

            //setTimeout(() => {
            //    window.location.reload();
            //}, 2000);
        });

    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }



}
