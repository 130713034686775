<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Let's Help </title>

    <!-- core CSS -->
  
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

    <!--[if lt IE 9]>
        <script src="js/html5shiv.js"></script>
        <script src="js/respond.min.js"></script>
        <![endif]
        <link rel="shortcut icon" href="images/ico/favicon.ico">
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png">
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png">
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png">
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png">
    </head><!--/head-->
</head>
<body>
    <!--Bootstrap Approach-->
     
    <div class=" pt-100">
        <div class="container-fluid">
            <!--card1-->
            <div class="container" style="background-color: #fb0300;">

                <div class="row">
                    <div class="card_menu">
                        <div class="col-lg-3 col-9">
                            <a href="/">
                                <img src="assets/img/Blood_DonationImages/LETS_HELP_LOGO.png" alt="logo" class="width" />
                            </a>
                        </div>
                       
                        <div class="col-lg-9 col-3 none ml-n5">
                            <ng-container *ngIf="LoginDetails; else showLoginButton">
                                <div class="dropdown" (click)="toggleDropdown()">
                                    <button class="dropbtn" style="color:white">
                                        {{letter}}

                                    </button>
                                    <div class="dropdown-content" style="    width:220%;">
                                        <a href="Profile" (click)="pro()"> My Profile </a>
                                        <a (click)="signOut()">Sign Out</a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #showLoginButton>
                                <p class="Login log_infont" (click)="openLoginModal()"><b>LOGIN</b></p>
                            </ng-template>

                        </div>
                    </div>
                </div>
                <div class="row">
                  

                        <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff;">
                            <h2 class="textcolor"> <a name="bloodfacts">Blood Facts and Statistics</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>Every two seconds someone in India needs blood.</li>
                                <li>Approximately 36,000 units of red blood cells are needed every day in India.</li>
                                <li>Nearly 7,000 units of platelets and 10,000 units of plasma are needed daily in India.</li>
                                <li>Nearly 21 million blood components are transfused each year in India.</li>
                                <li>The average red blood cell transfusion is approximately 3 pints.</li>
                                <li>The blood type most often requested by hospitals is type O.</li>
                                <li>It is estimated that sickle cell disease affects 90,000 to 100,000 people in India. About 1,000 babies are born with the disease each year. Sickle cell patients can require frequent blood transfusions throughout their lives.</li>
                                <li>According to the American Cancer Society, more than 1.69 million people are expected to be diagnosed with cancer in 2017. Many of them will need blood, sometimes daily, during their chemotherapy treatment.</li>
                                <li>
                                    A single car accident victim can require as many as 100 pints of blood.
                                    <p style="font-style: italic;">
                                        For more information about national blood collection and utilization trends, visit the  <a href="http://www.aabb.org/tm/Pages/default.aspx"> <span style="color: #c52d2f;">AABB website. </span></a>   AABB is an international, not-for-profit association representing individuals and institutions involved in the field of transfusion medicine and cellular therapies.
                                    </p>
                                </li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About The Blood Supply</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>The number of whole blood and red blood cell units collected in India in a year: 13.6 million</li>
                                <li>The number of blood donors in India in a year: 6.8 million</li>
                                <li>Although an estimated 38 percent of India population is eligible to donate blood at any given time, less than 10 percent of that eligible population actually do each year.</li>
                                <li>Blood cannot be manufactured – it can only come from generous donors.</li>
                                <li>Type O negative blood (red cells) can be transfused to patients of all blood types. It is always in great demand and often in short supply.</li>
                                <li>Type AB positive plasma can be transfused to patients of all other blood types. AB plasma is also usually in short supply.</li>
                            </ul>
                            <p style="font-style: italic;">For more information about national blood collection and utilization trends, visit the <a href="http://www.aabb.org/tm/Pages/default.aspx"><span style="color: #c52d2f;">AABB website.</span></a> AABB is an international, not-for-profit association representing individuals and institutions involved in the field of transfusion medicine and cellular therapies.</p>

                            <h2 class="textcolor"><a name="bloodfacts">Facts About The Blood Donation Process</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>Donating blood is a safe process. A sterile needle is used only once for each donor and then discarded.</li>
                                <li>Blood donation is a simple four-step process: registration, medical history and mini-physical, donation and refreshments.</li>
                                <li>Every blood donor is given a mini-physical, checking the donor's temperature, blood pressure, pulse and hemoglobin to ensure it is safe for the donor to give blood.</li>
                                <li>The actual blood donation typically takes less than 10-12 minutes. The entire process, from the time you arrive to the time you leave, takes about an hour and 15 minutes. Donors may save time by completing a RapidPass on the day of their donation prior to arriving at the blood drive.</li>
                                <li>The average adult has about 10 pints of blood in his body. Roughly 1 pint is given during a donation.</li>
                                <li>A healthy donor may donate red blood cells every 56 days or Power Red every 112 days.</li>
                                <li>A healthy donor may donate platelets as few as 7 days apart, but a maximum of 24 times a year.</li>
                                <li>All donated blood is tested for HIV, hepatitis B and C, syphilis and other infectious diseases before it can be released to hospitals.</li>
                                <li>Information you give to the American Red Cross during the donation process is confidential. It may not be released without your permission except as directed by law.</li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About Blood and Its Components</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>There are four types of transfusable products that can be derived from blood: red cells, platelets, plasma and cryoprecipitate. Typically, two or three of these are produced from a pint of donated whole blood.</li>
                                <li>A single donation can potentially help more than one patient.</li>
                                <li>Donors can give either whole blood or specific blood components only. The process of donating specific blood components – red cells, plasma or platelets – is called apheresis.</li>
                                <li>One transfusion dose of platelets can be obtained through one apheresis donation of platelets or by combining the platelets derived from five whole blood donations.</li>
                                <li>Most donated red blood cells must be used within 42 days of collection.</li>
                                <li>Donated platelets must be used within five days of collection – new donations are constantly needed.</li>
                                <li>Plasma and cryoprecipitate are stored in frozen state and can be used for up to one year after collection.</li>
                                <li>Healthy bone marrow makes a constant supply of red cells, plasma and platelets. The body will replenish the elements given during a blood donation – some in a matter of hours and others in a matter of weeks.</li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About Donors</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>The number one reason donors say they give blood is because they "want to help others."</li>
                                <li>Two most common reasons cited by people who don't give blood are: "Never thought about it" and "I don't like needles."</li>
                                <li>Half of Red Cross donors male, and half are female.</li>
                                <li>The Red Cross only accepts blood donations from volunteer donors.</li>
                                <li>Among Red Cross donors in a given year, 24 percent donate occasionally, 26 percent are first-time donors, and 50 percent are regular, loyal donors.</li>
                                <li>Only 7 percent of people in the U.S. have type O negative blood. Type O negative donors are universal red cell donors as their blood can be given to people of all blood types.</li>
                                <li>Type O negative blood is needed in emergencies before the patient's blood type is known and with newborns who need blood.</li>
                                <li>About 45 percent of people in the U.S. have type O (positive or negative) blood. This percentage is higher among Hispanics – 57 percent, and among African Americans – 51 percent.</li>
                                <li>Only 3 percent of people in the U.S. have AB positive blood type. AB positive type blood donors are universal donors of plasma, which is often used in emergencies, for newborns and for patients requiring massive transfusions.</li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About American Red Cross Blood Services</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>The American Red Cross blood program started in 1940, under the leadership of Dr. Charles Drew.</li>
                                <li>The Red Cross supplies about 40 percent of the nation's blood supply.</li>
                                <li>The Red Cross provides blood for patients in approximately 2,600 hospitals across the U.S.</li>
                                <li>The Red Cross makes blood available to any patient who needs it — patients are not required to find donors to replace the blood they use (a practice common in Europe and some U.S. blood banks) allowing the patient and their family to focus on recovery.</li>
                                <li>Eighty percent of the blood donations given to the Red Cross are collected at mobile blood drives set up at community organizations, companies, high schools, colleges, places of worship or military installations. The remaining 20 percent are collected at Red Cross donation centers.</li>
                                <li>The Red Cross works with more than 58,000 blood drive sponsors each year to hold more than 145,000 blood drives, providing convenient locations for people to give blood.</li>
                            </ul>


                            <h2 class="textcolor" style=" margin-top: -0%;"> <a name="bloodfacts">  Blood Components</a></h2>&nbsp;
                            <h3 style=" margin-top: -3%;">How Can One Donation Help Multiple People?</h3>
                            <ul style=" margin-top: -3%;">
                                <li>
                                    In modern medical treatments, patients may
                                    receive a pint of whole blood or just specific components of the blood
                                    needed to treat their particular condition. Up to four components can
                                    be derived from donated blood. This approach to treatment, referred to as
                                    blood component therapy, allows several patients to benefit from one pint
                                    of donated whole blood. The main transfusable blood components include:
                                </li>
                            </ul>
                            <br>
                            <img src="assets/images/blood-components-vile.jpg" class="img-responsive pull-left">
                            <h3>Whole Blood</h3>
                            <p style=" margin-top: -3%;">
                                Whole blood contains red cells, white cells, and platelets (~45% of volume)
                                suspended in plasma (~55% of volume).
                            </p><br>
                            <h3 style=" margin-top: -1%;">Red Cells</h3>
                            <p style=" margin-top: -3%;">
                                Red cells, or erythrocytes, carry oxygen from the lungs to your body’s
                                tissue and take carbon dioxide back to your lungs to be exhaled.
                            </p><br>
                            <h3>Platelets</h3>

                            <p style=" margin-top: -3%;">
                                Platelets, or thrombocytes, are small, colorless cell fragments in the
                                blood whose main function is to interact with clotting proteins to stop or
                                prevent bleeding.
                            </p>
                            <br>
                            <h3 style=" margin-top: -1%;">Plasma</h3>
                            <p style=" margin-top: -3%;">
                                Plasma is a fluid, composed of about 92% water, 7% vital proteins such as
                                albumin, gamma globulin, anti- hemophilic factor, and other clotting
                                factors, and 1% mineral salts, sugars, fats, hormones and vitamins.
                            </p>
                            <br>
                            <h3 style=" margin-top: -1%;">Cryoprecipitated AHF</h3>
                            <p style=" margin-top: -3%;">
                                Cryoprecipitated Antihemophilic Factor (Cryo) is a portion of plasma rich
                                in clotting factors, including Factor VIII and fibrinogen. It is prepared
                                by freezing and then slowly thawing the frozen plasma.
                            </p>
                            <br>
                            <h3 class="textcolor1"> Transfusable Blood Components Summary </h3>
                            <img src="assets/images/statistics.jpg" class="img-responsive center-block">
                            <br>
                            <h2 class="textcolor"> <a name="bloodfacts"> Whole Blood and Red Blood Cells</a></h2>
                            <p class="small_text" style="text-align: justify; margin-top: -2%; ">
                                <img src="assets/images/blood_components_RBCs.jpg" class="img-responsive pull-right" style="padding: 10px;">
                                Red Blood Cells (RBCs) are perhaps the most recognizable component of whole blood. RBCs contain hemoglobin, a complex protein containing iron that carries oxygen through the body. The percentage of blood volume composed of red blood cells is called the “hematocrit.” There are about one billion red blood cells in two to three drops of blood, and for every 600 red blood cells, there are about 40 platelets and one white cell.<br><br>
                                Manufactured in the bone marrow, RBCs are continuously produced and broken down. They live for about 120 days in the circulatory Red Blood Cells (RBCs)system. Red blood cells are prepared from whole blood by removing plasma, or the liquid portion of the blood, and they are used to treat anemia while minimizing an increase in blood volume. Improvements in cell preservation solutions over decades have increased the shelf-life of red blood cells from 21 to 42 days.<br><br>
                                RBCs may be treated and frozen for an extended storage, of 10 years or more. Patients who benefit most from transfusion of red blood cells include those with chronic anemia resulting from kidney failure or gastrointestinal bleeding, and those with acute blood loss resulting from trauma.<br><br>
                                Prestorage leukocyte-reduced red blood cells require special preparation by removing leukocytes (white blood cells) by filtration shortly after donation. This is done before storage because high numbers of leukocytes remaining in a unit of RBCs during the storage process can fragment, deteriorate, and release cytokines (chemicals that affect the inflammatory response). Leukocytes have been implicated as a cause of reactions to a current and subsequent blood transfusions in some transfusion recipients.


                                <h2 class="textcolor"> <a name="bloodfacts"> Platelets</a></h2>

                            <p class="small_text" style="text-align: justify; margin-top: -2%; ">
                                <img src="assets/images/blood_component_platelets.jpg" class="img-responsive pull-right" style="padding: 10px;">
                                Platelets are small blood components that help the clotting process by sticking to the lining of blood vessels. Platelets are made in the bone marrow and survive in the circulatory system<br>
                                Platelets help with clotting

                                Platelets are prepared by using a centrifuge to separate the platelet-rich plasma from the donated unit of whole blood.<br>
                                Platelets may also be obtained from a donor by a process known as apheresis, or plateletpheresis. In this process, blood is drawn from the donor into an apheresis instrument which separates the blood into its components, retains some of the platelets, and returns the remainder of the blood to the donor.<br>
                                This single donor platelet product contains about four to six times as many platelets as a unit of platelets obtained from whole blood. Platelets are used to treat a condition called thrombocytopenia, in which there is a shortage of platelets, and they are also used to treat platelet function abnormalities. Platelets are stored at room temperature with constant agitation for 5 days.

                            </p>

                            <br>

                            <h2 class="textcolor" style=" margin-top: -1%;"> <a name="bloodfacts"> Plasma</a></h2>
                            <p class="small_text" style="text-align: justify; margin-top: -2%; ">
                                Plasma is the liquid portion of blood – a protein-salt solution in which red and white blood cells and platelets are suspended. Plasma, which is 92 percent water, constitutes 55 percent of blood volume. Plasma contains albumin (the chief protein constituent), fibrinogen (responsible, in part, for the clotting of blood) and globulins (including antibodies). Plasma serves a variety of functions, from maintaining a satisfactory blood pressure and volume to supplying critical proteins for blood clotting and immunity. PlasmaIt also serves as the medium for exchange of vital minerals such as sodium and potassium and helps to maintain a proper pH (acid-base) balance in the body, which is critical to cell function. Plasma is obtained by separating the liquid portion of blood from the cells.<br>
                                Plasma is frozen quickly after donation (up to 24 hours) to preserve clotting factors, stored up to one year, and thawed shortly before use. It is commonly transfused to trauma patients and patients with severe liver disease or multiple clotting factor deficiencies.<br>
                                Plasma derivatives are concentrates of specific plasma proteins prepared from pools (many donor units) of plasma. Plasma derivatives are obtained through a process known as fractionation. The derivatives are treated with heat and/or solvent detergent to kill certain viruses like those that cause HIV, hepatitis B, and hepatitis C.<br>
                            </p>
                            <h3 class="textcolor">
                                Donate Blood Plasma to Those in Need »
                            </h3>
                            <h4 style=" margin-top: -2%;">Plasma derivatives include:</h4>

                            <ul style=" margin-top: -3%;">
                                <li>Factor VIII Concentrate</li>
                                <li> Factor IX Concentrate</li>
                                <li>
                                    Anti-Inhibitor Coagulation Complex (AICC)
                                    Albumin
                                </li>
                                <li>Immune Globulins, including Rh Immune Globulin</li>
                                <li>Anti-Thrombin III Concentrate</li>
                                <li>Alpha 1-Proteinase Inhibitor Concentrate</li>
                            </ul>


                            <h2 class="textcolor"> <a name="whitebloodcells"> White Blood Cells & Granulocytes</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%; ">
                                <h4>White Blood Cells</h4>
                                <p style=" margin-top: -3%;">
                                    White blood cells (leukocytes) are one of the body’s defenses against disease. Some white cells travel throughout the body and destroy bacteria, some produce antibodies against bacteria and viruses, and others help  malignant diseases. One’s own “leukocytes” help maintain the body’s immune function, but when present in donated blood, they serve no purpose. In fact, leukocytes may carry viruses that cause immune suppression and release toxic substances in the recipient. Leukocytes can cause a reaction when transfused, and are often removed from the transfusable blood components, a process called leuko-reduction. The majority of white blood cells are produced in the bone marrow, where they outnumber red blood cells by 2 to 1. However, in the blood stream, there are about 600 red blood cells for every white blood cell. There are several different types of white blood cells.
                                </p>
                                <br>
                                <h4 style=" margin-top: -1%;">Granulocytes</h4>
                                <p style=" margin-top: -3%;">
                                    Granulocytes are one type of several types of white blood cells that are in fact used in more specialized transfusion therapy. Granulocytes and monocytes protect against infection by surrounding and destroying invading bacteria and viruses, and lymphocytes aid in the immune defense system. Granulocytes are prepared by an automated process called apheresis, and must be transfused within 24 hours after collection. They are used for infections that are unresponsive to antibiotic therapy.
                                </p>
                            </div>
                            <h2 class="textcolor"> <a name="whitebloodcells"> Types of Blood Transfusions</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%; ">
                                Most of the donated blood collected by the American Red Cross is used for direct blood transfusions. Common types of blood transfusions including platelet, plasma and red blood cell transfusions.

                                <h4>Red Blood Cell Transfusions</h4>
                                <p style=" margin-top: -3%;">
                                    A patient suffering from an iron deficiency or anemia, a condition where the body does not have enough red blood cells, may receive a Red Blood Cell Transfusion. This type of transfusion increases a patient’s hemoglobin and iron levels, while improving the amount of oxygen in the body.
                                </p>
                                <h4>Platelet Transfusions</h4>
                                <p style=" margin-top: -3%;">
                                    Platelets are a component of blood that stops the body from bleeding. Often patients suffering from leukemia, or other types of cancer, have lower platelet counts as a side effect of their chemotherapy treatments. Patients who have illnesses that prevent the body from making enough platelets have to get regular transfusions to stay healthy.
                                </p>
                                <h4>Plasma Transfusions</h4>
                                <p style=" margin-top: -3%;">
                                    Plasma is the liquid part of the body’s blood. It contains important proteins and other substances crucial to one’s overall health. Plasma transfusions are used for patients with liver failure, severe infections, and serious burns.
                                </p>
                            </div>
                            <h2 class="textcolor"> <a name="whitebloodcells"> Reasons People Receive Blood Transfusions</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%; ">
                                Blood transfusions are a very common medical procedure. A total of 21 million blood components are transfused each year in the United States alone.<br>

                                <h4>Here are the reasons that a patient may undergo a blood transfusion:</h4>
                                <p style=" margin-top: -3%;">
                                    Most patients who have a major surgical procedure, will have a blood transfusion to replace any blood loss during their surgery.
                                    Blood transfusions are used for patients whom have experienced serious injuries from car crashes or natural disasters.
                                    Individuals with an illness that causes anemia, such as leukemia or kidney disease will often be the recipients of blood transfusions.
                                </p>
                            </div>


                            <h2 class="textcolor"> <a name="whitebloodcells"> The Process</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%;">
                                <h4>Location</h4>
                                <p style=" margin-top: -3%;">
                                    Like most medical procedures, a blood transfusion will take place at a hospital or doctor’s office.<br>
                                </p>
                                <h4>Before the Procedure</h4>
                                <p style=" margin-top: -3%;">
                                    A nurse or doctor will check the patient’s blood pressure, pulse, and temperature before starting the transfusion.<br>
                                </p>
                                <h4>During the Procedure</h4>
                                <p style=" margin-top: -3%;">
                                    The blood transfusion procedure begins when an intravenous (IV) line is placed onto the patient’s body. It is through the IV that the patient will begin to receive the new blood. Depending on the amount of blood, a simple blood transfusion can take between 1-4 hours.<br>
                                </p>
                                <h4>After the Procedure</h4>
                                <p style=" margin-top: -3%;">
                                    Following the completion of the blood transfusion, the patient’s vital signs are checked and the IV is removed. For a couple of days after the transfusion, patients may experience some soreness near the puncture for the IV. The patient’s doctor may request a checkup after the transfusion to see the body’s reaction to the new blood.
                                </p>
                            </div>
                            <h2 class="textcolor"> <a name="whitebloodcells">Risks & Complications</a></h2>

                            <div class="small_text" style="text-align: justify;   margin-top: -2%;">
                                Often patients who have received a blood transfusion experience no complications or problems. However, minor to severe problems do occasional occur. Some of the most common complications include:

                                <h4>Allergic Reactions</h4>
                                <p style=" margin-top: -3%;">
                                    Some people have allergic reactions to blood received during a transfusion, even when given the right blood type. In these cases symptoms include hives and itching. Like most allergic reactions, this can be treated with antihistamines. However, if the reaction becomes serious, a doctor should be consulted.
                                </p>
                                <h4>Fever</h4>
                                <p style=" margin-top: -3%;">
                                    Developing a fever after a transfusion is not serious. A fever is your body’s response to the white blood cells in the transfused blood. However, it can be a sign of a serious reaction if the patient is also experiencing nausea or chest pain. Patients should consult their doctors if other symptoms or side effects are present.
                                </p>
                                <h4>Acute Immune Hemolytic Reaction</h4>
                                <p style=" margin-top: -3%;">
                                    An acute immune hemolytic reaction is a very serious, but rare, reaction caused by a patient’s body attacking the transfused red blood cells. The attack triggers a release of a substance that damages the kidneys. This is often the case when the donor blood is not a proper match with the patient’s blood type. Symptoms include nausea, fever, chills, chest and lower back pain, and dark urine.
                                </p>
                                <h4>Blood-borne Infections</h4>
                                <p style=" margin-top: -3%;">
                                    All donated blood is screened and tested for potential viruses, bacteria, and parasites. However, occasionally these agents can still infect a transfusion patient after the procedure.<br>
                                    The risk of catching a virus or any other blood-borne infection from a blood transfusion is very low.<br>
                                    <ul>
                                        <li>HIV: All donated blood is thoroughly tested for HIV. There is a 1 in 2 million chance that donated blood will not only carry HIV but also infect a transfusion recipient.</li>
                                        <li>Hepatitis B and C: The odds of catching hepatitis B from donated blood is about 1 in 300,000, while the risk with hepatitis C is 1 in 1.5 million.</li>
                                        <li>West Nile Virus: The risk of catching West Nile Virus from a blood transfusion is approximately 1 in 350,000.</li>
                                    </ul>
                            </div>

                            <h2 class="textcolor"> <a name="whitebloodcells"> What Donors Should Know About Iron and Blood Donation</a></h2>

                            <p class="small_text" style="text-align: justify;   margin-top: -2%; ">
                                The American Red Cross routinely checks your hemoglobin before each blood and platelet donation. Hemoglobin is a protein in your body that contains iron and carries oxygen to the tissues in your body. A screening test measures the amount of hemoglobin from a single drop of blood obtained from a finger stick. If your hemoglobin is too low, we will ask that you wait to donate.<br>
                                Iron is an essential mineral that is a part of hemoglobin that helps maintain your strength and energy. Your body needs iron to make new blood cells, replacing the ones lost through blood donations<br>.

                                There are things you can do to help maintain healthy iron levels:<br>
                                Eat a nutritious, well-balanced diet with foods rich in iron and high in vitamin C<br>
                                Frequent donors and young donors should consult with their health-care provider about taking multivitamins with 18 mg of iron<br>
                            </p>

                        </form>

                   
                </div>

            </div><br />
       
        </div>
        <!--<div class="container-fluid">
            <div class="container card1" id="feature">

                <div id="conatcat-info ">
                    <div class="container">
                        <br />
                        <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff; margin-right: 15px; margin-left: 15px;">
                            <h2 class="textcolor"> <a name="bloodfacts">Blood Facts and Statistics</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>Every two seconds someone in India needs blood.</li>
                                <li>Approximately 36,000 units of red blood cells are needed every day in India.</li>
                                <li>Nearly 7,000 units of platelets and 10,000 units of plasma are needed daily in India.</li>
                                <li>Nearly 21 million blood components are transfused each year in India.</li>
                                <li>The average red blood cell transfusion is approximately 3 pints.</li>
                                <li>The blood type most often requested by hospitals is type O.</li>
                                <li>It is estimated that sickle cell disease affects 90,000 to 100,000 people in India. About 1,000 babies are born with the disease each year. Sickle cell patients can require frequent blood transfusions throughout their lives.</li>
                                <li>According to the American Cancer Society, more than 1.69 million people are expected to be diagnosed with cancer in 2017. Many of them will need blood, sometimes daily, during their chemotherapy treatment.</li>
                                <li>
                                    A single car accident victim can require as many as 100 pints of blood.
                                    <p style="font-style: italic;">
                                        For more information about national blood collection and utilization trends, visit the  <a href="http://www.aabb.org/tm/Pages/default.aspx"> <span style="color: #c52d2f;">AABB website. </span></a>   AABB is an international, not-for-profit association representing individuals and institutions involved in the field of transfusion medicine and cellular therapies.
                                    </p>
                                </li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About The Blood Supply</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>The number of whole blood and red blood cell units collected in India in a year: 13.6 million</li>
                                <li>The number of blood donors in India in a year: 6.8 million</li>
                                <li>Although an estimated 38 percent of India population is eligible to donate blood at any given time, less than 10 percent of that eligible population actually do each year.</li>
                                <li>Blood cannot be manufactured – it can only come from generous donors.</li>
                                <li>Type O negative blood (red cells) can be transfused to patients of all blood types. It is always in great demand and often in short supply.</li>
                                <li>Type AB positive plasma can be transfused to patients of all other blood types. AB plasma is also usually in short supply.</li>
                            </ul>
                            <p style="font-style: italic;">For more information about national blood collection and utilization trends, visit the <a href="http://www.aabb.org/tm/Pages/default.aspx"><span style="color: #c52d2f;">AABB website.</span></a> AABB is an international, not-for-profit association representing individuals and institutions involved in the field of transfusion medicine and cellular therapies.</p>

                            <h2 class="textcolor"><a name="bloodfacts">Facts About The Blood Donation Process</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>Donating blood is a safe process. A sterile needle is used only once for each donor and then discarded.</li>
                                <li>Blood donation is a simple four-step process: registration, medical history and mini-physical, donation and refreshments.</li>
                                <li>Every blood donor is given a mini-physical, checking the donor's temperature, blood pressure, pulse and hemoglobin to ensure it is safe for the donor to give blood.</li>
                                <li>The actual blood donation typically takes less than 10-12 minutes. The entire process, from the time you arrive to the time you leave, takes about an hour and 15 minutes. Donors may save time by completing a RapidPass on the day of their donation prior to arriving at the blood drive.</li>
                                <li>The average adult has about 10 pints of blood in his body. Roughly 1 pint is given during a donation.</li>
                                <li>A healthy donor may donate red blood cells every 56 days or Power Red every 112 days.</li>
                                <li>A healthy donor may donate platelets as few as 7 days apart, but a maximum of 24 times a year.</li>
                                <li>All donated blood is tested for HIV, hepatitis B and C, syphilis and other infectious diseases before it can be released to hospitals.</li>
                                <li>Information you give to the American Red Cross during the donation process is confidential. It may not be released without your permission except as directed by law.</li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About Blood and Its Components</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>There are four types of transfusable products that can be derived from blood: red cells, platelets, plasma and cryoprecipitate. Typically, two or three of these are produced from a pint of donated whole blood.</li>
                                <li>A single donation can potentially help more than one patient.</li>
                                <li>Donors can give either whole blood or specific blood components only. The process of donating specific blood components – red cells, plasma or platelets – is called apheresis.</li>
                                <li>One transfusion dose of platelets can be obtained through one apheresis donation of platelets or by combining the platelets derived from five whole blood donations.</li>
                                <li>Most donated red blood cells must be used within 42 days of collection.</li>
                                <li>Donated platelets must be used within five days of collection – new donations are constantly needed.</li>
                                <li>Plasma and cryoprecipitate are stored in frozen state and can be used for up to one year after collection.</li>
                                <li>Healthy bone marrow makes a constant supply of red cells, plasma and platelets. The body will replenish the elements given during a blood donation – some in a matter of hours and others in a matter of weeks.</li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About Donors</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>The number one reason donors say they give blood is because they "want to help others."</li>
                                <li>Two most common reasons cited by people who don't give blood are: "Never thought about it" and "I don't like needles."</li>
                                <li>Half of Red Cross donors male, and half are female.</li>
                                <li>The Red Cross only accepts blood donations from volunteer donors.</li>
                                <li>Among Red Cross donors in a given year, 24 percent donate occasionally, 26 percent are first-time donors, and 50 percent are regular, loyal donors.</li>
                                <li>Only 7 percent of people in the U.S. have type O negative blood. Type O negative donors are universal red cell donors as their blood can be given to people of all blood types.</li>
                                <li>Type O negative blood is needed in emergencies before the patient's blood type is known and with newborns who need blood.</li>
                                <li>About 45 percent of people in the U.S. have type O (positive or negative) blood. This percentage is higher among Hispanics – 57 percent, and among African Americans – 51 percent.</li>
                                <li>Only 3 percent of people in the U.S. have AB positive blood type. AB positive type blood donors are universal donors of plasma, which is often used in emergencies, for newborns and for patients requiring massive transfusions.</li>
                            </ul>

                            <h2 class="textcolor" style=" margin-top: -0%;"><a name="bloodfacts">Facts About American Red Cross Blood Services</a></h2>
                            <ul style=" margin-top: -3%;">
                                <li>The American Red Cross blood program started in 1940, under the leadership of Dr. Charles Drew.</li>
                                <li>The Red Cross supplies about 40 percent of the nation's blood supply.</li>
                                <li>The Red Cross provides blood for patients in approximately 2,600 hospitals across the U.S.</li>
                                <li>The Red Cross makes blood available to any patient who needs it — patients are not required to find donors to replace the blood they use (a practice common in Europe and some U.S. blood banks) allowing the patient and their family to focus on recovery.</li>
                                <li>Eighty percent of the blood donations given to the Red Cross are collected at mobile blood drives set up at community organizations, companies, high schools, colleges, places of worship or military installations. The remaining 20 percent are collected at Red Cross donation centers.</li>
                                <li>The Red Cross works with more than 58,000 blood drive sponsors each year to hold more than 145,000 blood drives, providing convenient locations for people to give blood.</li>
                            </ul>


                            <h2 class="textcolor" style=" margin-top: -0%;"> <a name="bloodfacts">  Blood Components</a></h2>&nbsp;
                            <h3 style=" margin-top: -3%;">How Can One Donation Help Multiple People?</h3>
                            <ul style=" margin-top: -3%;">
                                <li>
                                    In modern medical treatments, patients may
                                    receive a pint of whole blood or just specific components of the blood
                                    needed to treat their particular condition. Up to four components can
                                    be derived from donated blood. This approach to treatment, referred to as
                                    blood component therapy, allows several patients to benefit from one pint
                                    of donated whole blood. The main transfusable blood components include:
                                </li>
                            </ul>
                            <br>
                            <img src="assets/images/blood-components-vile.jpg" class="img-responsive pull-left">
                            <h3>Whole Blood</h3>
                            <p style=" margin-top: -3%;">
                                Whole blood contains red cells, white cells, and platelets (~45% of volume)
                                suspended in plasma (~55% of volume).
                            </p><br>
                            <h3 style=" margin-top: -1%;">Red Cells</h3>
                            <p style=" margin-top: -3%;">
                                Red cells, or erythrocytes, carry oxygen from the lungs to your body’s
                                tissue and take carbon dioxide back to your lungs to be exhaled.
                            </p><br>
                            <h3>Platelets</h3>

                            <p style=" margin-top: -3%;">
                                Platelets, or thrombocytes, are small, colorless cell fragments in the
                                blood whose main function is to interact with clotting proteins to stop or
                                prevent bleeding.
                            </p>
                            <br>
                            <h3 style=" margin-top: -1%;">Plasma</h3>
                            <p style=" margin-top: -3%;">
                                Plasma is a fluid, composed of about 92% water, 7% vital proteins such as
                                albumin, gamma globulin, anti- hemophilic factor, and other clotting
                                factors, and 1% mineral salts, sugars, fats, hormones and vitamins.
                            </p>
                            <br>
                            <h3 style=" margin-top: -1%;">Cryoprecipitated AHF</h3>
                            <p style=" margin-top: -3%;">
                                Cryoprecipitated Antihemophilic Factor (Cryo) is a portion of plasma rich
                                in clotting factors, including Factor VIII and fibrinogen. It is prepared
                                by freezing and then slowly thawing the frozen plasma.
                            </p>
                            <br>
                            <h3 class="textcolor1"> Transfusable Blood Components Summary </h3>
                            <img src="assets/images/statistics.jpg" class="img-responsive center-block">
                            <br>
                            <h2 class="textcolor"> <a name="bloodfacts"> Whole Blood and Red Blood Cells</a></h2>
                            <p class="small_text" style="text-align: justify; margin-top: -2%; ">
                                <img src="assets/images/blood_components_RBCs.jpg" class="img-responsive pull-right" style="padding: 10px;">
                                Red Blood Cells (RBCs) are perhaps the most recognizable component of whole blood. RBCs contain hemoglobin, a complex protein containing iron that carries oxygen through the body. The percentage of blood volume composed of red blood cells is called the “hematocrit.” There are about one billion red blood cells in two to three drops of blood, and for every 600 red blood cells, there are about 40 platelets and one white cell.<br><br>
                                Manufactured in the bone marrow, RBCs are continuously produced and broken down. They live for about 120 days in the circulatory Red Blood Cells (RBCs)system. Red blood cells are prepared from whole blood by removing plasma, or the liquid portion of the blood, and they are used to treat anemia while minimizing an increase in blood volume. Improvements in cell preservation solutions over decades have increased the shelf-life of red blood cells from 21 to 42 days.<br><br>
                                RBCs may be treated and frozen for an extended storage, of 10 years or more. Patients who benefit most from transfusion of red blood cells include those with chronic anemia resulting from kidney failure or gastrointestinal bleeding, and those with acute blood loss resulting from trauma.<br><br>
                                Prestorage leukocyte-reduced red blood cells require special preparation by removing leukocytes (white blood cells) by filtration shortly after donation. This is done before storage because high numbers of leukocytes remaining in a unit of RBCs during the storage process can fragment, deteriorate, and release cytokines (chemicals that affect the inflammatory response). Leukocytes have been implicated as a cause of reactions to a current and subsequent blood transfusions in some transfusion recipients.


                                <h2 class="textcolor"> <a name="bloodfacts"> Platelets</a></h2>

                            <p class="small_text" style="text-align: justify; margin-top: -2%; ">
                                <img src="assets/images/blood_component_platelets.jpg" class="img-responsive pull-right" style="padding: 10px;">
                                Platelets are small blood components that help the clotting process by sticking to the lining of blood vessels. Platelets are made in the bone marrow and survive in the circulatory system<br>
                                Platelets help with clotting

                                Platelets are prepared by using a centrifuge to separate the platelet-rich plasma from the donated unit of whole blood.<br>
                                Platelets may also be obtained from a donor by a process known as apheresis, or plateletpheresis. In this process, blood is drawn from the donor into an apheresis instrument which separates the blood into its components, retains some of the platelets, and returns the remainder of the blood to the donor.<br>
                                This single donor platelet product contains about four to six times as many platelets as a unit of platelets obtained from whole blood. Platelets are used to treat a condition called thrombocytopenia, in which there is a shortage of platelets, and they are also used to treat platelet function abnormalities. Platelets are stored at room temperature with constant agitation for 5 days.

                            </p>

                            <br>

                            <h2 class="textcolor" style=" margin-top: -1%;"> <a name="bloodfacts"> Plasma</a></h2>
                            <p class="small_text" style="text-align: justify; margin-top: -2%; ">
                                Plasma is the liquid portion of blood – a protein-salt solution in which red and white blood cells and platelets are suspended. Plasma, which is 92 percent water, constitutes 55 percent of blood volume. Plasma contains albumin (the chief protein constituent), fibrinogen (responsible, in part, for the clotting of blood) and globulins (including antibodies). Plasma serves a variety of functions, from maintaining a satisfactory blood pressure and volume to supplying critical proteins for blood clotting and immunity. PlasmaIt also serves as the medium for exchange of vital minerals such as sodium and potassium and helps to maintain a proper pH (acid-base) balance in the body, which is critical to cell function. Plasma is obtained by separating the liquid portion of blood from the cells.<br>
                                Plasma is frozen quickly after donation (up to 24 hours) to preserve clotting factors, stored up to one year, and thawed shortly before use. It is commonly transfused to trauma patients and patients with severe liver disease or multiple clotting factor deficiencies.<br>
                                Plasma derivatives are concentrates of specific plasma proteins prepared from pools (many donor units) of plasma. Plasma derivatives are obtained through a process known as fractionation. The derivatives are treated with heat and/or solvent detergent to kill certain viruses like those that cause HIV, hepatitis B, and hepatitis C.<br>
                            </p>
                            <h3 class="textcolor">
                                Donate Blood Plasma to Those in Need »
                            </h3>
                            <h4 style=" margin-top: -2%;">Plasma derivatives include:</h4>

                            <ul style=" margin-top: -3%;">
                                <li>Factor VIII Concentrate</li>
                                <li> Factor IX Concentrate</li>
                                <li>
                                    Anti-Inhibitor Coagulation Complex (AICC)
                                    Albumin
                                </li>
                                <li>Immune Globulins, including Rh Immune Globulin</li>
                                <li>Anti-Thrombin III Concentrate</li>
                                <li>Alpha 1-Proteinase Inhibitor Concentrate</li>
                            </ul>


                            <h2 class="textcolor"> <a name="whitebloodcells"> White Blood Cells & Granulocytes</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%; ">
                                <h4>White Blood Cells</h4>
                                <p style=" margin-top: -3%;">
                                    White blood cells (leukocytes) are one of the body’s defenses against disease. Some white cells travel throughout the body and destroy bacteria, some produce antibodies against bacteria and viruses, and others help  malignant diseases. One’s own “leukocytes” help maintain the body’s immune function, but when present in donated blood, they serve no purpose. In fact, leukocytes may carry viruses that cause immune suppression and release toxic substances in the recipient. Leukocytes can cause a reaction when transfused, and are often removed from the transfusable blood components, a process called leuko-reduction. The majority of white blood cells are produced in the bone marrow, where they outnumber red blood cells by 2 to 1. However, in the blood stream, there are about 600 red blood cells for every white blood cell. There are several different types of white blood cells.
                                </p>
                                <br>
                                <h4 style=" margin-top: -1%;">Granulocytes</h4>
                                <p style=" margin-top: -3%;">
                                    Granulocytes are one type of several types of white blood cells that are in fact used in more specialized transfusion therapy. Granulocytes and monocytes protect against infection by surrounding and destroying invading bacteria and viruses, and lymphocytes aid in the immune defense system. Granulocytes are prepared by an automated process called apheresis, and must be transfused within 24 hours after collection. They are used for infections that are unresponsive to antibiotic therapy.
                                </p>
                            </div>
                            <h2 class="textcolor"> <a name="whitebloodcells"> Types of Blood Transfusions</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%; ">
                                Most of the donated blood collected by the American Red Cross is used for direct blood transfusions. Common types of blood transfusions including platelet, plasma and red blood cell transfusions.

                                <h4>Red Blood Cell Transfusions</h4>
                                <p style=" margin-top: -3%;">
                                    A patient suffering from an iron deficiency or anemia, a condition where the body does not have enough red blood cells, may receive a Red Blood Cell Transfusion. This type of transfusion increases a patient’s hemoglobin and iron levels, while improving the amount of oxygen in the body.
                                </p>
                                <h4>Platelet Transfusions</h4>
                                <p style=" margin-top: -3%;">
                                    Platelets are a component of blood that stops the body from bleeding. Often patients suffering from leukemia, or other types of cancer, have lower platelet counts as a side effect of their chemotherapy treatments. Patients who have illnesses that prevent the body from making enough platelets have to get regular transfusions to stay healthy.
                                </p>
                                <h4>Plasma Transfusions</h4>
                                <p style=" margin-top: -3%;">
                                    Plasma is the liquid part of the body’s blood. It contains important proteins and other substances crucial to one’s overall health. Plasma transfusions are used for patients with liver failure, severe infections, and serious burns.
                                </p>
                            </div>
                            <h2 class="textcolor"> <a name="whitebloodcells"> Reasons People Receive Blood Transfusions</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%; ">
                                Blood transfusions are a very common medical procedure. A total of 21 million blood components are transfused each year in the United States alone.<br>

                                <h4>Here are the reasons that a patient may undergo a blood transfusion:</h4>
                                <p style=" margin-top: -3%;">
                                    Most patients who have a major surgical procedure, will have a blood transfusion to replace any blood loss during their surgery.
                                    Blood transfusions are used for patients whom have experienced serious injuries from car crashes or natural disasters.
                                    Individuals with an illness that causes anemia, such as leukemia or kidney disease will often be the recipients of blood transfusions.
                                </p>
                            </div>


                            <h2 class="textcolor"> <a name="whitebloodcells"> The Process</a></h2>

                            <div class="small_text" style="text-align: justify; margin-top: -2%;">
                                <h4>Location</h4>
                                <p style=" margin-top: -3%;">
                                    Like most medical procedures, a blood transfusion will take place at a hospital or doctor’s office.<br>
                                </p>
                                <h4>Before the Procedure</h4>
                                <p style=" margin-top: -3%;">
                                    A nurse or doctor will check the patient’s blood pressure, pulse, and temperature before starting the transfusion.<br>
                                </p>
                                <h4>During the Procedure</h4>
                                <p style=" margin-top: -3%;">
                                    The blood transfusion procedure begins when an intravenous (IV) line is placed onto the patient’s body. It is through the IV that the patient will begin to receive the new blood. Depending on the amount of blood, a simple blood transfusion can take between 1-4 hours.<br>
                                </p>
                                <h4>After the Procedure</h4>
                                <p style=" margin-top: -3%;">
                                    Following the completion of the blood transfusion, the patient’s vital signs are checked and the IV is removed. For a couple of days after the transfusion, patients may experience some soreness near the puncture for the IV. The patient’s doctor may request a checkup after the transfusion to see the body’s reaction to the new blood.
                                </p>
                            </div>
                            <h2 class="textcolor"> <a name="whitebloodcells">Risks & Complications</a></h2>

                            <div class="small_text" style="text-align: justify;   margin-top: -2%;">
                                Often patients who have received a blood transfusion experience no complications or problems. However, minor to severe problems do occasional occur. Some of the most common complications include:

                                <h4>Allergic Reactions</h4>
                                <p style=" margin-top: -3%;">
                                    Some people have allergic reactions to blood received during a transfusion, even when given the right blood type. In these cases symptoms include hives and itching. Like most allergic reactions, this can be treated with antihistamines. However, if the reaction becomes serious, a doctor should be consulted.
                                </p>
                                <h4>Fever</h4>
                                <p style=" margin-top: -3%;">
                                    Developing a fever after a transfusion is not serious. A fever is your body’s response to the white blood cells in the transfused blood. However, it can be a sign of a serious reaction if the patient is also experiencing nausea or chest pain. Patients should consult their doctors if other symptoms or side effects are present.
                                </p>
                                <h4>Acute Immune Hemolytic Reaction</h4>
                                <p style=" margin-top: -3%;">
                                    An acute immune hemolytic reaction is a very serious, but rare, reaction caused by a patient’s body attacking the transfused red blood cells. The attack triggers a release of a substance that damages the kidneys. This is often the case when the donor blood is not a proper match with the patient’s blood type. Symptoms include nausea, fever, chills, chest and lower back pain, and dark urine.
                                </p>
                                <h4>Blood-borne Infections</h4>
                                <p style=" margin-top: -3%;">
                                    All donated blood is screened and tested for potential viruses, bacteria, and parasites. However, occasionally these agents can still infect a transfusion patient after the procedure.<br>
                                    The risk of catching a virus or any other blood-borne infection from a blood transfusion is very low.<br>
                                    <ul>
                                        <li>HIV: All donated blood is thoroughly tested for HIV. There is a 1 in 2 million chance that donated blood will not only carry HIV but also infect a transfusion recipient.</li>
                                        <li>Hepatitis B and C: The odds of catching hepatitis B from donated blood is about 1 in 300,000, while the risk with hepatitis C is 1 in 1.5 million.</li>
                                        <li>West Nile Virus: The risk of catching West Nile Virus from a blood transfusion is approximately 1 in 350,000.</li>
                                    </ul>
                            </div>

                            <h2 class="textcolor"> <a name="whitebloodcells"> What Donors Should Know About Iron and Blood Donation</a></h2>

                            <p class="small_text" style="text-align: justify;   margin-top: -2%; ">
                                The American Red Cross routinely checks your hemoglobin before each blood and platelet donation. Hemoglobin is a protein in your body that contains iron and carries oxygen to the tissues in your body. A screening test measures the amount of hemoglobin from a single drop of blood obtained from a finger stick. If your hemoglobin is too low, we will ask that you wait to donate.<br>
                                Iron is an essential mineral that is a part of hemoglobin that helps maintain your strength and energy. Your body needs iron to make new blood cells, replacing the ones lost through blood donations<br>.

                                There are things you can do to help maintain healthy iron levels:<br>
                                Eat a nutritious, well-balanced diet with foods rich in iron and high in vitamin C<br>
                                Frequent donors and young donors should consult with their health-care provider about taking multivitamins with 18 mg of iron<br>
                            </p>

                        </form>

                    </div>

                </div>/#conatcat-info
            </div>
        </div>-->
    </div>

    <footer id="footer" class="midnight-blue">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    &copy; 2024 Let's Help  all rights reserved.
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-lg-3"></div>
                            <div class="col-lg-2 zoom-in"><a href="#" style="color:white">Home</a></div>
                            <div class="col-lg-2 zoom-in"><a href="about" style="color:white">About</a></div>
                            <div class="col-lg-2 zoom-in"><a href="FAQS" style="color:white">FAQ's</a></div>
                            <div class="col-lg-3 zoom-in"><a href="contact" style="color:white">Contact Us</a></div>
                        </div>

                    </div>
            </div>
        </div>
    </footer><!--/#footer-->
    <script src="js/jquery.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.prettyPhoto.js"></script>
    <script src="js/jquery.isotope.min.js"></script>
    <script src="js/main.js"></script>
    <script src="js/wow.min.js"></script>

 
</body>
</html>

<style>
    h1, h2, h3, h4, h5, h6{
        margin:2% 0% 3% 0%;
        font-weight:bold
    }
    body {
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
        overflow-y: auto; /* Enable vertical scrolling */
    }
    .pt-100 {
        padding-top: 0px;
    }
    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }
    a:hover, a:focus {
        color: #fb0300;
        text-decoration: none;
        outline: none;
    }
    .midnight-blue{
        background-color:black;
        color:white;
        padding:10px;
    }
    ul{
         padding:16px
     }
    ul li {
        list-style: disc;
    }
    li {
        padding: 4px;
    }
        li a {
            color: white;
        }

    @media only screen and (max-width: 600px) {
      .none{
          display:none;
      }
    }
    footer div a {
        float: inline-end;
    }
</style>

