<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Let's Help </title>

    <!-- core CSS -->

    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

     
</head>
<body>
    <!--Bootstrap Approach-->

    <div class=" pt-100">
        <div class="container-fluid">
            <div class="container" style="background-color: #fb0300;">
                <div class="row">
                    <div class="card_menu">
                        <div class="col-lg-3 col-9">
                            <a href="/">
                                <img src="assets/img/Blood_DonationImages/LETS_HELP_LOGO.png" alt="logo" class="width" />
                            </a>
                        </div>

                        <div class="col-lg-9 col-3 none ml-n5">
                            <ng-container *ngIf="LoginDetails; else showLoginButton">
                                <div class="dropdown" (click)="toggleDropdown()">
                                    <button class="dropbtn" style="color:white">
                                        {{letter}}

                                    </button>
                                    <div class="dropdown-content" style="width:220%;">
                                        <a href="Profile" (click)="pro()"> My Profile </a>
                                        <a (click)="signOut()">Sign Out</a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #showLoginButton>
                                <p class="Login log_infont" (click)="openLoginModal()"><b>LOGIN</b></p>
                            </ng-template>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff;">
                        <h2 style="color: #c52d2f;">Health and wellness</h2> 
                            <ul>
                                <li>
                                    Health and wellness encompass multifaceted dimensions of overall well-being, extending beyond mere absence of illness to encompass physical, mental, emotional, social, and even spiritual aspects of health. Here's a deep exploration:
                                </li>
                                <li>
                                    Physical Health: Physical health relates to the proper functioning of the body and its systems. It includes factors such as nutrition, exercise, sleep, hygiene, and regular medical check-ups. Maintaining physical health involves adopting healthy lifestyle habits, such as balanced diet, regular exercise, avoiding harmful substances like tobacco and excessive alcohol, and managing chronic conditions effectively.
                                </li>
                                <li>
                                    Mental Health: Mental health refers to emotional, psychological, and cognitive well-being. It involves how individuals think, feel, and cope with life's challenges. Promoting mental health includes managing stress, practicing self-care and relaxation techniques, seeking support when needed, and addressing mental health disorders through therapy, counseling, or medication.
                                </li>
                                <li>
                                    Emotional Health: Emotional health involves understanding and managing one's emotions in a healthy and constructive manner. It includes recognizing and expressing feelings, building resilience, fostering positive relationships, and developing coping strategies to navigate life's ups and downs effectively.
                                </li>
                                <li>
                                    Social Health: Social health relates to the quality of interactions and relationships with others. It encompasses aspects such as communication skills, empathy, social support networks, and a sense of belonging and connectedness within communities. Cultivating social health involves building and maintaining meaningful relationships, contributing to social causes, and fostering a sense of community and inclusion.
                                <li>
                                    Environmental Health: Environmental health focuses on the impact of the external environment on individual and community well-being. It includes factors such as access to clean air, water, and food, exposure to pollutants and toxins, and the sustainability of natural resources. Protecting environmental health involves promoting environmental stewardship, sustainable practices, and advocating for policies that ensure a safe and healthy environment for all.
                                </li>
                                <li>
                                    Occupational Health: Occupational health pertains to the well-being of individuals in their work environments. It involves promoting safe and healthy working conditions, preventing work-related injuries and illnesses, managing occupational hazards, and supporting work-life balance and employee well-being initiatives.
                                </li>
                                <li>
                                    Spiritual Health: Spiritual health relates to a sense of purpose, meaning, and connection to something greater than oneself. It encompasses beliefs, values, ethics, and existential questions about life's purpose and significance. Cultivating spiritual health involves practices such as meditation, prayer, reflection, mindfulness, and engaging in activities that align with one's values and beliefs.
                                </li>
                                <li>
                                    Holistic Approach: Health and wellness are best approached holistically, considering the interconnectedness of various dimensions of well-being. Adopting a holistic approach involves addressing physical, mental, emotional, social, and spiritual needs in a balanced and integrated manner. It emphasizes preventive care, self-awareness, self-care, and proactive efforts to promote overall health and vitality across the lifespan.
                                </li>
                                <li>
                                    In conclusion, health and wellness encompass a comprehensive understanding of individual and collective well-being, incorporating physical, mental, emotional, social, environmental, occupational, and spiritual dimensions. Striving for optimal health and wellness involves nurturing these dimensions through healthy lifestyle choices, supportive environments, and holistic approaches to care and self-care.
                                </li>
                            </ul>

                    </form>
                </div>
            </div><br />
        </div>
        <!--<section id="feature" class="card1">
            <div class="container">
                <section id="conatcat-info" style="background-color: #ffffff;">
                    <div class="container">
                        <br /><form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff; margin-right: 15px; margin-left: 15px;">
                            <h2 style="color:red;text-align:center;">Health and wellness</h2><br />

                            <p style="margin-top:-3%">
                                <ul style="list-style-type: disc;">
                                    <li>
                                        Health and wellness encompass multifaceted dimensions of overall well-being, extending beyond mere absence of illness to encompass physical, mental, emotional, social, and even spiritual aspects of health. Here's a deep exploration:
                                    </li>
                                    <li>
                                        Physical Health: Physical health relates to the proper functioning of the body and its systems. It includes factors such as nutrition, exercise, sleep, hygiene, and regular medical check-ups. Maintaining physical health involves adopting healthy lifestyle habits, such as balanced diet, regular exercise, avoiding harmful substances like tobacco and excessive alcohol, and managing chronic conditions effectively.
                                    </li>
                                    <li>
                                        Mental Health: Mental health refers to emotional, psychological, and cognitive well-being. It involves how individuals think, feel, and cope with life's challenges. Promoting mental health includes managing stress, practicing self-care and relaxation techniques, seeking support when needed, and addressing mental health disorders through therapy, counseling, or medication.
                                    </li>
                                    <li>
                                        Emotional Health: Emotional health involves understanding and managing one's emotions in a healthy and constructive manner. It includes recognizing and expressing feelings, building resilience, fostering positive relationships, and developing coping strategies to navigate life's ups and downs effectively.
                                    </li>
                                    <li>
                                        Social Health: Social health relates to the quality of interactions and relationships with others. It encompasses aspects such as communication skills, empathy, social support networks, and a sense of belonging and connectedness within communities. Cultivating social health involves building and maintaining meaningful relationships, contributing to social causes, and fostering a sense of community and inclusion.
                                    <li>
                                        Environmental Health: Environmental health focuses on the impact of the external environment on individual and community well-being. It includes factors such as access to clean air, water, and food, exposure to pollutants and toxins, and the sustainability of natural resources. Protecting environmental health involves promoting environmental stewardship, sustainable practices, and advocating for policies that ensure a safe and healthy environment for all.
                                    </li>
                                    <li>
                                        Occupational Health: Occupational health pertains to the well-being of individuals in their work environments. It involves promoting safe and healthy working conditions, preventing work-related injuries and illnesses, managing occupational hazards, and supporting work-life balance and employee well-being initiatives.
                                    </li>
                                    <li>
                                        Spiritual Health: Spiritual health relates to a sense of purpose, meaning, and connection to something greater than oneself. It encompasses beliefs, values, ethics, and existential questions about life's purpose and significance. Cultivating spiritual health involves practices such as meditation, prayer, reflection, mindfulness, and engaging in activities that align with one's values and beliefs.
                                    </li>
                                    <li>
                                        Holistic Approach: Health and wellness are best approached holistically, considering the interconnectedness of various dimensions of well-being. Adopting a holistic approach involves addressing physical, mental, emotional, social, and spiritual needs in a balanced and integrated manner. It emphasizes preventive care, self-awareness, self-care, and proactive efforts to promote overall health and vitality across the lifespan.
                                    </li>
                                    <li>
                                        In conclusion, health and wellness encompass a comprehensive understanding of individual and collective well-being, incorporating physical, mental, emotional, social, environmental, occupational, and spiritual dimensions. Striving for optimal health and wellness involves nurturing these dimensions through healthy lifestyle choices, supportive environments, and holistic approaches to care and self-care.
                                    </li>
                                </ul>

                        </form>
                    </div>
                </section> 
            </div>
        </section>-->
    </div>

    <footer id="footer" class="midnight-blue">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    &copy; 2024 Let's Help  all rights reserved.
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-2 zoom-in"><a href="#" style="color:white">Home</a></div>
                        <div class="col-lg-2 zoom-in"><a href="about" style="color:white">About</a></div>
                        <div class="col-lg-2 zoom-in"><a href="FAQS" style="color:white">FAQ's</a></div>
                        <div class="col-lg-3 zoom-in"><a href="contact" style="color:white">Contact Us</a></div>
                    </div>

                </div>
            </div>
        </div>
    </footer><!--/#footer-->
    <script src="js/jquery.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.prettyPhoto.js"></script>
    <script src="js/jquery.isotope.min.js"></script>
    <script src="js/main.js"></script>
    <script src="js/wow.min.js"></script>


</body>
</html>

<style>
    h1, h2, h3, h4, h5, h6 {
        margin: 1% 0% 0% 0%;
        font-weight: bold
    }

    body {
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
        overflow-y: auto; /* Enable vertical scrolling */
    }

    .pt-100 {
        padding-top: 0px;
    }

    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }

    a:hover, a:focus {
        color: #fb0300;
        text-decoration: none;
        outline: none;
    }

    .midnight-blue {
        background-color: black;
        color: white;
        padding: 10px;
    }

    li {
        /*   display: inline-block;*/
        color: black;
        padding: 10px 2px;
        list-style: disc;
    }
    ul {
        padding: 16px
    }

        ul li {
            list-style: disc;
        }

    li {
        padding: 4px;
    }

        li a {
            color: white;
        }

    @media only screen and (max-width: 600px) {
        .none {
            display: none;
        }
    }
    footer div a{
        float:inline-end;
    }
</style>

