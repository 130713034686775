import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CountUpModule } from 'ngx-countup';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {TabsModule} from 'ngx-tabset';
import { TooltipModule } from 'ng2-tooltip-directive';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { DemoSidebarComponent } from './components/common/demo-sidebar/demo-sidebar.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { DonersformComponent } from './components/donersform/donersform.component';
import { BlooddonationComponent } from './components/blooddonation/blooddonation.component';
import { SearchComponent } from './components/search/search.component';
import { HarphanComponent } from './components/harphan/harphan.component'
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PrerequisitesComponent } from './components/prerequisites/prerequisites.component';
import { RequestformComponent } from '../app/components/requestform/requestform.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { OldagehomeComponent } from './components/oldagehome/oldagehome.component';
import { ShareyourservicesComponent } from './components/shareyourservices/shareyourservices.component';
import { ProfileComponent } from './components/profile/profile.component';
//import { AgmCoreModule } from '@agm/core';
//import { GMapModule } from 'primeng/gmap';
import { BrochuresComponent } from './components/brochures/brochures.component';
import { JoincompainComponent } from './components/joincompain/joincompain.component';
import { LoginComponent } from './components/login/login.component';
//import { LearnaboutbloodComponent } from './components/learnaboutblood/learnaboutblood.component';
import { DatePipe } from '@angular/common';
import { GalleryComponent } from './components/gallery/gallery.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { RequestloginComponent } from './components/requestlogin/requestlogin.component';
import { StatedisrticrcityService } from './components/Sevices/statedisrticrcity.service';
import { DownloadsbrouchersComponent } from './components/downloadsbrouchers/downloadsbrouchers.component';
import { VolunteerloginComponent } from './components/volunteerlogin/volunteerlogin.component';
import { VolunteerprofileComponent } from './components/volunteerprofile/volunteerprofile.component';
import { VolunterComponent } from './components/volunter/volunter.component';
import { AddmemberComponent } from './components/addmember/addmember.component';
import { HospitalComponent } from './components/hospital/hospital.component';
import { StickbrochureComponent } from './components/stickbrochure/stickbrochure.component';
import { RequestpresentationComponent } from './components/requestpresentation/requestpresentation.component';
@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent, BrochuresComponent, JoincompainComponent,// LearnaboutbloodComponent,
        HomeOneComponent, GalleryComponent, PartnerComponent, PrerequisitesComponent,
        NavbarComponent, RequestloginComponent, VolunteerloginComponent, StickbrochureComponent, RequestpresentationComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        ServicesTwoComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeTwelveComponent,
        HomeThirteenComponent,
        BlogDetailsComponent,
        SearchComponent, HarphanComponent, DownloadsbrouchersComponent,
        DemoSidebarComponent, DonersformComponent, BlooddonationComponent, TermsAndConditionsComponent,
        RequestformComponent, RegistrationComponent, OldagehomeComponent, ShareyourservicesComponent, ProfileComponent,
        LoginComponent, VolunteerprofileComponent, VolunterComponent, AddmemberComponent, HospitalComponent


    ],
    imports: [
        //AgmCoreModule.forRoot({
        //    apiKey: ' AIzaSyBt1tPGRqBqmXWUgeMmaVmBuSO-eOTZuIE& v=4.exp',
        //      }),

        BrowserModule,
        //GMapModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        CarouselModule,
        // AutoCompleteModule,
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        CountUpModule,
        TooltipModule,
        NgxTypedJsModule, AutoCompleteModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })

    ],
    providers: [DatePipe, StatedisrticrcityService],
    bootstrap: [AppComponent]
})
export class AppModule { }

