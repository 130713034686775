import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../general.service';

@Injectable({
  providedIn: 'root'
})
export class StatedisrticrcityService {
    arr: any;
    Statedata: any = []; deptList: any;
    selectedState1: any; selectedState2: any;
    districtdata: any = []; deptList1: any;
    Citydata: any = []; deptList2: any;

    constructor(private http: HttpClient, public genralservice: GeneralService) {

    }

//     var url = "api/BuddyOnWheel/get_Bookings";
//return this.httpClient.post(urlname + url, payload);

    getstate(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.arr = [{
                RegId: '1',
                TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            }];

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');
            var url = "api/BG/StatesMaster_crudCustomer";

            this.genralservice.PostData(url, UploadFile).then(data => {
                // Data received successfully, resolve the Promise with the received data
                console.log("STATEDTA-->",data);
                resolve(data);
            }, err => {
                // Error occurred, reject the Promise with the error
                this.genralservice.ShowAlert('ERROR', 'Something went wrong', 'error');
                reject(err);
            });
        });
    }


    // drop down for states //
    searchCont(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }

    getDistrict(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.arr = [{
                RegId: '1',
                TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
                StateId: data
            }];

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '5');
            var url = "api/BG/DistrictMaster_crudCustomer";

            this.genralservice.PostData(url, UploadFile).then(districtData => {
                // District data received successfully, resolve the Promise with the received data
                resolve(districtData);
            }, err => {
                // Error occurred, reject the Promise with the error
                this.genralservice.ShowAlert('ERROR', 'Something went wrong', 'error');
                reject(err);
            });
        });
    }


    // drop down for District //
    searchCont1(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }


    getCity(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.arr = [{
                RegId: '1',
                TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
                DistrictId: data
            }];

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '5');
            var url = "api/BG/CitiesMaster_Crudcustomer";

            this.genralservice.PostData(url, UploadFile).then(cityData => {
                // City data received successfully, resolve the Promise with the received data
                resolve(cityData);
            }, err => {
                // Error occurred, reject the Promise with the error
                this.genralservice.ShowAlert('ERROR', 'Something went wrong', 'error');
                reject(err);
            });
        });
    }


    // drop down for cities //
    searchCont2(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }

    onStateSelect1(event) {
        console.log('Selected State:', this.selectedState1);
        // You can perform additional actions with the selected state here
    }

    onStateSelect2(event) {
        console.log('Selected Distict:', this.selectedState2);
        // You can perform additional actions with the selected state here
    }


}
