<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>

    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="Let's Help ">
    <meta property="og:image" content="dist/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="Let's Help " />
    <meta property="og:site_name" content="Let's Help " />
    <meta property="og:description" content="Let's Help " />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>
        <div id="page-content">
            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">
                    <div class="breadcrumb">
                        <a href="index.html"><i class="icon-chevron-left"></i>Careers</a>
                    </div>
                    <div class="bg-vid" data-src="dist/vid/Let's Help " style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="line-height: 60PX;font-size:32px;font-weight:bold">Donor Registration</h2>
                        <!--<p class="para">Whether you have an existing account or a new registrant, your online account will allow you to do the following:</p>
                    <p class="para">1. Become a blood donor and start saving life..!(Select, willing to donate blood)</p>
                    <p class="para">2. Post a Blood request.</p>
                    <p class="para">3. Search and view the donor contact information. </p>
                    <p class="para"> 4. View the contact info of the Old age, Blood Banks and Orphan Homes in India.  </p>
                    <p class="para"> 5. Comment, Share and Vote on the social issues. </p>-->
                    </header>
                </div>
            </div>

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="col-lg-6">
                        <div class="right_side">
                            <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6">
                                    <div class="cartdetails">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-3"> <label for="username">Mobile </label></div>
                                                <div class="col-lg-3"></div>
                                                <div class="col-lg-3"></div>
                                                <div class="col-lg-3" style="text-align: end;
    text-decoration: underline;
    cursor: pointer;">
                                                    <div (click)="EditMobileNumber()" *ngIf="OTPtoMobile != null">Edit</div>

                                                </div>
                                            </div>



                                            <input type="text" [(ngModel)]="Mobile" placeholder="Enter Your Mobile Number" id="username" name="username" inputmode="numeric" pattern="[0-9]*" maxlength="10" required oninput="this.value = this.value.replace(/[^0-9]/g, '');" [disabled]="isMobileDisabled"
                                                   (input)="checkMobileLength($event)">


                                            <label for="password" *ngIf="OTPtoMobile != null">OTP </label>
                                            <input type="text" [(ngModel)]="OTP"inputmode="numeric" pattern="[0-9]*" maxlength="4" required oninput="this.value = this.value.replace(/[^0-9]/g, '');" id="password" name="password" *ngIf="OTPtoMobile != null" placeholder="Enter your OTP" required>

                                            <button class="btn btn-primary" type="submit" (click)="SendOTPtoMobile()" [hidden]="!showLogin1" style="color:white" [disabled]="otpSent">Send OTP</button>
                                            &nbsp;
                                            <div class="row">
                                                <!--<div class="col-lg-1">
         <input type="checkbox" id="registerAsDonor" style="display: inline; margin-top: 17px;">
        </div>-->
                                                <!--<div class="col-lg-1">
            <input type="checkbox" id="registerAsDonor" style="display: inline; margin-top: 17px;"
                   [(ngModel)]="registerAsDonor" name="registerAsDonor">
        </div>
        <div class="col-lg-11">
            <label class="label" for="registerAsDonor">Register as a Blood Donor</label> <br />
        </div>-->

                                            </div>
                                            <button class="btn btn-primary" type="submit" (click)="checkmobile(Mobile)" style="color:white" [hidden]="!showLogin">Sign in</button>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-lg-2"></div>
                            </div>

                            <!--<div class="row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-10">
                                <button class="btn" (click)="sendmail()">Submit</button>
                            </div>

                        </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>


</html>

<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    @media (min-width: 992px) {
        .pane__inner.allow-scroll {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            overflow-x: clip;
        }
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -109%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 2px solid white;
    }


        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 2px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .cartdetails {
        width: 100%;
        height: 55%;
        margin-top: 56%;
        border-radius: 10px;
        border: 2px solid black;
        background-color: white;
        padding: 17px;
        color: black;
    }

    .label {
        display: inline-block;
        margin-top: 12px;
    }

    .para {
        font-size: 15px;
        text-align: left;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
        margin-left: 11%;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
</style>

