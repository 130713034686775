<div class="container-fluid background">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-xs-6">
                <a routerLink="/">
                    <img class="image_logo" src="../../../assets/img/Blood_DonationImages/LETS_HELP_LOGO.png" />
                </a>


            </div>
            <div class="col-lg-3 col-xs-6 text">
                <p class="elementstyle">LETS'S HELP</p><sub>Together ,for a Nobel Cause.!</sub>
            </div>
            <div class="col-lg-5 col-xs-6">
            </div>

            <div class="col-lg-1 col-xs-6 menubars">

                <nav role="navigation">
                    <div id="menuToggle">
                        <input type="checkbox" />

                        <span></span>
                        <span></span>
                        <span></span>
                        <ul id="menu">
                            <a routerLink="/Oldagehome"><li>A Ray Of Hope</li></a>
                            <a routerLink="/blooddonation"><li>Blood Donation</li></a>
                            <a routerLink="/Orphanage"><li>Be a Support</li></a>
                            <a routerLink="/about"><li>About Us</li></a>
                            <a routerLink="/contact"><li>Contact US</li></a>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div id="Registration">
            <div class="card">
                <div class="border border-secondary">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-7">
                            <h1 class="mt-5 text_align">Registration</h1><br />
                            <h4>Create your Lets Fight Account</h4>
                            <p>
                                Blood donation registration allows you to do the following:<br />
                                1. Become a blood donor and start saving life..!<br />
                                2. Post a Blood request<br />
                                3. Search and view the donor contact information<br />
                                4. View the Contact info of the Old age, Blood Banks and Orphan Homes in India<br />
                                5. Comment, share and Vote on the social issues<br />
                                6. Date of Birth will be hidden, and its only purpose is to calculate your age<br />
                            </p>
                        </div>
                        <div class="col-lg-2"></div>

                        <form [formGroup]="form">
                            <div class="row">
                                <!--<div class="form-group">
                                    <label><b> Surname<span style="color:red">*</span></b></label>
                                    <select class="form-control" formControlName="SurName" (change)="onSurnameSelection($event.target.value)">
                                        <option value="">Select Surname</option>
                                        <option *ngFor="let surname of surnamedata" [value]="surname.Surname">{{ surname.Surname }}</option>
                                    </select>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.SurName.touched && form.controls.SurName.errors?.required">Surname is required</small>
                                </div>-->
                                <div class="col-lg-4">

                                    <label for="name"><b>Name<span style="color:red">*</span></b> </label>
                                    <input type="text" id="FullName" formControlName="FullName" placeholder="Enter Your Name" name="name" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.FullName.touched && form.controls.FullName.errors?.required">rrFullName is required</small>
                                </div>
                                <div class="col-lg-4">
                                    <label for="name"><b>Email<span style="color:red">*</span></b> </label>
                                    <input type="text" id="Email" formControlName="Email" placeholder="Enter Your Email" name="Email" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Email.touched && form.controls.Email.errors?.required">Email is required</small>
                                </div>
                                <div class="col-lg-4">
                                    <label for="name"><b>Age<span style="color:red">*</span></b> </label>
                                    <input type="text" id="Age" formControlName="Age" placeholder="Enter Your Age" name="Age" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Age.touched && form.controls.Age.errors?.required">Age is required</small>
                                </div>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-6 col-xs-6">
                                            <label for="Phonenumber"><b>Mobile number<span style="color:red">*</span></b> </label>
                                            <input type="text" inputmode="numeric" pattern="[0-9]*" [maxLength]="10" placeholder="Enter Your Phonenumber" id="mobile" name="Phonenumber" required formControlName="Phonenumber">
                                            <small style="color:red" class="text-danger" *ngIf="form.controls.Phonenumber.touched && form.controls.Phonenumber.errors?.required">Phonenumber is required</small>
                                        </div>
                                        <div class="col-lg-6 col-xs-6">
                                            <label></label>
                                            <div class="btn_otp" (click)="SendOTPtoMobile(form.value)">Send OTP</div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <label for="otp" *ngIf="OTPtoMobile!=null"><b>OTP<span style="color:red">*</span></b></label>
                                    <input placeholder="Enter Otp" type="text" id="otp" name="otp" maxlength="6" *ngIf="OTPtoMobile!=null">
                                </div>

                                <div class="col-lg-4">
                                    <label for="name"><b>Gender<span style="color:red">*</span></b> </label>
                                    <input type="text" id="Gender" formControlName="Gender" placeholder="Enter Your Gender" name="Gender" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Gender.touched && form.controls.Gender.errors?.required">Gender is required</small>
                                </div>
                                <div class="col-lg-4">
                                    <label for="name"><b>BloodGroupId<span style="color:red">*</span></b> </label>
                                    <input type="text" id="BloodGroupId" formControlName="BloodGroupId" placeholder="Enter Your BloodGroupId" name="name" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.BloodGroupId.touched && form.controls.BloodGroupId.errors?.required">BloodGroupId is required</small>
                                </div>
                                <div class="col-lg-4">
                                    <label for="name"><b>StateId<span style="color:red">*</span></b> </label>
                                    <input type="text" id="StateId" formControlName="StateId" placeholder="Enter Your StateId" name="StateId" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.StateId.touched && form.controls.StateId.errors?.required">StateId is required</small>
                                </div>
                                <div class="col-lg-4">
                                    <label for="name"><b>DistrictId<span style="color:red">*</span></b> </label>
                                    <input type="text" id="DistrictId" formControlName="DistrictId" placeholder="Enter Your DistrictId" name="DistrictId" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.DistrictId.touched && form.controls.DistrictId.errors?.required">DistrictId is required</small>
                                </div>
                                <div class="col-lg-4">
                                    <label for="name"><b>CityId<span style="color:red">*</span></b> </label>
                                    <input type="text" id="FullName" formControlName="CityId" placeholder="Enter Your CityId" name="CityId" required>
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.CityId.touched && form.controls.CityId.errors?.required">CityId is required</small>
                                </div>
                            </div>

                        </form>
                        <div class="row">
                            <div class="col-lg-4"></div>

                            <div class="col-lg-4 pb-5">
                                <button type="submit" class="sign_up" (click)="onSubmit(form.value)"> Sign Up </button>
                            </div>
                            <div class="col-lg-4"></div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<br />

<style>
   
    #menuToggle {
        display: block;
        position: relative;
        top: 50px;
        left: 35px;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }

        #menuToggle a {
            text-decoration: none;
            color: #232323;
            transition: color 0.3s ease;
        }

            #menuToggle a:hover {
                color: tomato;
            }


        #menuToggle input {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
            cursor: pointer;
            opacity: 0; /* hide this */
            z-index: 2; /* and place it over the hamburger */
            -webkit-touch-callout: none;
        }

        /*
        * Just a quick hamburger
        */
        #menuToggle span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            background: #cdcdcd;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
        }

            #menuToggle span:first-child {
                transform-origin: 0% 0%;
            }

            #menuToggle span:nth-last-child(2) {
                transform-origin: 0% 100%;
            }

        /*
        * Transform all the slices of hamburger
        * into a crossmark.
        */
        #menuToggle input:checked ~ span {
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);
            background: white;
        }

            /*
        * But let's hide the middle one.
        */
            #menuToggle input:checked ~ span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            /*
        * Ohyeah and the last one should go the other direction
        */
            #menuToggle input:checked ~ span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }

    /*
        * Make this absolute positioned
        * at the top left of the screen
        */
    #menu {
        position: absolute;
        width: 300px;
        margin: 12px 0 0 -240px; /* Adjusted margin-left value */
        padding: 50px;
        padding-top: 40px;
        background: #ededed;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
        transform-origin: 0% 0%;
        transform: translateX(-490%);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    }



        #menu li {
            padding: 10px 0;
            font-size: 22px;
        }

    /*
        * And let's slide it in from the left
        */
    #menuToggle input:checked ~ ul {
        transform: none;
    }
</style>

