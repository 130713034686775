import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { AboutComponent } from './components/common/about/about.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { DonersformComponent } from './components/donersform/donersform.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { BlooddonationComponent } from './components/blooddonation/blooddonation.component';
import { SearchComponent } from './components/search/search.component';
import { HarphanComponent } from './components/harphan/harphan.component'
import { RequestformComponent } from '../app/components/requestform/requestform.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrerequisitesComponent } from './components/prerequisites/prerequisites.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { OldagehomeComponent } from './components/oldagehome/oldagehome.component';
import { ShareyourservicesComponent } from './components/shareyourservices/shareyourservices.component';
import { ProfileComponent } from './components/profile/profile.component';
import { BrochuresComponent } from './components/brochures/brochures.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { JoincompainComponent } from './components/joincompain/joincompain.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
//import { LearnaboutbloodComponent } from './components/learnaboutblood/learnaboutblood.component';
import { LoginComponent } from './components/login/login.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { TeamComponent } from './components/common/team/team.component';
import { RequestloginComponent } from './components/requestlogin/requestlogin.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { DownloadsbrouchersComponent } from './components/downloadsbrouchers/downloadsbrouchers.component';
import { VolunteerloginComponent } from './components/volunteerlogin/volunteerlogin.component';
import { VolunteerprofileComponent } from './components/volunteerprofile/volunteerprofile.component';
import { VolunterComponent } from './components/volunter/volunter.component';
import { AddmemberComponent } from './components/addmember/addmember.component';
import { HospitalComponent } from './components/hospital/hospital.component';
import { StickbrochureComponent } from './components/stickbrochure/stickbrochure.component';
import { RequestpresentationComponent } from './components/requestpresentation/requestpresentation.component';
import { BlogComponent } from './components/common/blog/blog.component';
const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'home-two', component: HomeTwoComponent },
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'aboutus', component: PartnerComponent },
    { path: 'home-four', component: HomeFourComponent },
    { path: 'home-five', component: HomeFiveComponent },
    { path: 'home-six', component: HomeSixComponent },
    { path: 'home-seven', component: HomeSevenComponent },
    { path: 'home-eight', component: HomeEightComponent },
    { path: 'home-nine', component: HomeNineComponent },
    { path: 'home-ten', component: HomeTenComponent },
    { path: 'home-eleven', component: HomeElevenComponent },
    { path: 'home-twelve', component: HomeTwelveComponent },
    { path: 'home-thirteen', component: HomeThirteenComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'Menu', component: BlogComponent },


    { path: 'about', component: AboutComponent },
    { path: 'Login', component: LoginComponent },
    { path: 'Donersform', component: DonersformComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'Oldagehome', component: OldagehomeComponent },
    { path: 'Galleryy', component: FeedbackComponent },
    { path: 'blooddonation', component: BlooddonationComponent },
    { path: 'Userdetails', component: SearchComponent },
    { path: 'Orphanage', component: HarphanComponent },
    { path: 'Requestform', component: RequestformComponent },
    { path: 'Termsandconditions', component: TermsAndConditionsComponent },
    { path: 'Prerequisites', component: PrerequisitesComponent },
    { path: 'Registration', component: RegistrationComponent },
    { path: 'Shareyourservices', component: ShareyourservicesComponent },
    { path: 'Profile', component: ProfileComponent },
    { path: 'Brochures', component: BrochuresComponent },
    { path: 'FAQS', component: FaqComponent },
    { path: 'Joincampain', component: JoincompainComponent },
    { path: 'navbar', component: NavbarComponent },
    { path: 'BloodComponents', component: CtaComponent },
    { path: 'BloodTransfusion', component: PreloaderComponent },
    { path: 'IronBloodDonation', component: SkillComponent },
    { path: 'BloodDiversity', component: StrategyComponent },
    { path: 'HealthWellness', component: TeamComponent },
    { path: 'Gallery', component: GalleryComponent },
    { path: 'Requestlogin', component: RequestloginComponent },
    { path: 'demo', component: WhoWeAreComponent },
    { path: 'Downloadsbrouchers', component: DownloadsbrouchersComponent },
    { path: 'Volunteerlogin', component: VolunteerloginComponent },
    { path: 'Volunteerprofile', component: VolunteerprofileComponent },
    { path: 'Volunters', component: VolunterComponent },
    { path: 'Addtomember', component: AddmemberComponent },
    { path: 'Hospital', component: HospitalComponent},
    { path: 'Stickbrochure', component: StickbrochureComponent},
    { path: 'Requestpresentation', component: RequestpresentationComponent},

    // Here add new pages component

    { path: '**', component: HomeOneComponent } // This line will remain down from the whole pages component list
];



@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
