
import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../general.service';
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    values: any;
    constructor(private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient) { }

  ngOnInit() {
  }
   
    btn(value:any) {
        this.values=value
    }

    Navbar() {
        this.router.navigate(['/navbar']);
    }

}
