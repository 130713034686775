<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Let's Help </title>

    <!-- core CSS -->

    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

    <!--[if lt IE 9]>
        <script src="js/html5shiv.js"></script>
        <script src="js/respond.min.js"></script>
        <![endif]
        <link rel="shortcut icon" href="images/ico/favicon.ico">
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png">
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png">
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png">
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png">
    </head><!--/head-->
</head>
<body>
    <!--Bootstrap Approach-->

    <div class=" pt-100">
        <div class="container-fluid">
            <div class="container" style="background-color: #fb0300;">
                <div class="row">
                    
                    <div class="card_menu">
                        <div class="col-lg-3 col-6">
                            <a href="/">
                                <img src="assets/img/Blood_DonationImages/LETS_HELP_LOGO.png" alt="logo" class="width" />
                            </a>
                        </div>
                        
                        <div class="col-lg-9 col-6 none ml-n5">
                            <ng-container *ngIf="LoginDetails; else showLoginButton">
                                <div class="dropdown" (click)="toggleDropdown()">
                                    <button class="dropbtn" style="color:white">
                                        {{ letter }}

                                    </button>
                                    <div class="dropdown-content" style="    width:220%;">
                                        <a href="Profile" (click)="pro()"> My Profile </a>
                                        <a (click)="signOut()">Sign Out</a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #showLoginButton>
                                <p class="Login log_infont" (click)="openLoginModal()" ><b>LOGIN</b></p>
                            </ng-template>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff;">
                        <h2 style="color: #c52d2f; ">Blood Transfusion</h2><br />
                        <ul>
                            <li>Blood transfusion is a a crucial medical intervention that involves the transfer of blood or blood products from a healthy donor to a recipient who requires it. This procedure is essential for various medical situations, including surgeries, trauma cases, cancer treatments, childbirth complications, and management of certain medical conditions.</li>
                            <li>The process of blood transfusion typically begins with a thorough assessment of the recipient's medical history, including their blood type and any potential risk factors for transfusion reactions. It is vital to match the blood type of the donor and recipient to minimize the risk of adverse reactions. The major blood group systems include ABO and Rh(D), which determine compatibility between donor and recipient blood.</li>
                            <li>Blood transfusion is a crucial medical intervention that involves the transfer of blood or blood products from a healthy donor to a recipient who requires it. This procedure is essential for various medical situations, including surgeries, trauma cases, cancer treatments, childbirth complications, and management of certain medical conditions.</li>
                            <li>The process of blood transfusion typically begins with a thorough assessment of the recipient's medical history, including their blood type and any potential risk factors for transfusion reactions. It is vital to match the blood type of the donor and recipient to minimize the risk of adverse reactions. The major blood group systems include ABO and Rh(D), which determine compatibility between donor and recipient blood.</li>
                            <li>Once compatibility is confirmed, blood is collected from a voluntary donor who has undergone screening to ensure the safety of the donated blood. The collected blood is then processed to separate it into various components, including red blood cells, white blood cells, platelets, and plasma. This allows healthcare providers to tailor the transfusion to the specific needs of the recipient.</li>
                            <li>During the transfusion process, the blood component is administered intravenously into the recipient's bloodstream using a sterile infusion set. Healthcare providers closely monitor the transfusion to watch for any signs of adverse reactions, such as allergic reactions, transfusion-related acute lung injury (TRALI), transfusion-associated circulatory overload (TACO), or hemolytic reactions.</li>
                            <li>Red blood cell transfusions are commonly used to treat anemia, which is characterized by a deficiency of red blood cells or hemoglobin in the blood. Anemia can result from various medical conditions, including blood loss, chronic diseases, nutritional deficiencies, or bone marrow disorders. By providing additional red blood cells, transfusions help improve oxygen delivery to tissues and alleviate symptoms such as fatigue, weakness, and shortness of breath.</li>
                            <li>Platelet transfusions are administered to patients with low platelet counts, a condition known as thrombocytopenia. Platelets play a crucial role in blood clotting, and low platelet counts can lead to increased bleeding and difficulty in controlling bleeding, particularly during surgery or following trauma.</li>
                            <li>Fresh frozen plasma (FFP) transfusions are used to replenish clotting factors in patients with clotting disorders or coagulopathies. FFP contains a mixture of proteins, including clotting factors, albumin, and immunoglobulins, which help restore the balance of coagulation factors and promote blood clotting.</li>
                            <li>In addition to treating medical conditions, blood transfusions are also integral to supporting patients undergoing certain medical procedures, such as organ transplants, chemotherapy, or major surgeries, where significant blood loss is anticipated.</li>
                            <li>Overall, blood transfusion is a life-saving intervention that plays a vital role in modern medicine by providing patients with the necessary blood components to restore or maintain their health and well-being. However, it is essential to ensure proper screening, compatibility testing, and monitoring to minimize the risk of complications and ensure the safety and efficacy of transfusion therapy.</li>
                        </ul>
                       
                    </form>
                </div>



            </div><br />
        </div>
        <!--<section id="feature" >
                    <div class="container">
                        <section id="conatcat-info" style="background-color: #ffffff;">
                            <div class="container">
                                <br /> <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff;">
                                <h2 style="color:red;text-align:center;">Blood Transfusion</h2><br />
                                   <ul >
                                         <li>Blood transfusion is a a crucial medical intervention that involves the transfer of blood or blood products from a healthy donor to a recipient who requires it. This procedure is essential for various medical situations, including surgeries, trauma cases, cancer treatments, childbirth complications, and management of certain medical conditions.</li>
                                         <li>The process of blood transfusion typically begins with a thorough assessment of the recipient's medical history, including their blood type and any potential risk factors for transfusion reactions. It is vital to match the blood type of the donor and recipient to minimize the risk of adverse reactions. The major blood group systems include ABO and Rh(D), which determine compatibility between donor and recipient blood.</li>
                                         <li>Blood transfusion is a crucial medical intervention that involves the transfer of blood or blood products from a healthy donor to a recipient who requires it. This procedure is essential for various medical situations, including surgeries, trauma cases, cancer treatments, childbirth complications, and management of certain medical conditions.</li>
                                         <li>The process of blood transfusion typically begins with a thorough assessment of the recipient's medical history, including their blood type and any potential risk factors for transfusion reactions. It is vital to match the blood type of the donor and recipient to minimize the risk of adverse reactions. The major blood group systems include ABO and Rh(D), which determine compatibility between donor and recipient blood.</li>
                                         <li>Once compatibility is confirmed, blood is collected from a voluntary donor who has undergone screening to ensure the safety of the donated blood. The collected blood is then processed to separate it into various components, including red blood cells, white blood cells, platelets, and plasma. This allows healthcare providers to tailor the transfusion to the specific needs of the recipient.</li>
                                        <li>During the transfusion process, the blood component is administered intravenously into the recipient's bloodstream using a sterile infusion set. Healthcare providers closely monitor the transfusion to watch for any signs of adverse reactions, such as allergic reactions, transfusion-related acute lung injury (TRALI), transfusion-associated circulatory overload (TACO), or hemolytic reactions.</li>
                                        <li>Red blood cell transfusions are commonly used to treat anemia, which is characterized by a deficiency of red blood cells or hemoglobin in the blood. Anemia can result from various medical conditions, including blood loss, chronic diseases, nutritional deficiencies, or bone marrow disorders. By providing additional red blood cells, transfusions help improve oxygen delivery to tissues and alleviate symptoms such as fatigue, weakness, and shortness of breath.</li>
                                        <li>Platelet transfusions are administered to patients with low platelet counts, a condition known as thrombocytopenia. Platelets play a crucial role in blood clotting, and low platelet counts can lead to increased bleeding and difficulty in controlling bleeding, particularly during surgery or following trauma.</li>
                                       <li>Fresh frozen plasma (FFP) transfusions are used to replenish clotting factors in patients with clotting disorders or coagulopathies. FFP contains a mixture of proteins, including clotting factors, albumin, and immunoglobulins, which help restore the balance of coagulation factors and promote blood clotting.</li>
                                       <li>In addition to treating medical conditions, blood transfusions are also integral to supporting patients undergoing certain medical procedures, such as organ transplants, chemotherapy, or major surgeries, where significant blood loss is anticipated.</li>
                                       <li>Overall, blood transfusion is a life-saving intervention that plays a vital role in modern medicine by providing patients with the necessary blood components to restore or maintain their health and well-being. However, it is essential to ensure proper screening, compatibility testing, and monitoring to minimize the risk of complications and ensure the safety and efficacy of transfusion therapy.</li>
                                   </ul>
            <br />
        </form>
                            </div>

                        </section>-->
        <!--/#conatcat-info-->
        <!--</div>
        </section>-->
    </div>

    <footer id="footer" class="midnight-blue">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    &copy; 2024 Let's Help  all rights reserved.
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-2 zoom-in"><a href="#" style="color:white">Home</a></div>
                        <div class="col-lg-2 zoom-in"><a href="about" style="color:white">About</a></div>
                        <div class="col-lg-2 zoom-in"><a href="FAQS" style="color:white">FAQ's</a></div>
                        <div class="col-lg-3 zoom-in"><a href="contact" style="color:white">Contact Us</a></div>
                    </div>

                </div>
            </div>
        </div>
    </footer><!--/#footer-->
    <script src="js/jquery.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.prettyPhoto.js"></script>
    <script src="js/jquery.isotope.min.js"></script>
    <script src="js/main.js"></script>
    <script src="js/wow.min.js"></script>


</body>
</html>

<style>
    h1, h2, h3, h4, h5, h6 {
        margin: 1% 0% -2% 0%;
        font-weight: bold
    }

    body {
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
        overflow-y: auto; /* Enable vertical scrolling */
    }

    .pt-100 {
        padding-top: 0px;
    }
   
    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }

    a:hover, a:focus {
        color: #fb0300;
        text-decoration: none;
        outline: none;
    }
    ul {
        padding: 16px;
    }

        ul li {
            list-style: disc;
        }
    .midnight-blue {
        background-color: black;
        color: white;
        padding: 10px 2px;
    }

    li {
       /* display: inline-block;*/
        color:black;
        padding: 4px;
        list-style-type:disc;
    }

        li a {
            color: white;
        }
        


    @media only screen and (max-width: 600px) {
        .none {
            display: none;
        }
    }
    footer div a {
        float: inline-end;
    }
</style>

