<div class="pt-100">



    <div class="container" style="margin-bottom:1%;margin-top: -13px;">
        <div class="row">
            <div class="col-lg-3">
                <a routerLink="/">
                    <img src="../../../assets/images/logo.jpg" class="zoom-in" />
                </a>
            </div>
            <div class="col-lg-6">
                <div class="card1">
                    <div class="row">
                        <div class="col-lg-4">
                            <div routerLink="/Joincampain" class="join" routerLinkActive="active" (click)="sharee()"><div>Join Let's Help Campaign</div></div>
                        </div>
                        <div class="col-lg-4">
                            <div routerLink="/blooddonation" routerLinkActive="active" (click)="share()"> <div>Share Your Services</div></div>
                        </div>
                        <div class="col-lg-4">
                            <!--<div (click)="share()">Orphanage</div>-->
                            <div class="title" (click)="showSocialList = !showSocialList">
                                Share this Gallery <i class="fas fa-share-alt"></i>
                                <div class="row">
                                    <div class="col-lg-12" *ngIf="showSocialList" style="margin-top: 1px;cursor: pointer;">
                                        <ul class="social-list">
                                            <li (click)="shareTo('Facebook')"><i class="fab fa-facebook"></i></li>
                                            <li (click)="shareTo('Twitter')"><i class="fa-brands fa-x-twitter"></i></li>
                                            <li (click)="shareTo('WhatsApp')"><i class="fab fa-whatsapp"></i></li>
                                            <li (click)="copyPageUrl()" class="social"><i class="fas fa-copy"></i></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-2">
                <ul style="text-align: center;">
                    <li>
                        <a class="facebook" href="https://www.facebook.com/" target="_blank">

                            <i class="fab fa-facebook icon" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a class="twitter" href="https://twitter.com/i/flow/login" target="_blank">

                            <i class="fab fa-twitter icon" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a class="instagram" href="https://www.instagram.com/accounts/login/?hl=en" target="_blank">

                            <i class="fab fa-instagram icon" aria-hidden="true"></i>
                        </a>
                    </li>

                </ul>
            </div>

            <div class="col-lg-1 ">
                <ng-container *ngIf="LoginDetails; else showLoginButton">
                    <ng-container *ngIf="LoginDetails; else showLoginButton">
                        <div (click)="toggleDropdown()" class="profile1">
                            <div class="dropbtn bttn">
                                {{ letter }}
                            </div>
                            <div class="dropdown-content" id="dropdownContent" style="width:100px;">
                                <a href="Profile"> My Profile </a>
                                <a (click)="signOut()">Sign Out</a>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #showLoginButton>

                    <p class="services www" (click)="openLoginModal()" style="text-decoration:underline;cursor:pointer">  LOGIN</p>

                </ng-template>

            </div>

        </div>
    </div>

    <div class="modal" id="myModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">
                        Login
                    </h4>

                    <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:black">X</span></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="card_1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <fieldset>
                                        <div class="cards">

                                            <form>
                                                <label for="username">Mobile </label>
                                                <input type="text" [(ngModel)]="Mobile" placeholder="Enter Your Mobile Number" id="username" name="username" inputmode="numeric" pattern="[0-9]*" maxlength="10" required>
                                                <label for="password" *ngIf="OTPtoMobile != null">OTP </label>
                                                <input type="password" [(ngModel)]="OTP" id="password" name="password" *ngIf="OTPtoMobile != null" required>
                                                <br />
                                                <button class="btns btn-primary" type="submit" (click)="SendOTPtoMobile()" [hidden]="!showLogin1" [disabled]="otpSent">Send OTP</button>
                                                &nbsp;
                                                <br />
                                                <button class="btns btn-primary" type="submit" (click)="checkmobile(Mobile)" [hidden]="!showLogin">Login</button>
                                            </form>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container">
        <!--<div class="row">
            <div class="col-lg-10">
                <div>
                    <ul>
                        <li><a routerLink="/blooddonation" class="active">Blood Donation ></a></li>
                        <li><a routerLink="/Gallery">Donar Gallery</a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-2 text-right">
                <nav aria-label="Page navigation">
                    <ul class="pagination1">
                        <li class="page-item">
                            <a class="page-link" aria-label="Previous" (click)="prevPage()">
                                <span>&larr; Previous</span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" aria-label="Next" (click)="nextPage()">
                                <span>Next &rarr;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>-->



        <div class="row">
            <div class="col-lg-3">
                <h3 class="title1">Let's Help Gallery</h3>
                <div class="card border-0">
                    <div class="card-body p-0">
                        <button class="btnss" (click)="Getimages()" [ngClass]="{'active': isActive === 'bloodDonation' && !isSearching && !isSearchInputFocused}" [disabled]="isSearching">
                            Blood Donation
                        </button>
                        <button class="btnss" (click)="Getimages1()" [ngClass]="{'active': isActive === 'oldAgeService' && !isSearching && !isSearchInputFocused}" [disabled]="isSearching">
                            Old Age Service
                        </button>
                        <button class="btnss" (click)="Getimages2()" [ngClass]="{'active': isActive === 'orphanServices' && !isSearching && !isSearchInputFocused}" [disabled]="isSearching">
                            Orphan Services
                        </button>
                        <button class="btnss" (click)="Getimages3()" [ngClass]="{'active': isActive === 'Campaign' && !isSearching && !isSearchInputFocused}" [disabled]="isSearching">
                            Campaign
                        </button>

                    </div>
                </div>
            </div>

            <div class="col-lg-9">

                <input type="text" placeholder="Search by Name/Email ID/ Phone number" [(ngModel)]="searchQuery"
                       (input)="Gallerysearch(searchQuery)" (focus)="isSearchInputFocused = true" (blur)="isSearchInputFocused = false"
                       (keyup.enter)="Gallerysearch(searchQuery)" />

                <div class="row" style="min-height: 200px;">
                    <div class="col-lg-4 col-12" *ngFor="let item of getPagedItems(); let i = index;">
                        <!--<div class="flip-box">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">-->

                        <div class="image-container" (click)="viewDetails(item.GalleryID,item.FullName,item.StateName)">
                            <img [src]="HomeUrl + item.GalleryImages" alt="Image {{(currentPage-1)*itemsPerPage + i + 1}}" class="gallery-image">
                            <div class="share_1" (click)="flipToBack($event); disableViewDetailsOnClick()">
                                <i class="fas fa-share-alt fa_share"></i>
                            </div>
                            <div class="flip-box-back" (mouseleave)="flipToFront($event)">
                                <div class="details">
                                    <p style="color:red">{{ item.FullName }}</p>
                                    <p style="color:black">{{ item.StateName }}</p>
                                </div>

                                <ul class="social-icons-horizontally">
                                    <li (click)="shareTo1('Facebook', HomeUrl + item.GalleryImages); disableViewDetailsOnClick()"><i class="fab fa-facebook"></i></li>
                                    <li (click)="shareTo1('Twitter', HomeUrl + item.GalleryImages); disableViewDetailsOnClick()"><i class="fa-brands fa-x-twitter i_share"></i></li>
                                    <li (click)="shareTo1('WhatsApp', HomeUrl + item.GalleryImages); disableViewDetailsOnClick()"><i class="fab fa-whatsapp"></i></li>
                                    <li (click)="copyUrl(HomeUrl + item.GalleryImages); disableViewDetailsOnClick()"><i class="fas fa-copy i_share"></i></li>
                                </ul>
                            </div>
                        </div>

                        <!--</div>
                            </div>
                        </div>-->
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-4">

                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-start">
                            <li class="page-item">
                                <a class="page-link" aria-label="First" (click)="goToFirstPage()">
                                    <span aria-hidden="true">&laquo;&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" aria-label="Previous" (click)="prevPage()">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item" *ngFor="let page of getPages(); let i = index;" [class.active]="currentPage === page">
                                <a class="page-link" (click)="setPage(page)">{{page}}</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" aria-label="Next" (click)="nextPage()">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" aria-label="Last" (click)="goToLastPage()">
                                    <span aria-hidden="true">&raquo;&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>

                </div>
                <div class="col-lg-3"></div>
            </div>


        </div>
    </div>
    <br />

    <div class="modal" id="myModal123">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 class="title h4_color" id="modal-primary-label"></h4>
                    <button type="button" class="close" (click)="close()" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" style="color:black">X</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div>
                            <div class="col-md-12">
                                <p>
                                    <span class="view">{{ FullName }}</span>
                                    <span *ngIf="FullName" class="comma">,&nbsp;&nbsp;</span>
                                    <span class="st">{{ StateName }}</span>
                                </p>
                            </div>
                        </div>
                        <img src="{{ HomeUrl + GalleryImages }}">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="footer">
    <div class="container-fluid" style="background-color:black;padding:10px;margin-top:1rem;color:white;">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div>&copy; 2024 Let's Help All Rights Reserved</div>
                </div>
                <div class="col-lg-5">
                </div>
                <div class="col-lg-1 zoom-in">
                    <a routerLink="/"> <div class="color"  >Home</div></a>
                </div>
                <div class="col-lg-1 zoom-in">
                    <a routerLink="/about"> <div  class="color"  >About Us</div></a>
                </div>
                <div class="col-lg-1 zoom-in">
                    <a routerLink="/"> <div  class="color"  >FAQ's</div></a>
                </div>
                <div class="col-lg-1 zoom-in">
                    <a routerLink="/contact"> <div  class="color" >Contact Us</div></a>
                </div>

            </div>
        </div>
    </div>
</div>
