<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->
<head>
    <!-- build success -->
    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="Let's Help ">
                                               <meta property="og:image" content="assets/Newcss/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="Let's Help " />
    <meta property="og:site_name" content="Let's Help " />
    <meta property="og:description" content="Let's Help " />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href="https://fonts.googleapis.com/css?family=Lato:400&display=swap" rel="stylesheet">
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <script src="www.google-analytics.com/cx/ffdac.txt?experiment=xioP1WLxTVuxOM3d4NX95g"></script>
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />
    <link href="assets/Newcss/css/newone.css" />
</head>
<body>
  

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>

        <div id="page-content">



            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">

                    <div class="bg-vid" data-src="dist/vid/Let's Help " style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="font-size:32px;font-weight:bold">Terms of Use and Disclaimer</h2>
                    </header>
                </div>
            </div>

            <div class="pane pane--right" data-pane="right">
        <div class="pane__inner allow-scroll">

            <div class="section__content">

                <div class="section__main section__main--standalone post post--single"></div>

                <h5>&nbsp;</h5>
                <p style="text-align: justify;">
                    In using this Site, you agree to and understand these Terms of Use as well as our Privacy Policy.
                    Lets-help website reserves the right to change the content within the web pages and within the Website
                    Privacy Policy and Terms and Conditions at any time, without notice, at any time by updating this
                    posting. You should visit this page periodically to review the current terms and conditions.<br>
                    <h5 style="text-align: justify;">GENERAL DISCLAIMER:</h5><br>
                    <strong>  Lets Help.com is a Non Profit, Non Commercial, ......</strong>
                <p style="text-align: justify;">
                    <br />
                    The information on this Site or otherwise communicated to users of this Site, including information
                    communicated via e-mail is is provided solely for the Social cause and this site is Non Commercial, .<br>
                    We make reasonable efforts to present current and accurate information. We would not
                    assume any legal liability and makes no representations or warranties concerning the accuracy,
                    completeness, timeliness, reliability or usefulness of the materials accessed from or through this Site
                    or any other site accessed through this Site. No warranty is made that the information provided is error-
                    free. The numbers, percentages and ratios contained within the web pages may be, at any time,
                    outdated and may include inaccuracies and/or errors.<br>
                    Lets-help assumes no responsibility or liability for any consequence resulting directly or indirectly from
                    any action you take based on or made in reliance on the information or materials on this Site, or other
                    sites linked to it. It does not represent or warrant that access to this Site or any sites linked to this Site
                    will be uninterrupted, that this Site will operate error free. This Site or any portion thereof may be
                    unavailable at any time<br>
                    <h5 style="text-align: justify;">NOTIFICATION OF CHANGES:</h5><br>
                <p style="text-align: justify;">
                    We aim to update our site regularly, and may change the content at any time. If the need arises, we may
                    suspend access to our site, or close it indefinitely. Any of the material on our site may be out of date at
                    any given time, and we are under no obligation to update such material.<br>
                    <h5 style="text-align: justify;">SECURITY</h5>
                <p style="text-align: justify;">
                    This website takes reasonable precautions to protect user information from unauthorized use or
                    alteration. All data, correspondence, and responses that the user of the website provides will be
                    handled confidentially, both online and offline.. Any Information Users submit via this website will not
                    be shared nor rented, nor sold to others in any forms. All of our users’ personally identifiable
                    information is restricted to our website only. However, the information that is needed for the Sole
                    purpose of blood donation registration will be available for the public view.
                    COPY RIGHT and TRADE MARKS
                    All media (downloaded or samples), text, images, graphics, user interfaces, videos, photographs, and
                    other content on the Site, including, but not limited to, the design, selection, arrangement, and
                    coordination of such content on the Site, is not owned or licensed by or to Lets-help. Being a Social,
                    Non commercial website, its been sourced through various forms from the Internet. We would like to

                    thank all the News Channels in India, not limited to, English, Hindi or regional who has played a
                    prominent part in building up this Website,we would like to thank Google, Wikipedia, Youtube and
                    many more online informational sites that being a building block in making Lets-help and we assure
                    you that none of the material will not be exploited for any commercial usage. You have been a part of
                    our sincere endeavor of creating a good society and a social cause. If you still feel that your material,
                    information should be deleted from this website, please feel free to send an email with the
                    information to letshelp.ind gmail.com and we would delete it as soon as possible.

                    REVIEWS, COMMENTS, COMMUNICATION and OTHER
                    Site visitors may send communications; and submit suggestions, ideas, comments, questions, or other
                    information, provided that the content is not illegal, obscene, threatening, defamatory, invasive of
                    privacy or objectionable and does not consist of or contain software viruses, political campaigning,
                    commercial solicitation, chain letters, mass mailings, or any form of “spam”. You may not use a false
                    email address, impersonate any person or entity, or otherwise mislead as to the content’s origin.
                    We have the right to disable any user identification code or password, whether chosen by you or
                    allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of
                    these terms of use.

                    LINKS TO OTHER SITES
                    This website may provide links or references to other sites. These links are provided for your
                    convenience and Lets-help is not responsible for the content of these other sites, does not make any
                    representations or gives any warranties with respect to any information contained in or at these other
                    sites, and shall not be liable for any damages or injury arising from the content of these other
                    sites.When leaving Lets-help website to visit another website, we recommend that you review that
                    website’s terms and conditions and privacy policy.
                    YOUR CONCERNS – TO CONTACT US
                    If you have any questions concerning your right to the privacy and confidentiality of your personal
                    information and data that has been entrusted to Lets-help.com website or for any specific inquiries,
                    please contact us directly using our online contact us form

                    Thank you for visiting our site.
                </p>
                <br />
            </div>
        </div>
    </div>
            <!--<div class="pane pane--right" data-pane="right">
        <div class="pane__inner allow-scroll">
            <div class="section__content">
                <div class="section__main section__main--standalone post post--single">
                </div>

                <h3 style="color:black; font-size: 24px;">Eligibility Criteria to Donate Blood</h3>
                <ul class="order">
                    <li><strong>Overall health:</strong> The donor must be fit and healthy, and should not be suffering from transmittable diseases.</li>
                    <li><strong>Age and weight:</strong> The donor must be 18–60 years old and should weigh a minimum of 50 kg.</li>
                    <li><strong>Pulse rate:</strong> Between 50 and 100 without irregularities.</li>
                    <li><strong>Hemoglobin level:</strong> A minimum of 12.5 g/dL.</li>
                    <li><strong>Blood pressure:</strong> Diastolic: 50–100 mm Hg, Systolic: 100–180 mm Hg.</li>
                    <li><strong>Body temperature:</strong> Should be normal, with an oral temperature not exceeding 37.5 °C.</li>
                    <li><strong>The time period between successive blood donations:</strong> Should be more than 3 months.</li>
                </ul>&nbsp;
                <h4 style="color:black;"><b>Individuals under certain conditions are deemed ineligible to donate blood</b></h4>

                <ul class="order">
                    <li>A person who has been tested HIV positive.</li>
                    <li>Individuals suffering from ailments like cardiac arrest, hypertension, blood pressure, cancer, epilepsy, kidney ailments, and diabetes.</li>
                    <li>A person who has undergone ear/body piercing or tattoo in the past 6 months.</li>
                    <li>Individuals who have undergone immunization in the past 1 month.</li>
                    <li>Individuals treated for rabies or received Hepatitis B vaccine in the past 6 months.</li>
                    <li>A person who has consumed alcohol in the past 24 hours.</li>
                    <li>Women who are pregnant or breastfeeding.</li>
                    <li>Individuals who have undergone major dental procedures or general surgeries in the past 1 month.</li>
                    <li>Women who have had miscarriage in the past 6 months.</li>
                    <li>Individuals who have had fits, tuberculosis, asthma, and allergic disorders in the past.</li>
                </ul>

            </div>
        </div>
    </div>-->






        </div>
    </div>


   
</body>


</html>
<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    @media (min-width: 992px) {
        .pane__inner.allow-scroll {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            overflow-x: clip;
        }
    }
    @media only screen and (max-width:600px){
        p {
            text-align: left;
        }
    }
    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .patient {
        color: red;
        font-size: 25px;
        margin: 2% 0% -2% 0%;
    }


    .wrapper {
        position: fixed;
        top: 0;
        right: -109%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px; 
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border:2px solid white;
    }

        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 3px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .cartdetails {
        width: 100%;
        height: 55%;
        margin-top: 56%;
        border-radius: 10px;
        border: 2px solid black;
        background-color: white;
        padding: 17px;
        color: black;
    }

    .label {
        display: inline-block;
        margin-top: 12px;
    }

    .para {
        font-size: 15px;
        text-align: left;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
        margin-left: 11%;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
    .order {
        text-align: left;
        list-style-type: disc;
        line-height:30px;
    }
    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }

    @media (min-width: 768px) {
        .col-md-3 {
            /*flex: 0 0 auto;*/
            width: 25%;
        }
    }

</style>
