import { ChangeDetectorRef, Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-stickbrochure',
  templateUrl: './stickbrochure.component.html',
  styleUrls: ['./stickbrochure.component.scss']
})
export class StickbrochureComponent {
    HomeURL: any;
    brochuedata: any = [];
    showSocialMedia: any;
    Statedata: any = [];
    observ: any = [];
    deptList: any;
    districtdata: any = [];
    deptList1: any;
    Citydata: any = [];
    deptList2: any;
    deptList9: any;
    selectedState1: any;
    selectedState2: any;
    selectedState3: any; brochures: any;
    pincode: any; DownloadLogo: any = 2;
    LoginDetails1: any; LoginDetails: any; activeCardIndex: number = -1;
    pdfurl: any;
    pdfpath: any;
    arr1: any = [];
    isActive: boolean = false; arr: any;
    public form: FormGroup;
    selectedVenue: string;

    constructor(public generalservice: GeneralService, private location: Location,
        private cdr: ChangeDetectorRef, public router: Router, public http: HttpClient, public fb: FormBuilder) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            //  this.pdfurl = 'https://localhost:44387/';
            this.pdfurl = 'https://letshelp.breakingindiaapp.com/Webservices/';
        });

        this.form = this.fb.group({
            // Define your form controls here
            StateName: ['',],
            DistrictName: ['',],
            CityName: ['',],
            pincode: ['',],
            location: ['',],
            WorkPlace: ['',],
            Place_name: ['',],

        });

    }

    ngOnInit(): void {
        this.getvenue();
        this.brochure();
        this.getstate();
       



    }
    handleClick(index: number) {
        if (this.activeCardIndex === index) {
            this.activeCardIndex = -1; // Toggle off if same card clicked again
        } else {
            this.activeCardIndex = index;
        }
    }
    selectVenue(venue: string) {
      
        this.selectedVenue = venue;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    Brochureinsert() {

        if (!this.form.value.pincode || !this.form.value.DistrictName || !this.form.value.WorkPlace.WorkPlace || !this.form.value.CityName
            || !this.form.value.StateName) {
            alert('Please select all the details ');
            return;
        }
       
        this.arr = [];
        this.arr.push({
            stateid: this.form.value.StateName.StateId,
            districtid: this.form.value.DistrictName.DistrictID,
            cityid: this.form.value.CityName.CityId,
            Pincode: this.form.value.pincode,
            Location: this.form.value.location,
            Place_name: this.form.value.Place_name,
            Place: this.form.value.WorkPlace.WorkPlace,
          //  Place: this.selectedVenue,
        })
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(this.arr));
        var url = "api/BG/InsertBrochures_Deatails";
        this.generalservice.PostData(url, uploadfile)
            .then(data => {
                if (data === "SUCCESS") {
                    this.downloadFile(this.pdfpath);
                } else {
                    console.error("Error occurred while processing file:", data);
                }
            })
            .catch(error => {
                console.error("Error occurred while downloading file:", error);
            });



    }

    Brochureinsert1() {
        if (!this.form.value.pincode || !this.form.value.DistrictName || !this.form.value.CityName || !this.form.value.WorkPlace.WorkPlace
            || !this.form.value.StateName) {
            alert('Please select all the details ');
            return;
        }
       
        this.arr = [];
        this.arr.push({
            stateid: this.form.value.StateName.StateId,
            districtid: this.form.value.DistrictName.DistrictID,
            cityid: this.form.value.CityName.CityId,
            Pincode: this.form.value.pincode,
            Location: this.form.value.location,
            Place_name: this.form.value.Place_name,
            Place: this.form.value.WorkPlace.WorkPlace,
        });
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        var url = "api/BG/InsertBrochures_Deatails";
        this.generalservice.PostData(url, uploadfile)
            .then(data => {
                if (data === "SUCCESS") {
                    this.generalservice.ShowAlert('SUCCESS', 'Successfully added the Brochure details', 'SUCCESS');
                  
                     
                        window.location.reload();
               
                } else {
                    console.error("Error occurred while processing file:", data);
                }
            })
            .catch(error => {
                console.error("Error occurred while processing file:", error);
            });
    }

    brochure() {
       
        this.arr = [];
        this.arr.push({

        })
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '4');
        var url = "api/BG/BG_Brochers_Crud";
        this.generalservice.PostData(url, uploadfile).then(data => {
            this.brochuedata = data;
            this.pdfpath = this.brochuedata[0].BrochurePath
        })

    }
    getvenue() {
        
        this.arr = []
        this.arr.push({
         
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BG_Work_Place_Crud";
        this.generalservice.PostData(url, UploadFile).then(data => {
            debugger
            this.observ = data;
        }, err => {
            this.generalservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont9(event) {
      
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.observ.length; i++) {
                let country = this.observ[i];
                if (country.WorkPlace.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList9 = filtered;
        }
    }

    download1(filePath: string | null): void {
        if (filePath) {
            const fileName: string = filePath.split('/').pop() || '';
            const absoluteUrl: string = `https://localhost:44387/${filePath}`;

            const link: HTMLAnchorElement = document.createElement('a');
            link.href = absoluteUrl;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('File path not available');
        }
    }
    download(filePath: string | null): void {
        if (filePath) {
            const timestamp = new Date().getTime(); // Get current timestamp
            const absoluteUrl: string = `https://letshelp.breakingindiaapp.com/Webservices/${filePath}?t=${timestamp}`;

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = absoluteUrl;
            link.setAttribute('download', ''); // This will set the download attribute to force download
            document.body.appendChild(link);

            // Simulate a click on the anchor element
            link.click();

            // Remove the anchor element from the DOM after a short delay
            setTimeout(() => {
                document.body.removeChild(link);
            }, 100);
        } else {
            console.error('File path not available');
        }
    }
    downloadFile(filePath: string): void {
  
        const absoluteUrl: string = `https://letshelp.breakingindiaapp.com/Webservices/${filePath}`;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = absoluteUrl;
        link.setAttribute('download', ''); // This will set the download attribute to force download
        document.body.appendChild(link);

        // Simulate a click on the anchor element
        link.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(link);
    }





    toggleSocialMedia(value: number) {
        // Update showSocialMedia variable
        this.router.navigate(['/Requestpresentation'])
    }

    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }



    toggleSocialMedias(value: any) {
      
        if (value != 2) {
            this.showSocialMedia = value;

        }
        else {
            //  this.downloadlogo();
        }
    }


    openInstagram() {
        // Replace 'your-instagram-url' with the actual Instagram share URL
        const instagramShareUrl = 'https://www.instagram.com/';
        const urlToShare = 'https://letshelp.breakingindiaapp.com/Brochures';

        // Combine Instagram URL and the URL to share
        const finalInstagramUrl = `${instagramShareUrl}?url=${encodeURIComponent(urlToShare)}`;

        // Open Instagram in a new window or tab
        window.open(finalInstagramUrl, '_blank');
    }
    openFacebook() {
        // Replace 'your-facebook-url' with the actual Facebook share URL
        const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
        const urlToShare = 'https://letshelp.breakingindiaapp.com/Brochures';

        // Combine Facebook URL and the URL to share
        const finalFacebookUrl = `${facebookShareUrl}${encodeURIComponent(urlToShare)}`;

        // Open Facebook in a new window or tab
        window.open(finalFacebookUrl, '_blank');
    }
    openWhatsApp() {
        // Replace 'your-whatsapp-url' with the actual WhatsApp share URL
        const whatsappShareUrl = 'https://wa.me/?text=';
        const textToShare = 'Check out this link: https://letshelp.breakingindiaapp.com/Brochures';

        // Combine WhatsApp URL and the text to share
        const finalWhatsAppUrl = `${whatsappShareUrl}${encodeURIComponent(textToShare)}`;

        // Open WhatsApp in a new window or tab
        window.open(finalWhatsAppUrl, '_blank');
    }

    openGmailApp() {
        // Replace 'your-whatsapp-url' with the actual WhatsApp share URL
        const GmailShareUrl = 'https://mail.google.com/mail/u/0/';
        const textToShare = 'Check out this link: https://letshelp.breakingindiaapp.com/Brochures';

        // Combine WhatsApp URL and the text to share
        const finalWhatsAppUrl = `${GmailShareUrl}${encodeURIComponent(textToShare)}`;

        // Open WhatsApp in a new window or tab
        window.open(finalWhatsAppUrl, '_blank');
    }


    setActiveCard(index: number) {
        this.activeCardIndex = index;
    }

    getstate() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.generalservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.generalservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }

    getDistrict() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.selectedState1.StateId,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.districtdata = data;
        }, err => {
            this.generalservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.selectedState2.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.Citydata = data;
        }, err => {
            this.generalservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }



    // drop down for cities //
    searchCont2(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }

    onStateSelect1(event) {
        console.log('Selected State:', this.selectedState1);
        // You can perform additional actions with the selected state here
    }
    onStateSelect2(event) {
        console.log('Selected Distict:', this.selectedState2);
        // You can perform additional actions with the selected state here
    }
    onStateSelect3(event) {
        console.log('Selected City:', this.selectedState3);
        // You can perform additional actions with the selected state here
    }

    reg() {
        debugger
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
    downloadlogo() {

    }

}
