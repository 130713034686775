import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-joincompain',
  templateUrl: './joincompain.component.html',
  styleUrls: ['./joincompain.component.scss']
})
export class JoincompainComponent {
    showSocialMedia = false;
    LoginDetails1: any; LoginDetails: any;
    constructor(private router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
    }
    ngOnInit() { }

    toggleSocialMedia() {
        this.showSocialMedia = !this.showSocialMedia;
    }
    openTwitter() {
        // Replace 'your-twitter-url' with the actual Twitter share URL
        const twitterShareUrl = 'https://twitter.com/intent/tweet';
        const urlToShare = 'https://letshelp.breakingindiaapp.com/Brochures';

        // Combine Twitter URL and the URL to share
        const finalTwitterUrl = `${twitterShareUrl}?url=${encodeURIComponent(urlToShare)}`;

        // Open Twitter in a new window or tab
        window.open(finalTwitterUrl, '_blank');
    }

    openInstagram() {
        // Replace 'your-instagram-url' with the actual Instagram share URL
        const instagramShareUrl = 'https://www.instagram.com/';
        const urlToShare = 'https://letshelp.breakingindiaapp.com/Brochures';

        // Combine Instagram URL and the URL to share
        const finalInstagramUrl = `${instagramShareUrl}?url=${encodeURIComponent(urlToShare)}`;

        // Open Instagram in a new window or tab
        window.open(finalInstagramUrl, '_blank');
    }
    openFacebook() {
        // Replace 'your-facebook-url' with the actual Facebook share URL
        const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
        const urlToShare = 'https://letshelp.breakingindiaapp.com/Brochures';

        // Combine Facebook URL and the URL to share
        const finalFacebookUrl = `${facebookShareUrl}${encodeURIComponent(urlToShare)}`;

        // Open Facebook in a new window or tab
        window.open(finalFacebookUrl, '_blank');
    }
    openWhatsApp() {
        // Replace 'your-whatsapp-url' with the actual WhatsApp share URL
        const whatsappShareUrl = 'https://wa.me/?text=';
        const textToShare = 'Check out this link: https://letshelp.breakingindiaapp.com/Brochures';

        // Combine WhatsApp URL and the text to share
        const finalWhatsAppUrl = `${whatsappShareUrl}${encodeURIComponent(textToShare)}`;

        // Open WhatsApp in a new window or tab
        window.open(finalWhatsAppUrl, '_blank');
    }

    openGmailApp() {
        // Replace 'your-whatsapp-url' with the actual WhatsApp share URL
        const GmailShareUrl = 'https://mail.google.com/mail/u/0/';
        const textToShare = 'Check out this link: https://letshelp.breakingindiaapp.com/Brochures';

        // Combine WhatsApp URL and the text to share
        const finalWhatsAppUrl = `${GmailShareUrl}${encodeURIComponent(textToShare)}`;

        // Open WhatsApp in a new window or tab
        window.open(finalWhatsAppUrl, '_blank');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']);
        }
    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }
}

