<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>

    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="Let's Help ">
    <meta property="og:image" content="assets/Newcss/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="Let's Help " />
    <meta property="og:site_name" content="Let's Help " />
    <meta property="og:description" content="Let's Help " />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>
        <div id="page-content">
            <br />
            <div class="pane pane--left" data-pane="left" style="background-color: #4e4e48;">
                <div class="pane__inner allow-scroll" style="overflow:hidden">
                    <div class="section__content">
                        <div class="section__main section__main--standalone post post--single" style="padding-top: 50px;">
                        </div>
                        <div class="post-list__share share-job">
                            <br />  <br />


                            <div class="container">
                                <div class="row">

                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === 1 }" (click)="handleClick(0)">
                                            <a routerLink="/Addtomember">
                                                <img src="assets/img/addamember.jpg">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === -1 }" (click)="handleClick(1)">
                                            <img src="assets/img/requestpresentation.jpg" (click)="toggleSocialMedia(1)">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === 1 }" (click)="handleClick(2)">
                                            <img src="assets/Newcss/img/download.jpg" (click)="downloadlogo()">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === 3 }" (click)="handleClick(3)">
                                            <a routerLink="/Hospital">
                                                <img src="assets/img/hospitaldetails.jpg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="pane pane--left" data-pane="left">
            <div class="pane__inner pane-header">

                <div class="bg-vid" data-src="dist/vid/Let's Help " style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                <header class="pane-header__content">
                    <h2 class="page-title u-white" style="line-height: 60PX;">Donor Registration Form</h2>

                </header>

            </div>
        </div>-->

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="section__content">

                        <div class="section__main section__main--standalone post post--single">


                        </div>
                        <h3 style="color:#000000">
                            <b>Request for a presentation from Let's help team</b>
                        </h3>
                        <br />
                        <form class="form_1" [formGroup]="form" (ngSubmit)="Donersform()">


                            <div class="row">
                                <div class="col-lg-4 mt-3">
                                    <label for="age">Mode<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <!--<select class="form-control" (change)="selectGender($event.target.value)">
                                    <option [ngValue]="''" [selected]="true" disabled>Search Mode</option>

                                    <option [ngValue]="'In-Person'" [selected]="selectedGender === 'In-Person'">In-Person</option>
                                    <option [ngValue]="'Virtual'" [selected]="selectedGender === 'Virtual'">Virtual</option>
                                </select>-->

                                    <div class="dropdown1">
                                        <select class="form-control dropdown1-select" style="border-radius:5px;" (change)="selectGender($event.target.value)">
                                            <option [ngValue]="''" [selected]="true" disabled>Search Mode</option>
                                            <option [ngValue]="'In-Person'" [selected]="selectedGender === 'In-Person'">In-Person</option>
                                            <option [ngValue]="'Virtual'" [selected]="selectedGender === 'Virtual'">Virtual</option>
                                        </select>
                                        <div class="dropdown1-icon">
                                            <i class="fa fa-chevron-down"></i>
                                        </div>
                                    </div>


                                </div>


                                <div class="col-lg-4 mt-3">
                                    <label for="age">Audience<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <div class="dropdown1">
                                        <select class="form-control" style="border-radius:5px;" (change)="selectaudi($event.target.value)">
                                            <option [ngValue]="''" [selected]="true" disabled>Search Audience number</option>
                                            <option [ngValue]="'100+'" [selected]="selectedaudi === '100+'">100+</option>
                                            <option [ngValue]="'50-100'" [selected]="selectedaudi === '50-100'">50-100</option>
                                            <option [ngValue]="'Less than 50'" [selected]="selectedaudi === 'Less than 50'">Less than 50</option>
                                        </select>
                                        <div class="dropdown1-icon">
                                            <i class="fa fa-chevron-down"></i>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-4">
                                    <label for="age">Venue<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <!--<div class="row">
                                    <div class="col-lg-4 col-xs-6">
                                        <div class="chip1" (click)="selectVenue('College')" [ngClass]="{'selected': selectedVenue === 'College'}">College</div>
                                    </div>
                                    <div class="col-lg-4 col-xs-6">
                                        <div class="chip1" (click)="selectVenue('Organisation')" [ngClass]="{'selected': selectedVenue === 'Organisation'}">Organisation</div>
                                    </div>
                                    <div class="col-lg-4 col-xs-6">
                                        <div class="chip1" (click)="selectVenue('Other')" [ngClass]="{'selected': selectedVenue === 'Other'}">Other</div>
                                    </div>
                                </div>-->
                                    <div class="form__input">
                                        <label for="form--contact__name required" class="sr-only">Choose Place</label>
                                        <div class="form-group">
                                            <p-autoComplete [suggestions]="deptList9"
                                                            [dropdown]="true"
                                                            (completeMethod)="searchCont9($event)"
                                                            field="WorkPlace"
                                                            formControlName="WorkPlace"
                                                            placeholder="Search Place"
                                                            (onSelect)="searchCont9($event)"
                                                            [style]="{'color': 'black','width': '100%'}"
                                                            [panelStyle]="{'background-color': 'white','width':'30rem !important',  'border': '1px solid black','margin-left':'3px'}"></p-autoComplete>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-lg-4">
                                    <label for="username">Venue Name<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mb-3 mt-n3">
                                    <input type="text" id="username" formControlName="Venue_name" placeholder="Venue Name" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Venue_name.touched && form.controls.Venue_name.errors?.required">Venue Name is required</small>

                                </div>

                                <div class="col-lg-4">
                                    <label for="email">Contact Person<span style="color:red">*</span></label>
                                </div>
                                <br />
                                <div class="col-lg-8 mb-3">
                                    <input type="text" id="Contact_name" formControlName="Contact_name" placeholder="Contact Name" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Contact_name.touched && form.controls.Contact_name.errors?.required">Contact Name is required</small>
                                </div>

                                <div class="col-lg-4">
                                    <label for="email">Contact Number<span style="color:red">*</span></label>
                                </div>

                                <div class="col-lg-8 mb-2">
                                    <input type="text" id="Contact_number" formControlName="Contact_number" placeholder="Contact Number" maxlength="10" minlength="10" oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Contact_number.touched && form.controls.Contact_number.errors?.required">Contact Number is required</small>
                                </div>
                            </div>
                        </form>


                        <form [formGroup]="form" (ngSubmit)="Donersform()">

                            <div class="row">

                                <div class="col-lg-4">
                                    <label>State Name</label>
                                </div>
                                <div class="col-lg-8">

                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                        field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                        placeholder="Search State Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                               class="p-error">State Name is required.</small>
                                    </div>

                                </div>

                                <div class="col-lg-4">
                                    <label>District Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">

                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                        field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                        placeholder="Search District Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                               class="p-error">District Name is required.</small>
                                    </div>
                                </div>


                                <div class="col-lg-4">
                                    <label>City Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="cityname"
                                                        [suggestions]="deptList2"
                                                        (completeMethod)="searchCont2($event)"
                                                        field="CityName"
                                                        [dropdown]="true"
                                                        [size]="120"
                                                        placeholder="Search City Name"
                                                        [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                        </p-autoComplete>


                                        <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                               class="p-error">City Name is required.</small>
                                    </div>

                                </div>


                                <div class="col-lg-4">
                                    <label for="useraddress">Address</label>
                                </div>
                                <div class="col-lg-8 mb-3">
                                    <!--<input type="text" class="form-control" [(ngModel)]="useraddress" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>-->

                                    <input type="text" id="Address" formControlName="Address" placeholder="User Address" />
                                </div>

                                <div class="col-lg-4">
                                    <label for="area">Area</label>
                                </div>
                                <div class="col-lg-8 mb-3">
                                    <input type="text" id="Area" formControlName="Area" placeholder="Area" />
                                </div>

                                <div class="col-lg-4">
                                    <label for="mobile">Pincode<span style="color:red">*</span></label>
                                </div>
                                <br />
                                <div class="col-lg-8 mb-3">
                                    <input type="text" id="Pincode" formControlName="Pincode" placeholder="Pincode" maxlength="6" min="5" oninput="this.value = this.value.replace(/[^0-9]/g, '');" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Pincode.touched && form.controls.Pincode.errors?.required">Pincode is required</small>

                                </div>

                                <div class="col-lg-4">
                                    <label for="area">Message</label>
                                </div>
                                <div class="col-lg-8 mb-3">
                                    <input type="text" id="Message" formControlName="Message" placeholder="Message to  request presentation" />
                                </div>
                            </div>

                            <div class="col-lg-4"></div>
                            <div class="col-lg-4" style="text-align: center; cursor: pointer;">
                                <!--<button class="btn">Submit</button>-->
                                <button type="submit" class="btn1" style="background-color: #1c60d9;">Submit</button>
                            </div>
                            <div class="col-lg-4"></div>
                        </form>

                    </div>
                </div>
            </div>




        </div>
    </div>
 
   
    <!--</div>-->



</body>


</html>

<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    @media (min-width: 992px) {
        .allow-scroll .pane__content {
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
        }
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -109%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 2px solid white;
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .menu-btn span,
    .menu-btn:before,
    .menu-btn:after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 30%;
        width: 40%;
        border-bottom: 2px solid white;
        transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .menu-btn:before {
        transform: translateY(-8px);
    }

    .menu-btn:after {
        transform: translateY(8px);
    }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .cartdetails {
        width: 100%;
        height: 55%;
        margin-top: 56%;
        border-radius: 10px;
        border: 2px solid black;
        background-color: white;
        padding: 17px;
        color: black;
    }

    .label {
        display: inline-block;
        margin-top: 12px;
    }

    .para {
        font-size: 15px;
        text-align: left;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
        margin-left: 11%;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }


    /*static dropdown*/

    .dropdown1 {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 6px 0px;
    }

    .dropdown1-select {
        padding-right: 30px; /* Adjust as needed */
    }

    .dropdown1-icon {
        position: absolute;
        top: 50%;
        right: 10px; /* Adjust as needed */
        transform: translateY(-50%);
    }

    /* Assuming you're using Font Awesome for the dropdown icon */
    .fa {
        font-size: 14px; /* Adjust as needed */
    }


    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }

    @media (min-width: 768px) {
        .col-md-3 {
            /*flex: 0 0 auto;*/
            width: 25%;
        }
    }




</style>
