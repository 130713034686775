<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Work</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>
    <div class="container">
        <div class="work-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        OldAge Homes
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Orphanage Homes
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Blood Banks
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                    <span (click)="switchTab($event, 'tab5')">
                        Photoshop
                    </span>
                </li>
                <!--<li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                    <span (click)="switchTab($event, 'tab6')">
                        Illustrator
                    </span>
                </li>-->
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</div>
