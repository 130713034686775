<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->

<head>
    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="let's fight">
    <meta property="og:image" content="dist/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="let's fight" />
    <meta property="og:site_name" content="let's fight" />
    <meta property="og:description" content="let's fight" />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link href="assets/Newcss/css/newone.css" />
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <script src="www.google-analytics.com/cx/ffdac.txt?experiment=xioP1WLxTVuxOM3d4NX95g"></script>

</head>
<body>


    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>

    <div id="page-wrapper">
        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>

        <div id="page-content">
            <br>
            <div class="pane pane--left" data-pane="left" style="background-color: #4e4e48;">
                <div class="pane__inner allow-scroll" style="overflow:hidden">
                    <div class="section__content">
                        <div class="section__main section__main--standalone post post--single" style="padding-top: 50px;">
                        </div>
                        <div class="post-list__share share-job">
                            <br />  <br />


                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === 0 }" (click)="handleClick(0)">
                                            <a routerLink="/Addtomember">
                                                <img src="../../../assets/Newcss/img/addamember.jpg">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === 1 }" (click)="handleClick(1)">
                                            <img src="assets/img/requestpresentation.jpg" (click)="toggleSocialMedia(1)">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === -1 }" (click)="handleClick(2)">
                                            <img src="assets/Newcss/img/download.jpg" (click)="downloadlogo()">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="cards" [ngClass]="{ 'active': activeCardIndex === 3 }" (click)="handleClick(3)">
                                            <a (click)="vol()">
                                                <img src="../../../assets/Newcss/img/joinasleader.png" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="pane pane--right" data-pane="right" *ngIf="showSocialMedia!=1 || DownloadLogo==2">
                <div class="pane__inner bg-gray--dark">
                    <!--allow-scroll-->
                    <div class="pane__content content-container section section--contact mt-n5 section--gray bg-gray--dark">
                        <div class="section__content section__content--full">
                            <div class="section__header section__header--fluid">
                                <h2 class="section__title h1 no-line phone-number-text">DOWNLOAD BROUCHER</h2>
                                <div class="row">
                                    <div class="location col-xs-12">
                                        <div class="location__icon">
                                            <img src="assets/Newcss/img/only_download.png" alt="MEMBER" width="100">
                                        </div>
                                        <h3 class="location__title">Broucher Stick Location</h3>
                                        <!--    <div class="location__address">1339 Frankford Ave<br> Suite 3 <br>Philadelphia, PA 19125</div>-->
                                    </div>
                                </div>
                            </div>
                            <div class="section__main section-offset  " style="background-color: black;padding: 10px;">
                                <!-- <h3 class="section__title h2">Registration</h3>-->
                                <form [formGroup]="form" class="form form--contact">
                                    <div class="row">

                                        <div class="col-lg-12 mt-n5">
                                            <div class="form__input mt-n2">

                                                <div class="form-group">
                                                    <p-autoComplete [suggestions]="deptList9"
                                                                    [dropdown]="true"
                                                                    (completeMethod)="searchCont9($event)"
                                                                    field="WorkPlace"
                                                                    formControlName="WorkPlace"
                                                                    placeholder="Search Place"
                                                                    (onSelect)="searchCont9($event)"
                                                                    [style]="{'color': 'black','width': '100%'}"
                                                                    [panelStyle]="{'background-color': 'white','width':'30rem !important',  'border': '1px solid black','margin-left':'3px'}"></p-autoComplete>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mt-n4">
                                            <input type="text" id="Place_name" formControlName="Place_name" placeholder="Place Name" />
                                            <small style="color:red" class="text-danger" *ngIf="form.controls.Place_name.touched && form.controls.Place_name.errors?.required">Place Name is required</small>

                                        </div>

                                        <div class="col-lg-12">
                                            <div class="form__input">

                                                <div class="form-group">
                                                    <p-autoComplete [(ngModel)]="selectedState1"
                                                                    [suggestions]="deptList"
                                                                    [dropdown]="true"
                                                                    (completeMethod)="searchCont($event)"
                                                                    field="StateName"
                                                                    formControlName="StateName"
                                                                    placeholder="Search State" (click)="getDistrict()"
                                                                    (onSelect)="onStateSelect1($event)"
                                                                    [style]="{'color': 'black','width': '100%'}"
                                                                    [panelStyle]="{'background-color': 'white','width':'30rem !important',  'border': '1px solid black','margin-left':'3px'}"></p-autoComplete>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mt-n4">
                                            <div class="form__input mt-n2">

                                                <div class="form-group">
                                                    <p-autoComplete [(ngModel)]="selectedState2"
                                                                    [suggestions]="deptList1"
                                                                    [dropdown]="true"
                                                                    (completeMethod)="searchCont1($event)"
                                                                    field="DistrictName"
                                                                    formControlName="DistrictName" (click)="getCity()"
                                                                    placeholder="Search District"
                                                                    (onSelect)="onStateSelect2($event)" [style]="{'color': 'black','width': '100%'}"
                                                                    [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                                                    </p-autoComplete>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-lg-12 mt-n4">
                                            <div class="form__input mt-n2">

                                                <div class="form-group">
                                                    <p-autoComplete [(ngModel)]="selectedState3"
                                                                    [suggestions]="deptList2"
                                                                    [dropdown]="true"
                                                                    (completeMethod)="searchCont2($event)"
                                                                    field="CityName"
                                                                    formControlName="CityName"
                                                                    placeholder="Search City"
                                                                    (onSelect)="onStateSelect3($event)"
                                                                    [style]="{'color': 'black', 'width': '100%'}"
                                                                    [panelStyle]="{'background-color': 'white', 'border': '1px solid black', 'margin-left': '3px'}">
                                                    </p-autoComplete>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mt-n4">
                                            <div class="form__input">
                                                <label class="sr-only ">Pincode</label>
                                                <input style="width:100%" formControlName="pincode" [maxlength]="6" name="FNAME" type="text" placeholder="Pincode">
                                            </div>
                                        </div>


                                        <!--<div class="form__input">
                                        <label class="sr-only "> PreSize Location</label>
                                        <input style="width:100%" formControlName="location" name="FNAME" type="text" placeholder=" Precise Location"
                                               (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" #search>


                                    </div>-->
                                        <div class="col-lg-12">
                                            <div class="form__input mt-n2">

                                                <input style="width:100%" formControlName="location" name="FNAME" type="text" placeholder=" Precise Location"
                                                       (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" #search>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">

                                            <div class="form__input form__input--checkbox">
                                                <input id="form--contact__newsletter" class="form-control form-control" name="newsletter" type="checkbox">
                                                <!--<label for="form--contact__newsletter">Signup for our newsletter</label>-->
                                            </div>
                                            <div style="display:none">
                                                please leave this blank
                                                <input type="text" class="form__hp" name="blankCheck" value="">
                                            </div>
                                        </div>

                                        <div class="col-lg-3"></div>
                                        <div class="col-lg-3 mt-n4">
                                            <button class="btn123 bt11" (click)="Brochureinsert()">Download</button>
                                        </div>
                                        <div class="col-lg-3 mt-n4">
                                            <button class="btnsubmit" (click)="Brochureinsert1()">Submit</button>
                                        </div>
                                        <div class="col-lg-3"></div>
                                    </div>

                                    <div class="row">

                                    </div>





                                    <!--<button class="skipad" (click)="downloadFile(pdfpath)">Skip and Download</button>-->

                                </form>
                                <p class="form-message"><span class="message"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pane pane--right" data-pane="right" *ngIf="showSocialMedia==1 || DownloadLogo!=2">
                <div class="pane__inner bg-gray--dark allow-scroll">
                    <div class="pane__content content-container section section--contact section--gray bg-gray--dark">
                        <div class="section__content section__content--full">
                            <div class="section__header section__header--fluid">
                                <h2 class="section__title h1 no-line phone-number-text">SHARE</h2>
                                <div class="row">
                                    <div class="location col-xs-12">
                                        <div class="location__icon">
                                            <img src="assets/Newcss/img/only_share.png" alt="MEMBER" width="100">
                                        </div>
                                        <h3 class="location__title">Please share blood donar,<br>Issues and Services pages with one click using any ofthe below sharing options</h3>
                                        <!--    <div class="location__address">1339 Frankford Ave<br> Suite 3 <br>Philadelphia, PA 19125</div>-->
                                    </div>
                                </div>
                            </div>



                            <div class="labs-grid">


                                <div class="labs-grid__item">
                                    <a routerLink="/Donersform" class="labs-grid__link no-ajax trigger-overlay" (click)="openFacebook()" data-modal-bctext="View Our Labs" data-modal-bclink="/labs" style="background-color: #2878ea; ">
                                        <img alt="" src="assets/Newcss/img/fb.png"><br />
                                        <h3>Facebook</h3>
                                    </a>
                                </div>

                                <div class="labs-grid__item">
                                    <a (click)="openInstagram()" class="labs-grid__link no-ajax trigger-overlay" data-modal-bctext="View Our Labs" data-modal-bclink="/labs" style="background-color: rgb(236, 12, 12);">
                                        <img alt="" src="assets/Newcss/img/newt.jpg"><br />
                                        <h3>Twitter</h3>
                                    </a>
                                </div>

                                <div class="labs-grid__item">
                                    <a (click)="openGmailApp()" class="labs-grid__link no-ajax trigger-overlay" data-modal-bctext="View Our Labs" data-modal-bclink="/labs" style="background-color: #0efbf8;">
                                        <img alt="" src="assets/img/instagram.png">
                                        <h3>Instagram</h3>
                                    </a>
                                </div>

                                <div class="labs-grid__item">
                                    <a href="#" (click)="openWhatsApp()" class="labs-grid__link no-ajax trigger-overlay" data-modal-bctext="View Our Labs" data-modal-bclink="/labs" style="background-color: #ff24dc;">
                                        <img alt="" src="assets/Newcss/img/wap.png">
                                        <h3>Whatsapp</h3>
                                    </a>
                                </div>

                            </div>

                            <p class="form-message"><span class="message"></span></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</body>

</html>
<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    @media (min-width: 992px) {
        .allow-scroll .pane__content {
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
        }
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -109%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 2px solid white;
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .menu-btn span,
    .menu-btn:before,
    .menu-btn:after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 30%;
        width: 40%;
        border-bottom: 2px solid white;
        transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .menu-btn:before {
        transform: translateY(-8px);
    }

    .menu-btn:after {
        transform: translateY(8px);
    }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .cartdetails {
        width: 100%;
        height: 55%;
        margin-top: 56%;
        border-radius: 10px;
        border: 2px solid black;
        background-color: white;
        padding: 17px;
        color: black;
    }

    .label {
        display: inline-block;
        margin-top: 12px;
    }

    .para {
        font-size: 15px;
        text-align: left;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
        margin-left: 11%;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }

    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }

    @media (min-width: 768px) {
        .col-md-3 {
            /*flex: 0 0 auto;*/
            width: 25%;
        }
    }

</style>
