<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->

<meta http-equiv="content-type" content="text/html;charset=utf-8" />
<head>
    <!-- build success -->
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="let's fight">
    <meta property="og:image" content="dist/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="let's fight" />
    <meta property="og:site_name" content="let's fight" />
    <meta property="og:description" content="let's fight" />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <!--<link rel="stylesheet" href="dist/css/main.mineca4.css?v=20170719">-->
    <link href="assets/dist/css/main.mineca4.css" />
    <!--[if IE]>
            <link rel="stylesheet" type="text/css" href="/dist/css/ie.min.css" />
    <![endif]-->
    <script>dataLayer = [];</script>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <!-- Load the Content Experiment JavaScript API client for the experiment -->
    <script src="www.google-analytics.com/cx/ffdac.txt?experiment=xioP1WLxTVuxOM3d4NX95g"></script>
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />
    <!--[if lt IE 9]>
            <script src="//html5shiv.googlecode.com/svn/trunk/html5.js"></script>
            <script>window.html5 || document.write('<script src="/vendor/html5shiv/dist/html5shiv.min.js"><\/script>')</script>
    <![endif]-->
    <!-- Pingdom tracking -->
</head>
<body>
    <!-- Google Tag Manager -->
    
    <!-- End Google Tag Manager -->


    <div id="page-wrapper">
   
        <div id="page-content">
            <header class="site-header ">
                <span class="bg"></span>
                <a href="index.html" id="site-logo" class="logo-cube-wrap" data-title=""style="width:200px;">
                    <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png"style="width:100px;">

                    <title> About</title>


                </a>
               
                <!--<a href="index.html" data-title=""><i class="icon-chevron-left"></i></a>-->
                <a href="index.html" style="float:inline-end;color:white;padding:10px" data-title="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 56 56">
                        <title>Close</title>
                        <path fill="currentColor" d="M31.1 27.8l6.7-6.5-2.7-3.5-7.1 7-7.1-7-2.7 3.5 6.7 6.5-7 6.8 2.6 3.5 7.5-7.3 7.5 7.3 2.6-3.5z" />
                        <path fill="none" stroke="currentColor" stroke-width="3" d="M3 3h50v50H3z" />
                    </svg>
                </a>
            </header>

            <nav class="nav" id="nav-primary">
                <div class="nav__links">
                    <ul class="nav-boxes list--unstyled">
                        <li class="about"routerLink="/about">
                            <a class="bg-red--light">
                                <span class="bg bg-about"></span>
                                <div class="nav-boxes__content" routerLink="/about">
                                    <span class="h2">About</span>
                                    <span class="nav-boxes__description">
                                        <p>Explore Our World</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>





                        <li>
                            <a (click)="reg()" class=" bg-red--darker">
                                <span class="bg bg-work"></span>
                                <div class="nav-boxes__content">
                                    <span class="h2">Register for Donating Blood</span>
                                    <span class="nav-boxes__description">
                                        <div style="text-transform: uppercase;font-weight: 700;    font-size: 32px;margin-top:-15%">Register for Donating Blood</div>

                                        <p>Save Lives</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>



                        <li>
                            <a routerLink="/Prerequisites" class=" bg-red">
                                <span class="bg bg-labs"></span>
                                <div class="nav-boxes__content">
                                    <span class="h2">Terms of Use and Disclaimer</span>
                                    <span class="nav-boxes__description">
                                        <div style="text-transform: uppercase;font-weight: 700;
       font-size: 32px;margin-top:-15%">Terms of Use and Disclaimer</div>

                                        <p>Be a Life Saver</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>



                        <li>
                            <a (click)="vol()" class=" bg-red--dark">
                                <span class="bg bg-forge"></span>
                                <div class="nav-boxes__content">
                                    <span class="h2">Leaders</span>
                                    <span class="nav-boxes__description">
                                        <div style="text-transform: uppercase;font-weight:700;font-size:32px;margin-top:-15%">Leaders</div>

                                        <p>Empower Us</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>



                        <li>
                            <a routerLink="/Termsandconditions" class=" bg-red--darker">
                                <span class="bg bg-news"></span>
                                <div class="nav-boxes__content">
                                    <span class="h2">Request A Presentation</span>
                                    <span class="nav-boxes__description">
                                        <div style="text-transform: uppercase;font-weight: 700;    font-size: 32px;margin-top:-15%">Request A Presentation</div>

                                        <p>Read About Us</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>




                        <li>
                            <a routerLink="/Joincampain" class=" bg-red">
                                <span class="bg bg-careers"></span>
                                <div class="nav-boxes__content">
                                    <span class="h2">Join Campaign</span>
                                    <span class="nav-boxes__description">
                                        <p style="text-transform: uppercase;font-weight: 700;    font-size: 32px;margin-top:-15%">Join Campaign</p>
                                        <p>Work With Us</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>



                        <li>
                            <a routerLink="/contact" class=" bg-red--light">
                                <span class="bg bg-contact"></span>
                                <div class="nav-boxes__content">
                                    <span class="h2">Contact</span>
                                    <span class="nav-boxes__description">
                                        <p style="text-transform: uppercase;font-weight: 700;    font-size: 32px;margin-top:-15%">Contact</p>

                                        <p>Get In Touch</p>
                                        <i class="icon-chevron-right"></i>
                                    </span>
                                </div>
                            </a>
                        </li>

                        <li>
                            <div class="last-box">
                                <a href="javascript:;" class="btn btn-default no-ajax" id="newsletter-signup-open" data-remodal-target="modal">Newsletter</a>
                                <ul class="social-links social-links--nav">
                                    <li class="social-links--link">
                                        <a target="_blank" href="http://twitter.com/o3world" class="twitter" title="twitter">
                                            <i class="icon-twitter"></i>
                                        </a>
                                    </li>
                                    <li class="social-links--link">
                                        <a target="_blank" href="http://www.facebook.com/o3world" class="facebook" title="facebook">
                                            <i class="icon-facebook"></i>
                                        </a>
                                    </li>
                                    <li class="social-links--link">
                                        <a target="_blank" href="https://instagram.com/o3world/" class="instagram" title="instagram">
                                            <i class="icon-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div class="last-mobile">
                        <ul class="social-links social-links--nav">
                            <li class="social-links--link">
                                <a target="_blank" href="http://twitter.com/o3world" class="twitter" title="twitter">
                                    <i class="icon-twitter"></i>
                                </a>
                            </li>
                            <li class="social-links--link">
                                <a target="_blank" href="http://www.facebook.com/o3world" class="facebook" title="facebook">
                                    <i class="icon-facebook"></i>
                                </a>
                            </li>
                            <li class="social-links--link">
                                <a target="_blank" href="https://instagram.com/o3world/" class="instagram" title="instagram">
                                    <i class="icon-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </div>
    </div>

   
   
    <script>
var baseurl = "";
						var teamCount = 24;</script>
    <script src="ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
    <script src="vjs.zencdn.net/4.11/video.js"></script>
    <script src="dist/js/plugins.min.js"></script>
    <script src="dist/js/maineca4.js?v=20170719"></script>
    <!--[if IE]>
            <script src="/dist/js/ie9.js"></script>
    <![endif]-->
    <script type="text/javascript" src="raygun.min.js"></script>

</body>

</html>

