import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../../general.service';

import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    HomeUrl: any;
    OTP: any;
    OTPtoMobile: any;
    Mobile1: any;
    public form: FormGroup;
    SurName: any;
    Name: any;
    Mobile: any;

    otp: any;
    submitAttempt: boolean | undefined;
    arr: any;
    logindata: any;
    surnamedata: any;
    selectedSurname: any;

    constructor(public router: Router, public fb: FormBuilder, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        this.logindata = localStorage.getItem('LoginDetails');
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        this.form = fb.group({
            FullName: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(20)])],
         //   Name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(30)])],
           Email: ['', ],
           Age: ['', ],
            Gender: ['', ],
            BloodGroupId: ['', ],
            StateId: ['', ],
            DistrictId: ['', ],
            CityId: ['', ],
            Phonenumber: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
            // otp: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],

        });

    }
    Register() {
        this.router.navigate(['/Newprofiledetails']);
    }


    ngOnInit() {
      //  this.Getsurname();
    }

    Getsurname() {


        var url = "api/Events/GetSurNames";
        this.generalService.GetData(url).then(data => {
            
            this.surnamedata = data;





            //  this.filteredDataLength = this.filteredData.length;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    onSurnameSelection(value: string) {
        this.selectedSurname = value;
        // You can also set the value to your form control here if needed:
        // this.Form.controls['Surname'].setValue(value);
    }

    onSubmit(value: any) {
        ;
        this.submitAttempt = true;
        this.arr = [];

        // Assuming value contains the form data, make sure to access its properties correctly
        this.arr.push({
            FullName: value?.FullName, // Check if value is structured correctly
            Phonenumber: value?.Phonenumber,
            Mobile: value?.Mobile,
            Email: value?.Email,
            Age: value?.Age,
            Gender: value?.Gender,
             BloodGroupId: value?.BloodGroupId,
            StateId: value?.StateId,
            DistrictId: value?.DistrictId,
            CityId: value?.CityId,
       
            // Add other fields as needed
        });

        ;

        let formData: FormData = new FormData();
        formData.append('Param', JSON.stringify(this.arr));
        formData.append('Flag', '1');
        const url = "api/BG/Register_User_Curd";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data && data) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", value?.Mobile);
                var url = 'api/Events/checking_Mobile';
                this.generalService.PostData(url, UploadFile).then((data1: any) => {
                    this.logindata = JSON.stringify(data1[0]);
                    localStorage.setItem("LoginDetails", this.logindata);
                    this.generalService.ShowAlert('Success', 'Your registration completed successfully.', 'success');
                    localStorage.setItem('userData', JSON.stringify(this.arr));
                    ;
                    this.router.navigate(['/Newprofiledetails', { userdet: data }])

                        // this.router.navigate(['/Newprofiledetails', { UserDetails: JSON.stringify(this.arr[0].Mobile) }])
                        .then(() => {
                            window.location.reload();
                        });
                    //this.router.navigate(['/EditProfile', { Mobile: this.form.value.Mobile }]);
                    // this.navCtrl.pop();
                })
            }
        });
    }

    SendOTPtoMobile(value: any) {
        
        if (value.Phonenumber != null || value.Mobile != undefined) {
            if (value.Phonenumber.length == 10) {
                
                this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
                var Uploadfile = new FormData();
                Uploadfile.append("MobileNo", value.Phonenumber)
                Uploadfile.append("OTP", this.OTPtoMobile)
                var url = "api/BG/SendOtpToMobile";
                this.generalService.PostData(url, Uploadfile).then((data: any) => {

                })
            }
        }
    }

}

function matchingPasswords(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}


