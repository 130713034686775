import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Route, Router } from '@angular/router';

@Component({
    selector: 'app-requestlogin',
    templateUrl: './requestlogin.component.html',
    styleUrls: ['./requestlogin.component.scss']
})
export class RequestloginComponent {
    OTP: any;
    OTPtoMobile: any;
    Mobile: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    logindata: any; RegId: any;
    arr: any = []; flag: any;
    registerAsDonor: boolean = false;
    Fullname: any;
    LoginDetails: any; LoginDetails1: any;
    constructor(public generalservice: GeneralService, public http: HttpClient, public router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
    }

    ngOnInit(): void {

    }
    reg() {
        
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
    SendOTPtoMobile() {
        
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.generalservice.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
            })
        }
    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }


    checkmobile(Mobile) {
        ;
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);

                var url = 'api/BG/checking_Mobile'

                this.generalservice.PostData(url, UploadFile).then(data => {
                    ;
                    if (data != 'NOTEXIST') {
                        ;
                        this.RegId = data[0].RegId;
                        this.Fullname = data[0].FullName;
                        this.router.navigate(['/Requestform'])
                        //  .then(() => { window.location.reload(); });
                        localStorage.setItem("RegID", this.RegId);
                        localStorage.setItem("FullName", this.Fullname);
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);

                    } else {
                        // If the phone number doesn't exist, insert it into the table
                        this.insertPhoneNumber(Mobile);
                        // Navigate to the registration page
                        this.router.navigate(['/Requestform'], { queryParams: { mobile: Mobile } });
                    }
                })
            }
        }
    }

    insertPhoneNumber(Mobile) {
        var arr = [];
        this.arr = [{
            Phonenumber: Mobile,
            Status: true,
            Statusphn: true
        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');

        var url = 'api/BG/Insert_Update_requestForm';

        this.generalservice.PostData(url, uploadfile).then(data => {
            if (data != null) {
                ;
                this.router.navigate(['/Requestform']).then(() => { window.location.reload(); });
                this.logindata = JSON.stringify(data[0]);
                localStorage.setItem("LoginDetails", this.logindata);

                this.RegId = data[0].RegId;
                localStorage.setItem("RegID", this.RegId);
                //  localStorage.setItem("LoginDetails", JSON.stringify(this.arr));

            }
        });
    }



}
