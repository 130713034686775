import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GeneralService } from '../../general.service';
declare var $: any

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
    LoginDetails1: any; LoginDetails: any;
    public form: FormGroup;
    arr: any = [];
    selectedaudi: string;
    selectedGender: string | null = null;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList: any;
    districtdata: any = [];
    deptList1: any;
    deptList2: any;
    observ: any = [];
    deptList9: any;
    selectedVenue: string; pincodedata: any;
    Pincode: any; requestdata: any;
    presentaiondet: any = []; emailDataToAdmin: any;

    constructor(public router: Router, public fb: FormBuilder,
        public genralservice: GeneralService) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.form = this.fb.group({
            // Define your form controls here
            Mode: ['', Validators.required],
            Audiance: ['', Validators.required],
            WorkPlace: ['', Validators.required],
            Venue_name: ['',],
            statename: [''],
            districtname: [''],
            cityname: [''],
            Address: [''],
            Area: [''],

            Pincode: [''],
            Message: [''],
            Contact_name: [''],
            Contact_number: [''],

        });


    }


    ngOnInit(): void {

        this.getstate();
        this.getvenue();
     }


    reg() {
        debugger
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }

    }

    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }

    Donersform() {
        debugger
        if (!this.form.value.Contact_number || !this.form.value.Contact_name ||
            !this.form.value.Venue_name || !this.form.value.Pincode || this.selectedGender === "" ||
            this.selectedaudi === "" || !this.form.value.WorkPlace.WorkPlace) {
            // Display an error alert
            window.alert('ERROR: Please fill all required fields');

            return; // Stop further execution of the method
        }
        const stateId = this.form.value.statename && this.form.value.statename.StateId !== undefined
            ? this.form.value.statename.StateId
            : this.LoginDetails.StateId !== undefined
                ? this.LoginDetails.StateId
                : this.LoginDetails.StateID;

        const districtId = this.form.value.districtname && this.form.value.districtname.DistrictID !== undefined
            ? this.form.value.districtname.DistrictID
            : this.LoginDetails.DistrictId !== undefined
                ? this.LoginDetails.DistrictId
                : this.LoginDetails.DistrictID;

        const cityId = this.form.value.cityname && this.form.value.cityname.CityId !== undefined
            ? this.form.value.cityname.CityId
            : this.LoginDetails.CityId !== undefined
                ? this.LoginDetails.CityId
                : this.LoginDetails.CityID;
        ;
        this.arr = [];

        this.arr.push({


            Contact_number: this.form.value.Contact_number,
            Contact_name: this.form.value.Contact_name,
            Address: this.form.value.Address,
            Venue_name: this.form.value.Venue_name,

            Mode: this.selectedGender,
            Pincode: this.form.value.Pincode || this.LoginDetails.Pincode,
            Audiance: this.selectedaudi,
            // Venue: this.selectedVenue,
            Venue: this.form.value.WorkPlace.WorkPlace,
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
            Area: this.form.value.Area,
            Message: this.form.value.Message,
            CreatedBy: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');
        var url = "api/BG/Insert_Update_Requestpresent";
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
                debugger
                this.Pincode = this.form.value.Pincode
                //this.Getpincode();
                var uploadfile = new FormData();
                uploadfile.append("Param", this.Pincode);
                var url = "api/BG/Get_Users_basedOn_Pincode";
                this.genralservice.PostData(url, uploadfile).then(data1 => {
                    debugger
                    this.pincodedata = data1;
                    debugger
                    for (let i = 0; i < this.pincodedata.length; i++) {
                        const customer = this.pincodedata[i];
                        const staticinfo = "Do you want to become a donor? Click here to proceed.https://letshelp.breakingindiaapp.com/webservice/api/BG/replying_mail_admin";
                        this.presentaiondet = [];
                        this.presentaiondet.push({
                            Email: customer.Email,
                            Contact_number: this.form.value.Contact_number,
                            Contact_name: this.form.value.Contact_name,
                            Address: this.form.value.Address,
                            Venue_name: this.form.value.Venue_name,
                            Venue: this.form.value.WorkPlace.WorkPlace,
                            Mode: this.selectedGender,
                            Audiance: this.selectedaudi,
                            Message: staticinfo
                        })

                        debugger
                        var uploadfile = new FormData();
                        uploadfile.append("EMaildetails", JSON.stringify(this.presentaiondet));
                        var url = "api/BG/RequestPresentationDetails";
                    
                      this.genralservice.PostData(url, uploadfile).then(data2 => {                                                              
                        if (data2 == "SUCCESS") {
                            this.genralservice.ShowAlert("SUCCESS", 'Presentation Mail Sent Successfully', 'Success')
                            //localStorage.removeItem("RequestDetails");
                      
                        if (this.flag == 1) {
                            this.genralservice.ShowAlert("ERROR", 'The request has been successfully added, and an email request has been sent to leaders within the same pincode area.', 'error');
                        } else {
                            // localStorage.setItem('LoginDetails', JSON.stringify(this.arr));
                            this.genralservice.ShowAlert("SUCCESS", 'The request has been successfully added, and an email request has been sent to leaders within the same pincode area.', 'success');
                            //this.router.navigate(['/Volunters']).then(() => {
                            //    window.location.reload();
                            //});
                            this.form.reset();
                            }
                        }                       
                       
                     })
                    }
                    
                });
                
                               
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong. Please check validation fields', 'error');
            }
        });
    }

    getstate() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }

    getvenue() {

        this.arr = []
        this.arr.push({

        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BG_Work_Place_Crud";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.observ = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for venue //
    searchCont9(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        if (query != "") {
            this.deptList = [];
            for (var i = 0; i < this.observ.length; i++) {
                let country = this.observ[i];
                if (country.WorkPlace && country.WorkPlace.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(country);
                }
            }

            this.deptList9 = filtered;
        } else {
            this.deptList9 = this.observ;
        }
    }

    selectGender(gender: string) {

        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    } selectaudi(Audiance: string) {

        this.selectedaudi = Audiance;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    } selectVenue(venue: string) {

        this.selectedVenue = venue;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }

    Getpincode() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.Pincode);
        var url = "api/BG/Get_Users_basedOn_Pincode";
        this.genralservice.PostData(url, uploadfile).then(data => {
            debugger
            this.pincodedata = data;
          
        });
    }

   

}
