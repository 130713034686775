<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>

    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="Let's Help ">
    <meta property="og:image" content="assets/Newcss/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="Let's Help " />
    <meta property="og:site_name" content="Let's Help " />
    <meta property="og:description" content="Let's Help " />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>

        <div id="page-content">
            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">

                    <div class="bg-vid" data-src="dist/vid/LET'S Help" style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="line-height: 60PX;font-size: 32px;font-weight:bold;">Hospital Details</h2>
                    </header>

                </div>
            </div>

            <!--<div class="pane pane--left" data-pane="left">
        <div class="pane__inner pane-header">

            <div class="bg-vid" data-src="dist/vid/Let's Help " style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
            <header class="pane-header__content">
                <h2 class="page-title u-white" style="line-height: 60PX;">Donor Registration Form</h2>

            </header>

        </div>
    </div>-->

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="section__content">

                        <div class="section__main section__main--standalone post post--single">

                        </div>
                        <div class="row">
                            <div class="col-lg-9">
                                <h3 style="color:#000000">
                                    <b class="psyco">
                                        Verify/Add hospitals details
                                    </b>
                                </h3>
                            </div>
                            <div class="col-lg-3">


                                <img src="../../../assets/img/download.png" (click)="refresh()" style="cursor:pointer" class="refresh" />

                            </div>


                        </div>

                        <form [formGroup]="form" (ngSubmit)="AddBanner(form.value)">
                            <div class="row">


                                <div class="col-lg-4">
                                    <label for="category">Search Hospital<span style="color:red">*</span></label>
                                </div>

                                <div class="col-lg-8">
                                    <p-autoComplete formControlName="HsptName" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                    field="HsptName" [dropdown]="true" [size]="90"
                                                    (onSelect)="onDropdownSelect($event)"
                                                    placeholder="Select or Type Hospital" [minLength]="0"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
                                    </p-autoComplete>
                                    <small *ngIf="form.controls.HsptName.touched && form.controls.HsptName.errors?.required"
                                           class="p-error" style="color:red">Hospital Name is required.</small>
                                </div>



                                <!--<div class="col-lg-4" *ngIf="addextra==2">
                            <label><b>Add Hospital</b><b><span style="color:red;">*</span></b></label>
                        </div>
                        <div class="col-lg-8" *ngIf="addextra==2">
                            <input formControlName="HsptName" type="text" placeholder="Enter Hospital Name">
                        </div>

                        <div class="col-lg-7"></div>
                        <div class="col-lg-5">

                            <div *ngIf="addextra==2">
                                <button type="button" style="width:50%;" class="addbutton1 card1  mb-4" (click)="Addhospital(form.value)">Add</button>

                            </div>

                            <button type="button" (click)="addNewTrip(addextra)" *ngIf="addextra!=2" class="addbutton  mb-4" style=" text-transform: capitalize;">
                                <i class="fas fa-plus"></i>
                                Add Hospital
                            </button>

                        </div>-->


                                <div class="col-lg-4">
                                    <label for="Hospital">Hospital Contact No<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="HsptPhNum" formControlName="HsptPhNum" placeholder="Hospital Contact Number" />

                                </div>


                                <div class="col-lg-4">
                                    <label for="DoctorName">Doctor Name<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="DoctorName" formControlName="DoctorName" placeholder="Doctor Name" [readonly]="dct !== undefined" [ngClass]="{'readonly-input': dct !== undefined}" />
                                </div>
                            </div>
                        </form>



                        <form [formGroup]="form" (ngSubmit)="AddBanner(form.value)">

                            <div class="row">


                                <div class="col-lg-4">
                                    <label>State Name<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                        field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                        placeholder="Search State Name" [disabled]="stn !== undefined" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px', 'margin-left': '3px', 'padding': '10px', 'cursor': 'pointer'}"
                                                        class="{{ stn !== undefined ? 'disabled-dropdown' : '' }}">
                                        </p-autoComplete>

                                        <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                               class="p-error">State Name is required.</small>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <label>District Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">
                                        <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                        field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                        placeholder="Search District Name" [disabled]="dit !== undefined" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}"
                                                        class="{{ dit !== undefined ? 'disabled-dropdown' : '' }}">
                                        </p-autoComplete>


                                        <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                               class="p-error">District Name is required.</small>
                                    </div>


                                </div>



                                <div class="col-lg-4">
                                    <label>City Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">
                                        <p-autoComplete formControlName="cityname"
                                                        [suggestions]="deptList2"
                                                        (completeMethod)="searchCont2($event)"
                                                        field="CityName"
                                                        [dropdown]="true"
                                                        [size]="120"
                                                        placeholder="Search City Name"
                                                        [disabled]="cty !== undefined"
                                                        [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}"
                                                        class="{{ cty !== undefined ? 'disabled-dropdown' : '' }}">
                                        </p-autoComplete>



                                        <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                               class="p-error">City name is required.</small>
                                    </div>



                                </div>

                                <div class="col-lg-4">
                                    <label for="useraddress">Address</label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="HsptAddress" formControlName="HsptAddress" placeholder="Address" [readonly]="hspa !== undefined" [ngClass]="{'readonly-input': hspa !== undefined}" />
                                </div>



                                <div class="col-lg-4">
                                    <label for="useraddress">Pincode<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="Pincode" formControlName="Pincode" placeholder="Pincode" [readonly]="pic !== undefined" [ngClass]="{'readonly-input': pic !== undefined}" />
                                </div>


                                <div class="col-lg-4">
                                    <label for="useraddress">POC Name <span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">

                                    <input type="text" id="POCName" formControlName="POCName" placeholder="Point of Contact Name" />
                                </div>



                                <div class="col-lg-4">
                                    <label for="useraddress">POC No<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="POC_ContactNumber" formControlName="POC_ContactNumber" maxlength="10" placeholder="Point of  Contact Number" />
                                </div>

                                <div class="col-lg-4"></div>
                                <div class="col-lg-4 mt-3">
                                    <button type="submit" class="btn1" style="background-color: #1c60d9;">Submit</button>

                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>




        </div>
    </div>
</body>
</html>
<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    @media (min-width: 992px) {
        .allow-scroll .pane__content {
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
        }
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -109%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 2px solid white;
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    .menu-btn span,
    .menu-btn:before,
    .menu-btn:after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 30%;
        width: 40%;
        border-bottom: 2px solid white;
        transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .menu-btn:before {
        transform: translateY(-8px);
    }

    .menu-btn:after {
        transform: translateY(8px);
    }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .cartdetails {
        width: 100%;
        height: 55%;
        margin-top: 56%;
        border-radius: 10px;
        border: 2px solid black;
        background-color: white;
        padding: 17px;
        color: black;
    }

    .label {
        display: inline-block;
        margin-top: 12px;
    }

    .para {
        font-size: 15px;
        text-align: left;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
        margin-left: 11%;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }

    @media (min-width: 768px) {
        .col-md-3 {
            /*flex: 0 0 auto;*/
            width: 25%;
        }
    }

</style>
