<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>

    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="Let's Help ">
    <meta property="og:image" content="assets/Newcss/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="Let's Help " />
    <meta property="og:site_name" content="Let's Help " />
    <meta property="og:description" content="Let's Help " />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>

        <div id="page-content">



            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">

                    <div class="bg-vid" data-src="dist/vid/Let's Help " style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="line-height: 60PX;">BLOOD REQUEST DETAILS</h2>
                        <p>
                            1. Please fill the following informatiomn to post blood request will be displayed on home screen for next 24 hours
                        </p>
                        <p>2. We also request you to search and call nearest blood donors directly from our website</p>
                        <p>3. Date of Birth will be hidden, and its only purpose is to calculagte your age</p>

                    </header>

                </div>
            </div>

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="section__content">

                        <div class="section__main section__main--standalone post post--single">


                        </div>

                        <form [formGroup]="form" (ngSubmit)="Requestform()">
                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="username">Contact Person<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="username" formControlName="contactperson" placeholder="Contact Person Full Name" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.contactperson.touched && form.controls.contactperson.errors?.required">Contact Person Name is required</small>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="mobile">Mobile&nbsp;Number<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="mobile" formControlName="Contmobile" inputmode="numeric" pattern="[0-9]*" maxlength="10" placeholder="Patient Attender Mobile Number" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Contmobile.touched && form.controls.Contmobile.errors?.required">Attender Mobile is required</small>
                                </div>
                            </div>
                            <div class="patient">
                                Patient Information
                            </div><br />
                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="username">Patient Name<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="username" formControlName="username" placeholder="Paitent Full Name" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.username.touched && form.controls.username.errors?.required">Patient Name is required</small>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="email">Email</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="email" formControlName="email" placeholder="Patient Attender Email ID" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="age">Gender<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-7">
                                    <div class="row">
                                        <div class="col-lg-6 col-xs-6">
                                            <div class="chip1" (click)="selectGender('Male')" [ngClass]="{'selected': selectedGender === 'Male'}">Male</div>
                                        </div>
                                        <div class="col-lg-6 col-xs-6">
                                            <div class="chip1" (click)="selectGender('Female')" [ngClass]="{'selected': selectedGender === 'Female'}">Female</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="age">Patient Age</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="age" formControlName="age" placeholder="Age" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="useraddress">Patient&nbsp;Location</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="useraddress" formControlName="useraddress" placeholder="Patient Address" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="age">Required&nbsp;Date</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="date" formControlName="BloodRequestDate" id="BloodRequestDate" placeholder="Blood Required Date">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="age">Required&nbsp;Time</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="time" formControlName="RequestTime" id="RequestTime" placeholder="Blood Required Time">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-3">
                                    <label>Blood&nbsp;Group</label>
                                </div>
                                <div class="col-lg-7">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="bloodgroup" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                        field="BLGName" [dropdown]="true" [size]="90"
                                                        placeholder="Search Patient blood group" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
                                        </p-autoComplete>

                                        <small *ngIf="form.controls.bloodgroup.touched && form.controls.bloodgroup.errors?.required"
                                               class="p-error">Patient blood group is required.</small>
                                    </div>
                                </div>
                            </div><br />
                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="bloodgroup">Units&nbsp;of&nbsp;blood</label>
                                </div>
                                <div class="col-lg-7">
                                    <p-autoComplete formControlName="unitsofblood" [suggestions]="deptList4" (completeMethod)="searchCont4($event)"
                                                    field="UnitsofBlood" [dropdown]="true" [size]="90" placeholder="Search units of blood" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">

                                    </p-autoComplete>
                                    <small *ngIf="form.controls.unitsofblood.touched && form.controls.unitsofblood.errors?.required" class="p-error">
                                        units of blood is required.
                                    </small>
                                </div>
                            </div><br />
                        </form>

                        <form [formGroup]="form" (ngSubmit)="Requestform()">
                            <div class="row">

                                <div class="row">
                                    <div class="col-lg-3">
                                        <label for="useraddress">Doctor&nbsp;Name</label>
                                    </div>
                                    <div class="col-lg-7">
                                        <input type="text" id="DoctorName" formControlName="DoctorName" placeholder="DoctorName/Optional" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label for="area">Purpose</label>
                                    </div>
                                    <div class="col-lg-7">
                                        <input type="text" id="Purpose" formControlName="Purpose" placeholder="Purpose of blood" />
                                    </div>
                                </div>

                                <div class="patient" style="margin-bottom:4%">
                                    Location of Donation
                                </div><br />
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label for="useraddress">Hospital&nbsp;Name</label>
                                    </div>
                                    <div class="col-lg-7">
                                        <input type="text" id="HospitalName" formControlName="HospitalName" placeholder="Hospital Name" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label for="useraddress">Hospital&nbsp;Number</label>
                                    </div>
                                    <div class="col-lg-7">
                                        <input type="text" id="HospitalPhonenumber" formControlName="HospitalPhonenumber" placeholder="Hospital Number" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-3">
                                        <label for="area">Hospital&nbsp;Address</label>
                                    </div>
                                    <div class="col-lg-7">
                                        <input type="text" id="HospitalAddress" formControlName="HospitalAddress" placeholder="Hospital Address" />
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <label>State</label>
                                </div>
                                <div class="col-lg-7">

                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                        field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                        placeholder="Search State Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                               class="p-error">State Name is required.</small>
                                    </div>

                                </div>
                            </div> <br />

                            <div class="row">
                                <div class="col-lg-3">
                                    <label>District</label>
                                </div>
                                <div class="col-lg-7">

                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                        field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                        placeholder="Search District Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                               class="p-error">District Name is required.</small>
                                    </div>
                                </div>

                            </div> <br />

                            <div class="row">
                                <div class="col-lg-3">
                                    <label>City</label>
                                </div>
                                <div class="col-lg-7">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="cityname"
                                                        [suggestions]="deptList2"
                                                        (completeMethod)="searchCont2($event)"
                                                        field="CityName"
                                                        [dropdown]="true"
                                                        [size]="120"
                                                        placeholder="Search CityName"
                                                        [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                        </p-autoComplete>


                                        <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                               class="p-error">City Name is required.</small>
                                    </div>

                                </div>

                            </div>
                            <br />

                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="area">Patient&nbsp;Area</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="area" formControlName="area" placeholder="Patient Area" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <label for="area">Message</label>
                                </div>
                                <div class="col-lg-7">
                                    <input type="text" id="PersonalNote" formControlName="PersonalNote" placeholder="Message Your Personal Note" />
                                </div>
                            </div>

                            <br />
                            <div>
                                <button class="btn">Submit</button>
                            </div>
                            <br />
                        </form>


                    </div>
                </div>
            </div>




        </div>
    </div>


</body>



</html>

<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    .patient {
        color: red;
        font-size: 25px;
        margin: 2% 0% -2% 0%;
    }

    input {
        padding: 6px;
        border: 1px solid rgba(73, 80, 87, 0.6);
        margin-bottom: 3%;
        width: 100%;
        border-radius: 5px;
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -110%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        border-radius: 5px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: red;
    }

        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 2px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }

   

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    .h2, h2 {
        font-size: 32px;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 380px;
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    a {
        color: white;
        text-decoration: none;
    }

    h3 {
        color: white;
    }

    a:hover {
        color: white;
    }
    
    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
    .bg-red {
        background-color: #ca0000;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }

    @media (min-width: 768px) {
        .col-md-3 {
            /*flex: 0 0 auto;*/
            width: 25%;
        }
    }
</style>
