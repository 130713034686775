<div class=" ptb-100">
    <div class="container-fluid" style="background-color:red;height: 75px;
    padding: 10px;">
        <div class="container ">
            <div class="row">
                <div class="col-lg-3 zoom-in">
                    <a routerLink="/">
                        <img src="../../../../assets/svg/logo.png" class="logo" />
                    </a>
                </div>
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-lg-8">
                            <h3 class="title">Share Your Service</h3>

                        </div>
                        <div class="col-lg-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <ng-container *ngIf="LoginDetails; else showLoginButton">

                        <div class="dropdown">
                            <div class="circle dropbtn">
                                <div class="letter"> {{ letter }}</div>
                                <div class="dropdown-content">
                                    <a href="Profile">My Profile</a>

                                    <a href="#" (click)="signOut()">Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!--<ng-container *ngIf="LoginDetails; else showLoginButton">


                        <label for="button">
                            <div class="circle" (click)="toggleDropdown()">
                                <div class="letter">{{LoginDetails.FullName.charAt(0)}}</div>
                                <div class="dropdown-content" id="dropdownContent">
                                    <a href="/Profile">My Profile</a>
                                    <a href="#" (click)="signOut()">Sign Out</a>
                                </div>
                            </div>
                        </label>
                    </ng-container>-->
                    <ng-template #showLoginButton>
                        <!-- Display login button for users not logged in -->
                        <!--<div >Login</div>-->

                        <div class="col-lg-2">
                            <div class="services " (click)="openLoginModal()">  Login</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div><br />
    <div class="modal" id="myModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">
                        Login
                    </h4>

                    <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:black">X</span></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="card_1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <fieldset>
                                        <div class="card">

                                            <form>
                                                <label for="username">Mobile </label>
                                                <input type="text" [(ngModel)]="Mobile" placeholder="Enter Your Mobile Number" id="username" name="username" inputmode="numeric" pattern="[0-9]*" maxlength="10" required>
                                                <label for="password" *ngIf="OTPtoMobile != null">OTP </label>
                                                <input type="password" [(ngModel)]="OTP" id="password" name="password" *ngIf="OTPtoMobile != null" required>
                                                <br />
                                                <button class="btn btn-primary" type="submit" (click)="SendOTPtoMobile()" [hidden]="!showLogin1" [disabled]="otpSent">Send OTP</button>
                                                &nbsp;
                                                <br />
                                                <button class="btn btn-primary" type="submit" (click)="checkmobile(Mobile)" [hidden]="!showLogin">Login</button>
                                            </form>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    Sharing your experience and your service is always a reminder and motivitating
                    factor to someone so, please donot hesitate to share your services below
                </p>
                <p>
                    It will be desiplayed on the main page of blood donation and its always saved in our
                    lets-fight data base
                </p><br />
            </div>
            <div class="col-lg-3"></div>

            <div class="col-lg-6 ">

                <div class="card">
                    <form [formGroup]="form" (ngSubmit)="AddBanner(form.value, 1)">
                        <div class="row">

                            <div class="col-lg-12">
                                <label for="category">Category<span style="color:red">*</span></label>
                                <!--<div class="col-lg-7">
                                    <div style="padding-left:0px">-->

                                <p-autoComplete formControlName="Category" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                field="categoryname" [dropdown]="true" [size]="90"
                                                placeholder="Select Donor category" [minLength]="1"
                                                [style]="{'color': 'black'}"
                                                [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
                                </p-autoComplete>

                                <small *ngIf="form.controls.Category.touched && form.controls.Category.errors?.required"
                                       class="p-error" style="color:red">Donor category is required.</small>
                                <!--</div>
                                </div>-->
                            </div>
                        </div>
                        <br />
                        <div class="row">

                            <div class="col-lg-12 ">
                                <label for="username"> Upload Picture<span style="color:red">*</span></label>
                                <input formControlName="GalleryImages" input type="file" (change)="detectFiles2(input2)" accept="image/*" #input2 class="m-img-upload-btn mt-n4 input" />

                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Date of service<b><span style="color:red;">*</span></b></label>
                                    <div class="input-group">
                                        <!--<div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                            </div>
                                        </div>-->
                                        <input class="form-control notnull txtDate " formControlName="Dateofservice" required="" type="date" name="JoinedOn" id="JoinedOn">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>



                    <form [formGroup]="form" (ngSubmit)="AddBanner(form.value, 1)">

                        <div class="row">


                            <div class="col-lg-12">
                                <label>State Name<span style="color:red">*</span></label>
                                <div style="padding-left:0px">

                                    <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                    field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                    placeholder="Search State Name" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                    </p-autoComplete>

                                    <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                           class="p-error">State Name is required.</small>
                                </div>

                            </div>
                        </div> <br />

                        <div class="row">

                            <div class="col-lg-12">
                                <label>District Name</label>
                                <div style="padding-left:0px">
                                    <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                    field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                    placeholder="Search District Name" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                    </p-autoComplete>

                                    <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                           class="p-error">District Name is required.</small>
                                </div>
                            </div>

                        </div> <br />

                        <div class="row">

                            <div class="col-lg-12">
                                <label>City Name</label>
                                <div style="padding-left:0px">
                                    <p-autoComplete formControlName="cityname"
                                                    [suggestions]="deptList2"
                                                    (completeMethod)="searchCont2($event)"
                                                    field="CityName"
                                                    [dropdown]="true"
                                                    [size]="120"
                                                    placeholder="Search City Name"
                                                    [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                    </p-autoComplete>


                                    <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                           class="p-error">City name is required.</small>
                                </div>

                            </div>

                        </div>
                        <br />
                        <div class="row">

                            <div class="col-lg-12">
                                <label for="useraddress">Name of institution / Hospital<span style="color:red">*</span></label>
                                <input type="text" id="Institutionname" formControlName="Institutionname" placeholder="Name of Institution" />
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-12">
                                <label for="Message">Message your personal note </label><br />
                                <textarea id="Message" formControlName="Message" placeholder="Enter Your Description here"></textarea>
                                <br />  <button class="btn">Submit</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>


</div>



<style>
    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }
</style>
<div class=" ptb-100">
    <div class="container-fluid" style="background-color:red;height: 75px;
    padding: 10px;">
        <div class="container ">
            <div class="row">
                <div class="col-lg-3 zoom-in">
                    <a routerLink="/">
                        <img src="../../../../assets/svg/logo.png" class="logo" />
                    </a>
                </div>
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-lg-8">
                            <h3 class="title">Share Your Service</h3>

                        </div>
                        <div class="col-lg-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <ng-container *ngIf="LoginDetails; else showLoginButton">

                        <div class="dropdown">
                            <div class="circle dropbtn">
                                <div class="letter"> {{ letter }}</div>
                                <div class="dropdown-content">
                                    <a href="Profile">My Profile</a>

                                    <a href="#" (click)="signOut()">Sign Out</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!--<ng-container *ngIf="LoginDetails; else showLoginButton">


                        <label for="button">
                            <div class="circle" (click)="toggleDropdown()">
                                <div class="letter">{{LoginDetails.FullName.charAt(0)}}</div>
                                <div class="dropdown-content" id="dropdownContent">
                                    <a href="/Profile">My Profile</a>
                                    <a href="#" (click)="signOut()">Sign Out</a>
                                </div>
                            </div>
                        </label>
                    </ng-container>-->
                    <ng-template #showLoginButton>
                        <!-- Display login button for users not logged in -->
                        <!--<div >Login</div>-->

                        <div class="col-lg-2">
                            <div class="services " (click)="openLoginModal()">  Login</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div><br />
    <div class="modal" id="myModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">
                        Login
                    </h4>

                    <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:black">X</span></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="card_1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <fieldset>
                                        <div class="card">

                                            <form>
                                                <label for="username">Mobile </label>
                                                <input type="text" [(ngModel)]="Mobile" placeholder="Enter Your Mobile Number" id="username" name="username" inputmode="numeric" pattern="[0-9]*" maxlength="10" required>
                                                <label for="password" *ngIf="OTPtoMobile != null">OTP </label>
                                                <input type="password" [(ngModel)]="OTP" id="password" name="password" *ngIf="OTPtoMobile != null" required>
                                                <br />
                                                <button class="btn btn-primary" type="submit" (click)="SendOTPtoMobile()" [hidden]="!showLogin1" [disabled]="otpSent">Send OTP</button>
                                                &nbsp;
                                                <br />
                                                <button class="btn btn-primary" type="submit" (click)="checkmobile(Mobile)" [hidden]="!showLogin">Login</button>
                                            </form>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    Sharing your experience and your service is always a reminder and motivitating
                    factor to someone so, please donot hesitate to share your services below
                </p>
                <p>
                    It will be desiplayed on the main page of blood donation and its always saved in our
                    lets-fight data base
                </p><br />
            </div>
            <div class="col-lg-3"></div>

            <div class="col-lg-6 ">

                <div class="card">
                    <form [formGroup]="form" (ngSubmit)="AddBanner(form.value, 1)">
                        <div class="row">

                            <div class="col-lg-12">
                                <label for="category">Category<span style="color:red">*</span></label>
                                <!--<div class="col-lg-7">
                                    <div style="padding-left:0px">-->

                                <p-autoComplete formControlName="Category" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                field="categoryname" [dropdown]="true" [size]="90"
                                                placeholder="Select Donor category" [minLength]="1"
                                                [style]="{'color': 'black'}"
                                                [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
                                </p-autoComplete>

                                <small *ngIf="form.controls.Category.touched && form.controls.Category.errors?.required"
                                       class="p-error" style="color:red">Donor category is required.</small>
                                <!--</div>
                                </div>-->
                            </div>
                        </div>
                        <br />
                        <div class="row">

                            <div class="col-lg-12 ">
                                <label for="username"> Upload Picture<span style="color:red">*</span></label>
                                <input formControlName="GalleryImages" input type="file" (change)="detectFiles2(input2)" accept="image/*" #input2 class="m-img-upload-btn mt-n4 input" />

                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Date of service<b><span style="color:red;">*</span></b></label>
                                    <div class="input-group">
                                        <!--<div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                            </div>
                                        </div>-->
                                        <input class="form-control notnull txtDate " formControlName="Dateofservice" required="" type="date" name="JoinedOn" id="JoinedOn">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>



                    <form [formGroup]="form" (ngSubmit)="AddBanner(form.value, 1)">

                        <div class="row">


                            <div class="col-lg-12">
                                <label>State Name<span style="color:red">*</span></label>
                                <div style="padding-left:0px">

                                    <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                    field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                    placeholder="Search State Name" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                    </p-autoComplete>

                                    <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                           class="p-error">State Name is required.</small>
                                </div>

                            </div>
                        </div> <br />

                        <div class="row">

                            <div class="col-lg-12">
                                <label>District Name</label>
                                <div style="padding-left:0px">
                                    <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                    field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                    placeholder="Search District Name" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                    </p-autoComplete>

                                    <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                           class="p-error">District Name is required.</small>
                                </div>
                            </div>

                        </div> <br />

                        <div class="row">

                            <div class="col-lg-12">
                                <label>City Name</label>
                                <div style="padding-left:0px">
                                    <p-autoComplete formControlName="cityname"
                                                    [suggestions]="deptList2"
                                                    (completeMethod)="searchCont2($event)"
                                                    field="CityName"
                                                    [dropdown]="true"
                                                    [size]="120"
                                                    placeholder="Search City Name"
                                                    [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                    </p-autoComplete>


                                    <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                           class="p-error">City name is required.</small>
                                </div>

                            </div>

                        </div>
                        <br />
                        <div class="row">

                            <div class="col-lg-12">
                                <label for="useraddress">Name of institution / Hospital<span style="color:red">*</span></label>
                                <input type="text" id="Institutionname" formControlName="Institutionname" placeholder="Name of Institution" />
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-12">
                                <label for="Message">Message your personal note </label><br />
                                <textarea id="Message" formControlName="Message" placeholder="Enter Your Description here"></textarea>
                                <br />  <button class="btn">Submit</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>


</div>



<style>
    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }
</style>
