<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Let's Help </title>

    <!-- core CSS -->

    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

    <!--[if lt IE 9]>
        <script src="js/html5shiv.js"></script>
        <script src="js/respond.min.js"></script>
        <![endif]
        <link rel="shortcut icon" href="images/ico/favicon.ico">
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png">
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png">
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png">
        <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png">
    </head><!--/head-->
</head>
<body>
    <!--Bootstrap Approach-->

    <div class=" pt-100">
        <div class="container-fluid">
            <div class="container"style="background-color: #fb0300;">
                <div class="row">
                    <div class="card_menu">
                        <div class="col-lg-3 col-6">
                            <a href="/">
                                <img src="assets/img/Blood_DonationImages/LETS_HELP_LOGO.png" alt="logo" class="width" />
                            </a>
                        </div>

                        <div class="col-lg-9 col-6 none ml-n5">
                            <ng-container *ngIf="LoginDetails; else showLoginButton">
                                <div class="dropdown" (click)="toggleDropdown()">
                                    <button class="dropbtn" style="color:white">
                                        {{ letter }}

                                    </button>
                                    <div class="dropdown-content" style="width:100%;">
                                        <a href="Profile" (click)="pro()"> My Profile </a>
                                        <a (click)="signOut()">Sign Out</a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #showLoginButton>
                                <p class="Login log_infont" (click)="openLoginModal()"><b>LOGIN</b></p>
                            </ng-template>

                        </div>
                    </div>

                </div>

                <div class="row">
                    <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff;">
                        <h2 style="color: #c52d2f;">Iron and blood donation</h2>
                        <ul>
                            <li>
                                Iron and blood donation are intricately connected processes, as regular blood donation can impact the iron levels in the donor's body. Here's a deeper explanation:
                            </li>
                            <li>
                                When an individual donates blood, they are essentially giving a portion of their red blood cells to help others in need. Each donation results in the loss of some iron-containing hemoglobin from the donor's body. As a result, frequent blood donation can lead to a gradual decrease in the donor's iron stores over time.
                            </li>
                            <li>
                                For many blood donors, especially those who donate regularly, maintaining optimal iron levels becomes important to prevent iron deficiency anemia, a condition characterized by low levels of hemoglobin and insufficient oxygen transport capacity in the blood. Iron deficiency anemia can lead to symptoms such as fatigue, weakness, dizziness, pale skin, and shortness of breath.
                            </li>
                            <li>
                                To address the potential impact of blood donation on iron levels, blood donation centers often provide guidance on iron supplementation and dietary recommendations to donors. Iron supplements or dietary changes rich in iron, such as consuming red meat, poultry, fish, leafy greens, legumes, and iron-fortified foods, can help replenish iron stores and maintain adequate hemoglobin levels.
                            </li>
                            <li>
                                However, it's essential for donors to consult with healthcare professionals before starting iron supplementation, as excessive iron intake can lead to adverse effects and complications, particularly in individuals with certain medical conditions or genetic predispositions.
                            </li>
                            <li>
                                Furthermore, blood donation centers typically implement measures to ensure the health and safety of donors, including screening for eligibility criteria based on hemoglobin levels and providing post-donation care instructions to minimize the risk of iron depletion and associated health concerns.
                            </li>
                            <li>
                                In summary, while blood donation is a noble act that saves lives and contributes to the well-being of others, it's essential for donors to be aware of the potential impact on their iron levels and take proactive steps to maintain optimal iron status through appropriate supplementation and dietary choices, thereby supporting their own health while continuing to donate blood altruistically.
                            </li>
                        </ul>

                    </form>
                </div>
            </div><br />
        </div>
        <!--<section id="feature" class="card1">
            <div class="container">
                <section id="conatcat-info" style="background-color: #ffffff;">
                    <div class="container">
                        <form class="well form-horizontal" method="post" id="contact_form" style="background-color: #ffffff; margin-right: 15px; margin-left: 15px;">
                                <h2 style="color: #c52d2f;">Iron and blood donation</h2><br />
                                <ul>
                                <li>
                                    Iron and blood donation are intricately connected processes, as regular blood donation can impact the iron levels in the donor's body. Here's a deeper explanation:
                                </li>
                                <li>
                                    When an individual donates blood, they are essentially giving a portion of their red blood cells to help others in need. Each donation results in the loss of some iron-containing hemoglobin from the donor's body. As a result, frequent blood donation can lead to a gradual decrease in the donor's iron stores over time.
                                </li>
                                <li>
                                    For many blood donors, especially those who donate regularly, maintaining optimal iron levels becomes important to prevent iron deficiency anemia, a condition characterized by low levels of hemoglobin and insufficient oxygen transport capacity in the blood. Iron deficiency anemia can lead to symptoms such as fatigue, weakness, dizziness, pale skin, and shortness of breath.
                                </li>
                                <li>
                                    To address the potential impact of blood donation on iron levels, blood donation centers often provide guidance on iron supplementation and dietary recommendations to donors. Iron supplements or dietary changes rich in iron, such as consuming red meat, poultry, fish, leafy greens, legumes, and iron-fortified foods, can help replenish iron stores and maintain adequate hemoglobin levels.
                                </li>
                                <li>
                                    However, it's essential for donors to consult with healthcare professionals before starting iron supplementation, as excessive iron intake can lead to adverse effects and complications, particularly in individuals with certain medical conditions or genetic predispositions.
                                </li>
                                <li>
                                    Furthermore, blood donation centers typically implement measures to ensure the health and safety of donors, including screening for eligibility criteria based on hemoglobin levels and providing post-donation care instructions to minimize the risk of iron depletion and associated health concerns.
                                </li>
                                <li>
                                    In summary, while blood donation is a noble act that saves lives and contributes to the well-being of others, it's essential for donors to be aware of the potential impact on their iron levels and take proactive steps to maintain optimal iron status through appropriate supplementation and dietary choices, thereby supporting their own health while continuing to donate blood altruistically.
                                </li>
                            </ul>

                        </form>
                    </div>

                </section>-->
        <!--/#conatcat-info-->
        <!--</div>
        </section>-->
    </div>

    <footer id="footer" class="midnight-blue">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    &copy; 2024 Let's Help  all rights reserved.
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-2 zoom-in"><a href="#" style="color:white">Home</a></div>
                        <div class="col-lg-2 zoom-in"><a href="about" style="color:white">About</a></div>
                        <div class="col-lg-2 zoom-in"><a href="FAQS" style="color:white">FAQ's</a></div>
                        <div class="col-lg-3 zoom-in"><a href="contact" style="color:white">Contact Us</a></div>
                    </div>

                </div>
            </div>
        </div>
    </footer><!--/#footer-->
    <script src="js/jquery.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.prettyPhoto.js"></script>
    <script src="js/jquery.isotope.min.js"></script>
    <script src="js/main.js"></script>
    <script src="js/wow.min.js"></script>


</body>
</html>

<style>
    h1, h2, h3, h4, h5, h6 {
        margin: 1% 0% 0% 0%;
        font-weight: bold
    }

    body {
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
        overflow-y: auto; /* Enable vertical scrolling */
    }

    .pt-100 {
        padding-top: 0px;
    }

    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }

    a:hover, a:focus {
        color: #fb0300;
        text-decoration: none;
        outline: none;
    }

    .midnight-blue {
        background-color: black;
       /* color: white;*/
        padding: 10px 2px;
    }
    ul {
        padding: 16px;
    }

        ul li {
            list-style: disc;
        }
    li {
       /* display: inline-block;*/
        color:black;
        padding: 10px;
        list-style:disc;
    }

        li a {
            color: white;
        }
    @media only screen and (max-width: 600px) {
        .none {
            display: none;
        }
    }
    footer div a {
        float: inline-end;
    }
</style>

