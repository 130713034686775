import { Component, ViewChild, ElementRef, NgZone, ViewEncapsulation } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
//import { MapsAPILoader, MouseEvent } from '@agm/core';
declare var $: any
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-requestpresentation',
  templateUrl: './requestpresentation.component.html',
  styleUrls: ['./requestpresentation.component.scss']
})
export class RequestpresentationComponent {
    public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    latitude: any;
    longitude: any;
    lat: any; selectedpast: string;
 selectedaudi: string;
    long: any;
    zoom: number;
    address: string;
    Dropaddress: string; activeCardIndex: number = -1;
    private geoCoder;
    map: boolean
    @ViewChild('search')
    public searchElementRef: ElementRef;
    trip: any; Address: any;
    profiledata: any;
    LoginDetails1: any; LoginDetails: any;
    Lastdonatedate: any;
    loginDet: any;
    logindata: any;
    modalVisible: boolean;
    RegId: any;
    selectedVenue: string;
    deptList9: any;
    observ: any = [];
    constructor(public genralservice: GeneralService, public http: HttpClient, private datePipe: DatePipe, public fb: FormBuilder, public router: Router,
    ) {
        //this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.form = this.fb.group({
            // Define your form controls here
            Mode: ['', Validators.required],
            Audiance: ['', Validators.required],
            WorkPlace: ['', Validators.required],
            Venue_name: ['',],
          statename: [''],
            districtname: [''],
            cityname: [''],
            Address: [''],
            Area: [''],
        
            Pincode: [''],
            Message: [''],
            Contact_name: [''],
            Contact_number: [''],
         
        });

       
           
        
    }


    ngOnInit(): void {
        

        // this.getProfileAndBindFormData();
       
        this.getstate();
        this.getvenue();

    }

    downloadlogo() {
        
        // Assuming value is boolean
        this.router.navigate(['/Stickbrochure']).then(() => { window.location.reload(); });
    }
    handleClick(index: number) {
        if (this.activeCardIndex === index) {
            this.activeCardIndex = -1; // Toggle off if same card clicked again
        } else {
            this.activeCardIndex = index;
        }
    }
    toggleSocialMedia(value: number) {
        // Update showSocialMedia variable
        this.router.navigate(['/Requestpresentation']).then(() => { window.location.reload(); });
    }
    reg() {
        
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
 

    getprofile() {
       
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : '');

        var url = "api/BG/checking_Mobile";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.profiledata = data;
            //this.bindFormData(this.profiledata);
            localStorage.setItem("LoginDetails", JSON.stringify(this.profiledata));

            this.LoginDetails = this.profiledata;// Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }




    Donersform() {
        debugger
        if (!this.form.value.Contact_number || !this.form.value.Contact_name ||
            !this.form.value.Venue_name || !this.form.value.Pincode || this.selectedGender === "" ||
            this.selectedaudi === "" || !this.form.value.WorkPlace.WorkPlace) {
            // Display an error alert
            window.alert('ERROR: Please fill all required fields');

            return; // Stop further execution of the method
        }
        const stateId = this.form.value.statename && this.form.value.statename.StateId !== undefined
            ? this.form.value.statename.StateId
            : this.LoginDetails.StateId !== undefined
                ? this.LoginDetails.StateId
                : this.LoginDetails.StateID;

        const districtId = this.form.value.districtname && this.form.value.districtname.DistrictID !== undefined
            ? this.form.value.districtname.DistrictID
            : this.LoginDetails.DistrictId !== undefined
                ? this.LoginDetails.DistrictId
                : this.LoginDetails.DistrictID;

        const cityId = this.form.value.cityname && this.form.value.cityname.CityId !== undefined
            ? this.form.value.cityname.CityId
            : this.LoginDetails.CityId !== undefined
                ? this.LoginDetails.CityId
                : this.LoginDetails.CityID;
        ;
        this.arr = [];

        this.arr.push({
       
          
            Contact_number: this.form.value.Contact_number,
            Contact_name: this.form.value.Contact_name,
            Address: this.form.value.Address,
            Venue_name: this.form.value.Venue_name,
           
            Mode: this.selectedGender,
            Pincode: this.form.value.Pincode || this.LoginDetails.Pincode,
            Audiance: this.selectedaudi,
           // Venue: this.selectedVenue,
            Venue: this.form.value.WorkPlace.WorkPlace,
           
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
           
            Area: this.form.value.Area ,
            Message: this.form.value.Message,
            CreatedBy:this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');
        var url = "api/BG/Insert_Update_Requestpresent";
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
                if (this.flag == 1) {
                    this.genralservice.ShowAlert("ERROR", 'Request added Succesfully', 'error');

                } else {
                    // localStorage.setItem('LoginDetails', JSON.stringify(this.arr));
                    this.genralservice.ShowAlert("SUCCESS", 'Request added Succesfully', 'success');
                    this.router.navigate(['/Volunters']).then(() => {
                       
                        window.location.reload();
                    });

                }
                this.form.reset();
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong. Please check validation fields', 'error');
            }
        });
    }


    toggleStatus(checked: boolean) {
        this.LoginDetails.Status = checked ? 1 : 0;
    }

    getstate() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getvenue() {

        this.arr = []
        this.arr.push({

        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BG_Work_Place_Crud";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.observ = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont9(event) {
     
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.observ.length; i++) {
                let country = this.observ[i];
                if (country.WorkPlace.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList9 = filtered;
        }
    }

    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }

    toggleDropdown() {
        this.showDropdownContent = !this.showDropdownContent;
    }


    // Function to select a blood group
    selectBloodgroup(bloodgroup: string) {
        this.selectedBloodgroup = bloodgroup;
        this.showDropdownContent = false; // Hide the dropdown after selection if needed
    }

    selectGender(gender: string) {
        
        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    } selectaudi(Audiance: string) {
        
        this.selectedaudi = Audiance;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    } selectVenue(venue: string) {
        
        this.selectedVenue = venue;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    PastDonation(Past: string) {
        
        this.selectedpast = Past;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }
}
