import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-volunteerprofile',
  templateUrl: './volunteerprofile.component.html',
  styleUrls: ['./volunteerprofile.component.scss']
})
export class VolunteerprofileComponent {
    public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    constructor(public genralservice: GeneralService, private datePipe: DatePipe, public http: HttpClient, public fb: FormBuilder, public router: Router,) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.form = this.fb.group({
            username: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['',],
            Gender: ['',],
            age: ['', Validators.required],
            //unitsofblood: [''],
            bloodgroup: ['', Validators.required],
            statename: [''],
            districtname: [''],
            cityname: [''],
            useraddress: [''],
            area: [''],
            Status: [''],
            Messagetorequester: [''],
            Weight: [''],
            PastDonation: ['', Validators.required],
            Lastdonatedate: [''],
            Pincode: ['', Validators.required],

            Statusphn: [''],
            Rolestatus: [''],
     
        });


    }


    ngOnInit(): void {
        this.getprofile();
        this.getBloodgroup();
        this.getstate();
       // this.getDistrict();
        //this.getCity();
        // this.bindFormData();


    }


    getprofile() {
        debugger;
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : '');

        var url = "api/BG/checking_Mobile";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.profiledata = data;
            this.bindFormData(this.profiledata);

            this.LoginDetails = this.profiledata;
            // Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }

    reg() {
        debugger;
        if (!this.LoginDetails || this.LoginDetails.length === 0) {
            // If no login details available, navigate to Login
            this.router.navigate(['/Login']);
        } else {
            // Check the role id
            const roleId = this.LoginDetails[0].RoleId || this.LoginDetails.RoleId;
            if (roleId === 4) {
                // Navigate to volunteer if role id is 4
                this.router.navigate(['/Volunteerprofile']);
            } else {
                // Otherwise, navigate to Profile
                this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
            }
        }
    }

    bindFormData(data) {
        debugger
        if (data) {
            debugger
            this.selectedGender = data[0].Gender;
            this.selectedpast = data[0].PastDonation;
            const lastDonateDate = data[0].Lastdonatedate && !isNaN(Date.parse(data[0].Lastdonatedate)) ?
                this.datePipe.transform(new Date(data[0].Lastdonatedate), 'yyyy-MM-dd') : null;

            const dob = data[0].DOB && !isNaN(Date.parse(data[0].DOB)) ?
                this.datePipe.transform(new Date(data[0].DOB), 'yyyy-MM-dd') : null;

            debugger
            this.form.patchValue({

                username: data[0].FullName,
                mobile: data[0].Phonenumber,
                email: data[0].Email,
                age: data[0].Age,
                bloodgroup: data[0].BLGName,
                BloodGroupId: data[0].BLGId,
                statename: data[0].StateName,
                StateId: data[0].StateId,
                districtname: data[0].DistrictName,
                DistrictId: data[0].DistrictId,
                cityname: data[0].CityName,
                CityId: data[0].CityId,
                useraddress: data[0].UserAddress,
                area: data[0].Area,
                Status: data[0].Status || data.Status,
                Weight: data[0].Weight,
                Pincode: data[0].Pincode,


                Statusphn: data[0].Statusphn || data.Statusphn,
                Rolestatus: data[0].Rolestatus || data.Rolestatus,
                Lastdonatedate: lastDonateDate,
                Messagetorequester: data[0].Messagetorequester
            });
        }
    }


    Donersform() {
        debugger
        if (!this.form.value.username || !this.form.value.mobile || !this.form.value.Pincode || this.selectedGender === "" ||
            (this.form.controls['Rolestatus'].value && !this.selectedpast) ||
            (!this.form.value.bloodgroup && this.form.controls['Rolestatus'].value) ||
            (!this.form.value.Weight && this.form.controls['Rolestatus'].value) ||
            (!this.form.value.age && this.form.controls['Rolestatus'].value) ||
            (!this.form.value.age && this.form.controls['Rolestatus'].value)) {
            alert(" Please fill all required fields");
           
            return; // Stop further execution of the method
        }

        debugger;
        this.arr = [];
        const bloodgroup = this.form.value.bloodgroup && this.form.value.bloodgroup.BLGId !== undefined
            ? this.form.value.bloodgroup.BLGId
            : this.LoginDetails[0] && this.LoginDetails[0].BLGId !== undefined
                ? this.LoginDetails[0].BLGId
                : this.LoginDetails.BLGId !== undefined
                    ? this.LoginDetails.BLGId
                    : this.LoginDetails.BloodGroupId;

        const stateId = this.form.value.statename && this.form.value.statename.StateId !== undefined
            ? this.form.value.statename.StateId
            : this.LoginDetails[0] && this.LoginDetails[0].StateId !== undefined
                ? this.LoginDetails[0].StateId
                : this.LoginDetails.StateId !== undefined
                    ? this.LoginDetails.StateId
                    : this.LoginDetails.StateID;

        const districtId = this.form.value.districtname && this.form.value.districtname.DistrictID !== undefined
            ? this.form.value.districtname.DistrictID
            : this.LoginDetails[0] && this.LoginDetails[0].DistrictID !== undefined
                ? this.LoginDetails[0].DistrictID
                : this.LoginDetails.DistrictID !== undefined
                    ? this.LoginDetails.DistrictID
                    : this.LoginDetails.DistrictID;

        const cityId = this.form.value.cityname && this.form.value.cityname.CityId !== undefined
            ? this.form.value.cityname.CityId
            : this.LoginDetails[0] && this.LoginDetails[0].CityId !== undefined
                ? this.LoginDetails[0].CityId
                : this.LoginDetails.CityId !== undefined
                    ? this.LoginDetails.CityId
                    : this.LoginDetails.CityID;
        const lastDonateDate = this.selectedpast === 'No' ? null : this.form.value.Lastdonatedate || this.LoginDetails.Lastdonatedate;

        this.arr.push({
            RegId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
            TokenId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].TokenId : this.LoginDetails.TokenId) : '',
            FullName: this.form.value.username,
            Phonenumber: this.form.value.mobile,
            Weight: this.form.value.Weight,
            Email: this.form.value.email || this.LoginDetails.Email,
            Gender: this.selectedGender || this.LoginDetails.Gender,
            Lastdonatedate: lastDonateDate,
       
            Pincode: this.form.value.Pincode || this.LoginDetails.Pincode,
            PastDonation: this.selectedpast,
            age: this.form.value.age || this.LoginDetails.Age,
            BloodGroupId: bloodgroup,
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
            UserAddress: this.form.value.useraddress || this.LoginDetails.UserAddress,
            Area: this.form.value.area || this.LoginDetails.Area,
            Messagetorequester: this.form.value.Messagetorequester || this.LoginDetails.Messagetorequester,
            RoleId: '4',
            Status: this.form.value.Status || this.LoginDetails.Status,
            Statusphn: this.form.value.Statusphn || this.LoginDetails.Statusphn,
            Rolestatus: this.form.value.Rolestatus || this.LoginDetails.Rolestatus,
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/BG/Insert_Update_DonersForm";
        this.genralservice.PostData(url, uploadfile).then(data => {
            debugger
            if (data == "SUCCESS") {
                if (this.flag == 2) {
                    this.genralservice.ShowAlert("ERROR", 'Profile Updated Succesfully', 'error');
                } else {

                    localStorage.setItem('LoginDetails', JSON.stringify(this.arr));
                    this.genralservice.ShowAlert("SUCCESS", 'Profile Updated Succesfully', 'success');
                    this.router.navigate(['/blooddonation']).then(() => {
                        window.location.reload();

                    });

                }
                this.form.reset();
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong. Please check validation fields', 'error');
            }
        });
    }

    toggleStatuss(checked: boolean) {
        this.form.controls['Status'].setValue(checked);
    }


    toggleStatuss1(checked: boolean) {
        this.form.controls['Statusphn'].setValue(checked);
    }


    toggleStatuss2(checked: boolean) {
        this.form.controls['Rolestatus'].setValue(checked);
    }
 
    toggleStatus(checked: boolean) {
        this.form.controls['Status'].setValue(checked);
    }

    getstate() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getBloodgroup() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails.RegId,
            TokenId: this.LoginDetails.TokenId,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BloodGroupMaster_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.bloodgroupdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont3(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }

    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }

    toggleDropdown() {
        this.showDropdownContent = !this.showDropdownContent;
    }


    // Function to select a blood group
    selectBloodgroup(bloodgroup: string) {
        this.selectedBloodgroup = bloodgroup;
        this.showDropdownContent = false; // Hide the dropdown after selection if needed
    }

    selectGender(gender: string) {
        debugger
        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    PastDonation(Past: string) {
        debugger
        this.selectedpast = Past;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }
}
