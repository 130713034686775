import { Component, ViewChild, ElementRef, NgZone, ViewEncapsulation } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
//import { MapsAPILoader, MouseEvent } from '@agm/core';
declare var $: any
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.scss']
})
export class AddmemberComponent {
    public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    latitude: any;
    longitude: any;
    lat: any; selectedpast: string;
    long: any;
    zoom: number;
    address: string;
    Dropaddress: string; activeCardIndex: number = -1;
    private geoCoder;
    map: boolean
    @ViewChild('search')
    public searchElementRef: ElementRef;
    trip: any; Address: any;
    profiledata: any;
    LoginDetails1: any; LoginDetails: any;
    Lastdonatedate: any;
    loginDet: any;
    logindata: any;
    modalVisible: boolean;
    RegId: any;
    pwd1: any;
    userid: any;
    Mobile: any;
    mobile: any;
    OTP: any;
   
    OTPtoMobile: any;
    
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false; 
    showOTPInput: boolean = false;
   
    showFullNameInput: boolean = false;
  
    constructor(public genralservice: GeneralService, public http: HttpClient, private datePipe: DatePipe, public fb: FormBuilder, public router: Router,
    ) {
        //this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

        this.form = this.fb.group({
            // Define your form controls here
            username: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['', Validators.required],
            Gender: ['',],
            DOB: ['',],
            PastDonation: ['',],
            age: [''],
            // unitsofblood: [''],
            bloodgroup: ['', Validators.required],
            statename: [''],
            districtname: [''],
            cityname: [''],
            useraddress: [''],
            area: [''],
            Weight: [''],
            Lastdonatedate: [''],
            Pincode: [''],
            OTP: [''],
            Messagetorequester: [''],
            termsAndConditions: [false, Validators.requiredTrue],
            eligibilityCriteria: [false, Validators.requiredTrue]
        });


    }


    ngOnInit(): void {
        

       // this.getProfileAndBindFormData();
        this.getBloodgroup();
        this.getstate();
        this.getDistrict();
        this.getCity();

    }

    downloadlogo() {
        ;
        // Assuming value is boolean
        this.router.navigate(['/Stickbrochure']).then(() => { window.location.reload(); });
    }
    handleClick(index: number) {
        if (this.activeCardIndex === index) {
            this.activeCardIndex = -1; // Toggle off if same card clicked again
        } else {
            this.activeCardIndex = index;
        }
    }
    toggleSocialMedia(value: number) {
        // Update showSocialMedia variable
        this.router.navigate(['/Requestpresentation']).then(() => { window.location.reload(); });
    }
    reg() {
        
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
    async getProfileAndBindFormData() {
        try {
            const profileData = await this.getprofile();
            // Assuming `getprofile()` returns `null` or some default value when no profile data is available
            if (profileData !== null) {
              //  this.bindFormData(profileData);
            } else {
                // Handle case where profile data is not available
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
            // Handle error appropriately, e.g., show error message to user
        }
    }

    checkFormValidity() {
        // Check if all form controls are valid
        this.form.updateValueAndValidity();
    }
    openLoginModal() {
        // Show the modal here
        $('#myModal').modal('show');
    }
    openLoginModal1() {
        // Show the modal here
        $('#myModal1').modal('show');
    }
    closeModal() {
        // this.modalVisible = false;
        $('#myModal').modal('hide');
    }


    getprofile() {
        ;
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : '');

        var url = "api/BG/checking_Mobile";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.profiledata = data;
            //this.bindFormData(this.profiledata);
            localStorage.setItem("LoginDetails", JSON.stringify(this.profiledata));

            this.LoginDetails = this.profiledata;// Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }


   

    Donersform() {
        if (!this.form.value.username || !this.form.value.mobile || !this.form.value.bloodgroup || !this.form.value.Weight || !this.form.value.age || !this.form.value.Pincode || this.selectedGender === "" ||
            this.selectedpast === "") {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }

        ;
        this.arr = [];
        const bloodgroup = this.form.value.bloodgroup && this.form.value.bloodgroup.BLGId !== undefined
            ? this.form.value.bloodgroup.BLGId
            : this.LoginDetails.BLGId !== undefined
                ? this.LoginDetails.BLGId
                : this.LoginDetails.BloodGroupId;

        const stateId = this.form.value.statename && this.form.value.statename.StateId !== undefined
            ? this.form.value.statename.StateId
            : this.LoginDetails.StateId !== undefined
                ? this.LoginDetails.StateId
                : this.LoginDetails.StateID;

        const districtId = this.form.value.districtname && this.form.value.districtname.DistrictID !== undefined
            ? this.form.value.districtname.DistrictID
            : this.LoginDetails.DistrictId !== undefined
                ? this.LoginDetails.DistrictId
                : this.LoginDetails.DistrictID;

        const cityId = this.form.value.cityname && this.form.value.cityname.CityId !== undefined
            ? this.form.value.cityname.CityId
            : this.LoginDetails.CityId !== undefined
                ? this.LoginDetails.CityId
                : this.LoginDetails.CityID;
        const lastDonateDate = this.selectedpast === 'No' ? null : this.form.value.Lastdonatedate || this.LoginDetails.Lastdonatedate;

        this.arr.push({
            RegId: this.RegId,
            FullName: this.form.value.username,
            Phonenumber: this.form.value.mobile,
            Weight: this.form.value.Weight,
            Email: this.form.value.email || this.LoginDetails.Email,
            Gender: this.selectedGender || this.LoginDetails.Gender,
            Lastdonatedate: lastDonateDate,
            DOB: this.form.value.DOB || this.LoginDetails.DOB,
            Pincode: this.form.value.Pincode || this.LoginDetails.Pincode,
            PastDonation: this.selectedpast,
            age: this.form.value.age || this.LoginDetails.Age,
            BloodGroupId: bloodgroup,
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
            UserAddress: this.form.value.useraddress || this.LoginDetails.UserAddress,
            Area: this.form.value.area || this.LoginDetails.Area,
            Messagetorequester: this.form.value.Messagetorequester || this.LoginDetails.Messagetorequester,
            RoleId: '2',
            Status: true,
            CreatedBy: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : ''
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '1');
        var url = "api/BG/Insert_Update_Addmembers";
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data === "SUCCESS") {
                if (this.flag === 1) {
                    this.genralservice.ShowAlert("ERROR", 'Your number already exists.', 'error');
                } else {
                    this.genralservice.ShowAlert("SUCCESS", 'Added Donor Successfully', 'success');
                    this.router.navigate(['/Addtomember']).then(() => { window.location.reload(); });
                    this.form.reset();
                }
            } else {
                alert('ERROR: Your phone number already exists');

            }
        });

    }


    toggleStatus(checked: boolean) {
        this.LoginDetails.Status = checked ? 1 : 0;
    }

    getstate() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getBloodgroup() {
        
        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails.RegId,
            TokenId: this.LoginDetails.TokenId,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BloodGroupMaster_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.bloodgroupdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont3(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }

    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }

    toggleDropdown() {
        this.showDropdownContent = !this.showDropdownContent;
    }


    // Function to select a blood group
    selectBloodgroup(bloodgroup: string) {
        this.selectedBloodgroup = bloodgroup;
        this.showDropdownContent = false; // Hide the dropdown after selection if needed
    }

    selectGender(gender: string) {
        
        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }
    PastDonation(Past: string) {
        
        this.selectedpast = Past;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }

    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }
    checkOTP() {

             const otpControl = this.form.get('OTP');
        if (otpControl && otpControl.value && otpControl.value.length === 4) {
            this.showFullNameInput = true;
        }
    }


    async CheckMobileNumber() {
        try {
            const UploadFile = new FormData();
            UploadFile.append("Mobile", this.form.value.mobile);
            const url = 'api/BG/checking_Mobile';

            const data = await this.genralservice.PostData(url, UploadFile);

            this.SendOTPtoMobile(data);
        } catch (error) {
            console.error("Error checking mobile number:", error); 
        }
    }

    async SendOTPtoMobile(data: any) {
        debugger
        try {
            if (data === 'NOTEXIST') {
                const OTP = Math.floor(1000 + Math.random() * 9000);

                const formData = new FormData();
                formData.append("MobileNo", this.form.value.mobile);
                formData.append("OTP", OTP.toString());

                const url = "api/BG/SendOtpToMobile";

                await this.genralservice.PostData(url, formData);

                this.otpSent = true;
                this.showOTPInput = true;
                this.showLogin1 = false;
            } else {
                alert("Mobile number already exists.");
            }
        } catch (error) {
            console.error("Error sending OTP to mobile:", error);
        }
    }

}
