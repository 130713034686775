
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { EmailValidators } from 'ngx-validators'

//import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../../general.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss']
})
export class CtaComponent implements OnInit {
    HomeUrl: any;
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true; loginDet:any;
    LoginDetails: any; LoginDetails1: any; letter: any;
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0) {
            this.letter = this.LoginDetails[0].FullName.charAt(0);
        } else if (this.LoginDetails && this.LoginDetails.FullName) {
            this.letter = this.LoginDetails.FullName.charAt(0);
        } else {
            this.letter = '';
        }
        
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }
    ngOnInit() {
       // this.Getsurname();
    }
    SendOTPtoMobile() {
        
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.generalService.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
            })
        }
    }
    openLoginModal() {
        // Show the modal here
        this.router.navigate(['/Login']);
    }

    share() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Shareyourservices']);
        }
    }
    pro() {
        this.router.navigate(['/Profile']);
    }
    signOut() {
        
        // Remove the login details from localStorage
        localStorage.removeItem('LoginDetails');
        window.location.reload();
        // Optionally, you might want to perform other sign-out related tasks such as
        // navigating the user to the login page, clearing any local state, etc.
    }
    checkmobile(Mobile) {
        
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);


                var url = 'api/BG/checking_Mobile'

                this.generalService.PostData(url, UploadFile).then(data => {
                    //this.logindata = JSON.stringify(data[0]);
                    //localStorage.setItem("LoginDetails", this.logindata);
                    
                    if (data != 'NOTEXIST') {
                        
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;
                        this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);

                    }
                    else {

                        this.router.navigate(['/Donersform'])
                            // this.router.navigate(['/register', { Mobile: this.form.value.Mobile }])
                            .then(() => {
                                window.location.reload();
                            });
                    }
                })
            }
        }
    }


    showmenu() {

    }

    toggleDropdown() {
        const dropdownContent = document.getElementById("dropdownContent");
        if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
        } else {
            dropdownContent.style.display = "block";
        }
    }

}

