import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {
    disableViewDetails: boolean = false;   
    item: any;
    isActive: string;
    isSearching: boolean = false;
    isSearchInputFocused: boolean = false;
    prevSearchQuery: string = '';
    activeButton: string = '';
    arr3: any[];
    HomeUrl: any;
    public ckeditorContent: any;
    gridview: boolean = true;
    Form: FormGroup;
    GalleryImages: any;
    urls: any;
    arr: any = [];
    isDonorRegisterClicked: boolean = false;
    isRegistrationFormVisible: boolean = false;
    isDonorRegisterClickedd: boolean = false;
    isshareFormVisible: boolean = false;
    isrequestFormVisible: boolean = false;
    isprofileFormVisible: boolean = false;
    dataResult: any = [];
    showSocialList: boolean = false;  
    currentPage: number = 1;
    itemsPerPage: number = 6;
    startIndex: number = 0;
    LoginDetails1: any; LoginDetails: any
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    modalVisible: false;
    Age: any;
    BloodRequestDate: any;
    RequestTime: any;
    Purpose: any; letter: any;
    searchQuery: string;
    GalleryID: any;
    FullName: any;
    StateName: any;

    constructor(public generalService: GeneralService, public http: HttpClient, public fb: FormBuilder, private router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0) {
            this.letter = this.LoginDetails[0].FullName.charAt(0);
        } else if (this.LoginDetails && this.LoginDetails.FullName) {
            this.letter = this.LoginDetails.FullName.charAt(0);
        } else {
            this.letter = '';
        }
        
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    ngOnInit(): void {

        this.Getimages();


    }
   
    toggleDropdown() {
        const dropdownContent = document.getElementById("dropdownContent");
        if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
        } else {
            dropdownContent.style.display = "block";
        }
    }
    checkLoginStatus() {
        // Check if user is logged in (replace with your actual logic)
        // If user is logged in, set LoginDetails, otherwise set it to null
        if (this.LoginDetails != null) {
            this.LoginDetails = {

            };
        } else {
            this.LoginDetails = null;
        }
    }

    checkmobile(Mobile) {
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);


                var url = 'api/BG/checking_Mobile'

                this.generalService.PostData(url, UploadFile).then(data => {
                    //this.logindata = JSON.stringify(data[0]);
                    //localStorage.setItem("LoginDetails", this.logindata);
                    
                    if (data != 'NOTEXIST') {
                        
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;
                        this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);

                    }
                    else {

                        this.router.navigate(['/Donersform'])
                            // this.router.navigate(['/register', { Mobile: this.form.value.Mobile }])
                            .then(() => {
                                window.location.reload();
                            });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);
                    }
                })
            }
        }
    }
    openLoginModal() {
        // Show the modal here
        $('#myModal').modal('show');
    }
    SendOTPtoMobile() {
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.generalService.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
            })
        }
    }
    sharee()
    {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Joincampain']);
        }
    }
    //share() {
    //    if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
    //        // Route to login page
    //        this.router.navigate(['/Login']);
    //        return; // Stop further execution
    //    }
    //    else {
    //        this.router.navigate(['/blooddonation']);
    //    }
    //}

    share() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        } else {
            this.router.navigate(['/blooddonation'], { queryParams: { isshare: true } });
        }
    }

    closeModal() {
        this.modalVisible = false;
        $('#myModal').modal('hide');
    }
    signOut() {
        // Remove the login details from localStorage
        localStorage.removeItem('LoginDetails');
        window.location.reload();
    }

    Getimages() {
        this.isActive = 'bloodDonation';
        this.activeButton = 'bloodDonation';
        this.arr = []
        this.arr.push({
            

        });

        var UploadFile = new FormData();     
        UploadFile.append("Param1", '1');
        var url = "api/BG/Get_gallerycatwise";
        this.generalService.PostData(url, UploadFile).then(data => {
           
            this.dataResult = data;

        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    Getimages1() {
        this.isActive = 'oldAgeService';
        this.activeButton = 'oldAgeService';
        this.arr = [];
        this.arr.push({
            
        });

        var UploadFile = new FormData();
        UploadFile.append("Param1", '2');
        var url = "api/BG/Get_gallerycatwise";
        this.generalService.PostData(url, UploadFile).then(data => {
            this.dataResult = data;
            this.currentPage = 1; 
            this.startIndex = 0;  
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        });
    }

    Getimages2() {
        this.isActive = 'orphanServices';
        this.activeButton = 'orphanServices';
        this.arr = []
        this.arr.push({
           

        });

        var UploadFile = new FormData();
        UploadFile.append("Param1", '3');
        var url = "api/BG/Get_gallerycatwise";
        this.generalService.PostData(url, UploadFile).then(data => {
            this.dataResult = data;
            this.currentPage = 1; 
            this.startIndex = 0; 

        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    Getimages3() {
        debugger
        this.isActive = 'Campaign';
        this.activeButton = 'Campaign';
        this.arr = []
        this.arr.push({
            // ServicePrice: 0

        });

        var UploadFile = new FormData();
        UploadFile.append("Param1", '5');
        var url = "api/BG/Get_gallerycatwise";
        this.generalService.PostData(url, UploadFile).then(data => {
            this.dataResult = data;
            this.currentPage = 1; 
            this.startIndex = 0;  
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
   

    shareTo(platform: string) {
        let url = 'https://letshelp.breakingindiaapp.com/Gallery'; // Your page URL
        switch (platform) {
            case 'Twitter':
                url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
                break;
            case 'WhatsApp':
                url = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                break;
            case 'Facebook':
                url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
                break;
            default:
                break;
        }
        window.open(url, '_blank');
    }
 
    shareTo1(platform: string, imageUrl: string) {
        this.disableViewDetails = true;
        let shareUrl: string;
        if (platform === 'Facebook') {
            shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(imageUrl);
        } else if (platform === 'Twitter') {
            shareUrl = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(imageUrl);
        } else if (platform === 'WhatsApp') {
            shareUrl = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(imageUrl);
        }
        window.open(shareUrl, '_blank');
    }


    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.startIndex -= this.itemsPerPage;
        }
    }


    nextPage() {
        debugger
        let totalPages = Math.ceil(this.dataResult.length / this.itemsPerPage);
        if (this.currentPage < totalPages) {
            this.currentPage++;
            this.startIndex += this.itemsPerPage;
        }
    }

    getPages(): number[] {
        const totalPages = Math.ceil(this.dataResult.length / this.itemsPerPage);
        let startPage = Math.max(1, this.currentPage - 1); // Ensure startPage is at least 1
        let endPage = Math.min(startPage + 5, totalPages); // Show at most 3 pages

        // Adjust startPage if endPage is less than 3
        if (endPage - startPage < 5) {
            startPage = Math.max(1, endPage - 5);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    }

    setPage(page: number) {
        this.currentPage = page;
        this.startIndex = (page - 1) * this.itemsPerPage;
    }

    getPagedItems(): any[] {
        return this.dataResult.slice(this.startIndex, this.startIndex + this.itemsPerPage);
    }

    goToFirstPage() {
    this.setPage(1);
    }

  goToLastPage() {
      const totalPages = Math.ceil(this.dataResult.length / this.itemsPerPage);
      this.setPage(totalPages);
  }
   
    Gallerysearch(searchQuery: string) {
        this.arr = [];
        this.isSearching = true; // Set isSearching to true when search is initiated

        if (searchQuery && searchQuery.trim() !== '') {
            // Search for images based on the search query
            var UploadFile = new FormData();
            UploadFile.append("Param1", searchQuery);
            var url = "api/BG/Get_gallery_Search";
            this.generalService.PostData(url, UploadFile).then(data => {
                this.dataResult = data;
                this.currentPage = 1;
                this.startIndex = 0;
                this.isSearching = false; // Reset isSearching to false after search is completed
            }, err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
                this.isSearching = false; // Reset isSearching to false on error
            });
        } else {
            // Filter images based on the selected category
            let categoryId = 1; // Default category ID
            switch (this.isActive) {
                case 'bloodDonation':
                    categoryId = 1;
                    break;
                case 'oldAgeService':
                    categoryId = 2;
                    break;
                case 'orphanServices':
                    categoryId = 3;
                    break;
                case 'Campaign':
                    categoryId = 5;
                    break;
                default:
                    break;
            }

            var UploadFile = new FormData();
            UploadFile.append("Param1", categoryId.toString());
            var url = "api/BG/Get_gallerycatwise";
            this.generalService.PostData(url, UploadFile).then(data => {
                this.dataResult = data;
                this.currentPage = 1;
                this.startIndex = 0;
                this.isSearching = false; // Reset isSearching to false after filter is applied
            }, err => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
                this.isSearching = false; // Reset isSearching to false on error
            });
        }
    }


    autoSearch() {
        // Only trigger the search if the search input field is not focused
        if (!this.isSearchInputFocused) {
            if (this.searchQuery.length >= 1) {
                this.Gallerysearch(this.searchQuery);
            } else if (this.prevSearchQuery.length >= 1 && this.activeButton !== '') {
                this.isActive = this.activeButton;
                switch (this.activeButton) {
                    case 'bloodDonation':
                        this.Getimages();
                        break;
                    case 'oldAgeService':
                        this.Getimages1();
                        break;
                    case 'orphanServices':
                        this.Getimages2();
                        break;
                    case 'Campaign':
                        this.Getimages3();
                        break;
                    default:
                        break;
                }
            } else {
                this.isActive = ''; 
                this.Getimages(); 
            }
        }

        this.prevSearchQuery = this.searchQuery; // Update the previous search query
    }
    copyUrl(url: string) {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert('Image URL copied to clipboard');
    }

    copyPageUrl() {
        const url = window.location.href;
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        alert('image URL copied to clipboard');
    }
   
    viewDetails(GalleryID: any, FullName: any, StateName: any) {
        
        if (!this.disableViewDetails) {
            $('#myModal123').modal('show');
            var UploadFile = new FormData();
            UploadFile.append("Param1", GalleryID);
            var url = "api/BG/Gallery_View";
            this.generalService.PostData(url, UploadFile).then(data => {
                
                console.log("API-RESPONSE", data);
                this.GalleryID = data;
                this.GalleryImages = this.GalleryID[0].GalleryImages;
                this.FullName = FullName;
                this.StateName = StateName;
                
            }, err => {

                this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
            });
        }

    }

    close() {
        $('#myModal123').modal('hide');
    }

    flipToBack(event: any) {
        const container = event.currentTarget.closest('.image-container');
        if (container) {
            container.classList.add('flipped');
        }
        this.disableViewDetailsOnClick(); // Disable viewDetails click event
    }

    flipToFront(event: any) {
        const target = event.target;
        const isSocialIcon = target.classList.contains('fab') || target.closest('.fab');
        if (!isSocialIcon) { // Check if the target is not a social media icon
            const container = event.currentTarget.closest('.image-container');
            if (container) {
                container.classList.remove('flipped');
            }
            this.enableViewDetailsOnClick(); // Enable viewDetails click event
        }
    }
    disableViewDetailsOnClick() {
        this.disableViewDetails = true;
    }
    enableViewDetailsOnClick() {
        this.disableViewDetails = false;
    }

}




