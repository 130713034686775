import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'
declare var $: any;
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {
    arr: any[];
    countrydata: any;
    Orphanagedata: any;
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    observ: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    deptList4: any;
    deptList6: any;
    selectedState1: any;
    selectedState2: any;
    selectedState3: any;
    selectedState4: any;
    selectedState5: any;
    selectedState6: any;
    Areadata: any = [];
    State: any;
    distict: any;
    city: any;
    bloodgroup: any;
    area: any;
    lat = 20.5937;
    lng = 78.9629;
    zoom = 5;
    markers: any[] = []; numberdata: any; mobilenumber: any;
      NoDonors: boolean = false;
    pincode: any; selctedpincode: any;
    pincodedata: any; deptList5: any = [];
    currentPage: number = 1;
    itemsPerPage: number = 10;
    startIndex: number = 0;
    StateName: any; DistictName: any; CityName: any;
    Pincode: any; BloodGroup: any;
   
    formData: any;

    public form: FormGroup;
    public form1: FormGroup;
    HsptName: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    dropdownActive: boolean = false; unitsofblood: any = [];
  selectedItem1: any;
    selectedGender: string | null = null;
    Startdate: any; fromDate: any; mindate: any; toDate: any;
    logindata: any;
    RegId: any; LoginDetails: any;
    LoginDetails1: any;
    selectedaudi: string;
    deptList8: any = [];
    addextra: any;
    baseurl: string;
    arr2: any[];
    Email: any;
    FullName: any;
    submissionCount: number = 0;
    Messagetorequester: any;
    showNote: boolean;
    isTitleVisible: boolean=false;
    constructor(public genralservice: GeneralService, public fb: FormBuilder, public http: HttpClient, public router: Router, private route: ActivatedRoute) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        this.selectedState1 = localStorage.getItem("StateName");
        this.selectedState2 = localStorage.getItem("DistictName");
        this.selectedState3 = localStorage.getItem("CityName");
        this.selctedpincode = localStorage.getItem("PinCode");
        this.selectedState5 = localStorage.getItem("BloodGroup");
        this.form = this.fb.group({
            // Define your form controls here
            contactperson: ['',],
            username: ['', Validators.required],
            Contmobile: ['', Validators.required],
            email: ['', Validators.email],
            Gender: ['',],
            age: [''],
            BloodRequestDate: ['',],
            RequestTime: ['',],
            unitsofblood: [''],
            bloodgroup: [''],
            Purpose: ['',],
            useraddress: [''],
            area: [''],
            DoctorName: ['',],
            HsptName: ['',],
            HospitalPhonenumber: ['',],
            HospitalAddress: ['',],
            statename: [''],
            districtname: [''],
            cityname: ['',],
            Pincode: ['',],
            PersonalNote: ['',],
            requestid: ['',],
        });
        this.form1 = this.fb.group({
            // Define your form controls here
            FullName: ['',],
            ObservationReason: ['', Validators.required],
            Comments: ['', Validators.required],
        });
        
        const storedFormData = localStorage.getItem('formData');
        if (storedFormData) {
            this.formData = JSON.parse(storedFormData);
            this.setFormData();
        }
    }

    openLoginModal() {
        $('#myModal1').modal('show');
    }

    ngOnInit(): void {
        this.GetHospitals();
        this.getunitofblood();
       this.getobserv();
        this.getstate();
        this.checkSubmissionCount();
        this.getBloodgroup();
        this.GetPincode();
     
        this.getAreas();
        this.DataFilter();     
        this.route.queryParams.subscribe(params => {
            console.log(params); // Check if query parameters are received
            this.applyFilters(
                params.State.trim(),
                params.district.trim(),
                params.city.trim(),
                params.BloodGroup.trim(),
                params.pincode.trim()

                );

        });

        this.route.queryParams.subscribe(params => {
            debugger
            console.log(params); // Check if query parameters are received
            //this.State = params.State.trim(); // Trim whitespace
            //this.distict = params.district.trim();
            //this.city = params.city.trim();
            //this.bloodgroup = params.BloodGroup.trim();
            //this.pincode = params.pincode.trim();
            this.State = params.State ? params.State.trim() : '';
            this.distict = params.district ? params.district.trim() : '';
            this.city = params.city ? params.city.trim() : '';
            this.bloodgroup = params.BloodGroup ? params.BloodGroup.trim() : '';
            this.pincode = params.pincode ? params.pincode.trim() : '';

            this.selectedState1 = { StateName: this.State };
            this.selectedState2 = { DistrictName: this.distict };
            this.selectedState3 = { CityName: this.city };
            this.selctedpincode = { Pincode: this.pincode };
            this.selectedState5 = { BLGName: this.bloodgroup };
            //this.applyFilters(this.State, this.distict, this.city, this.bloodgroup, this.Pincode);
            this.DonorFilterData();
        });
    

    }

 applyFilters(State: string, district: string, city: string, bloodgroup: string, pincode: string) {
    var UploadFile = new FormData();
    UploadFile.append("Param1", State);
    UploadFile.append("Param2", district);
    UploadFile.append("Param3", city);
    UploadFile.append("Param4", pincode);
    UploadFile.append("Param5", bloodgroup);
    UploadFile.append("Param6", '');

    var url = "api/BG/Get_Searchdetails";
    this.genralservice.PostData(url, UploadFile).then((data: any[]) => {
        if (data == null || (Array.isArray(data) && data.length === 0)) {
            this.NoDonors = true;
            this.Orphanagedata = [];
        } else {
            this.NoDonors = false;
            this.Orphanagedata = data.filter((item: any) => {
                return (
                    item.StateName.toLowerCase().includes(State.toLowerCase()) &&
                    item.DistrictName.toLowerCase().includes(district.toLowerCase()) &&
                    item.CityName.toLowerCase().includes(city.toLowerCase()) &&
                    item.BLGName.toLowerCase().includes(bloodgroup.toLowerCase()) &&
                    item.Pincode.toLowerCase().includes(pincode.toLowerCase())
                );
            });

           
        }
    }, err => {
        this.NoDonors = true;
        this.genralservice.ShowAlert('ERROR', 'Something went wrong', 'error');
    });
}

    DataFilter() {
        
        var UploadFile = new FormData()
        UploadFile.append("Param1", this.selectedState1);
        UploadFile.append("Param2", this.selectedState2);
        UploadFile.append("Param3", this.selectedState3);
        UploadFile.append("Param4", this.selctedpincode);
        UploadFile.append("Param5", this.selectedState5);
        UploadFile.append("Param6", '');
        var url = "api/BG/Get_Searchdetails";
        this.genralservice.PostData(url, UploadFile).then((data: any) => {
            
            if (data == null || (Array.isArray(data) && data.length === 0)) {
                this.NoDonors = true;
                alert("No donors are available.")
                this.Orphanagedata = [];
            } else {
                this.Orphanagedata = data;

            }
          //  this.NoDonors = true;
        })
    }

    DonorFilterData() {
        debugger
        this.State = this.selectedState1 ? this.selectedState1.StateName : '';
        this.distict = this.selectedState2 ? this.selectedState2.DistrictName : '';
        this.city = this.selectedState3 ? this.selectedState3.CityName : '';
        this.bloodgroup = this.selectedState5 ? this.selectedState5.BLGName : '';
        this.Pincode = this.selctedpincode ? this.selctedpincode.Pincode : '';
        var UploadFile = new FormData()
        UploadFile.append("Param1", this.State );
        UploadFile.append("Param2", this.distict);
        UploadFile.append("Param3", this.city);
        UploadFile.append("Param4", this.Pincode);
        UploadFile.append("Param5", this.bloodgroup);
        UploadFile.append("Param6", '');
        var url = "api/BG/Get_Searchdetails";
        this.genralservice.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data == null || (Array.isArray(data) && data.length === 0)) {
                this.NoDonors = true;
                alert("No donors are available.")
                this.Orphanagedata = [];
            } else {
                this.Orphanagedata = data;
                const emailsWithNames = this.Orphanagedata.map((donor: any) => ({ email: donor.Email, fullName: donor.FullName }));
                this.send_Mail1(emailsWithNames);

                this.NoDonors = false;
            }
            
        })
    }


    send_Mail1(emailsWithNames: { email: string; fullName: string }[]) {
        debugger;
        this.baseurl = "https://letshelp.breakingindiaapp.com/webservices";

        // Extract the top 10 emails with names
        const top10EmailsWithNames = emailsWithNames.slice(0, 10);

        // Iterate over each email with name in the top 10 array
        top10EmailsWithNames.forEach(({ email, fullName }) => {
            // Create an array with current email and full name
            const emailArr = [{ Email: email, FullName: fullName }];

            // Create FormData for this email
            var uploadFile = new FormData();
            uploadFile.append("Blood", JSON.stringify(emailArr));
            uploadFile.append("url", this.baseurl);

            // Send the email data
            var url = "api/BG/MailPlaces";
            this.genralservice.PostData(url, uploadFile).then((data: any) => {
                // Handle the response if needed
            });
        });
    }



    refresh() {
        
        this.loadDefaultData();
        // Clear dropdown selections
        this.selectedState1 = null;
        this.selectedState2 = null;
        this.selectedState3 = null;
        this.selctedpincode = null;
        this.selectedState5 = null;
    }



    loadDefaultData() {
        
        var UploadFile = new FormData();
        UploadFile.append("Param1", '');
        UploadFile.append("Param2", '');
        UploadFile.append("Param3", '');
        UploadFile.append("Param4", '');
        UploadFile.append("Param5", '');
        UploadFile.append("Param6", '');

       
    }
    toggleMessageVisibility(event: MouseEvent): void {
        const icon = event.currentTarget as HTMLElement;
        icon.classList.toggle('clicked');
    }
    showMessage(event: MouseEvent): void {
        // Toggle the 'clicked' class to show or hide the message
        const icon = event.currentTarget as HTMLElement;
        icon.classList.toggle('clicked');
    }
    getstate() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }
    onStateSelect1(event) {
        console.log('Selected State:', this.selectedState1);
        // You can perform additional actions with the selected state here
    }
    onStateSelect2(event) {
        console.log('Selected Distict:', this.selectedState2);
        // You can perform additional actions with the selected state here
    }
    onStateSelect3(event) {
        console.log('Selected City:', this.selectedState3);
        // You can perform additional actions with the selected state here
    }
    onSelctedpincode(event) {
        console.log('Selected Pincode:', this.selctedpincode);
        // You can perform additional actions with the selected state here
    }
    onStateSelect5(event) {
        console.log('Selected BloodGroup:', this.selectedState5);
        // You can perform additional actions with the selected state here
    }
    onStateSelect6(event) {
        console.log('Selected Area:', this.selectedState6);
    }


    getAreas() {

        var url = "api/BG/Get_Areass";
        this.genralservice.GetData(url).then(data => {
            
            this.Areadata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    searchCont6(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList6 = [];
            for (var i = 0; i < this.Areadata.length; i++) {
                let country = this.Areadata[i];
                if (country.Area.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList6 = filtered;
        }
    }
    getDistrict() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.selectedState1.StateId,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.selectedState2.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getBloodgroup() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BloodGroupMaster_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.bloodgroupdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont3(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }


    // select country
    getcountry() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.countrydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont4(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList4 = [];
            for (var i = 0; i < this.countrydata.length; i++) {
                let country = this.countrydata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList4 = filtered;
        }
    }
    GetHospitals() {

        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails[0]?.RegId || this.LoginDetails?.RegId,
            TokenId: this.LoginDetails[0]?.TokenId || this.LoginDetails?.TokenId || 0,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '6');
        var url = "api/BG/HospitalDetails_CRUD";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.HsptName = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }
    searchCont8(event) {

        let filtered: any = [];
        let query = event.query;
        {

            this.deptList3 = [];
            for (var i = 0; i < this.HsptName.length; i++) {

                let country = this.HsptName[i];
                if (country.HsptName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList8 = filtered;
        }
    }
    //addNewTrip(addextra) {

    //    //this.trip != newTrip
    //    this.addextra = 2;
    //}
    //Addhospital(value) {

    //    const Flag = '7';


    //    var arr = [];

    //    arr.push({
    //        RegId: this.LoginDetails[0].RegId,
    //        TokenId: this.LoginDetails[0].TokenId,
    //        HsptID: this.form.value.HsptName.HsptID,
    //        HsptName: this.form.value.HsptName,
    //    });

    //    var UploadFile = new FormData();
    //    UploadFile.append("Param", JSON.stringify(arr));
    //    UploadFile.append("Flag", "7");
    //    var url = "api/BG/HospitalDetails_CRUD";

    //    this.genralservice.PostData(url, UploadFile).then(data => {

    //        if (data == "SUCCESS") {
    //            if (Flag == '7') {
    //                this.genralservice.ShowAlert('SUCCESS', 'Hospital added successfully', 'success');
    //            } else {
    //                this.genralservice.ShowAlert('SUCCESS', 'Hospital updated successfully', 'success');
    //            }
    //            // Reload the form

    //            this.form.reset();
                
    //        }
    //        if (data == "Exist") {
    //            this.genralservice.ShowAlert('Exist', 'Data Already Exist', 'success');
    //        }
    //    }, error => ({
    //        title: 'ERROR',
    //        text: 'Something went wrong. Please try again later.',
    //        type: 'error',
    //        confirmButtonText: 'Ok'
    //    })).then(() => {
    //        // Reload the page
    //        // window.location.reload();
    //        this.addextra = 1;
    //        this.GetHospitals();
    //    });
    //}
    phonenum(val: any) {
   
        //  this.Id = rowData.Id
        var UploadFile = new FormData();
        UploadFile.append("Param", val);

        var url = "api/BG/Get_Phonenumber";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.numberdata = data;
            this.mobilenumber = this.numberdata[0].Phonenumber;
            this.Email = this.numberdata[0].Email;
            this.FullName = this.numberdata[0].FullName;
            this.Messagetorequester = this.numberdata[0].Messagetorequester;

            debugger
            // Check if the middle three digits of the phone number are "xxx"
            if (this.mobilenumber.includes('xxxx')) {
                // Render the <p> element or perform any other necessary actions
                // For example, you can set a boolean flag to indicate whether to render the <p> element in your template
                this.showNote = true;
            } else {
                // Hide the <p> element or perform any other necessary actions
                // For example, you can set the flag to false
                this.showNote = false;
            }
                // Open the modal if the middle three digits are "xxx"
                $('#phoneNumberModal').modal('show');
       

        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }


    openModal(val: any) {
        
        //  this.Id = rowData.Id
        var UploadFile = new FormData();
        UploadFile.append("Param", val);

        var url = "api/BG/Get_Phonenumber";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.numberdata = data;
            this.mobilenumber = this.numberdata[0].Phonenumber;
            this.form1.get('FullName').setValue(this.numberdata[0].FullName);
            this.Email = this.numberdata[0].Email;
            this.FullName = this.numberdata[0].FullName;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })


        $('#phoneNumberModal1').modal('show');
    }

    selectaudi(Audiance: string) {
        
        this.selectedaudi = Audiance;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }

    GetPincode() {
        
        var url = "api/BG/Get_PincodeDropdown";
        this.genralservice.GetData(url).then(data => {
            this.pincodedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')
        })
    }

    Selectcount5(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList5 = [];
            for (var i = 0; i < this.pincodedata.length; i++) {
                let country = this.pincodedata[i];
                if (country.Pincode.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }
            this.deptList5 = filtered;
        }
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.startIndex -= this.itemsPerPage;
        }
    }

    nextPage() {
        let totalPages = Math.ceil(this.Orphanagedata.length / this.itemsPerPage);
        if (this.currentPage < totalPages) {
            this.currentPage++;
            this.startIndex += this.itemsPerPage;
        }
    }

    getPages(): number[] {
        const pageCount = Math.ceil(this.Orphanagedata.length / this.itemsPerPage);
        return Array.from({ length: pageCount }, (_, i) => i + 1);
    }

    setPage(page: number) {
        this.currentPage = page;
        this.startIndex = (page - 1) * this.itemsPerPage;
    }

    getPagedItems(): any[] {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.Orphanagedata.slice(startIndex, endIndex);
    }

    goToFirstPage() {
        this.setPage(1);
    }

    goToLastPage() {
        const totalPages = Math.ceil(this.Orphanagedata.length / this.itemsPerPage);
        this.setPage(totalPages);
    }






    register() {
        this.router.navigate(['/blooddonation'], { queryParams: { Visible: true } });

    }
    setFormData() {
        
        // Set form values from stored data
        this.form.patchValue({
            contactperson: this.formData[0].ContactPerson,
            Contmobile: this.formData[0].ContactMobile,
            bloodgroup: this.formData[0].BLGName,
            BloodRequestDate: this.formData[0].BloodRequestDate,
            RequestTime: this.formData[0].RequestTime,
            // Set other form values similarly...
      
            HsptName: this.formData[0].HsptName,
            statename: this.formData[0].StateName,
            districtname: this.formData[0].DistrictName,
            cityname: this.formData[0].CityName,
            area: this.formData[0].Area,
            PersonalNote: this.formData[0].Note,
            Pincode: this.formData[0].Pincode,
            requestid: this.formData[0].requestid
        });
    }

    //request  form
    Requestform() {
        debugger
        if (!this.form.value.contactperson || !this.form.value.Contmobile || !this.form.value.bloodgroup
            || !this.form.value.HospitalAddress || !this.form.value.unitsofblood || !this.form.value.BloodRequestDate || !this.form.value.RequestTime)
        {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }
        if (this.submissionCount >= 10) {
            this.genralservice.ShowAlert("ERROR", 'You have submitted  not more than 10 times. It will be enabled again tomorrow.', 'error');
            return;
        }
        let HsptName;
        if (this.form.value.HsptName) {
            // Check if HsptName is not empty
            let hospitalNameParts;
            if (typeof this.form.value.HsptName === 'string') {
                // If HsptName is a string, directly split it
                hospitalNameParts = this.form.value.HsptName.split(' - ');
            } else if (typeof this.form.value.HsptName.HsptName === 'string') {
                // If HsptName is an object with HsptName property as string, split that
                hospitalNameParts = this.form.value.HsptName.HsptName.split(' - ');
            }

            if (hospitalNameParts && hospitalNameParts.length >= 2) {
                // Ensure that HsptName can be split into parts
                HsptName = hospitalNameParts[0]; // Assign the split result to HsptName
            } else {
                // Handle the case where split result is not as expected
                console.error('Unexpected format for HsptName:', this.form.value.HsptName);
                // Add appropriate error handling or fallback behavior here
            }
        } else {
            // Handle the case where HsptName is empty
            console.error('HsptName is empty');
            // Add appropriate error handling or fallback behavior here
        }


        this.arr = [];
        this.arr.push({
            RegId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
            ContactPerson: this.form.value.contactperson,
            FullName: this.form.value.username,
            ContactMobile: this.form.value.Contmobile,
            Email: this.form.value.email,
            Gender: this.selectedGender,
            age: this.form.value.age,
            UnitsofBloodId: this.form.value.unitsofblood.UnitsofBloodId,
            UserAddress: this.form.value.useraddress,
            Area: this.form.value.area,
            BloodRequestDate: this.form.value.BloodRequestDate,
            RequestTime: this.form.value.RequestTime,
            DoctorName: this.form.value.DoctorName,
            Purpose: this.form.value.Purpose,
            HsptName: HsptName,
            HospitalPhonenumber: this.form.value.HospitalPhonenumber,
            HospitalAddress: this.form.value.HospitalAddress,
            BloodGroupId: this.form.value.bloodgroup.BLGId,
            StateId: this.form.value.statename ? this.form.value.statename.StateId : null,
            DistrictId: this.form.value.districtname ? this.form.value.districtname.DistrictID : null,
            CityId: this.form.value.cityname ? this.form.value.cityname.CityId : null,

           // StateId: this.form.value.statename.StateId ,
            //DistrictId: this.form.value.districtname.DistrictID,
            //CityId: this.form.value.cityname.CityId,
            Note: this.form.value.PersonalNote,
            Pincode: this.form.value.Pincode,
            requestid: 1,

        })
        localStorage.setItem('formData', JSON.stringify(this.arr));
        var uploadfile = new FormData()
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/BG/Insert_Update_requestForm";
        this.genralservice.PostData(url, uploadfile).then(data => {
            debugger
            if (data == "SUCCESS") {
                if (this.flag == 1) {
                    this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');
                } else {
                    this.genralservice.ShowAlert("SUCCESS", 'Blood Request Raised Succesfully,Please Wait Untill Admin Approve', 'success');
                }
               // this.addextra = 1;
                this.router.navigate(['/Userdetails']);
                $('#phoneNumberModal').modal('hide');

                // Call send_Mail() after processing the request form
                this.submissionCount++;
                localStorage.setItem('submissionCount', this.submissionCount.toString());
                const hsptName = this.extractHospitalName(this.form.value.HsptName);

                this.send_Mail(this.arr, this.arr2, hsptName );
                   
            } else {
                this.genralservice.ShowAlert("ERROR", 'Some thing went wrong', 'error');
            }
        });
    }
    extractHospitalName(hsptName: any): string {
        if (!hsptName) return "";
        if (typeof hsptName === 'string') {
            return hsptName.split(' - ')[0];
        } else if (typeof hsptName.HsptName === 'string') {
            return hsptName.HsptName.split(' - ')[0];
        } else {
            console.error('Unexpected format for HsptName:', hsptName);
            // Handle the unexpected format
            return "";
        }
    }
    send_Mail(arr1: any[], arr2: any[], hsptName: string) {
        debugger
        this.baseurl = "https://letshelp.breakingindiaapp.com/webservices";
        this.arr2 = [];
        this.arr2.push({ ////SPR details
            ContactPerson: this.form.value.contactperson,
            ContactMobile: this.form.value.Contmobile,
           // Email: this.form.value.email,
            unitsofblood: this.form.value.unitsofblood.UnitsofBlood,
            Area: this.form.value.area,
            HsptName: hsptName,
            HospitalAddress: this.form.value.HospitalAddress,
            BloodRequestDate: this.form.value.BloodRequestDate,
            BLGName: this.form.value.bloodgroup.BLGName,
            Pincode: this.form.value.Pincode,
           
            StateName: this.form.value.statename ? this.form.value.statename.StateName : '',
            DistrictName: this.form.value.districtname ? this.form.value.districtname.DistrictName : '',
            CityName: this.form.value.cityname ? this.form.value.cityname.CityName : '',

            Email: this.Email,
            FullName: this.FullName,
         

        });

        var UploadFile = new FormData();
        UploadFile.append("Blood", JSON.stringify(this.arr2));
        UploadFile.append("url", this.baseurl);
     var url = "api/BG/MailPlaceOrder";
        this.genralservice.PostData(url, UploadFile).then((data: any) => {
            // Handle the response if needed
        });
    }
    Requestform1() {
    debugger
        // Check if form fields are filled
        if (!this.form1.value.Comments || !this.form1.value.ObservationReason?.ObservationReason) {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }

     
        const formData = {
            RegId: this.numberdata[0].RegId,
            Observation: this.form1.value.ObservationReason.ObservationReason,
            Comments: this.form1.value.Comments,
            CreatedBy: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
        };

        // Submit data
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify([formData]));
        uploadfile.append("Flag", '1');
        var url = "api/BG/Insert_Update_Report";
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data === "1" || data === "0") {
                // Execute this block only when data is "1" or an empty string
                this.genralservice.ShowAlert("SUCCESS", 'Report Raised Successfully', 'success');
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong', 'error');
            }

            this.router.navigate(['/Userdetails']);
            $('#phoneNumberModal1').modal('hide');
            if (data == "1") {
                this.send_observation([formData], this.arr2);
            }

            // Increment submission count and save
          //  this.submissionCount++;
           // localStorage.setItem('submissionCount', this.submissionCount.toString());

        });
    }
    checkSubmissionCount() {
        // Retrieve submission count from local storage or server
        const savedSubmissionCount = localStorage.getItem('submissionCount');

        // Check if savedSubmissionCount exists and if it's from today
        const savedDate = localStorage.getItem('submissionDate');
        const currentDate = new Date().toLocaleDateString();

        if (savedSubmissionCount && savedDate === currentDate) {
            this.submissionCount = parseInt(savedSubmissionCount, 10);
        } else {
            // Reset submission count for a new day
            this.submissionCount = 0;
            localStorage.setItem('submissionCount', '0');
            localStorage.setItem('submissionDate', currentDate);
            // Re-enable the form
            this.form1.enable();
            this.form.enable();
        }
    }

    send_observation(arr1: any[], arr2: any[]) {
        debugger
        this.baseurl = "https://letshelp.breakingindiaapp.com/webservices";
        this.arr2 = [];
        this.arr2.push({ ////SPR details
            Observation: this.form1.value.ObservationReason.ObservationReason,
            Comments: this.form1.value.Comments,
            Email: this.Email,
            FullName: this.FullName

        });

        var UploadFile = new FormData();
        UploadFile.append("Blood", JSON.stringify(this.arr2));
        UploadFile.append("url", this.baseurl);
        var url = "api/BG/MailtoDonar";
        this.genralservice.PostData(url, UploadFile).then((data: any) => {
            // Handle the response if needed
        });
    }
    getobserv() {
        
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BG_Observation_Crud";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.observ = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont9(event) {
      
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.observ.length; i++) {
                let country = this.observ[i];
                if (country.ObservationReason.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList6 = filtered;
        }
    }
  
 
    getunitofblood() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/UnitsofBlood_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.unitsofblood = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont41(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.unitsofblood.length; i++) {
                let country = this.unitsofblood[i];
                if (country.UnitsofBlood.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList4 = filtered;
        }
    }

    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }

    toggleDropdown() {
        this.showDropdownContent = !this.showDropdownContent;
    }

    toggleDropdownActive() {
        this.dropdownActive = !this.dropdownActive;
    }

    back() {
        this.router.navigate(['/blooddonation']);
    }

    selectOption1(gender: string) {
        this.form.value('Gender').setValue(gender === '1' ? 'Female' : 'Male');
    }

    getChipStyle1(option: string): any {
        return {
            'background-color': this.selectedItem1 === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem1 === option ? 'white' : 'black',
            'border-color': this.selectedItem1 === option ? 'green' : '#ccc'
        };
    }

    selectGender(gender: string) {
        this.selectedGender = gender;
        // Optionally call your Interior function with the `id`
        // this.Interior(id);
    }

    toggleStatus(checked: number) {
        this.form.get('requestid').setValue(checked ? 1 : 0);
    }

    //request form

  
    getDistrict1() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont11(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity1() {

        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
           
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont21(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }
}
