<div class=" ptb-100">
    <div class="container-fluid" style="background-color:red;height:100px;">
        <div class="container ">
            <div class="row">
                <div class="col-lg-3">
                    <a routerLink="/">
                        <img src="../../../../assets/img/Blood_DonationImages/LETS HELP LOGO-02.png" class="logo" />
                    </a>
                </div>
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-lg-8">
                            <h3 class="title">Share Your Service</h3>

                        </div>
                        <div class="col-lg-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="circle">
                        <div class="letter">{{LoginDetails.FullName.charAt(0)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div><br />
     
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    Sharing your experience and your service is always a reminder and motivitating
                    factor to someone so, please donot hesitate to share your services below
                </p>
                <p>
                    It will be desiplayed on the main page of blood donation and its always saved in our
                    lets-fight data base
                </p><br />
            </div>
            <div class="col-lg-3"></div>

            <div class="col-lg-6 ">

                <div class="card">
                    <form [formGroup]="form" (ngSubmit)="AddBanner(form.value, 1)">
                        <div class="row">

                            <div class="col-lg-12">
                                <label for="category">Category<span style="color:red">*</span></label>
                                <br /><select id="category" formControlName="Category">
                                    <option value="Blood Donation">Blood Donation</option>
                                    <option value="Old Age Home">Old Age Home</option>
                                    <option value="Orphanage">Orphanage</option>
                                </select>
                                <small style="color:red" class="text-danger" *ngIf="form.controls.Category.touched && form.controls.Category.errors?.required">Category is required</small>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-12 ">
                                <label for="username"> Upload Picture<span style="color:red">*</span></label>
                                <input formControlName="GalleryImages" input type="file" (change)="detectFiles2(input2)" accept="image/*" #input2 class="m-img-upload-btn mt-n4 input" />
                                <div style="color: red" class="text-danger"><b>Note:Image dimensions 398x150 are preferable.</b></div>
                            </div>
                        </div>
                        <br />
                        <div class="row">

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Date of service<b><span style="color:red;">*</span></b></label>
                                    <div class="input-group">
                                        <!--<div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i>
                                            </div>
                                        </div>-->
                                        <input class="form-control notnull txtDate " formControlName="Dateofservice" required="" type="date" name="JoinedOn" id="JoinedOn">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>



                    <form [formGroup]="form" (ngSubmit)="AddBanner(form.value, 1)">

                        <div class="row">


                            <div class="col-lg-12">
                                <label>State Name<span style="color:red">*</span></label>
                                <div style="padding-left:0px">

                                    <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                    field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                    placeholder="Search State Name" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                    </p-autoComplete>

                                    <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                           class="p-error">State Name is required.</small>
                                </div>

                            </div>
                        </div> <br />

                        <div class="row">

                            <div class="col-lg-12">
                                <label>District Name<span style="color:red">*</span></label>
                                <div style="padding-left:0px">
                                    <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                    field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                    placeholder="Search District Name" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                    </p-autoComplete>

                                    <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                           class="p-error">District Name is required.</small>
                                </div>
                            </div>

                        </div> <br />

                        <div class="row">

                            <div class="col-lg-12">
                                <label>city Name<span style="color:red">*</span></label>
                                <div style="padding-left:0px">
                                    <p-autoComplete formControlName="cityname"
                                                    [suggestions]="deptList2"
                                                    (completeMethod)="searchCont2($event)"
                                                    field="CityName"
                                                    [dropdown]="true"
                                                    [size]="120"
                                                    placeholder="Search City Name"
                                                    [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                    </p-autoComplete>


                                    <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                           class="p-error">City name is required.</small>
                                </div>

                            </div>

                        </div>
                        <br />
                        <div class="row">

                            <div class="col-lg-12">
                                <label for="useraddress">Name of institution</label>
                                <input type="text" id="Institutionname" formControlName="Institutionname" placeholder="Name of Institution" />
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-lg-12">
                                <label for="area">Message your personal note </label><br />
                                <textarea id="area" formControlName="area" placeholder="Enter Your Description here"></textarea>
                                <br />  <button class="btn">Submit</button>

                            </div>
                        </div>

                        <br />
                        <div style="text-align: center;">
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>
    <br />


</div>
<br />


