<div class="container web_menu">
    <div class="row">
        <div class="col-lg-6" style="width: 50%;">
            <div class="bg-red--light cardreabout bg_1 card-hover">
                <a href="" class="logo-link zoom-in">
                    <img src="assets/svg/logo.png" class="logo-image">
                    <h2 class="heading headdding">About Us</h2>
                </a>
                <div class="card-bg">
                    <div class="card-block">
                        <h3 class="card-title">A Brief Information</h3>
                        <p class="card-text"><i class="icon-chevron-right"></i></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6" style="width: 50%;">
            <div class="bg-red--darker cardre bg_1 card-hover">
                <div style="width: 100%;">
                    <h2 class="heading">Register for Donating Blood</h2>
                    <div class="card-bg">
                        <div class="card-block">
                            <h3 class="card-title">Save Lives</h3>
                            <p class="card-text"><i class="icon-chevron-right"></i></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    



    <div class="col-lg-3 bg-red cardpre">
        <a routerLink="/Prerequisites">
            <div class="">
                <h2 class="heading">Pre-Requisites for Donating Blood</h2>
                <div class="card-bg">
                    <div class="card-block">
                        <h3 class="card-title">Basic but important</h3>
                        <p class="card-text"> <i class="icon-chevron-right"></i></p>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-lg-3 bg-red--dark cardvol" style="background-color:#a00105">
        <a href="">
            <div class="">
                <h2 class="heading">Volunteer</h2>
                <div class="card-bg">
                    <div class="card-block">
                        <h3 class="card-title">Empower Us</h3>
                        <p class="card-text"> <i class="icon-chevron-right"></i></p>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-lg-3 bg-red--darker cardterm">
        <a routerLink="/Termsandconditions">
            <div class="">
                <h2 class="heading"> Terms of Use and Disclaimer</h2>
                <div class="card-bg">
                    <div class="card-block">
                        <h3 class="card-title">A must read</h3>
                        <p class="card-text"> <i class="icon-chevron-right"></i></p>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-lg-3 bg-red cardjio">
        <a routerLink="/Joincampain">
            <div class="">
                <br />
                <h2 class="heading"> Join Campaign</h2>
                <div class="card-bg">
                    <div class="card-block">
                        <h3 class="card-title">Work With Us</h3>
                        <p class="card-text"> <i class="icon-chevron-right"></i></p>
                    </div>
                </div>
            </div>

        </a>
    </div>

    <div class="col-lg-3  bg-red--light cardcon">
        <a routerLink="/contact">
            <div class="">
                <h2 class="heading">Contact</h2>
                <div class="card-bg">
                    <div class="card-block">
                        <h3 class="card-title">Get In Touch</h3>
                        <p class="card-text"> <i class="icon-chevron-right"></i></p>
                    </div>
                </div>
            </div>

        </a>

    </div>
    <div class="col-lg-3">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <br /><br /><br /><br /><br />
                <div class="buttons">Newsletter</div>

            </div>
            <div class="col-lg-2"></div>
        </div>
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <ul class="social-links social-links--nav">
                    <li class="icons_social" style="width:50px;height:50px;border:2px solid white">
                        <a target="_blank" href="" title="twitter">
                            <!--<i class="icon-twitter zoom-in"></i>-->
                            <img src="../../../assets/img/twitternew.png" />
                        </a>
                    </li>
                    <li style="width:50px;height:50px;border:2px solid white" class="icons_social">
                        <a target="_blank" href="" title="facebook" style="background:#3b5998">
                            <i class="icon-facebook zoom-in"></i>
                        </a>
                    </li>
                    <li style="width:50px;height:50px;border:2px solid white" class="icons_social">
                        <a target="_blank" href="" title="instagram">
                            <!--<i class="icon-instagram zoom-in"></i>-->
                            <img src="../../../assets/img/instagram.png" />
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2"></div>
        </div>

    </div>
</div>
</div>
