import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
@Component({
    selector: 'app-hospital',
    templateUrl: './hospital.component.html',
    styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent {
    activeCardIndex: number = -1;
    public form: FormGroup;
    arr: any = []; profiledata: any;
    districtdata: any = [];
    deptList: any;
    flag: any; data: any[] = [];
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    GalleryImages: any;
    imagefile2: any;
    HomeUrl: any;
    GalleryID: any;
    Form: FormGroup;
    LoginDetails1: any; LoginDetails: any
    HsptName: any = []; deptList3: any;
    RegId: any;
    addextra: any;

    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    modalVisible: false;
    Age: any;
    BloodRequestDate: any;
    RequestTime: any;
    Purpose: any;
    letter: any;
    HsptID: any;
    hstid: any;
    sid: any;
    ddid: any;
    ccid: any;
    pcname: any;
    hspa: any;
    cty: any;
    pic: any;
    dit: any;
    stn: any;
    dct: any;
    hptn: any;
    pom: any;
    pccc: any;
    filteredDeptList: any[];
    ButtonText: any;
    constructor(public genralservice: GeneralService, public http: HttpClient, public fb: FormBuilder, private router: Router) {

        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0) {
            this.letter = this.LoginDetails[0].FullName.charAt(0);
        } else if (this.LoginDetails && this.LoginDetails.FullName) {
            this.letter = this.LoginDetails.FullName.charAt(0);
        } else {
            this.letter = '';
        }
        this.form = this.fb.group({
            HsptName: ['', Validators.required],
            HsptAddress: [''],
            HsptPhNum: [''],
            statename: [''],
            districtname: [''],
            cityname: [''],
            POCName: [''],
            Pincode: ['', Validators.required],
            POC_ContactNumber: [''],
            DoctorName: [''],
            CityExtraName:[''],
        });


    }
    ngOnInit(): void {
        this.GetHospitals();
        this.getstate();
        this.getDistrict();
        this.getCity();

    }
    getstate() {
        
        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails[0].RegId,
            TokenId: this.LoginDetails[0].TokenId,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
       
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    filterDepts(event) {
        const query = event.query;
        this.filteredDeptList = this.deptList3.filter(dept => dept.HsptName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }


    getDistrict() {
        
        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails[0].RegId,
            TokenId: this.LoginDetails[0].TokenId,
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }


    refresh() {
        this.form.reset();
        window.location.reload();
    }
    getCity() {
     
        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails[0].RegId,
            TokenId: this.LoginDetails[0].TokenId,
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
          
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }



    GetHospitals() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: this.LoginDetails[0].RegId ,
            TokenId: this.LoginDetails[0].TokenId ,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '6');
        var url = "api/BG/HospitalDetails_CRUD";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.HsptName = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }
    searchCont3(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {

            this.deptList3 = [];
            for (var i = 0; i < this.HsptName.length; i++) {

                let country = this.HsptName[i];
                if (country.HsptName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }



    onDropdownSelect(value: any) {
     
        console.log("Dropdown item selected:", value);
        if (value && value.value.HsptID) {
            this.hstid = value.value.HsptID;
            this.flag = 2;
            this.GetHospitalsfilter();
            //this.GetHospitalsfilter(value.value.HsptID);
        } else {
            console.error("Invalid selected item:", value);
        }
    }




    GetHospitalsfilter() {
      
        // No need to pass HsptID to an array, you can directly use it here
        var UploadFile = new FormData();
        UploadFile.append("Param1", this.hstid);
        var url = "api/BG/Get_Hospital";
        this.genralservice.PostData(url, UploadFile).then(data => {
           
            this.HsptName = data;
            this.bindFormDatas(this.HsptName);
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }
    bindFormDatas(data) {
     
        if (data) {
            // Create a copy of the existing form values
            const formValues = { ...this.form.value };

            // Update only the fields that have corresponding data in 'data'
            
            if (data[0].HsptPhNum) {
                formValues.HsptPhNum = data[0].HsptPhNum;
                this.hptn = data[0].HsptPhNum
            }
            //if (data[0].DoctorName) {
            //    formValues.DoctorName = data[0].DoctorName;
            //    this.dct = data[0].DoctorName
            //}
            if (data[0].StateName) {
                formValues.statename = data[0].StateName;
                this.stn = data[0].StateName
            }
            if (data[0].StateId) {
                formValues.StateId = data[0].StateId;
                this.sid = data[0].StateId
            }
            if (data[0].DistrictName) {
                formValues.districtname = data[0].DistrictName;
                this.dit = data[0].DistrictName
            }
            if (data[0].DistrictID) {
                formValues.DistrictId = data[0].DistrictId;
                this.ddid = data[0].DistrictID
            }
            if (data[0].CityName) {
                formValues.cityname = data[0].CityName;
                this.cty = data[0].CityName
            }
            if (data[0].CityId) {
                formValues.CityId = data[0].CityId;

                this.ccid = data[0].CityId
            }
            if (data[0].HsptAddress) {
                formValues.HsptAddress = data[0].HsptAddress;
                this.hspa = data[0].HsptAddress
            }
            if (data[0].POCName) {
                formValues.POCName = data[0].POCName;
                this.pom = data[0].HsptAddress
            }
            if (data[0].POC_ContactNumber) {
                formValues.POC_ContactNumber = data[0].POC_ContactNumber;
                this.pccc = data[0].HsptAddress
            }
            if (data[0].Pincode) {
                formValues.Pincode = data[0].Pincode;
                this.pic = data[0].Pincode
            }
            
            // Update the form with the modified values
            this.form.patchValue(formValues);
        }
    }
    //addNewTrip(addextra) {
        
    //    //this.trip != newTrip
    //    this.addextra = 2;
    //}
    AddBanner(value) {
        debugger
        const Flag = '1';
        if (!value.HsptName) {
            this.genralservice.ShowAlert('Warning', 'Please provide the name of the hospital.', 'Warning');
            return;
        }
        if (!value.HsptPhNum) {
            this.genralservice.ShowAlert('Warning', 'Please provide the contact number of the hospital.', 'Warning');
            return;
        }
        if (!value.DoctorName) {
            this.genralservice.ShowAlert('Warning', 'Please provide the Doctor name.', 'Warning');
            return;
        }
        if (!value.statename) {
            this.genralservice.ShowAlert('Warning', 'Please provide the name of the state.', 'Warning');
            return;
        }
        if (!value.POCName) {
            this.genralservice.ShowAlert('Warning', 'Please provide the name of the POC.', 'Warning');
            return;
        }
        if (!value.POC_ContactNumber) {
            this.genralservice.ShowAlert('Warning', 'Please provide the contact number of the POC.', 'Warning');
            return;
        } if (!value.Pincode) {
            this.genralservice.ShowAlert('Warning', 'Please provide the Pincode.', 'Warning');
            return;
        }
       
        const stateId =
            this.data[0]?.StateId === undefined
                ? this.form.value.statename && this.form.value.statename.StateId
                : this.data[0].StateId;

        const districtId =
            this.data[0]?.DistrictId === undefined
                ? this.form.value.districtname && this.form.value.districtname.DistrictID
                : this.data[0].DistrictId;
        const cityId =
            this.data[0]?.CityId === undefined
                ? this.form.value.cityname && this.form.value.cityname.CityId
                : this.data[0].CityId;
     
        var arr = [];
      
        arr.push({
            RegId: this.LoginDetails[0].RegId,
            TokenId: this.LoginDetails[0].TokenId,
            HsptID: this.form.value.HsptName.HsptID,
        //    HsptName: this.form.value.HsptName.HsptName.split(' - ')[0],
            HsptName: typeof this.form.value.HsptName === 'string'
                ? this.form.value.HsptName
                : this.form.value.HsptName.HsptName.split(' - ')[0],

            HsptAddress: this.form.value.HsptAddress,
           // StateId: stateId,
             //DistrictID: districtId,
            //CityId: cityId,
            StateId: stateId !== undefined ? stateId : this.sid,
            DistrictID: districtId !== undefined ? districtId : this.ddid,
            CityId: cityId !== undefined ? cityId : this.ccid,
            CreatedBy: this.LoginDetails[0].RegId,

           
            POCName: this.form.value.POCName,
            POC_ContactNumber: this.form.value.POC_ContactNumber,
            Pincode: this.form.value.Pincode,
            HsptPhNum: this.form.value.HsptPhNum,
            DoctorName: this.form.value.DoctorName,
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(arr));
        UploadFile.append("Flag", "1");
        var url = "api/BG/HospitalDetails_CRUD";

        this.genralservice.PostData(url, UploadFile).then(data => {
           
            if (data == "SUCCESS") {
                if (Flag == '1') {
                    this.genralservice.ShowAlert('SUCCESS', 'Hospital added successfully', 'success');
                } else {
                    this.genralservice.ShowAlert('SUCCESS', 'Hospital updated successfully', 'success');
                }
                // Reload the form
                this.form.reset();
                // this.form.controls['HsptName'].reset(); // Reset specific control if needed
            }
            if (data == "Exist") {
                this.genralservice.ShowAlert('Exist', 'Data Already Exist', 'success');
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'
        })).then(() => {
            // Reload the page
           // this.addextra = 1;
            window.location.reload();
        });
    }


    //Addhospital(value) {
       
    //    const Flag = '7';
        

    //    var arr = [];

    //    arr.push({
    //        RegId: this.LoginDetails[0].RegId,
    //        TokenId: this.LoginDetails[0].TokenId,
    //        HsptID: this.form.value.HsptName.HsptID,
    //        HsptName: this.form.value.HsptName, 
    //    });

    //    var UploadFile = new FormData();
    //    UploadFile.append("Param", JSON.stringify(arr));
    //    UploadFile.append("Flag", "7");
    //    var url = "api/BG/HospitalDetails_CRUD";

    //    this.genralservice.PostData(url, UploadFile).then(data => {

    //        if (data == "SUCCESS") {
    //            if (Flag == '7') {
    //                this.genralservice.ShowAlert('SUCCESS', 'Hospital added successfully', 'success');
    //            } else {
    //                this.genralservice.ShowAlert('SUCCESS', 'Hospital updated successfully', 'success');
    //            }
    //            // Reload the form
              
    //            this.form.reset();
              
    //            this.GetHospitals();
    //        }
    //        if (data == "Exist") {
    //            this.genralservice.ShowAlert('Exist', 'Data Already Exist', 'success');
    //        }
    //    }, error => ({
    //        title: 'ERROR',
    //        text: 'Something went wrong. Please try again later.',
    //        type: 'error',
    //        confirmButtonText: 'Ok'
    //    })).then(() => {
    //        // Reload the page
    //       // window.location.reload();
    //        this.addextra = 1;
    //    });
    //}


    reg() {
       
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']).then(() => { window.location.reload(); });
        }
    }
    handleClick(index: number) {
        if (this.activeCardIndex === index) {
            this.activeCardIndex = -1; // Toggle off if same card clicked again
        } else {
            this.activeCardIndex = index;
        }
    }
    toggleSocialMedia(value: number) {
        // Update showSocialMedia variable
        this.router.navigate(['/Requestpresentation']).then(() => { window.location.reload(); });
    }
    downloadlogo() {
       
        // Assuming value is boolean
        this.router.navigate(['/Stickbrochure']).then(() => { window.location.reload(); });
    }
    vol() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // If LoginDetails is empty or not set, route to the login page
            this.router.navigate(['/Volunteerlogin']);
            return; // Stop further execution
        }
        else {
            // Assuming roleid is a property of LoginDetails
            if ((this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus === null)) || (this.LoginDetails[0].RoleId === 4 && this.LoginDetails[0].Rolestatus === true) || (this.LoginDetails[0].RoleId === 2 && this.LoginDetails[0].Rolestatus === true)) {
                // If roleid is 4, navigate to the '/Volunters' page
                this.router.navigate(['/Volunters']);
            }

            else if (this.LoginDetails[0].RoleId === 0 && (this.LoginDetails[0].Rolestatus === false)) {
                // If roleid is not 4, display an alert message
                this.router.navigate(['/Volunteerprofile']);
                // alert("Please update your profile as a volunteer to access this page.");
            }
            else {
                this.router.navigate(['/Profile']);
                alert("Please update your profile as a volunteer to access this page.");
            }

        }
    }

    cancel() {
       
        //this.gridview = true;
        this.addextra = 1;
    }

    //**This is used to back from the form.**//
    Back() {
      
        //this.gridview = true;
        this.addextra = 1;
    }

    //**This is used to open add from**//
    openAddForm() {
       

        this.Form.reset();
        //this.gridview = false;
        this.flag = 1;
        //this.SId = undefined;
        this.ButtonText = "Add";
        this.addextra = 1;
    }



}
