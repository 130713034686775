<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>


    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="let's help">
    <meta property="og:image" content="assets/Newcss/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="let's help" />
    <meta property="og:site_name" content="let's help" />
    <meta property="og:description" content="let's help" />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                                <a href="">
                                    <p class="logotext">LET'S HELP</p>
                                    <sub>Together,For a Nobel Cause.</sub>
                                </a>
                            </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>

        <div id="page-content">



            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">

                    <div class="bg-vid" data-src="dist/vid/LET'S Help" style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="line-height: 60PX;font-size: 32px;font-weight:bold;">ABOUT US</h2>
                    </header>

                </div>
            </div>

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="section__content">

                        <div class="section__main section__main--standalone post post--single">


                        </div>

                        <h2 style="color:black">Let’s Help</h2>
                        &nbsp;
                        <h4 style="color:black">Together, for a noble cause!</h4><br />


                        <p style="text-align: justify;">

                            Hope and Service are the two factors on which <strong>
                                Let's Help
                            </strong> is built upon. <br>
                            Its main intention is to
                            bring all the kind and intellectual hearts to one place for the wellbeing of the society

                            <br>
                            The registration of this website domain, hosting and design has done long back in 2010, lots of
                            challenges delayed this Service.
                            <br>
                            <br>
                            <strong>Blood donation</strong>, is a divine act which has no alternative, expect donations. Even this has
                            become an act of Business with the mediators. Through <strong>Let's Help, </strong>
                            there will be no mediator, no
                            money involved. <br>
                            We all together can completely abolish deaths due to lack of blood.<br>
                            <br>
                            There are numerous sufferers for various reasons.<strong>
                                Corruption, Caste, Region, Poor, Rich,
                                Politics, Legal System, Naxalism, Terrorism, Policing, Patriotism, Democracy, Reservation, Parliament,
                                Tax, Media, etc.
                            </strong> these keep on coming.
                            <br>
                            Lot of things which always needs an attention, a modification, a change, or a replacement but how can this
                            happen? <br>&nbsp;
                            <h3>Let's Help </h3>
                        <p style="text-align: justify;">
                            -Against the social injustice, inequality, religious mis-concepts, against the Social evil termites
                            eating
                            away our country’s progress
                            <br>
                            -Towards creating a helping hand, a supportive system and finally towards a positive change <br>
                        </p><br><br>

                        <a style="background-color: red; color: white;padding: 10px;margin-bottom:13%" routerLink="/aboutus">Learn More</a>

                    </div>
                </div>
            </div>




        </div>
    </div>

    <br>


</body>


</html>

<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }



    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }

   

    .wrapper {
        position: fixed;
        top: 0;
        right: -110%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }


    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 2px solid white;
    }

        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 3px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }

    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }
    @media (min-width: 768px) {
        .col-md-3 {
/*            flex: 0 0 auto;
*/            width: 25%;
        }
    }
</style>
