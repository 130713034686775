import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
@Component({
    selector: 'app-shareyourservices',
    templateUrl: './shareyourservices.component.html',
    styleUrls: ['./shareyourservices.component.scss']
})
export class ShareyourservicesComponent implements OnInit {

    public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    GalleryImages: any;
    imagefile2: any;
    HomeUrl: any;
    GalleryID: any;
    Form: FormGroup;
    LoginDetails1: any; LoginDetails: any
    category: any = []; deptList3: any;
    RegId: any;


    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    modalVisible: false;
    Age: any;
    BloodRequestDate: any;
    RequestTime: any;
    Purpose: any;
    letter: any;
  
    constructor(public genralservice: GeneralService, public http: HttpClient, public fb: FormBuilder, private router: Router) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0) {
            this.letter = this.LoginDetails[0].FullName.charAt(0);
        } else if (this.LoginDetails && this.LoginDetails.FullName) {
            this.letter = this.LoginDetails.FullName.charAt(0);
        } else {
            this.letter = '';
        }
        this.form = this.fb.group({
            Category: ['', Validators.required],
            Dateofservice: [''],
            //bloodgroup: [''],
            statename: [''],
            districtname: [''],
            cityname: [''],
            Institutionname: [''],
            GalleryImages: ['', Validators.required],
            Message: ['',],
        });


    }


    ngOnInit(): void {
        this.getgallerycat();
        this.getstate();
        this.getDistrict();
        this.getCity();

    }
    toggleDropdown() {
        const dropdownContent = document.getElementById("dropdownContent");
        if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
        } else {
            dropdownContent.style.display = "block";
        }
    }

    checkLoginStatus() {
        // Check if user is logged in (replace with your actual logic)
        // If user is logged in, set LoginDetails, otherwise set it to null
        if (this.LoginDetails != null) {
            this.LoginDetails = {

            };
        } else {
            this.LoginDetails = null;
        }
    }

    checkmobile(Mobile) {
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);


                var url = 'api/BG/checking_Mobile'

                this.genralservice.PostData(url, UploadFile).then(data => {
                    //this.logindata = JSON.stringify(data[0]);
                    //localStorage.setItem("LoginDetails", this.logindata);
                    debugger
                    if (data != 'NOTEXIST') {
                        debugger
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;
                        this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);

                    }
                    else {

                        this.router.navigate(['/Donersform'])
                            // this.router.navigate(['/register', { Mobile: this.form.value.Mobile }])
                            .then(() => {
                                window.location.reload();
                            });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);
                    }
                })
            }
        }
    }
    openLoginModal() {
        // Show the modal here
        $('#myModal').modal('show');
    }
    SendOTPtoMobile() {
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.genralservice.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
            })
        }
    }


    closeModal() {
        this.modalVisible = false;
        $('#myModal').modal('hide');
    }
    signOut() {
        // Remove the login details from localStorage
        localStorage.removeItem('LoginDetails');

        // Optionally, you might want to perform other sign-out related tasks such as
        // navigating the user to the login page, clearing any local state, etc.
    }


    AddBanner(value, flag) {
        debugger
        if (!value.GalleryImages) {
            this.genralservice.ShowAlert('Warning', 'Please upload an image file', 'Warning');
            return;
        }
        if (!value.Category) {
            this.genralservice.ShowAlert('Warning', 'Please specify a category', 'Warning');
            return;
        }
        if (!value.Dateofservice) {
            this.genralservice.ShowAlert('Warning', 'Please choose a Date of service', 'Warning');
            return;
        }
        if (!value.statename) {
            this.genralservice.ShowAlert('Warning', 'Please choose a state', 'Warning');
            return;
        }
        if (!value.Institutionname) {
            this.genralservice.ShowAlert('Warning', 'Please enter the institution name', 'Warning');
            return;
        }
        var arr = [];
        debugger
        arr.push({
            RegId: this.LoginDetails[0]?.RegId || this.LoginDetails?.RegId || 0,
            GalleryID: this.GalleryID,
            Categoryid: value.Category.Categoryid,
            GalleryImages: this.GalleryImages,
            Dateofservice: this.form.value.Dateofservice,
            State: this.form.value.statename.StateId,
            District: this.form.value.districtname.DistrictID,
            City: this.form.value.cityname.CityId,
            Institutionname: this.form.value.Institutionname,
            Message: this.form.value.Message,
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(arr));
        UploadFile.append("Flag", flag);
        var url = "api/BG/Gallery_Crud";

        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            if (data == "SUCCESS") {
                if (flag == 1) {
                    this.genralservice.ShowAlert('SUCCESS', 'Image Uploaded successfully ', 'success');
                } else {
                    this.genralservice.ShowAlert('SUCCESS', 'Image  Updated successfully', 'success');
                }
                this.form.reset();
                this.router.navigate(['/Gallery']);

            }
            if (data == "Exist") {
                this.genralservice.ShowAlert('Exist', 'Data Already Exist ', 'success');
            }
        }, error => ({
            title: 'ERROR',
            text: 'Something went wrong. Please try again later.',
            type: 'error',
            confirmButtonText: 'Ok'
        }));
    }




    getstate() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.Statedata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont1(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont2(event) {

        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }


    getgallerycat() {
        debugger
        this.arr = []
        this.arr.push({

        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/GalleryCat_Crud";
        this.genralservice.PostData(url, UploadFile).then(data => {
            debugger
            this.category = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }
    searchCont3(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.category.length; i++) {
                let country = this.category[i];
                if (country.categoryname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }
    detectFiles2(input2) {
        debugger
        //let arr = [];
        //arr.push({ UserID: this.loginDet.Id, TokenID: this.loginDet.TokenID })
        const reader = new FileReader();
        if (input2.files.length) {

            const file = input2.files[0];
            reader.onload = () => {

                this.imagefile2 = reader.result;
                this.GalleryImages = this.imagefile2;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "gif" || extFile == "webp") {

                var UploadFile = new FormData();
                UploadFile.append("UploadedImage", file);
                var url = "api/BG/UploadGallery";
                this.genralservice.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) {
                        debugger
                        this.imagefile2 = this.HomeUrl + data;
                        this.GalleryImages = data;
                    }
                });
            }
            else {
                alert("Only jpg/jpeg, png, gif, and webp files are allowed!");
            }

        }

    }
}
