<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="author" content="">
    <title>Let's Help </title>

    <!-- core CSS -->

    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">



</head>
<body> 
    <!--Bootstrap Approach-->

    <div class="pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="container" style="background-color: #fb0300; }">
                        <div class="row">
                            <div class="col-lg-3">
                                <a href="/">
                                    <img class="logo" src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png">
                                </a>
                            </div>
                            <div class="col-lg-2"></div>  <div class="col-lg-4">
                                <h3 class="title" style="font-weight:bold;">Search Donor Details</h3>
                            </div>
                            <div class="col-lg-3"></div>
                            <!--<div class="col-lg-3">
                    <ng-container *ngIf="LoginDetails; else showLoginButton">
                        <div class="dropdown" (click)="toggleDropdown()">
                            <button class="dropbtn" style="color:white">
                                {{ letter }}

                            </button>
                            <div class="dropdown-content" style="    width:220%;">
                                <a href="Profile" (click)="pro()"> My Profile </a>
                                <a (click)="signOut()">Sign Out</a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #showLoginButton>
                        <p class="Login" (click)="openLoginModal()" style="text-decoration:underline;cursor:pointer"><b>LOGIN</b></p>
                    </ng-template>

                </div>-->

                        </div>
                    </div>
                </div>
                <div class="col-lg-1"></div>
            </div>
     
        <section id="feature">
          
                <div id="conatcat-info" style="background-color: #ffffff;">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10">
                                <div class="row ">
                                    
                                    <div class="col-lg-8 mt-4 mb-4">
                                        <p>
                                            <span style="color:red;"></span><b>
                                                No Donors are there please Fill
                                                a request form to raise the blood request
                                            </b>
                                        </p>
                                    </div>
                                    <div class="col-lg-4 mt-4 mb-4">
                                        <div (click)="register()" style="cursor:pointer">Click here</div>
                                    </div>
                                   
                                </div>
                                <!--well-->   <form class=" form-horizontal" method="post" id="contact_form" style="background-color: #ffffff; margin-right: 15px;">
                                    <div class="container">
                                        <!--<div>

                <p>
                    <span class="searchdet"><b>Note:</b></span>
                    <b>
                        1.Search for nearest donors also empowers volunteers
                        to play an active role in saving lives.
                    </b>
                <p>
                <p class="para">
                    <b>
                        2.This not only enhances the efficiency of blood donation drives but also instills a
                        sense of purpose and responsibility.
                    </b>
                </p>
            </div>-->




                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                    </div>
                </div><!--/#conatcat-info-->
           


        </section>
        <div class="container">
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">

                    <div class="row">


                        <div class="col-lg-2">
                            <p-autoComplete [(ngModel)]="selectedState1"
                                            [suggestions]="deptList"
                                            [dropdown]="true"
                                            (completeMethod)="searchCont($event)"
                                            field="StateName"
                                            placeholder="Select state" (click)="getDistrict()"
                                            (onSelect)="onStateSelect1($event)"
                                            [style]="{'color': 'black'}"
                                            [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}"></p-autoComplete>

                        </div>
                        <div class="col-lg-2">
                            <p-autoComplete [(ngModel)]="selectedState2"
                                            [suggestions]="deptList1"
                                            [dropdown]="true"
                                            (completeMethod)="searchCont1($event)"
                                            field="DistrictName"
                                            placeholder="Select District" (click)="getCity()"
                                            (onSelect)="onStateSelect2($event)" [style]="{'color': 'black'}"
                                            [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                            </p-autoComplete>

                        </div>
                        <div class="col-lg-2">
                            <p-autoComplete [(ngModel)]="selectedState3"
                                            [suggestions]="deptList2"
                                            [dropdown]="true"
                                            (completeMethod)="searchCont2($event)"
                                            field="CityName"
                                            placeholder="Select City"
                                            (onSelect)="onStateSelect3($event)" [style]="{'color': 'black'}"
                                            [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                            </p-autoComplete>

                        </div>
                        <!--<div class="col-lg-2">
                    <p-autoComplete [(ngModel)]="selectedState6"
                                    [suggestions]="deptList6"
                                    [dropdown]="true"
                                    (completeMethod)="searchCont6($event)"
                                    field="Area"
                                    placeholder="Select Area"
                                    (onSelect)="onStateSelect6($event)" [style]="{'color': 'black'}"
                                    [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                    </p-autoComplete>

                </div>-->
                        <div class="col-lg-2">
                            <p-autoComplete [(ngModel)]="selectedState5"
                                            [suggestions]="deptList3"
                                            [dropdown]="true"
                                            (completeMethod)="searchCont3($event)"
                                            field="BLGName"
                                            placeholder="Select BloodGroup"
                                            (onSelect)="onStateSelect5($event)" [style]="{'color': 'black'}"
                                            [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                            </p-autoComplete>

                        </div>
                        <div class="col-lg-2">
                            <p-autoComplete [(ngModel)]="selctedpincode"
                                            [suggestions]="deptList5"
                                            [dropdown]="true"
                                            (completeMethod)="Selectcount5($event)"
                                            field="Pincode"
                                            placeholder="Select pincode"
                                            (onSelect)="onSelctedpincode($event)" [style]="{'color': 'black'}"
                                            [panelStyle]="{'background-color': 'white', 'border': '1px solid black','margin-left':'3px'}">

                            </p-autoComplete>

                        </div>
                        <div class="col-lg-1  mt-2">
                            <!--<button type="submit" (click)="onSearchClick(selectedState1,selectedState2,selectedState3,selectedState6,selectedState5,selctedpincode)">Search</button>-->
                            <button type="submit" (click)="DonorFilterData()">Search</button>
                        </div>
                        <div class="col-lg-1  mt-2">

                            <img src="../../../assets/img/download.png" (click)="refresh()" style="cursor:pointer" class="im" />
                        </div>
                    </div><br />

                </div>


                <div class="col-lg-1"></div>
            </div>

            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <table id="customers">
                        <tr>
                            <th>Donor</th>
                            <th>Donor Number</th>
                            <th>Report/Update</th>
                        </tr>
                        <tr *ngFor="let item of getPagedItems()">
                            <td>
                                {{ item.FullName }}&nbsp;
                                <ng-container *ngIf="item.Messagetorequester !== ''">
                                    <i class="far fa-envelope mail-icon"
                                       style="color: #3498db; cursor: pointer;"
                                       [attr.titlee]=" item.Messagetorequester"
                                       (click)="toggleMessageVisibility($event)"></i>
                                </ng-container>
                            </td>
                            <td><span style="color:red;cursor:pointer" (click)="phonenum(item.RegId)"> Click to contact</span></td>
                            <td><span style="color:red;cursor:pointer" (click)="openModal(item.RegId)">Click here</span></td>
                        </tr>
                    </table>
                    <br />

                </div>
                <div class="col-lg-1"></div>
            </div>


            <!--<div *ngIf="NoDonors">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    <span style="color:red;"><b>Note</b>:</span><b>
                        No Donors are there in this area
                    </b>
                </p>
            </div>
           
        </div>

    </div>-->

            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="col">
                        <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-center">
                                <li class="page-item">
                                    <a class="page-link" aria-label="First" (click)="goToFirstPage()">
                                        <span aria-hidden="true" style="color: black;">&laquo;&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" aria-label="Previous" (click)="prevPage()">
                                        <span aria-hidden="true" style="color: black;">&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" *ngFor="let page of getPages(); let i = index;" [class.active]="currentPage === page">
                                    <a class="page-link" (click)="setPage(page)">{{page}}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" aria-label="Next" (click)="nextPage()">
                                        <span aria-hidden="true" style="color: black;">&raquo;</span>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" aria-label="Last" (click)="goToLastPage()">
                                        <span aria-hidden="true" style="color: black;">&raquo;&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-lg-1"></div>
            </div>
            <br /><br /><br /><br /><br /><br />
        </div>

        </div>



    </div>


    <!--<div class="modal fade" id="phoneNumberModal" tabindex="-1" role="dialog" aria-labelledby="phoneNumberModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <p> <b>Phone Number</b></p>
                        </div>
                        <div class="col-lg-6">
                            <p>{{ mobilenumber }}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>-->

    <div class="modal fade" id="phoneNumberModal" tabindex="-1" role="dialog" aria-labelledby="phoneNumberModalLabel" aria-hidden="true">
       

            <div class="modal-dialog" role="document">
                <div class="modal-content" style="margin-top: 32%;">

                    <div class="modal-header state modal-primary">
                       
                        <div style="margin-left:30%;"> <b style="text-align:center">Phone Number</b>&nbsp;&nbsp;<br />{{ mobilenumber }}</div>
                                                                                     
                                <p data-dismiss="modal" title="Close" style="cursor:pointer;font-size:20px;"><span aria-hidden="true" style="color:white;">X</span></p>
                            
                    </div>
                    <!--<div class="modal-body">
                        <form [formGroup]="form" (ngSubmit)="Requestform()">
                            <div class="row">
                                <div class="col-lg-11"></div>

                            </div>

                            <p *ngIf="showNote">
                                <span style="color:red;"><b>Note:</b></span>
                                <b>The Donor Opted for Privacy. However, you can send an email or post a request.</b>
                            </p>

                            <hr />
                            <div class="row">
                                <div class="col-lg-4 ">
                                    <span><b class="checkboxx"><span class="note"></span>Post as a request</b></span>
                                </div>
                                <div class="col-lg-8 ml-n2">
                                    <span style="float:inline-end;border:1px solid lightgrey;border-radius:2px;padding:0px;"> <i class="fas fa-check"></i></span>

                                </div>
                                <div class="col-lg-4">
                                    <span><b class="checkboxx"><span class="note"></span>Mail</b></span>
                                </div>
                                <div class="col-lg-8 ml-n2">
                                    <input type="checkbox" id="myCheckbox">
                                    ✔
                                    <span style="float:inline-end;border:1px solid lightgrey;border-radius:2px;padding:0px;"> <i class="fas fa-check"></i></span>

                                </div>
                                <div class="col-lg-4">
                                    <label for="username">Requester's Name<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="username" formControlName="contactperson" placeholder="Contact Person Full Name" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.contactperson.touched && form.controls.contactperson.errors?.required">Contact Person Name is required</small>

                                </div>

                                <div class="col-lg-4 mt-n2">
                                    <label for="mobile">Contact&nbsp;Number<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <input type="text" id="mobile" formControlName="Contmobile" inputmode="numeric" pattern="[0-9]*" maxlength="10" placeholder="Patient Attender Mobile Number" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Contmobile.touched && form.controls.Contmobile.errors?.required">Attender Mobile is required</small>
                                </div>

                                <div class="col-lg-4 mt-n2">
                                    <label>Blood&nbsp;Group <span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="bloodgroup" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                        field="BLGName" [dropdown]="true" [size]="90"
                                                        placeholder="Search Patient blood group" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
                                        </p-autoComplete>

                                        <small *ngIf="form.controls.bloodgroup.touched && form.controls.bloodgroup.errors?.required"
                                               class="p-error">Patient blood group is required.</small>
                                    </div>
                                </div>

                                <div class="col-lg-4 mt-n2">
                                    <label for="bloodgroup">Units&nbsp;of&nbsp;blood <span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <p-autoComplete formControlName="unitsofblood" [suggestions]="deptList4" (completeMethod)="searchCont41($event)"
                                                    field="UnitsofBlood" [dropdown]="true" [size]="90" placeholder="Search units of blood" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">

                                    </p-autoComplete>
                                    <small *ngIf="form.controls.unitsofblood.touched && form.controls.unitsofblood.errors?.required" class="p-error">
                                        units of blood is required.
                                    </small>
                                </div>

                                <div class="col-lg-4">
                                    <label for="age">Required&nbsp;Date <span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="date" formControlName="BloodRequestDate" id="BloodRequestDate" placeholder="Blood Required Date">
                                </div>

                                <div class="col-lg-4 mt-3">
                                    <label for="age">Required&nbsp;Time  <span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mt-3">
                                    <input type="time" formControlName="RequestTime" id="RequestTime" placeholder="Blood Required Time">
                                </div>


                            </div>

                        </form>

                        <form [formGroup]="form" (ngSubmit)="Requestform()">
                            <div class="row">

                                <div class="col-lg-4 mt-2">
                                    <label for="category">Search Hospital<span style="color:red">*</span></label>
                                </div>

                                <div class="col-lg-8 mt-2">
                                    <p-autoComplete formControlName="HsptName" [suggestions]="deptList8" (completeMethod)="searchCont8($event)"
                                                    field="HsptName" [dropdown]="true" [size]="90"
                                                    placeholder="Select Hospital" [minLength]="1"
                                                    [style]="{'color': 'black'}"
                                                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
                                    </p-autoComplete>
                                    <small *ngIf="form.controls.HsptName.touched && form.controls.HsptName.errors?.required"
                                           class="p-error" style="color:red">Hospital Name is required.</small>
                                </div>

                                    <div class="col-lg-4 mt-n2">
                                        <label for="area">Hospital&nbsp;Address<span style="color:red">*</span></label>
                                    </div>
                                    <div class="col-lg-8 mt-n2">
                                        <input type="text" id="HospitalAddress" formControlName="HospitalAddress" placeholder="Hospital Address" />
                                    </div>
                                
                                <div class="col-lg-4 mt-n2">
                                    <label>State</label>
                                </div>
                                <div class="col-lg-8 mt-n2">

                                    <div style="padding-left:0px">

                                        <p-autoComplete  formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                        field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict1()"
                                                        placeholder="Search State Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                               class="p-error">State Name is required.</small>
                                    </div>

                                </div>



                                <div class="col-lg-4 mt-n2">
                                    <label>District</label>
                                </div>
                                <div class="col-lg-8 mt-n2">

                                    <div style="padding-left:0px">

                                        <p-autoComplete  formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont11($event)"
                                                        field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity1()"
                                                        placeholder="Search District Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                               class="p-error">District Name is required.</small>
                                    </div>
                                </div>


                                <div class="col-lg-4 mt-n2">
                                    <label>City</label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">

                                        <p-autoComplete  formControlName="cityname"
                                                        [suggestions]="deptList2"
                                                        (completeMethod)="searchCont21($event)"
                                                        field="CityName"
                                                        [dropdown]="true"
                                                        [size]="120"
                                                        placeholder="Search CityName"
                                                        [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                        </p-autoComplete>

                                        <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                               class="p-error">City Name is required.</small>
                                    </div>

                                </div>

                                <div class="col-lg-4 mt-n2">
                                    <label for="mobile">Pincode<span style="color:red">*</span></label>
                                </div>

                                <div class="col-lg-8 mt-n2">
                                    <input type="text" id="Pincode" formControlName="Pincode" placeholder="Pincode" maxlength="6" min="5" oninput="this.value = this.value.replace(/[^0-9]/g, '');" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Pincode.touched && form.controls.Pincode.errors?.required">Pincode is required</small>

                                </div>

                                <div class="col-lg-4 mt-n2">
                                    <label for="area">Patient&nbsp;Area</label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <input type="text" id="area" formControlName="area" placeholder="Patient Area" />
                                </div>

                                <div class="col-lg-4">
                                    <label for="area">Message</label>
                                </div>
                                <div class="col-lg-8">
                                    <textarea id="PersonalNote" formControlName="PersonalNote" class="form-control" placeholder="Message Your Personal Note"></textarea>
                                </div>                           

                            </div>

                            <div class="row">
                                <div class="col-lg-8">
                                </div>
                                <div class="col-lg-4">
                                    <button class="submit  btn-secondary">Submit</button>
                                </div>
                            </div>

                        </form>
                    </div>-->

                </div>
            </div>

        </div>


        <div class="modal fade" id="phoneNumberModal1" tabindex="-1" role="dialog" aria-labelledby="phoneNumberModalLabel" aria-hidden="true">


            <div class="modal-dialog" role="document">
                <div class="modal-content">


                    <div class="modal-header state modal-primary">
                        <b>Report/Update contact number</b>
                        <p data-dismiss="modal" title="Close" style="cursor:pointer;">X</p>
                    </div>



                        <div class="modal-body">

                            <form [formGroup]="form1" (ngSubmit)="Requestform1()">


                                <p>
                                    <span style="color:red;"><b>Note</b>:</span><b>
                                        Update here if there is any issue reaching the donor so that we rectify the problem for the future users.
                                    </b>

                                </p>
                                <hr />


                                <div class="row">
                                    <div class="col-lg-4">
                                        <label for="useraddress">Donor&nbsp;Name</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <input type="text" id="FullName" formControlName="FullName" placeholder="Full Name" readonly />
                                        <small style="color:red" class="text-danger" *ngIf="form1.controls.FullName.touched && form1.controls.FullName.errors?.required">Donor Name is required</small>

                                    </div>

                                    <!--<div class="row">
                        <div class="col-lg-3">
                            <label for="age">Observation<span style="color:red">*</span></label>
                        </div>
                        <div class="col-lg-7">
                            <div class="row">
                                <div class="col-lg-6 col-xs-6" *ngFor="let observation of observ">
                                    <div class="chip1" (click)="selectaudi(observation.ObservationReason)" [ngClass]="{'selected': selectedaudi === observation.ObservationReason}">{{ observation.ObservationReason }}</div>
                                </div>
                            </div>
                        </div>
                    </div>-->

                                    <div class="col-lg-4 mt-n2">
                                        <label>Observation</label>
                                    </div>
                                    <div class="col-lg-8 mt-n2">
                                        <div style="padding-left:0px">
                                            <p-autoComplete formControlName="ObservationReason" [suggestions]="deptList6" (completeMethod)="searchCont9($event)"
                                                            field="ObservationReason" [dropdown]="true" [size]="90" placeholder="Search Observation"
                                                            [style]="{'color': 'black', 'width': '100%'}" [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px', 'width': '100%'}">
                                            </p-autoComplete>
                                        </div>
                                    </div>




                                    <div class="col-lg-4">
                                        <label for="area">Additional Comments<span style="color:red">*</span></label>
                                    </div>
                                    <div class="col-lg-8">
                                        <textarea id="PersonalNote" formControlName="Comments" class="form-control" placeholder="Additional Comments"></textarea>
                                    </div>


                                </div>


                                <div class="row mt-3">
                                    <div class="col-lg-8"></div>
                                    <div class="col-lg-4">
                                        <button class="btnsubmit" type="submit">Submit</button>
                                    </div>

                                </div>



                            </form>
                        </div>
                        <!--<div class="modal-footer">
            <div class="row">
                <div class="col-lg-6">
                    <button class="submit">Submit</button>
                </div>
                <div class="col-lg-6">
                    <button type="button" class="btn1" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>-->
                    </div>
                </div>

        </div>
        <footer id="footer" class="midnight-blue">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        &copy; 2024 Let's Help  all rights reserved.
                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-lg-3">
                                <div routerLink="/"style="cursor:pointer">Home</div>
                            </div>
                            <div class="col-lg-3">
                                <div routerLink="/about"style="cursor:pointer">About Us</div>
                            </div>
                            <div class="col-lg-3">
                                <div routerLink="/FAQS"style="cursor:pointer">FAQ's</div>
                            </div>
                            <div class="col-lg-3">
                                <div routerLink="/contact"style="cursor:pointer">Contact</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </footer><!--/#footer-->
        <script src="js/jquery.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/jquery.prettyPhoto.js"></script>
        <script src="js/jquery.isotope.min.js"></script>
        <script src="js/main.js"></script>
        <script src="js/wow.min.js"></script>


</body>
</html>

<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    button {
        padding: 7px;
        background-color: red;
        color: white;
        border: none;
        border-radius: 5px
    }

    .ptb-100 {
        height: 100vh;
        overflow-y: auto;
    }

    .btn {
        padding: 14px;
        width: 30%;
        border-radius: 5px;
        font-weight: bold;
    }

        .btn:hover {
            padding: 14px;
            width: 30%;
            border-radius: 5px;
            font-weight: bold;
            color: white
        }

    .wrapper {
        position: fixed;
        top: 0;
        right: -110%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
        z-index: 1; /* Ensure the wrapper appears beneath other elements */
    }

    #active:checked ~ .wrapper {
        right: 0;
    }


    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }


    .h2, h2 {
        font-size: 32px;
    }

    .h1, .h2, .h3, h1, h2, h3 {
        margin-top: 24px;
        margin-bottom: 12px;
        font-weight: bold;
        color: white;
    }
    .hatch3 {
        margin: 8% 1% 0% -136%;
        font-weight: bold;
    }
    .col-lg-3 {
        position: relative;
    }


    .bg-red--darker {
        background-color: #900;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 380px;
    }

    @media (min-width: 992px) {
        .allow-scroll .pane__content {
            height: 100%;
            overflow-y: initial;
            overflow-scrolling: touch;
            overflow-x: hidden;
        }
    }

    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    @media (min-width: 992px) {
        .pane__inner.allow-scroll {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
        }
    }

    a {
        color: white;
        text-decoration: none;
    }

    h3 {
        color: white;
    }

    a:hover {
        color: white;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }

    .bg-red {
        background-color: #ca0000;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 380px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .para {
        margin-left: 3.8rem;
        margin-left: -5px;
    }






    h1, h2, h4, h5, h6 {
        margin: 2% 0% 3% 0%;
        font-weight: bold
    }

    h3 {
        margin: 5% 0% 3% 0%;
        font-weight: bold
    }

    body {
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
        overflow-y: auto; /* Enable vertical scrolling */
    }

    .pt-100 {
        padding-top: 0px;
    }

    .ptb-100 {
        padding-top: 0px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 100vh; /* Limit the maximum height to ensure it fits within the viewport */
    }

    a:hover, a:focus {
        color: #fb0300;
        text-decoration: none;
        outline: none;
    }

    .midnight-blue  {
        background-color: black;
        color: white;
        padding: 10px;
        margin-top: 7%;
    }

    li {
        display: inline-block;
        color: black;
        padding: 10px;
    }

        li a {
            color: white;
        }

    #footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px; /* Adjust according to your footer height */
        background-color: #000; /* Adjust according to your design */
        color: #fff; /* Adjust according to your design */
    }
    .logo{
        max-width:30%;
    }


    a {
        color: black !important;
        
        text-decoration: none;
    }
</style>

