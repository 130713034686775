<!DOCTYPE html>
<!--[if IE 9]> <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->
<head>
    <!-- build success -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <title>Let's Fight</title>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="O3 World is a Philadelphia based interactive agency specializing in web design and development, eCommerce and online strategy.">
    <meta property="og:image" content="dist/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="O3 World - Digital Product Design and Development Agency" />
    <meta property="og:site_name" content="O3 World" />
    <meta property="og:description" content="O3 World is a Philadelphia based interactive agency specializing in web design and development, eCommerce and online strategy." />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link href="assets/Newcss/css/newone.css" />


    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>


    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>

    <div id="page-wrapper">
        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <div class="logo_text">
                                <a href="">
                                    <p class="logotext">LET'S HELP</p>
                                    <sub>Together,For a Noble Cause.</sub>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <button id="burger"class="hamburgwers"   data-title="" routerLink="/Menu" style="float:right;">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>
        <div id="page-content">


            <div class="pane pane--left" data-pane="left">
                <div id="homepage" class="pane__inner pane-header pane-header--home">
                    <div class="bg-vid" data-src="/dist/vid/home" style="background-image: url(assets/Newcss/img/home.jpg)"></div>
                    <header class="pane-header__content">
                        <h3 class="pane-header__description u-white">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/5KWKvYI0U9o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </h3>
                        <a routerLink="/about" class="btn btn-default">About Us<i class="icon-chevron-right"></i></a>

                    </header>
                </div>
            </div>
            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner pane__inner--home">
                    <ul class="shelf shelf--home shelf--hover-state list--unstyled u-center">

                        <li class="bg-gi--secondary" style="margin:0%">
                            <a routerLink="/Oldagehome" title="Oldage Homes" class="no-ajax trigger-overlay" data-modal-bctext="View Our Work" data-modal-bclink="/work">
                                <span class="bg cover-gi" style="background-image: url(assets/images/gi_thumb.jpg)">

                                </span>
                            </a>

                        </li>
                        <li class="" style="margin:0%">
                            <a routerLink="/blooddonation" title="Blood Donation Hero">
                                <span class="bg cover-forge" style="background-image: url(assets/images/blood-donationhero.jpg)">
                                    <span>
                                    </span>
                                </span>
                            </a>
                        </li>
                        <li class="mobil_orphan">
                            <a routerLink="/Orphanage" title="Orphanage Homes" class="no-ajax trigger-overlay" data-modal-bctext="View Our Work" data-modal-bclink="/work">
                                <span class="bg cover-gi" style="background-image: url(assets/images/orphan.jpg)">

                                </span>
                            </a>

                        </li>
                    </ul>
                </div>
            </div>


        </div><!--end #page-content-->

    </div>



</body>

</html>
<style>

   


    .wrapper {
        position: fixed;
        top: 0;
        right: -110%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        /* left: calc(310% - 100px);*/
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        /*border-radius: 5px;*/
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        /*background-color: red;*/
        border: 3px solid white;
    }

        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 3px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
    sub {
        top: -2rem;
        left: 1.5rem;
        font-size: 11px;
    }
</style>
