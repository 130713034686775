<div class=" ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2">
                <a routerLink="/">
                    <img src="../../../../assets/img/Blood_DonationImages/bg_logo.png" class="logo" />
                </a>
            </div>
            <div class="col-lg-8">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-4">
                            <p>Donar Register</p>
                        </div>
                        <div class="col-lg-4">
                            <p>Post A Blood Request</p>
                        </div>
                        <div class="col-lg-4">
                            <p>Share Your services</p>
                        </div>
                    </div>
                </div>
                </div>
            <div class="col-lg-2">
                <div class="card1"></div>
            </div>
            </div>
        <br />
        <div class="row">
            <div class="col-lg-3">
                <div class="cards">
                    Lets-Fight Gallery
                </div><br />
                      <div class="card_img">
                          <img src="../../../../assets/img/Blood_DonationImages/home-blood.png" />
                          <div class="cards">
                              RECENT OLD AGE SERVICES
                          </div>
                      </div>
            </div>
            <div class="col-lg-6">
                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                    <!-- Indicators -->
                    <ol class="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                    </ol>

                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../../../../assets/img/Blood_DonationImages/Banner1.avif" alt="Los Angeles" style="width:100%;">
                        </div>

                        <div class="carousel-item">
                            <img src="../../../../assets/img/Blood_DonationImages/b.jpg" alt="Chicago" style="width:100%;">
                        </div>

                        <div class="carousel-item">
                            <img src="../../../../assets/img/Blood_DonationImages/Banner2.jpg" alt="New york" style="width:100%;">
                        </div>
                    </div>

                    <!-- Left and right controls -->
                    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>

            </div>
            <div class="col-lg-3"></div>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-12"><br />
                <h3  style="text-align:center;color:red">Welcome to the largest Blood Donars in India</h3>
           <br />     <p>At Let's help, we understand the critical need for blood donations to save lives. Our blood donor network is dedicated to connecting generous individuals with those in need of life-saving blood transfusions.</p>
                <p>Every two seconds, someone in the world requires blood. By donating blood, you have the power to make a direct impact on the lives of patients facing medical emergencies, undergoing surgeries, or battling life-threatening conditions such as cancer or anemia. Your donation can mean the difference between life and death for someone in need.</p>
            </div>
        </div>

        </div>
</div>
