<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How We <span>Work</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Planing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Designing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Product Launch</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
<!--<div class="container">
       <a class="navbar-brand" routerLink="/">
           <img src="../../../../assets/img/Blood_DonationImages/bg_logo.png" class="logo"/>
       </a>
       <button class="navbar-toggler" type="button" (click)="toggleClass()">
           <span class="burger-menu">
               <span class="top-bar"></span>
               <span class="middle-bar"></span>
               <span class="bottom-bar"></span>
           </span>
       </button>

       <div class="collapse navbar-collapse" id="navbarSupportedContent">
           <ul class="navbar-nav ml-auto">
               <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('about')">About</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Team</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Services</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Work</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Pricing</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Blog</span></li>
               <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
           </ul>
           <ul class="navbar-nav ml-auto for-responsive">
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Team</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Services</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Work</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Pricing</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">Blog</span></li>
               <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Contact</span></li>
           </ul>
       </div>
   </div>-->
