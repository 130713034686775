<!DOCTYPE html>
<!--[if IE 9 ]>    <html class="ie9 ie"> <![endif]-->
<!--[if (gt IE 9)|!(IE)]><!-->
<html class="">
<!--<![endif]-->


<head>

    <meta http-equiv="content-type" content="text/html;charset=utf-8" />
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="canonical" href="index.html">
    <meta name="description" content="let's fight">
    <meta property="og:image" content="assets/Newcss/img/share-image.jpg" />
    <meta property="og:image:width" content="1536" />
    <meta property="og:image:height" content="768" />
    <meta property="og:url" content="index.html" />
    <meta property="og:title" content="let's fight" />
    <meta property="og:site_name" content="let's fight" />
    <meta property="og:description" content="let's fight" />
    <meta name="google-site-verification" content="UmszyWVH-A9kg1qkMtAQgeW7q_MXxA2_2szNBNEN8Mo" />
    <link href='http://fonts.googleapis.com/css?family=Lato:400|Roboto+Slab:400,700' rel='stylesheet' type='text/css'>
    <link rel="apple-touch-icon" sizes="57x57" href="apple-touch-icon-57x57.png">
    <link rel="apple-touch-icon" sizes="60x60" href="apple-touch-icon-60x60.png">
    <link rel="apple-touch-icon" sizes="72x72" href="apple-touch-icon-72x72.png">
    <link rel="apple-touch-icon" sizes="76x76" href="apple-touch-icon-76x76.png">
    <link rel="apple-touch-icon" sizes="114x114" href="apple-touch-icon-114x114.png">
    <link rel="apple-touch-icon" sizes="120x120" href="apple-touch-icon-120x120.png">
    <link rel="apple-touch-icon" sizes="144x144" href="apple-touch-icon-144x144.png">
    <link rel="apple-touch-icon" sizes="152x152" href="apple-touch-icon-152x152.png">
    <link rel="apple-touch-icon" sizes="180x180" href="apple-touch-icon-180x180.png">
    <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32">
    <link rel="icon" type="image/png" href="android-chrome-192x192.png" sizes="192x192">
    <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96">
    <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16">
    <link rel="manifest" href="manifest.json">
    <meta name="msapplication-TileColor" content="#ca0000">
    <meta name="msapplication-TileImage" content="/mstile-144x144.png">
    <meta name="theme-color" content="#ffffff">
    <meta name="google-site-verification" content="zMbDS_-_Jn29SJhdwgRR5ynPmcVHsJHaxpmIHfIzF1U" />

</head>
<body>

    <div class="loader-bar"><div></div><div></div><div></div><div></div></div>


    <div id="page-wrapper">

        <header class="site-header">
            <div class="row">
                <div class="col-lg-6">
                    <span class="bg"></span><br>
                    <div class="row">
                        <div class="col-lg-3">
                            <a href="">
                                <img src="assets\img\Blood_DonationImages\LETS_HELP_LOGO.png" class="logo_all">
                            </a>
                        </div>
                        <div class="col-lg-5">
                            <!--<div class="logo_text">
                            <a href="">
                                <p class="logotext">LET'S HELP</p>
                                <sub>Together,For a Nobel Cause.</sub>
                            </a>
                        </div>-->
                        </div>
                        <div class="col-lg-2">
                            <button id="burger" class="hamburgwers" data-title="" routerLink="/Menu" style="float:right">
                                <span class="bars">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar3"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6"></div>
            </div>
        </header>

        <div id="page-content">



            <div class="pane pane--left" data-pane="left">
                <div class="pane__inner pane-header">

                    <div class="bg-vid" data-src="dist/vid/LET'S FIGHT" style="background-image: url(assets/Newcss/img/PREREQ.jpg)"></div>
                    <header class="pane-header__content">
                        <h2 class="page-title u-white" style="line-height: 60PX;">Profile</h2>

                    </header>

                </div>
            </div>

            <div class="pane pane--right" data-pane="right">
                <div class="pane__inner allow-scroll">

                    <div class="section__content">

                        <div class="section__main section__main--standalone post post--single">


                        </div>
                        <div class="row" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">

                            <div class="col-lg-10">
                                <span><b><span class="note">Note :</span> Enable or disable the visibility of your details to the public for donation</b></span>
                            </div>
                            <div class="col-lg-2">
                                <label class="switch">
                                    <input type="checkbox" formControlName="Status" id="Status"
                                           [checked]="form.get('Status').value"
                                           (change)="toggleStatus($event.target.checked)">
                                    <span class="slider"></span>
                                </label>
                            </div>


                        </div>
                        <div class="row" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">

                            <div class="col-lg-11">
                                <span><b><span class="note">Note :</span> Enable or disable the visibility of your phone number to the public for donation</b></span>
                            </div>
                            <div class="col-lg-">
                                <label class="switch">
                                    <input type="checkbox" formControlName="Statusphn" id="Status"
                                           [checked]="form.get('Statusphn').value"
                                           (change)="toggleStatuss1($event.target.checked)">
                                    <span class="slider"></span>
                                </label>
                            </div>


                        </div>
                        <form class="form_1" [formGroup]="form" (ngSubmit)="Donersform()">
                            <div class="row">

                                <div class="col-lg-4">
                                    <label for="username">Full Name<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="username" formControlName="username" placeholder="Donor Full Name" required />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.username.touched && form.controls.username.errors?.required">Donor Name is required</small>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="mobile">Mobile&nbsp;Number<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="mobile" formControlName="mobile" placeholder="Mobile Number" [readonly]="true" />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.mobile.touched && form.controls.mobile.errors?.required">Donor Mobile is required</small>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="email">Email<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="email" formControlName="email" placeholder="Email ID" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="age">Gender<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-3 col-xs-6">
                                            <div class="chip1" (click)="selectGender('Male')" [ngClass]="{'selected': selectedGender === 'Male'}" required>Male</div>
                                        </div>
                                        <div class="col-lg-3 col-xs-6">
                                            <div class="chip1" (click)="selectGender('Female')" [ngClass]="{'selected': selectedGender === 'Female'}" required>Female</div>
                                        </div>
                                        <div class="col-lg-4 col-xs-6"></div>
                                    </div>

                                </div>


                                <div class="col-lg-4" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                    <label for="age">Ever donated blood<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                    <div class="row">
                                        <div class="col-lg-3 col-xs-6">
                                            <div class="chip1" (click)="PastDonation('Yes')" [ngClass]="{'selected': selectedpast === 'Yes'}" required>Yes</div>

                                        </div>
                                        <div class="col-lg-3 col-xs-6">
                                            <div class="chip1" (click)="PastDonation('No')" [ngClass]="{'selected': selectedpast === 'No'}" required>No</div>

                                        </div>
                                        <div class="col-lg-4 col-xs-6"></div>
                                    </div>

                                </div>

                                <div class="col-lg-4" *ngIf="selectedpast !== 'No'">
                                    <div *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                        <label>Last donate date<span style="color:red">*</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-8" *ngIf="selectedpast !== 'No'">
                                    <div *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                        <div class="input-group">
                                            <input style="padding:6px" formControlName="Lastdonatedate" required type="date" name="Lastdonatedate" id="Lastdonatedate" [readonly]="selectedpast === 'No'">
                                        </div>
                                    </div>

                                </div>


                                <div class="col-lg-4" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                    <label for="mobile">Weight<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                    <input type="text" id="Weight" formControlName="Weight" placeholder="Weight " oninput="this.value = this.value.replace(/[^0-9]/g, '');" required />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Weight.touched && form.controls.Weight.errors?.required">Donor Weight is required</small>

                                </div>

                            </div>

                            <div class="row" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                <div class="col-lg-4">
                                    <label for="age">Age<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="age" formControlName="age" placeholder="Age" oninput="this.value = this.value.replace(/[^0-9]/g, '');" required />
                                </div>
                            </div>

                            <div class="row" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                <div class="col-lg-4 mt-n2">
                                    <label>Blood Group<span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="bloodgroup" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                        field="BLGName" [dropdown]="true" [size]="90"
                                                        placeholder="Search Blood Group" [minLength]="1"
                                                        [style]="{'color': 'black','border-radius':'5px'}"
                                                        [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                        </p-autoComplete>
                                        <small *ngIf="form.controls.bloodgroup.touched && form.controls.bloodgroup.errors?.required"
                                               class="p-error">blood group is required.</small>
                                    </div>
                                </div>
                            </div>



                            <div class="row">

                                <div class="col-lg-4 mt-n2">
                                    <label>State Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">

                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="statename" [suggestions]="deptList" (completeMethod)="searchCont($event)"
                                                        field="StateName" [dropdown]="true" [size]="90" (click)="getDistrict()"
                                                        placeholder="Search State Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px','cursor':'pointer',}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.statename.touched && form.controls.statename.errors?.required"
                                               class="p-error">State Name is required.</small>
                                    </div>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4 mt-n2">
                                    <label>District Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">

                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="districtname" [suggestions]="deptList1" (completeMethod)="searchCont1($event)"
                                                        field="DistrictName" [dropdown]="true" [size]="90" (click)="getCity()"
                                                        placeholder="Search District Name" [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px'}">

                                        </p-autoComplete>

                                        <small *ngIf="form.controls.districtname.touched && form.controls.districtname.errors?.required"
                                               class="p-error">District Name is required.</small>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-4 mt-n2">
                                    <label>City Name</label>
                                </div>
                                <div class="col-lg-8 mt-n2">
                                    <div style="padding-left:0px">

                                        <p-autoComplete formControlName="cityname"
                                                        [suggestions]="deptList2"
                                                        (completeMethod)="searchCont2($event)"
                                                        field="CityName"
                                                        [dropdown]="true"
                                                        [size]="120"
                                                        placeholder="Search City Name"
                                                        [minLength]="1"
                                                        [style]="{'color': 'black'}"
                                                        [panelStyle]="{'background-color': 'white', 'box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px','margin-left':'3px','padding':'10px',}">
                                        </p-autoComplete>


                                        <small *ngIf="form.controls.cityname.touched && form.controls.cityname.errors?.required"
                                               class="p-error">City Name is required.</small>
                                    </div>

                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="useraddress">Address</label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="useraddress" formControlName="useraddress" placeholder="Address" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="area">Area</label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="area" formControlName="area" placeholder="Area" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <label for="mobile">Pincode<span style="color:red">*</span></label>
                                </div>

                                <div class="col-lg-8">
                                    <input type="text" id="Pincode" formControlName="Pincode" maxlength="6" placeholder="Pincode " oninput="this.value = this.value.replace(/[^0-9]/g, '');" required />
                                    <small style="color:red" class="text-danger" *ngIf="form.controls.Pincode.touched && form.controls.Pincode.errors?.required">Pincode is required</small>

                                </div>
                            </div>
                            <div class="row" *ngIf="form.controls['Rolestatus'].value && LoginDetails[0].RoleId === 4 || LoginDetails[0].RoleId !== 4">
                                <div class="col-lg-4">
                                    <label for="area">Message to blood Requester</label>
                                </div>
                                <div class="col-lg-8">
                                    <input type="text" id="Messagetorequester" formControlName="Messagetorequester" placeholder="Message to Blood Requester" />
                                </div>
                            </div>
                            <br />

                            <div class="row">

                                <!--<div class="col-lg-11" *ngIf="(LoginDetails[0]?.RoleId === 4) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                <span><b><span class="note">Note:</span> Enable or disable to become as a donor</b></span>
                            </div>
                            <div class="col-lg-11" *ngIf="(LoginDetails[0]?.RoleId === 2) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                <span><b><span class="note">Note:</span> Enable or disable to become as a volunteer</b></span>
                            </div>
                            <div class="col-lg-">
                                <label class="switch">
                                    <input type="checkbox" formControlName="Rolestatus" id="Status" [checked]="form.get('Rolestatus').value" (change)="toggleStatuss2($event.target.checked)">
                                    <span class="slider"></span>
                                </label>
                            </div>-->

                                <div class="col-lg-11" *ngIf="(LoginDetails[0]?.RoleId === 4) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                    <span><b><span class="note">Note:</span> Enable or disable to become as a donor</b></span>
                                </div>
                                <div class="col-lg-11" *ngIf="(LoginDetails[0]?.RoleId === 2) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                    <span><b><span class="note">Note:</span> Enable or disable to become as a volunteer</b></span>
                                </div>
                                <div class="col-lg-1" *ngIf="(LoginDetails[0]?.RoleId === 4) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="Rolestatus" id="Status" [checked]="form.get('Rolestatus').value" (change)="toggleStatuss2($event.target.checked)">
                                        <span class="slider"></span>
                                    </label>
                                </div>
                                <div class="col-lg-1" *ngIf="(LoginDetails[0]?.RoleId === 2) && (LoginDetails[0]?.Rolestatus === false || LoginDetails[0]?.Rolestatus === true)">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="Rolestatus" id="Status" [checked]="form.get('Rolestatus').value" (change)="toggleStatuss2($event.target.checked)">
                                        <span class="slider"></span>
                                    </label>
                                </div>


                            </div>




                            <div class="row">
                                <div class="col-lg-3 col-1"></div>
                                <div class="col-lg-3 col-4">
                                    <button class="btn1" style="background-color: red; color: white;" onclick="window.location.href = '/blooddonation';">Cancel</button>
                                </div>
                                <div class="col-lg-3 col-4">
                                    <button class="btn1" style="background-color: #1c60d9; color: white;">Update</button>
                                </div>
                                <div class="col-lg-3 col-1"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>




        </div>
    </div>



    <section class="block" id="sticky-cta">
        <div class="block__row block__row--sticky">
            <div class="block__col block__col--cta">
                <span class="block__cta-text">Want to work with us?</span>
                <a href="contact/index.html" class="no-ajax btn btn-default btn-default--let's fight">Contact Us</a>
            </div>
        </div>
    </section>


    <div class="full-width-overlay"></div>

    <div class="remodal" data-remodal-id="modal"
         data-remodal-options="hashTracking: false, closeOnOutsideClick: false">

        <button data-remodal-action="close" class="remodal-close"></button>
        <h2 class="section__title">Newsletter Signup</h2>

        <div class="remodal__body">
            <form action="#" class="form form--contact form--newsletter" data-mailchimp="//o3world.us5.list-manage.com/subscribe/post-json?u=a38f844f24fcb8c227bc905a5&amp;id=3180f95113">
                <div class="form__input">
                    <label for="form--contact__email" class="sr-only">Email</label>
                    <input id="form--contact__email" data-entry="entry.2122287328" class="form-control required" name="EMAIL" type="email" placeholder="Email">
                </div>
                <div style="display:none">
                    please leave this blank
                    <input type="text" class="form__hp" name="blankCheck" value="">
                </div>
                <button class="form__submit btn btn-submit btn-lg" id="newsletter-submit-button" type="submit">
                    <span class="form__submit__val"><span>Submit</span> <i class="icon-chevron-right"></i></span>
                    <span class="form__submit__thanks">Thanks!</span>
                </button>
            </form>
            <p class="form-message">
                <span class="message"></span>
                <a href="javascript:;" class="btn btn-primary" data-remodal-action="cancel">Got It</a>
            </p>
        </div>
    </div>


    <div id="js-mediaquery-reference"></div>
    <div data-remodal-id="youtube" id="youtube-modal">
        <div id="player"></div>
    </div>

</body>


</html>

<style>
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }
    @media (min-width: 992px) {
        .col-lg-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    @media (min-width: 992px) {
        .pane__inner.allow-scroll {
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }
    }
    body{
    }
    .patient {
        color: red;
        font-size: 25px;
        margin: 2% 0% -2% 0%;
    }

    input {
        padding:5px 12px;
        border: 1px solid rgba(73, 80, 87, 0.6);
        margin-bottom: 3%;
        width: 100%;
        border-radius: 5px;
    }

    .wrapper {
        position: fixed;
        top: 0;
        right: -110%;
        height: 100%;
        width: 100%;
        background: black;
        transition: all 0.6s ease-in-out;
    }

    #active:checked ~ .wrapper {
        right: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 20px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border:2px solid white;
    }

        .menu-btn span,
        .menu-btn:before,
        .menu-btn:after {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 30%;
            width: 40%;
            border-bottom: 3px solid white;
            transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .menu-btn:before {
            transform: translateY(-8px);
        }

        .menu-btn:after {
            transform: translateY(8px);
        }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    /* closing animation */
    #active:checked + .menu-btn span {
        transform: scaleX(0);
    }

    #active:checked + .menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked + .menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }

    input[type="checkbox"] {
        display: none;
    }

    .menu {
        text-align: center;
        padding-top: 100px; /* Adjust as needed */
    }

        .menu a {
            display: inline-block;
            margin: 0 10px; /* Adjust spacing between links */
            color: #fff;
            text-decoration: none;
            font-size: 20px;
        }
    .menu-btn:hover span,
    .menu-btn:hover:before,
    .menu-btn:hover:after {
        border-bottom-color: red;
    }

    .menu-btn:hover {
        background-color: white;
    }

    }

    #active:checked:hover + .menu-btn:before:hover {
        transform: rotate(45deg);
        border-color: red;
    }

    #active:checked:hover + .menu-btn:after:hover {
        transform: rotate(-45deg);
        border-color: red;
    }
  
    .h2, h2 {
        font-size: 32px;
    }

    .h1, .h2, .h3, h1, h2, h3 {
        margin-top: 24px;
        margin-bottom: 12px;
        font-weight: bold;
        color: white;
    }

    .bg-red--light {
        background-color: #e00000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--darker {
        background-color: #900;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--light {
        background-color: red;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 330px;
    }



    .bg-red {
        background-color: #ca0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .bg-red--dark {
        background-color: #ae0000;
        height: 330px;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    #active:checked + .menu-btn:hover:before {
        border-color: red;
    }

    #active:checked + .menu-btn:hover:after {
        border-color: red;
    }

    @media (min-width: 576px) {
        .col-sm-6 {
            /* flex: 0 0 auto; */
            width: 50%;
        }
    }

    @media (min-width: 768px) {
        .col-md-3 {
            /*flex: 0 0 auto;*/
            width: 25%;
        }
    }

</style>
