import { Component } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { StatedisrticrcityService } from '../Sevices/statedisrticrcity.service';
declare var $: any;

@Component({
  selector: 'app-oldagehome',
  templateUrl: './oldagehome.component.html',
  styleUrls: ['./oldagehome.component.scss']
})
export class OldagehomeComponent {
    public form: FormGroup;
    arr: any[];
    modalVisible = false;
    OldAgeHome: any = [];
    LoginDetails1: any; LoginDetails: any
    HomeUrl: any;
    pwd1: any;
    userid: any;
    Mobile: any;
    OTP: any;
    OTPtoMobile: any;
    logindata: any;
    otpSent: boolean = false;
    showLoginButton: boolean = true;
    showLogin: boolean = false;
    showLogin1: boolean = true; loginDet: any;
    countrydata: any;
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    Citydata: any = [];
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    deptList3: any;
    deptList4: any;
    deptList6: any;
    selectedState1: any;
    selectedState2: any;
    selectedState3: any;
    selectedState4: any;
    selectedState5: any;
    selectedState6: any;
    Areadata: any = [];
    State: any;
    distict: any;
    city: any; isDropdownOpen: boolean = false;
    bloodgroup: any;
    Address: any;
    lat = 20.5937;
    lng = 78.9629;
    zoom = 5;
    markers: any[] = [];
    dropdownOpen: boolean = true;
    letter: any; Donersdata: any;
    GallaryCategory: any; selectedGender: string | null = null;
    GalleryImages1: any; selectedpast: string;
    ShowContent: boolean = true;
    showingProfile: boolean = true;
    profiledata: any;

    constructor(public genralservice: GeneralService, private statedisrticrcityService: StatedisrticrcityService, public http: HttpClient, public router: Router, public fb: FormBuilder,
       private datePipe: DatePipe) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (Array.isArray(this.LoginDetails) && this.LoginDetails.length > 0) {
            this.letter = this.LoginDetails[0].FullName.charAt(0);
        } else if (this.LoginDetails && this.LoginDetails.FullName) {
            this.letter = this.LoginDetails.FullName.charAt(0);
        } else {
            this.letter = '';
        }

        this.form = this.fb.group({
            username: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['',],
            Gender: ['',],
            age: ['', Validators.required],
            //unitsofblood: [''],
            bloodgroup: ['', Validators.required],
            statename: [''],
            districtname: [''],
            cityname: [''],
            useraddress: [''],
            area: [''],
            Status: [''],
            Messagetorequester: [''],
            Weight: [''],
            PastDonation: ['', Validators.required],
            Lastdonatedate: [''],
            Pincode: ['', Validators.required],
          
             Statusphn: [''],
            Rolestatus: [''],
        });

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            // this.HomeUrl = 'https://localhost:44387/';
        });

    }


    ngOnInit(): void {
        this.gallerycat();
        this.getAreas();
        this.getBloodgroup();
        this.getprofile();
        //    this.loadDefaultData();
        //this.getstate();
        //this.getDistrict();
        //this.getCity();
        
       // this.getcountry();
        //  this.getOrphanage();
      //  this.getOldAgeHome();
       
        this.statedisrticrcityService.getstate().then(data => {
            this.Statedata = data;
            console.log("STATEDATA", data);
        }).catch(error => {
            console.error("Error fetching state data:", error);
        });

    }

    // drop down for states //
    searchCont(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList = [];
            for (var i = 0; i < this.Statedata.length; i++) {
                let country = this.Statedata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList = filtered;
        }
    }


    getDistrict() {
        console.log("SELECTED Dirsrict-->", this.selectedState1.StateId);
        this.statedisrticrcityService.getDistrict(this.selectedState1.StateId).then(districtData => {
            console.log("DISTRICTDATA", districtData);
            this.districtdata = districtData;
        }).catch(error => {
            console.error("Error fetching district data:", error);
        });

    }
    // drop down for District //
    searchCont1(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }


    getCity() {
        console.log("SELECTED DISTRICTID", this.selectedState2.DistrictID);
        this.statedisrticrcityService.getCity(this.selectedState2.DistrictID).then(cityData => {
            console.log("CITYDATA", cityData);
            this.Citydata = cityData;
        }).catch(error => {
            console.error("Error fetching city data:", error);
        });

    }

    // drop down for cities //
    searchCont2(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }

    toggleDropdown() {
        const dropdownContent = document.getElementById("dropdownContent");
        if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
        } else {
            dropdownContent.style.display = "block";
        }
    }

    checkLoginStatus() {
        // If user is logged in, set LoginDetails, otherwise set it to null
        if (this.LoginDetails!=null) {
            this.LoginDetails = {
                
            };
        } else {
            this.LoginDetails = null;
        }
    }

    checkmobile(Mobile) {
        
        if (Mobile.length == 10) {
            if (this.OTPtoMobile == this.OTP) {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", Mobile);


                var url = 'api/BG/checking_Mobile'

                this.genralservice.PostData(url, UploadFile).then(data => {
                    //this.logindata = JSON.stringify(data[0]);
                    //localStorage.setItem("LoginDetails", this.logindata);
                    
                    if (data != 'NOTEXIST') {
                        
                        this.pwd1 = data[0].Password;
                        this.userid = data[0].ID;
                        this.router.navigate(['/blooddonation']).then(() => { window.location.reload(); });
                        this.logindata = JSON.stringify(data[0]);
                        localStorage.setItem("LoginDetails", this.logindata);

                    }
                    else {

                        this.router.navigate(['/Donersform'])
                            // this.router.navigate(['/register', { Mobile: this.form.value.Mobile }])
                            .then(() => {
                                window.location.reload();
                            });
                    }
                })
            }
        }
    }

    openLoginModal() {
        // Show the modal here
        $('#myModal').modal('show');
    }

    SendOTPtoMobile() {
        
        if (!this.otpSent && this.Mobile && this.Mobile.length === 10) {
            this.OTPtoMobile = Math.floor(1000 + Math.random() * 9000);
            var Uploadfile = new FormData();
            Uploadfile.append("MobileNo", this.Mobile)
            Uploadfile.append("OTP", this.OTPtoMobile)
            var url = "api/BG/SendOtpToMobile";
            this.genralservice.PostData(url, Uploadfile).then((data: any) => {
                // Set otpSent to true once OTP is sent
                this.otpSent = true;
                this.showLogin1 = false;
                this.showLogin = true;
            })
        }
    }
    sharelog() {
        // Pass parameters to blooddonation route
        this.router.navigate(['/blooddonation'], { queryParams: { issharee: true } });
    }
    closeModal() {
        this.modalVisible = false;
        $('#myModal').modal('hide');
    }

    signOut() {
        // Remove the login details from localStorage
        localStorage.removeItem('LoginDetails');
        window.location.reload();
    }
 

    onSearchClick(selectedState1, selectedState2, selectedState3, selectedState6, selectedState5) {
        
        this.State = this.selectedState1 != undefined ? this.selectedState1.StateName : '';
        this.distict = this.selectedState2 != undefined ? this.selectedState2.DistrictName : '';
        this.city = this.selectedState3 != undefined ? this.selectedState3.CityName : '';
        this.bloodgroup = this.selectedState5 != undefined ? this.selectedState5.BLGName : '';
        this.Address = this.selectedState6 != undefined ? this.selectedState6.Address : '';

        var UploadFile = new FormData()
        UploadFile.append("Param1", this.State);
        UploadFile.append("Param2", this.distict);
        UploadFile.append("Param3", this.city);
        UploadFile.append("Param4", '');
        UploadFile.append("Param5", this.bloodgroup);
        UploadFile.append("Param6", this.Address);
        
        var url = "api/BG/Oldagehomedetails";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.OldAgeHome = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

            })
        this.ShowContent = !this.ShowContent;
    }

    loadDefaultData() {
        var UploadFile = new FormData();
        UploadFile.append("Param1", '');
        UploadFile.append("Param2", '');
        UploadFile.append("Param3", '');
        UploadFile.append("Param4", '');
        UploadFile.append("Param5", '');
        UploadFile.append("Param6", '');

        var url = "api/BG/Oldagehomedetails";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.OldAgeHome = data;
        }).catch(err => {
            this.genralservice.ShowAlert('ERROR', 'Something went Wrong', 'error');
        });
    }

  
    onStateSelect1(event) {
        console.log('Selected State:', this.selectedState1);
        // You can perform additional actions with the selected state here
    }

    onStateSelect2(event) {
        console.log('Selected Distict:', this.selectedState2);
        // You can perform additional actions with the selected state here
    }

    onStateSelect3(event) {
        console.log('Selected City:', this.selectedState3);
        // You can perform additional actions with the selected state here
    }

    onStateSelect4(event) {
        console.log('Selected Country:', this.selectedState4);
        // You can perform additional actions with the selected state here
    }

    onStateSelect5(event) {
        console.log('Selected BloodGroup:', this.selectedState5);
        // You can perform additional actions with the selected state here
    }

    onStateSelect6(event) {
        console.log('Selected Address:', this.selectedState6);
    }

    getAreas() {
        var url = "api/BG/Get_oldAreass";
        this.genralservice.GetData(url).then(data => {
            this.Areadata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    searchCont6(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList6 = [];
            for (var i = 0; i < this.Areadata.length; i++) {
                let country = this.Areadata[i];
                if (country.Address.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList6 = filtered;
        }
    }


    getBloodgroup() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/BloodGroupMaster_CRUDCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.bloodgroupdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for bloodgroup //
    searchCont3(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (var i = 0; i < this.bloodgroupdata.length; i++) {
                let country = this.bloodgroupdata[i];
                if (country.BLGName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList3 = filtered;
        }
    }

    // select country
    getcountry() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/BG/StatesMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {

            this.countrydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for states //
    searchCont4(event) {
        
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList4 = [];
            for (var i = 0; i < this.countrydata.length; i++) {
                let country = this.countrydata[i];
                if (country.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList4 = filtered;
        }
    }

    cleanUpUrl(url: string): string {
        // Remove leading and trailing spaces
        return url.trim();
    }

    pro() {

        if (this.LoginDetails[0].RoleId === 4 && (this.LoginDetails[0].Rolestatus === false || this.LoginDetails[0].Rolestatus == null)) {
            this.router.navigate(['/Volunteerprofile']);

        } else {
            this.showingProfile = !this.showingProfile;

        }
    }

    share() {
        this.router.navigate(['/Oldagehome']);
    
    }
    register() {
        this.router.navigate(['/Orphanage']);
   
    }
  
    requestlogin() {
        this.router.navigate(['/blooddonation']);
      
    }

    gallerycat() {
        var uploadfile = new FormData()
        uploadfile.append("Param", '2');
        var url = "api/BG/Get_GallaryImagedBasedOn_ID";
        this.genralservice.PostData(url, uploadfile).then(data => {
            this.GallaryCategory = data;
            this.GalleryImages1 = this.GallaryCategory[0].GalleryImages;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    Gallery() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            // Route to login page
            window.open('/Gallery', '_self');
            return; // Stop further execution
        }
        else {
            window.open('/Gallery', '_self');
        }

    }

    clearsearch() {
        this.loadDefaultData();
    }

    Donersform() {
        
        if (!this.form.value.username || !this.form.value.mobile || !this.form.value.bloodgroup || !this.form.value.Weight || !this.form.value.age || !this.form.value.Pincode || this.selectedGender === "" ||
            this.selectedpast === "") {
            this.genralservice.ShowAlert("ERROR", 'Please fill all required fields', 'error');
            return; // Stop further execution of the method
        }

        ;
        this.arr = [];
        const bloodgroup = this.form.value.bloodgroup && this.form.value.bloodgroup.BLGId !== undefined
            ? this.form.value.bloodgroup.BLGId
            : this.LoginDetails[0] && this.LoginDetails[0].BLGId !== undefined
                ? this.LoginDetails[0].BLGId
                : this.LoginDetails.BLGId !== undefined
                    ? this.LoginDetails.BLGId
                    : this.LoginDetails.BloodGroupId;

        const stateId = this.form.value.statename && this.form.value.statename.StateId !== undefined
            ? this.form.value.statename.StateId
            : this.LoginDetails[0] && this.LoginDetails[0].StateId !== undefined
                ? this.LoginDetails[0].StateId
                : this.LoginDetails.StateId !== undefined
                    ? this.LoginDetails.StateId
                    : this.LoginDetails.StateID;

        const districtId = this.form.value.districtname && this.form.value.districtname.DistrictID !== undefined
            ? this.form.value.districtname.DistrictID
            : this.LoginDetails[0] && this.LoginDetails[0].DistrictID !== undefined
                ? this.LoginDetails[0].DistrictID
                : this.LoginDetails.DistrictID !== undefined
                    ? this.LoginDetails.DistrictID
                    : this.LoginDetails.DistrictID;

        const cityId = this.form.value.cityname && this.form.value.cityname.CityId !== undefined
            ? this.form.value.cityname.CityId
            : this.LoginDetails[0] && this.LoginDetails[0].CityId !== undefined
                ? this.LoginDetails[0].CityId
                : this.LoginDetails.CityId !== undefined
                    ? this.LoginDetails.CityId
                    : this.LoginDetails.CityID;
        const lastDonateDate = this.selectedpast === 'No' ? null : this.form.value.Lastdonatedate || this.LoginDetails.Lastdonatedate;
        let updatedRoleId: string;

        if (this.LoginDetails && this.LoginDetails[0].RoleId != null) {
            if (this.LoginDetails[0].RoleId === 0) {
                updatedRoleId = '2';
            } else if (this.LoginDetails[0].RoleId > 0) {
                updatedRoleId = this.LoginDetails[0].RoleId;
            }
        }
        this.arr.push({
            RegId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].RegId : this.LoginDetails.RegId) : '',
            TokenId: this.LoginDetails ? (this.LoginDetails[0] ? this.LoginDetails[0].TokenId : this.LoginDetails.TokenId) : '',
            FullName: this.form.value.username,
            Phonenumber: this.form.value.mobile,
            Weight: this.form.value.Weight,
            Email: this.form.value.email || this.LoginDetails.Email,
            Gender: this.selectedGender || this.LoginDetails.Gender,
            Lastdonatedate: lastDonateDate,
            DOB: this.form.value.DOB || this.LoginDetails.DOB,
            Pincode: this.form.value.Pincode || this.LoginDetails.Pincode,
            PastDonation: this.selectedpast,
            age: this.form.value.age || this.LoginDetails.Age,
            BloodGroupId: bloodgroup,
            StateId: stateId,
            DistrictId: districtId,
            CityId: cityId,
            UserAddress: this.form.value.useraddress || this.LoginDetails.UserAddress,
            Area: this.form.value.area || this.LoginDetails.Area,
            Messagetorequester: this.form.value.Messagetorequester || this.LoginDetails.Messagetorequester,
            RoleId: updatedRoleId,
            Status: this.form.value.Status || this.LoginDetails.Status,
            Statusphn: this.form.value.Statusphn || this.LoginDetails.Statusphn,
            Rolestatus: this.form.value.Rolestatus || this.LoginDetails.Rolestatus,
        });

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');
        var url = "api/BG/Insert_Update_DonersForm";
        this.genralservice.PostData(url, uploadfile).then(data => {
            
            if (data == "SUCCESS") {
                if (this.flag == 2) {
                    this.genralservice.ShowAlert("ERROR", 'Profile Updated Succesfully', 'error');
                } else {

                    localStorage.setItem('LoginDetails', JSON.stringify(this.arr));
                    this.genralservice.ShowAlert("SUCCESS", 'Profile Updated Succesfully', 'success');
                    this.router.navigate(['/blooddonation']).then(() => {
                        window.location.reload();

                    });

                }
                this.form.reset();
            } else {
                this.genralservice.ShowAlert("ERROR", 'Something went wrong. Please check validation fields', 'error');
            }
        });
    }
    toggleStatuss(checked: boolean) {
        this.form.controls['Status'].setValue(checked);
    }


    toggleStatuss1(checked: boolean) {
        this.form.controls['Statusphn'].setValue(checked);
    }


    toggleStatuss2(checked: boolean) {
        this.form.controls['Rolestatus'].setValue(checked);
    }
    selectGender(gender: string) {
        
        this.selectedGender = gender;
    }
    PastDonation(Past: string) {
        
        this.selectedpast = Past;
    }

    getprofile() {
        debugger
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.LoginDetails ? (this.LoginDetails.Phonenumber || (this.LoginDetails[0] && this.LoginDetails[0].Phonenumber)) : '');

        var url = "api/BG/checking_Mobile";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.profiledata = data;
            this.bindFormData(this.profiledata);

            this.LoginDetails = this.profiledata;
            // Call bindFormData with the received data
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }
    bindFormData(data) {
        
        if (data) {
            
            this.selectedGender = data[0].Gender;
            this.selectedpast = data[0].PastDonation;
            const lastDonateDate = data[0].Lastdonatedate && !isNaN(Date.parse(data[0].Lastdonatedate)) ?
                this.datePipe.transform(new Date(data[0].Lastdonatedate), 'yyyy-MM-dd') : null;

            const dob = data[0].DOB && !isNaN(Date.parse(data[0].DOB)) ?
                this.datePipe.transform(new Date(data[0].DOB), 'yyyy-MM-dd') : null;

            
            this.form.patchValue({

                username: data[0].FullName,
                mobile: data[0].Phonenumber,
                email: data[0].Email,
                age: data[0].Age,
                bloodgroup: data[0].BLGName,
                BloodGroupId: data[0].BLGId,
                statename: data[0].StateName,
                StateId: data[0].StateId,
                districtname: data[0].DistrictName,
                DistrictId: data[0].DistrictId,
                cityname: data[0].CityName,
                CityId: data[0].CityId,
                useraddress: data[0].UserAddress,
                area: data[0].Area,
                Status: data[0].Status || data.Status,
                Weight: data[0].Weight,
                Pincode: data[0].Pincode,
                Statusphn: data[0].Statusphn || data.Statusphn,
                Rolestatus: data[0].Rolestatus || data.Rolestatus,
                Lastdonatedate: lastDonateDate,
                Messagetorequester: data[0].Messagetorequester
            });
        }
    }

    getDistrict1() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            StateId: this.form.value.statename.StateId
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/DistrictMaster_crudCustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.districtdata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for District //
    searchCont11(event) {
        debugger
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList1 = [];
            for (var i = 0; i < this.districtdata.length; i++) {
                let country = this.districtdata[i];
                if (country.DistrictName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList1 = filtered;
        }
    }

    getCity1() {
        this.arr = []
        this.arr.push({
            RegId: '1',
            TokenId: '4A9493F9-8CD2-42F8-90ED-49C6B28DC544',
            DistrictId: this.form.value.districtname.DistrictID,
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '5');
        var url = "api/BG/CitiesMaster_Crudcustomer";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.Citydata = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    // drop down for cities //
    searchCont21(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList2 = [];
            for (var i = 0; i < this.Citydata.length; i++) {
                let country = this.Citydata[i];
                if (country.CityName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(country);
                }
            }

            this.deptList2 = filtered;
        }
    }
}

 
 
 
